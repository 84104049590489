import { call, put, takeEvery } from "redux-saga/effects";

// Leads Redux States
import { IMPORT_CSV, IMPORT_EXCEL } from "./actionTypes";

import { importCSVFail, importCSVSuccess, importExcelFail, importExcelSuccess } from "./actions";
import * as url from "../../helpers/url_helper";
import { post } from "../../helpers/api_helper";

export function* fetchImportExcel({payload: request}) {
  try {
    const response = yield call(handleImportExcel,request);
    yield put(importExcelSuccess(response));
  } catch (error) {
    yield put(importExcelFail(error));
  }
}

const handleImportExcel = (file) => post(url.IMPORT_EXCEL,file)

export function* fetchImportCSV({payload: request}) {
  try {
    const response = yield call(handleImportCSV,request);
    yield put(importCSVSuccess(response));
  } catch (error) {
    yield put(importCSVFail(error));
  }
}

const handleImportCSV = (request) => post(url.IMPORT_CSV,request)

function* importSaga() {
  yield takeEvery(IMPORT_CSV, fetchImportCSV);
  yield takeEvery(IMPORT_EXCEL, fetchImportExcel);
}

export default importSaga;
