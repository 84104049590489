import {
    GET_RESOURCES_FAIL,
    GET_RESOURCES_SUCCESS,
    ADD_RESOURCE_SUCCESS,
    ADD_RESOURCE_FAIL,
    UPDATE_RESOURCE_SUCCESS,
    UPDATE_RESOURCE_FAIL,
    DELETE_RESOURCE_SUCCESS,
    DELETE_RESOURCE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    resources: [],
    error: {},
};

const Resource = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_RESOURCES_SUCCESS:
            return {
                ...state,
                resources: action.payload,
            };

        case GET_RESOURCES_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_RESOURCE_SUCCESS:
            return {
                ...state,
                resources: [...state.resources, action.payload],
            };

        case ADD_RESOURCE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_RESOURCE_SUCCESS:
            return {
                ...state,
                resources: state.resources.map(resource =>
                    resource.id.toString() === action.payload.id.toString()
                        ? { resource, ...action.payload }
                        : resource
                ),
            };

        case UPDATE_RESOURCE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_RESOURCE_SUCCESS:
            return {
                ...state,
                resources: state.resources.filter(
                    resource => resource.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_RESOURCE_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default Resource;
