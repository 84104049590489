
/* RESOURCES */
export const GET_RESOURCES = "GET_RESOURCES"
export const GET_RESOURCES_SUCCESS = "GET_RESOURCES_SUCCESS"
export const GET_RESOURCES_FAIL = "GET_RESOURCES_FAIL"

/**
 * add RESOURCE
 */
export const ADD_NEW_RESOURCE = "ADD_NEW_RESOURCE"
export const ADD_RESOURCE_SUCCESS = "ADD_RESOURCE_SUCCESS"
export const ADD_RESOURCE_FAIL = "ADD_RESOURCE_FAIL"

/**
 * Edit RESOURCE
 */
export const UPDATE_RESOURCE = "UPDATE_RESOURCE"
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS"
export const UPDATE_RESOURCE_FAIL = "UPDATE_RESOURCE_FAIL"

/**
 * Delete RESOURCE
 */
export const DELETE_RESOURCE = "DELETE_RESOURCE"
export const DELETE_RESOURCE_SUCCESS = "DELETE_RESOURCE_SUCCESS"
export const DELETE_RESOURCE_FAIL = "DELETE_RESOURCE_FAIL"
