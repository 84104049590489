import {
    GET_MEDICAL_OPERATIONS_FAIL,
    GET_MEDICAL_OPERATIONS_SUCCESS,
    ADD_MEDICAL_OPERATION_SUCCESS,
    ADD_MEDICAL_OPERATION_FAIL,
    UPDATE_MEDICAL_OPERATION_SUCCESS,
    UPDATE_MEDICAL_OPERATION_FAIL,
    DELETE_MEDICAL_OPERATION_SUCCESS,
    DELETE_MEDICAL_OPERATION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    medicalOperations: [],
    error: {},
};

const MedicalOperation = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MEDICAL_OPERATIONS_SUCCESS:
            return {
                ...state,
                medicalOperations: action.payload,
            };

        case GET_MEDICAL_OPERATIONS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_MEDICAL_OPERATION_SUCCESS:
            return {
                ...state,
                medicalOperations: [...state.medicalOperations, action.payload],
            };

        case ADD_MEDICAL_OPERATION_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_MEDICAL_OPERATION_SUCCESS:
            return {
                ...state,
                medicalOperations: state.medicalOperations.map(medicalOperation =>
                    medicalOperation.id.toString() === action.payload.id.toString()
                        ? { medicalOperation, ...action.payload }
                        : medicalOperation
                ),
            };

        case UPDATE_MEDICAL_OPERATION_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_MEDICAL_OPERATION_SUCCESS:
            return {
                ...state,
                medicalOperations: state.medicalOperations.filter(
                    medicalOperation => medicalOperation.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_MEDICAL_OPERATION_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default MedicalOperation;
