import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Button, Card, CardBody, Row } from "reactstrap";

function CustomDualList(props) {
  const [leftList, setLeftList] = useState(props.leftList || []);
  const [rightList, setRightList] = useState(props.rightList || []);

  const handleItemClick = (item, sourceList) => {
    const updatedSourceList = sourceList.map((element) =>
      element.id === item.id ? { ...element, selected: !element.selected } : element
    );
    sourceList === leftList ? setLeftList(updatedSourceList) : setRightList(updatedSourceList);
  };

  const moveSelectedItems = (sourceList, destinationList) => {
    const selectedItems = sourceList.filter((item) => item.selected === true);
    const updatedSourceList = sourceList.filter((item) => !item.selected === true);
    const updatedDestinationList = [...destinationList, ...selectedItems];
    const newMappedUpdatedSourceList = updatedSourceList.map((element) => ({ ...element, selected: false }));
    const newMappedUpdatedDestinationList = updatedDestinationList.map((element) => ({ ...element, selected: false }));

    sourceList === leftList ? setLeftList(newMappedUpdatedSourceList) : setRightList(newMappedUpdatedSourceList);
    destinationList === leftList ? setLeftList(newMappedUpdatedDestinationList) : setRightList(newMappedUpdatedDestinationList);
  };

  const moveAllItems = (sourceList, destinationList) => {
    const updatedSourceList = sourceList.map((item) => ({ ...item, selected: true }));
    const updatedDestinationList = [...destinationList, ...updatedSourceList];
    const newMappedUpdatedSourceList = [];
    const newMappedUpdatedDestinationList = updatedDestinationList.map((element) => ({ ...element, selected: false }));

    sourceList === leftList ? setLeftList(newMappedUpdatedSourceList) : setRightList(newMappedUpdatedSourceList);
    destinationList === leftList ? setLeftList(newMappedUpdatedDestinationList) : setRightList(newMappedUpdatedDestinationList);
  };

  return (
    <Card className="card">
      <CardBody className="card-body">
        <Row>
          <div className="col">
            <h4>{props.leftTitle}</h4>
            <div className="border border-1 border-secondary border-opacity-25">
              <ul className="list-unstyled">
                {leftList.map((item, key) => {
                    if (item.isMain) {
                      return (
                        <li className="event-list" key={item.id} onClick={() => handleItemClick(item, leftList)}>
                          <div className={item.selected && "bg-info bg-opacity-25"}>
                            <label
                              className="form-check-label"
                              htmlFor={item.id}
                            >
                              {item.label}{item.selected && "(SELECTED)"}
                            </label>
                          </div>
                        </li>
                      );
                    } else {
                      return (
                        <li className="event-list" key={item.id} onClick={() => handleItemClick(item, leftList)}>
                          <div className={item.selected && "bg-info bg-opacity-25"}>
                            <label
                              className="form-check-label"
                              htmlFor={item.id}
                            >
                              {item.label}{item.selected && "(SELECTED)"}
                            </label>
                          </div>
                        </li>
                      );
                    }
                  }
                )}
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="btn-group-vertical">
              <Button size="sm" outline color="info" onClick={() => moveAllItems(leftList, rightList)}>{">>"}</Button>
              <Button size="sm" outline color="info"
                      onClick={() => moveSelectedItems(leftList, rightList)}>{">"}</Button>
              <Button size="sm" outline color="info"
                      onClick={() => moveSelectedItems(rightList, leftList)}>{"<"}</Button>
              <Button size="sm" outline color="info" onClick={() => moveAllItems(rightList, leftList)}>{"<<"}</Button>
            </div>
          </div>
          <div className="col">
            <h4>{props.rightTitle}</h4>
            <div className="border border-1 border-secondary border-opacity-25">
              <ul className="list-unstyled">
                {rightList.map((item) => (
                  <li className="event-list" key={item.id} onClick={() => handleItemClick(item, rightList)}>
                    <div className={item.selected && "bg-info bg-opacity-25"}>
                      <label
                        className="form-check-label"
                        htmlFor={item.id}
                      >
                        {item.label}{item.selected && "(SELECTED)"}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

CustomDualList.propTypes = {
  t: PropTypes.any,
  leftList: PropTypes.any,
  rightList: PropTypes.any,
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  hasSub: PropTypes.bool
};

export default withTranslation()(CustomDualList);