import React, { useEffect, useState } from "react";
//Import Breadcrumb
import PropTypes from "prop-types";
import { Toast, ToastContainer } from "react-bootstrap";
import { withTranslation } from "react-i18next";

const Notification = (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleClose = (val) => {
    setShow(val);
    props.callbackShow(val);
  };


  return (
    <div>
      <ToastContainer className="p-3" position="top-end">
        <Toast className="d-inline-block m-1" bg={props.type.toLowerCase()} onClose={()=>handleClose(false)} show={show}
               delay={3000}
               autohide>
          <Toast.Header>
            <strong className="me-auto">{props.t(props.type)}</strong>
          </Toast.Header>
          <Toast.Body>
            <h6 style={{ color: "white", display: "inline", marginRight: "20px" }}>{props.message}</h6>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

Notification.propTypes = {
  t: PropTypes.any,
  type: PropTypes.any,
  message: PropTypes.any,
  show: PropTypes.bool,
  callbackShow: PropTypes.func
};

export default withTranslation()(Notification);
