import { call, put, takeEvery } from "redux-saga/effects";

// PatientAppointments Redux States
import {
  ADD_NEW_PATIENT_APPOINTMENT,
  DELETE_PATIENT_APPOINTMENT,
  GET_PATIENT_APPOINTMENT_CRITERIA,
  GET_PATIENT_APPOINTMENTS,
  GET_USER_PATIENT_APPOINTMENTS,
  UPDATE_PATIENT_APPOINTMENT,
  ADD_NEW_PATIENT_WITH_APPT
} from "./actionTypes";

import {
  addPatientAppointmentFail,
  addPatientAppointmentSuccess,
  deletePatientAppointmentFail,
  deletePatientAppointmentSuccess,
  getPatientAppointmentCriteriaFail,
  getPatientAppointmentCriteriaSuccess,
  getPatientAppointmentsFail,
  getPatientAppointmentsSuccess,
  getUserPatientAppointmentsFail,
  getUserPatientAppointmentsSuccess,
  updatePatientAppointmentFail,
  updatePatientAppointmentSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";
import { addNewPatientWithAppointmentFail, addNewPatientWithAppointmentSuccess } from "./actions";

function* fetchPatientAppointments({ id }) {
  try {
    const response = yield call(getPatientAppointments, id);
    yield put(getPatientAppointmentsSuccess(response));
  } catch (error) {
    yield put(getPatientAppointmentsFail(error));
  }
}

const getPatientAppointments = (id) => get(`${url.GET_PATIENT_APPOINTMENT}/${id}`);

function* onUpdatePatientAppointment({ payload: patientAppointment }) {
  try {
    const response = yield call(updatePatientAppointment, patientAppointment);
    yield put(updatePatientAppointmentSuccess(response));
  } catch (error) {
    yield put(updatePatientAppointmentFail(error));
  }
}

const updatePatientAppointment = (patientAppointment) => post(url.UPDATE_PATIENT_APPOINTMENT, patientAppointment);

function* onDeletePatientAppointment({ id }) {
  try {
    const response = yield call(deletePatientAppointment, id);
    yield put(deletePatientAppointmentSuccess(response,id));
  } catch (error) {
    yield put(deletePatientAppointmentFail(error));
  }
}

const deletePatientAppointment = (id) => del(`${url.DELETE_PATIENT_APPOINTMENT}/${id}`);

function* onAddNewPatientAppointment({ payload: patientAppointment }) {
  try {
    const response = yield call(addNewPatientAppointment, patientAppointment);

    yield put(addPatientAppointmentSuccess(response));
  } catch (error) {
    yield put(addPatientAppointmentFail(error));
  }
}

const addNewPatientAppointment = (patientAppointment) => post(url.ADD_NEW_PATIENT_APPOINTMENT, patientAppointment);

function* fetchUserPatientAppointments({ id }) {
  try {
    const response = yield call(getUserPatientAppointments, id);
    yield put(getUserPatientAppointmentsSuccess(response));
  } catch (error) {
    yield put(getUserPatientAppointmentsFail(error));
  }
}

const getUserPatientAppointments = (id) => get(`${url.GET_USER_PATIENT_APPOINTMENTS}/${id}`);

function* fetchPatientAppointmentCriteria({ payload: body }) {
  try {
    const response = yield call(getPatientAppointmentCriteria, body);
    yield put(getPatientAppointmentCriteriaSuccess(response));
  } catch (error) {
    yield put(getPatientAppointmentCriteriaFail(error));
  }
}

const getPatientAppointmentCriteria = (body) => post(url.GET_PATIENT_APPOINTMENT_CRITERIA, body);

function* onAddNewPatientWithAppt({ payload: patient }) {
  try {
    const response = yield call(addNewPatientWithAppt, patient);
    yield put(addNewPatientWithAppointmentSuccess(response));
  } catch (error) {
    yield put(addNewPatientWithAppointmentFail(error));
  }
}

const addNewPatientWithAppt = (patient) => post(url.SAVE_WITH_APPT, patient);

function* patientAppointmentsSaga() {
  yield takeEvery(GET_PATIENT_APPOINTMENTS, fetchPatientAppointments);
  yield takeEvery(ADD_NEW_PATIENT_APPOINTMENT, onAddNewPatientAppointment);
  yield takeEvery(UPDATE_PATIENT_APPOINTMENT, onUpdatePatientAppointment);
  yield takeEvery(DELETE_PATIENT_APPOINTMENT, onDeletePatientAppointment);
  yield takeEvery(GET_USER_PATIENT_APPOINTMENTS, fetchUserPatientAppointments);
  yield takeEvery(GET_PATIENT_APPOINTMENT_CRITERIA, fetchPatientAppointmentCriteria);
  yield takeEvery(ADD_NEW_PATIENT_WITH_APPT, onAddNewPatientWithAppt);
}

export default patientAppointmentsSaga;
