import {
    GET_OPERATION_CATEGORIES,
    GET_OPERATION_CATEGORIES_FAIL,
    GET_OPERATION_CATEGORIES_SUCCESS,
    ADD_NEW_OPERATION_CATEGORY,
    ADD_OPERATION_CATEGORY_SUCCESS,
    ADD_OPERATION_CATEGORY_FAIL,
    UPDATE_OPERATION_CATEGORY,
    UPDATE_OPERATION_CATEGORY_SUCCESS,
    UPDATE_OPERATION_CATEGORY_FAIL,
    DELETE_OPERATION_CATEGORY,
    DELETE_OPERATION_CATEGORY_SUCCESS,
    DELETE_OPERATION_CATEGORY_FAIL,
} from "./actionTypes"

export const getOperationCategories = () => ({
    type: GET_OPERATION_CATEGORIES,
})

export const getOperationCategoriesSuccess = doctors => ({
    type: GET_OPERATION_CATEGORIES_SUCCESS,
    payload: doctors,
})

export const getOperationCategoriesFail = error => ({
    type: GET_OPERATION_CATEGORIES_FAIL,
    payload: error,
})

export const addNewOperationCategory = doctor => ({
    type: ADD_NEW_OPERATION_CATEGORY,
    payload: doctor,
})

export const addOperationCategorySuccess = doctor => ({
    type: ADD_OPERATION_CATEGORY_SUCCESS,
    payload: doctor,
})

export const addOperationCategoryFail = error => ({
    type: ADD_OPERATION_CATEGORY_FAIL,
    payload: error,
})

export const updateOperationCategory = doctor => ({
    type: UPDATE_OPERATION_CATEGORY,
    payload: doctor,
})

export const updateOperationCategorySuccess = doctor => ({
    type: UPDATE_OPERATION_CATEGORY_SUCCESS,
    payload: doctor,
})

export const updateOperationCategoryFail = error => ({
    type: UPDATE_OPERATION_CATEGORY_FAIL,
    payload: error,
})

export const deleteOperationCategory = id => ({
    type: DELETE_OPERATION_CATEGORY,
    id,
})

export const deleteOperationCategorySuccess = (doctor,req) => ({
    type: DELETE_OPERATION_CATEGORY_SUCCESS,
    payload: doctor,
    req:req,
})

export const deleteOperationCategoryFail = error => ({
    type: DELETE_OPERATION_CATEGORY_FAIL,
    payload: error,
})