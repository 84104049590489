import {
    ADD_DEPARTMENT_FAIL,
    ADD_DEPARTMENT_SUCCESS,
    ADD_NEW_DEPARTMENT,
    DELETE_DEPARTMENT,
    DELETE_DEPARTMENT_FAIL,
    DELETE_DEPARTMENT_SUCCESS,
    GET_DEPARTMENTS,
    GET_DEPARTMENTS_FAIL,
    GET_DEPARTMENTS_SUCCESS,
    UPDATE_DEPARTMENT,
    UPDATE_DEPARTMENT_FAIL,
    UPDATE_DEPARTMENT_SUCCESS
} from "./actionTypes";

export const getDepartments = () => ({
    type: GET_DEPARTMENTS,
})

export const getDepartmentsSuccess = groups => ({
    type: GET_DEPARTMENTS_SUCCESS,
    payload: groups,
})

export const getDepartmentsFail = error => ({
    type: GET_DEPARTMENTS_FAIL,
    payload: error,
})

export const addNewDepartment = group => ({
    type: ADD_NEW_DEPARTMENT,
    payload: group,
})

export const addDepartmentSuccess = group => ({
    type: ADD_DEPARTMENT_SUCCESS,
    payload: group,
})

export const addDepartmentFail = error => ({
    type: ADD_DEPARTMENT_FAIL,
    payload: error,
})

export const updateDepartment = group => ({
    type: UPDATE_DEPARTMENT,
    payload: group,
})

export const updateDepartmentSuccess = group => ({
    type: UPDATE_DEPARTMENT_SUCCESS,
    payload: group,
})

export const updateDepartmentFail = error => ({
    type: UPDATE_DEPARTMENT_FAIL,
    payload: error,
})

export const deleteDepartment = id => ({
    type: DELETE_DEPARTMENT,
    id,
})

export const deleteDepartmentSuccess = (group,req) => ({
    type: DELETE_DEPARTMENT_SUCCESS,
    payload: group,
    req: req,
})

export const deleteDepartmentFail = error => ({
    type: DELETE_DEPARTMENT_FAIL,
    payload: error,
})
