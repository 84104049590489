import { call, put, takeEvery } from "redux-saga/effects";

// Patients Redux States
import {
  ADD_NEW_PATIENT, ADD_NEW_PATIENT_WITH_APPT,
  ASSIGN_DOCTOR,
  DELETE_PATIENT,
  GET_PATIENT_BY_ID,
  GET_PATIENTS,
  PATIENT_CRITERIA,
  UPDATE_PATIENT
} from "./actionTypes";

import {
  addNewPatientWithAppointmentFail,
  addNewPatientWithAppointmentSuccess,
  addPatientFail,
  addPatientSuccess,
  assignDoctorFail,
  assignDoctorSuccess,
  deletePatientFail,
  deletePatientSuccess,
  getPatientByIdFail,
  getPatientByIdSuccess,
  getPatientsFail,
  getPatientsSuccess,
  patientCriteriaFail,
  patientCriteriaSuccess,
  updatePatientFail,
  updatePatientSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchPatients() {
  try {
    const response = yield call(getPatients);
    yield put(getPatientsSuccess(response));
  } catch (error) {
    yield put(getPatientsFail(error));
  }
}

const getPatients = () => get(url.GET_PATIENTS);

function* onUpdatePatient({ payload: patient }) {
  try {
    const response = yield call(updatePatient, patient);
    yield put(updatePatientSuccess(response));
  } catch (error) {
    yield put(updatePatientFail(error));
  }
}

const updatePatient = (patient) => post(url.UPDATE_PATIENT, patient);

function* onDeletePatient({ payload: patient }) {
  try {
    const response = yield call(deletePatient, patient);
    if (response.length > 0) {
      yield put(deletePatientSuccess(response));
    } else {
      yield put(deletePatientSuccess(patient));
    }
  } catch (error) {
    yield put(deletePatientFail(error));
  }
}

const deletePatient = (patient) => del(url.DELETE_PATIENT, patient);

function* onAddNewPatient({ payload: patient }) {
  try {
    const response = yield call(addNewPatient, patient);
    yield put(addPatientSuccess(response));
  } catch (error) {
    yield put(addPatientFail(error));
  }
}

const addNewPatient = (patient) => post(url.ADD_NEW_PATIENT, patient);

function* onGetPatient({ id }) {
  try {
    const response = yield call(getPatientById, id);
    yield put(getPatientByIdSuccess(response));
  } catch (error) {
    yield put(getPatientByIdFail(error));
  }
}

const getPatientById = (id) => get(`${url.GET_PATIENT_BY_ID}/${id}`);

function* onAssignDoctor({ payload: request }) {
  try {
    const response = yield call(assignDoctor, request);
    yield put(assignDoctorSuccess(response));
  } catch (error) {
    yield put(assignDoctorFail(error));
  }
}

const assignDoctor = (req) => post(url.SET_DOCTOR, req);

function* fetchPatientCriteria({ payload: req }) {

  try {
    const response = yield call(getPatientCriteria, req);
    yield put(patientCriteriaSuccess(response, req));
  } catch (error) {
    yield put(patientCriteriaFail(error));
  }
}

const getPatientCriteria = (req) => post(url.PATIENTS_CRITERIA, req);

function* patientsSaga() {
  yield takeEvery(GET_PATIENTS, fetchPatients);
  yield takeEvery(ADD_NEW_PATIENT, onAddNewPatient);
  yield takeEvery(UPDATE_PATIENT, onUpdatePatient);
  yield takeEvery(DELETE_PATIENT, onDeletePatient);
  yield takeEvery(GET_PATIENT_BY_ID, onGetPatient);
  yield takeEvery(ASSIGN_DOCTOR, onAssignDoctor);
  yield takeEvery(PATIENT_CRITERIA, fetchPatientCriteria);
}

export default patientsSaga;
