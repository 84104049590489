import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../hooks/withRouter";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";
import {
  deleteTranslate as onDeleteTranslate,
  getTranslates as onGetTranslates,
  translatesSaveAll,
  updateTranslate as onUpdateTranslate
} from "store/translate/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Code, Description, Name } from "./translateCol";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import { getLanguages as onGetLanguages } from "../../store/language/actions";
import Select from "react-select";
import { getCategories } from "../../store/category/actions";
import { getSegments } from "../../store/segment/actions";
import { getReferences } from "../../store/reference/actions";
import { getResources } from "../../store/resource/actions";
import { getDepartments } from "../../store/department/actions";
import { getSections } from "../../store/section/actions";

const Translate = props => {

  //meta title
  document.title = `CRM | ${props.t("Translates")}`;

  const dispatch = useDispatch();

  const initialTranslate = {
    type: "",
    sourceId: "",
    language: {},
    name: "",
    value: "",
    status: "ACTIVE",
    deletable: false
  };

  const typeOptions = [
    { label: `${props.t("CATEGORY")}`, value: "CATEGORY" },
    { label: `${props.t("SEGMENT")}`, value: "SEGMENT" },
    { label: `${props.t("REFERENCE")}`, value: "REFERENCE" },
    { label: `${props.t("RESOURCE")}`, value: "RESOURCE" },
    { label: `${props.t("DEPARTMENT")}`, value: "DEPARTMENT" },
    { label: `${props.t("SECTION")}`, value: "SECTION" }
  ];

  const [modal, setModal] = useState(false);
  const [translateList, setTranslateList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [translate, setTranslate] = useState(initialTranslate);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [languageOptions, setLanuageOptions] = useState([]);
  const [allLanguageOptions, setAllLanguageOptions] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");

  const { translates, error } = useSelector(state => ({
    translates: state.Translate.translates,
    error: state.Translate.error
  }));

  useEffect(() => {
    dispatch(onGetTranslates());
  }, []);

  const { languages } = useSelector(state => ({
    languages: state.Language.languages
  }));

  useEffect(() => {
    dispatch(onGetLanguages());
  }, []);

  useEffect(() => {
    const langs = languages && languages.length > 0 && languages.map(lang => {
      return { label: lang.code, value: lang };
    });
    setLanuageOptions(langs);
    setAllLanguageOptions(langs);
  }, [languages]);

  const { categories } = useSelector(state => ({
    categories: state.Category.categories
  }));

  useEffect(() => {
    setSourceOptions(categories && categories.length > 0 && categories.map(item => {
      return { label: item.name, value: item };
    }));
  }, [categories]);

  const { segments } = useSelector(state => ({
    segments: state.Segment.segments
  }));

  useEffect(() => {
    setSourceOptions(segments && segments.length > 0 && segments.map(item => {
      return { label: item.name, value: item };
    }));
  }, [segments]);

  const { references } = useSelector(state => ({
    references: state.Reference.references
  }));

  useEffect(() => {
    setSourceOptions(references && references.length > 0 && references.map(item => {
      return { label: item.name, value: item };
    }));
  }, [references]);

  const { resources } = useSelector(state => ({
    resources: state.Resource.resources
  }));

  useEffect(() => {
    setSourceOptions(resources && resources.length > 0 && resources.map(item => {
      return { label: item.name, value: item };
    }));
  }, [resources]);

  const { departments } = useSelector(state => ({
    departments: state.Department.departments
  }));

  useEffect(() => {
    setSourceOptions(departments && departments.length > 0 && departments.map(item => {
      return { label: item.name, value: item };
    }));
  }, [departments]);

  const { sections } = useSelector(state => ({
    sections: state.Section.sections
  }));

  useEffect(() => {
    setSourceOptions(sections && sections.length > 0 && sections.map(item => {
      return { label: item.name, value: item };
    }));
  }, [sections]);

  const handleSubmit = (values) => {
    if (isEdit) {
      dispatch(onUpdateTranslate(translate));
    } else {
      dispatch(translatesSaveAll(translateList.filter(item => !item.id)));
    }
    toggle();
  };

  const handleTranslateClick = arg => {
    setIsEdit(true);
    const translateRow = arg;
    setTranslate(translateRow);
    const source = findSource(translateRow.sourceId, translateRow.type);
    setSelectedSource({ label: source && source.name, value: source });
    setSelectedType({ label: translateRow.type, value: translateRow.type });
    setSelectedLanguage({ label: translateRow.language.code, value: translateRow.language.id });
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Type")}`,
        accessor: "type",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Name")}`,
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Language")}`,
        accessor: "language.code",
        filterable: true,
        Cell: (cellProps) => {
          return <Code {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Value")}`,
        accessor: "value",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const translateData = cellProps.row.original;
                  handleTranslateClick(translateData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const translateData = cellProps.row.original;
                  onClickDelete(translateData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const handleChange = (e) => {
    setTranslate({
      ...translate,
      [e.target.name]: e.target.value
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setTranslate(null);
      setSelectedType(null);
      setSelectedLanguage(null);
      setSelectedSource(null);
    } else {
      setModal(true);
    }
  };

  //delete translate
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (translate) => {
    setTranslate(translate);
    setDeleteModal(true);
  };

  const handleDeleteTranslate = () => {
    if (translate.id) {
      dispatch(onDeleteTranslate(translate.id));
      setDeleteModal(false);
    }
  };

  const handleTranslateClicks = () => {
    setTranslateList([]);
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetTranslates());
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined && Object.entries(error).length > 0) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  useEffect(() => {
    if (selectedType && selectedType.value === "CATEGORY") {
      dispatch(getCategories());
    } else if (selectedType && selectedType.value === "SEGMENT") {
      dispatch(getSegments());
    } else if (selectedType && selectedType.value === "REFERENCE") {
      dispatch(getReferences());
    } else if (selectedType && selectedType.value === "RESOURCE") {
      dispatch(getResources());
    } else if (selectedType && selectedType.value === "DEPARTMENT") {
      dispatch(getDepartments());
    } else if (selectedType && selectedType.value === "SECTION") {
      dispatch(getSections());
    }
  }, [selectedType]);

  function onChangeType(event) {
    setSelectedType(event);
    setTranslate({ ...translate, type: event.value });
  }

  function onChangeLanguage(event) {
    setSelectedLanguage(event);
    setTranslate({ ...translate, language: { id: event.value.id } });
  }

  function onChangeSource(event) {
    setSelectedSource(event);
    setTranslate({ ...translate, sourceId: event.value.id, name: event.value.name });
  }

  useEffect(() => {
    if (selectedSource && selectedSource.value) {
      const mapped = translates && translates.map((item) => {
        if (item.type === selectedType.value && item.sourceId === selectedSource.value.id) {
          return {
            ...item,
            name: item.name ? item.name : selectedSource.value.name
          };
        } else {
          return null;
        }
      }).filter(item => item !== null);
      const newMapped = allLanguageOptions && allLanguageOptions.filter(opt => {
        if (!mapped.some(transItem => transItem.language.id === opt.value.id)) {
          return opt;
        }
      });
      setLanuageOptions(newMapped);
      if (mapped) {
        setTranslateList(mapped);
      }
    }
  }, [selectedSource]);

  function addItemToListAndCreateNewOne() {
    const list = translateList;
    const newMapped = languageOptions && languageOptions.filter(opt => {
      if (opt.value.id !== translate.language.id) {
        return opt;
      }
    });
    setLanuageOptions(newMapped);
    list.push(translate);
    setTranslate({
      type: selectedType.value,
      name: selectedSource.value.name,
      sourceId: selectedSource.value.id,
      value: ""
    });
    setSelectedLanguage(null);
    setTranslateList(list);

  }

  const onClickRemoveFromList = (item) => {
    setTranslateList(translateList.filter(listItem => listItem !== item));
  };

  function findLangCode(id) {
    return languages && languages.filter((item) => item.id === id)[0] && languages.filter((item) => item.id === id)[0].code;
  }

  function findSource(id, type) {
    if (type === "CATEGORY") {
      return categories && categories.filter((item) => item.id === id)[0];
    } else if (type === "SEGMENT") {
      return segments && segments.filter((item) => item.id === id)[0];
    } else if (type === "REFERENCE") {
      return references && references.filter((item) => item.id === id)[0];
    } else if (type === "RESOURCE") {
      return resources && resources.filter((item) => item.id === id)[0];
    } else if (type === "DEPARTMENT") {
      return departments && departments.filter((item) => item.id === id)[0];
    } else if (type === "SECTION") {
      return sections && sections.filter((item) => item.id === id)[0];
    }
  }

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteTranslate}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Translates")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={translates}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleTranslateClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(translate);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Row>
                            <Col className="col-6">
                              <Label className="form-label">{props.t("Type")}</Label>
                              <Select
                                value={selectedType}
                                onChange={(event) => onChangeType(event)}
                                options={typeOptions}
                                className="bs-select"
                                isClearable={true}
                                isDisabled={isEdit}
                              />
                            </Col>
                            <Col className="col-6">
                              <Label className="form-label">{props.t("Source")}</Label>
                              <Select
                                value={selectedSource}
                                onChange={(event) => onChangeSource(event)}
                                options={sourceOptions}
                                className="bs-select"
                                isClearable={true}
                                isDisabled={isEdit}
                              />
                            </Col>
                            <hr />
                            <Col className="col-12 mt-3">
                              <Row>
                                {!isEdit ? (
                                  <>
                                    <Col className="col-11">
                                      <Row>
                                        <Col className="col-6">
                                          <div className="mb-3">
                                            <Label className="form-label">{props.t("Language")}</Label>
                                            <Select
                                              value={selectedLanguage}
                                              onChange={(event) => onChangeLanguage(event)}
                                              options={languageOptions}
                                              className="bs-select"
                                              isClearable={true}
                                            />
                                          </div>
                                        </Col>
                                        <Col className="col-6">
                                          <div className="mb-3">
                                            <Label className="form-label">{props.t("Value")}</Label>
                                            <Input
                                              name="value"
                                              type="text"
                                              onChange={handleChange}
                                              value={translate && translate.value || ""}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col className="col-1">
                                      <Row>
                                        <Col className="col-12">
                                          <div className="mt-4">
                                            <Link
                                              className="text-warning text-center"
                                              to="#"
                                              onClick={addItemToListAndCreateNewOne}
                                            >
                                              <i className="mdi mdi-plus-circle font-size-24" id="addToList" />
                                            </Link>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </>
                                ) : (
                                  <>
                                    <Col className="col-12">
                                      <Row>
                                        <Col className="col-6">
                                          <div className="mb-3">
                                            <Label className="form-label">{props.t("Language")}</Label>
                                            <Select
                                              value={selectedLanguage}
                                              onChange={(event) => onChangeLanguage(event)}
                                              options={languageOptions}
                                              className="bs-select"
                                              isClearable={true}
                                              isDisabled={isEdit}
                                            />
                                          </div>
                                        </Col>
                                        <Col className="col-6">
                                          <div className="mb-3">
                                            <Label className="form-label">{props.t("Value")}</Label>
                                            <Input
                                              name="value"
                                              type="text"
                                              onChange={handleChange}
                                              value={translate && translate.value || ""}
                                              invalid={!(translate && translate.value)}
                                              required={true}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </>
                                )}
                              </Row>
                              <Row>
                                <div className="table-nowrap">
                                  {translateList && translateList.length > 0 && (
                                    <Table className="table-centered table-nowrap">
                                      <thead>
                                      <tr>
                                        <th scope="col">{props.t("Language")}</th>
                                        <th scope="col">{props.t("Name")}</th>
                                        <th scope="col">{props.t("Value")}</th>
                                        {!isEdit && (
                                          <th scope="col-1">{props.t("Action")}</th>
                                        )}
                                      </tr>
                                      </thead>
                                      <tbody>
                                      {translateList && translateList.map((item, key) => {
                                        return (
                                          <tr key={key}>
                                            <td>{findLangCode(item.language.id)}</td>
                                            <td>{item.name}</td>
                                            <td>{item.value}</td>
                                            {!isEdit && (
                                              <td className="col-1">
                                                <div className="flex-md-wrap">
                                                  <Link
                                                    className="text-danger"
                                                    to="#"
                                                    onClick={() => onClickRemoveFromList(item)}
                                                  >
                                                    <i className="mdi mdi-minus-circle font-size-20 text-end"
                                                       id="removeTooltip" />
                                                  </Link>
                                                </div>
                                              </td>
                                            )}
                                          </tr>);
                                      })
                                      }
                                      </tbody>
                                    </Table>
                                  )}
                                </div>
                              </Row>
                            </Col>
                          </Row>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end mt-3">
                              <button
                                type="submit"
                                className="btn btn-success save-lead"
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
Translate.propTypes = {
  translates: PropTypes.array,
  onGetTranslates: PropTypes.func,
  onAddNewTranslate: PropTypes.func,
  onDeleteTranslate: PropTypes.func,
  onUpdateTranslate: PropTypes.func
};

export default withRouter(withTranslation()(Translate));
