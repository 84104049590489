import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {
    useAsyncDebounce,
    useExpanded,
    useFilters,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable
} from "react-table";
import {Button, Col, Input, Row, Table} from "reactstrap";
import {DefaultColumnFilter, Filter} from "./filters";
import {withTranslation} from "react-i18next";

// Define a default UI for filtering
function GlobalFilter({
                          preGlobalFilteredRows,
                          globalFilter,
                          setGlobalFilter, t
                      }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <Col sm={4}>
            <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
                   {t("Search")}
            </span>
                        <input
                            onChange={e => {
                                setValue(e.target.value);
                                onChange(e.target.value);
                            }}
                            id="new-search-bar"
                            name="new-search-bar"
                            type="text"
                            className="form-control"
                            placeholder={`${count} ${t("Record")}...`}
                            value={value || ""}
                            autoComplete="new-search-bar"
                        />
                    </label>
                    <i className="bx bx-search-alt search-icon"></i>
                </div>
            </div>
        </Col>
    );
}

const TableContainer = ({
                            columns,
                            data,
                            isGlobalFilter,
                            isColumnFilter,
                            isShowRecord,
                            isAddOptions,
                            handleAddClick,
                            handleRefreshClick,
                            customPageSize,
                            className,
                            customPageSizeOptions,
                            refreshButton,
                            handleRowClick,
                            t,
                            toolbar,
                            paginable
                        }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: {pageIndex, pageSize}
    } = useTable(
        {
            columns,
            data,
            defaultColumn: {Filter: DefaultColumnFilter},
            initialState: {
                pageIndex: 0,
                pageSize: customPageSize
            },
            autoResetPage: false
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    );

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
    };

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value));
    };

    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };

    return (
        <Fragment>
            <Row className="mb-2">
                <Col sm="4">
                    {isGlobalFilter && (
                        <GlobalFilter
                            t={t}
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    )}
                </Col>
                <Col sm="4">
                    {!!isShowRecord && (
                        <select
                            className="form-select"
                            value={pageSize}
                            onChange={onChangeInSelect}
                        >
                            {[25, 50, 75, 100, 125, 150, 175, 200].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {t("Record")} {pageSize}
                                </option>
                            ))}
                        </select>
                    )}
                </Col>
                <Col sm="4">
                    <div className="text-sm-end">
                        {toolbar}
                        {refreshButton && (
                            <Button
                                type="button"
                                color="primary"
                                className="btn-rounded mb-2 me-2"
                                onClick={handleRefreshClick}
                            >
                                <i className="mdi mdi-refresh"/>
                            </Button>
                        )}

                        {isAddOptions && (
                            <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                onClick={handleAddClick}
                            >
                                <i className="mdi mdi-plus me-1"/>
                                {t("Create")}
                            </Button>
                        )}
                    </div>
                </Col>
            </Row>

            <div className="table-responsive react-table">
                <Table bordered hover {...getTableProps()} className={className}>
                    <thead className="table-nowrap bg-transparent">
                    {headerGroups.map(headerGroup => (
                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th key={column.id}>
                                    <div className="mb-0" {...column.getSortByToggleProps()}>
                                        {column.render("HeaderLabel")}
                                        {generateSortingIndicator(column)}
                                    </div>
                                    {!!isColumnFilter && (<Filter column={column}/>)}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <Fragment key={row.getRowProps().key}>
                                <tr>
                                    {row.cells.map(cell => {
                                        if (cell.column.Header === "Action") {
                                            return (
                                                <td key={cell.id} {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td key={cell.id} {...cell.getCellProps()}
                                                    onClick={handleRowClick ? () => handleRowClick(row) : null}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        }

                                    })}
                                </tr>
                            </Fragment>
                        );
                    })}
                    </tbody>
                </Table>
            </div>
            {paginable && (
                <Row className="justify-content-md-end justify-content-center align-items-center">
                    <Col className="col-md-auto">
                        <div className="d-flex gap-1">
                            <Button
                                outline
                                color="secondary"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {"<<"}
                            </Button>
                            <Button
                                outline
                                color="secondary"
                                onClick={previousPage}
                                disabled={!canPreviousPage}
                            >
                                {"<"}
                            </Button>
                        </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                        {" "}
                        <strong>
                            {pageIndex + 1} / {pageOptions.length}
                        </strong>
                    </Col>
                    <Col className="col-md-auto">
                        <Input
                            type="number"
                            min={1}
                            style={{ width: 70 }}
                            max={pageOptions.length}
                            defaultValue={pageIndex + 1}
                            onChange={onChangeInInput}
                        />
                    </Col>

                    <Col className="col-md-auto">
                        <div className="d-flex gap-1">
                            <Button color="secondary" onClick={nextPage} disabled={!canNextPage} outline>
                                {">"}
                            </Button>
                            <Button
                                color="secondary"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                                outline
                            >
                                {">>"}
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

TableContainer.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
    t: PropTypes.any
};

export default withTranslation()(TableContainer);
