import {
  ADD_FIRM_INFO_FAIL,
  ADD_FIRM_INFO_SUCCESS,
  DELETE_FIRM_INFO_FAIL,
  DELETE_FIRM_INFO_SUCCESS,
  GET_FIRM_INFOS_FAIL,
  GET_FIRM_INFOS_SUCCESS,
  UPDATE_FIRM_INFO_FAIL,
  UPDATE_FIRM_INFO_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  firmInfos: [],
  error: {},
  success: false
};

const FirmInfo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FIRM_INFOS_SUCCESS:
      return {
        ...state,
        firmInfos: action.payload
      };

    case GET_FIRM_INFOS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_FIRM_INFO_SUCCESS:
      return {
        ...state,
        firmInfos: [...state.firmInfos, action.payload],
        success: true
      };

    case ADD_FIRM_INFO_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_FIRM_INFO_SUCCESS:
      return {
        ...state,
        firmInfos: state.firmInfos.map(firmInfo =>
          firmInfo.id.toString() === action.payload.id.toString()
            ? { firmInfo, ...action.payload }
            : firmInfo
        ),
        success: true
      };

    case UPDATE_FIRM_INFO_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_FIRM_INFO_SUCCESS:
      return {
        ...state,
        firmInfos: state.firmInfos.filter(
          firmInfo => firmInfo.id.toString() !== action.req.toString()
        )
      };

    case DELETE_FIRM_INFO_FAIL:
      return {
        ...state,
        error: action.payload
      };
    default:
      return {
        ...state,
        success: false,
          error: {}
      };
  }
};

export default FirmInfo;
