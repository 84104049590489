import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentCriteria, getAppointments, getUserAppointments } from "../../store/appointment/actions";
import { isAfter, isToday } from "date-fns";
import useAuth from "../../hooks/useAuth";

const ActivityComp = (props) => {
  const dispatch = useDispatch();
  const { authUser } = useAuth();
  const roleUser = authUser && authUser.role && authUser.role.name ==="ROLE_USER";

  const { appointments } = useSelector(state => ({
    appointments: state.Appointment.appointments
  }));

  const [viewMoreButton, setViewMoreButton] = useState(true);

  const initialBody = !roleUser ? { size: 10, page: 0, appointmentDateStart: new Date().toISOString().split("T")[0] } : {
    size: 10,
    page: 0,
    appointmentUser: authUser && authUser.id,
    appointmentDateStart: new Date().toISOString().split("T")[0]
  };

  useEffect(() => {
    dispatch(getAppointmentCriteria(initialBody));
  }, []);

  useEffect(() => {
    setViewMoreButton(props.viewMoreButton);
  }, [props.viewMoreButton]);

  return (
    <React.Fragment>
      <Card>
        <CardTitle className="mb-5">{props.t("Appointments")}</CardTitle>
        {appointments.length === 0 ? (
          <CardBody>
            <div className="text-center mt-4">{props.t("You Have No Appointment")}</div>
          </CardBody>
        ) : (
          <CardBody>
            <ul className="verti-timeline list-unstyled">
              {appointments.map((appointment,key) => {
                if (isAfter(new Date(appointment.appointmentDate), new Date())) {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <li className="event-list" key={key} id={key}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                      </div>
                      <div className="d-flex">
                        <div className="me-3">
                          <h5 className="font-size-14">
                            {appointment.appointmentDate}
                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                          </h5>
                        </div>
                        <div className="flex-sm-fill">
                          <div>{appointment.lead.firstName + " " + appointment.lead.lastName}</div>
                        </div>
                        <div className="flex-sm-wrap">
                          <div>{appointment.description}</div>
                        </div>
                      </div>
                    </li>
                  );
                } else if (isToday(new Date(appointment.appointmentDate))) {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <li className="event-list active" id={key}  key={key}>
                      <div className="event-timeline-dot">
                        <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
                      </div>
                      <div className="d-flex">
                        <div className="me-3">
                          <h5 className="font-size-14">
                            {appointment.appointmentDate}
                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                          </h5>
                        </div>
                        <div className="flex-sm-fill">
                          <div>{appointment.lead.firstName + " " + appointment.lead.lastName}</div>
                        </div>
                        <div className="flex-sm-wrap">
                          <div>{appointment.description}</div>
                        </div>
                      </div>
                    </li>
                  );
                }
              })}
            </ul>
            {viewMoreButton === true ? (
              <div className="text-center mt-4">
                <Link
                  to="/appointments"
                  className="btn btn-primary  btn-sm"
                >
                  {props.t("View more")}<i className="mdi mdi-arrow-right ms-1" />
                </Link>
              </div>
            ) : null}
          </CardBody>
        )}
      </Card>
    </React.Fragment>
  );
};
ActivityComp.propTypes = {
  t: PropTypes.any,
  getAppointments: PropTypes.func,
  getUserAppointments: PropTypes.func,
  viewMoreButton: PropTypes.bool
};

export default withTranslation()(ActivityComp);