import {
  ADD_NEW_REMINDER,
  ADD_REMINDER_FAIL,
  ADD_REMINDER_SUCCESS,
  DELETE_REMINDER,
  DELETE_REMINDER_FAIL,
  DELETE_REMINDER_SUCCESS,
  GET_LEAD_REMINDERS,
  GET_LEAD_REMINDERS_FAIL,
  GET_LEAD_REMINDERS_SUCCESS,
  GET_REMINDER_COUNT,
  GET_REMINDER_COUNT_FAIL,
  GET_REMINDER_COUNT_SUCCESS,
  GET_REMINDER_CRITERIA,
  GET_REMINDER_CRITERIA_FAIL,
  GET_REMINDER_CRITERIA_SUCCESS,
  GET_REMINDERS,
  GET_REMINDERS_FAIL,
  GET_REMINDERS_SUCCESS,
  GET_TODAY_REMINDERS,
  GET_TODAY_REMINDERS_FAIL,
  GET_TODAY_REMINDERS_SUCCESS,
  GET_USER_REMINDERS,
  GET_USER_REMINDERS_FAIL,
  GET_USER_REMINDERS_SUCCESS,
  UPDATE_REMINDER,
  UPDATE_REMINDER_FAIL,
  UPDATE_REMINDER_SUCCESS
} from "./actionTypes";

export const getReminders = () => ({
  type: GET_REMINDERS
});

export const getRemindersSuccess = reminders => ({
  type: GET_REMINDERS_SUCCESS,
  payload: reminders
});

export const getRemindersFail = error => ({
  type: GET_REMINDERS_FAIL,
  payload: error
});

export const addNewReminder = reminder => ({
  type: ADD_NEW_REMINDER,
  payload: reminder
});

export const addReminderSuccess = reminder => ({
  type: ADD_REMINDER_SUCCESS,
  payload: reminder
});

export const addReminderFail = error => ({
  type: ADD_REMINDER_FAIL,
  payload: error
});

export const updateReminder = reminder => ({
  type: UPDATE_REMINDER,
  payload: reminder
});

export const updateReminderSuccess = reminder => ({
  type: UPDATE_REMINDER_SUCCESS,
  payload: reminder
});

export const updateReminderFail = error => ({
  type: UPDATE_REMINDER_FAIL,
  payload: error
});

export const deleteReminder = id => ({
  type: DELETE_REMINDER,
  id
});

export const deleteReminderSuccess = (reminder,req) => ({
  type: DELETE_REMINDER_SUCCESS,
  payload: reminder,
  req:req,
});

export const deleteReminderFail = error => ({
  type: DELETE_REMINDER_FAIL,
  payload: error
});

export const getLeadReminders = id => ({
  type: GET_LEAD_REMINDERS,
  id
});

export const getLeadRemindersSuccess = reminder => ({
  type: GET_LEAD_REMINDERS_SUCCESS,
  payload: reminder
});

export const getLeadRemindersFail = error => ({
  type: GET_LEAD_REMINDERS_FAIL,
  payload: error
});

export const getUserReminders = id => ({
  type: GET_USER_REMINDERS,
  id
});

export const getUserRemindersSuccess = reminder => ({
  type: GET_USER_REMINDERS_SUCCESS,
  payload: reminder
});

export const getUserRemindersFail = error => ({
  type: GET_USER_REMINDERS_FAIL,
  payload: error
});

export const getTodayReminders = () => ({
  type: GET_TODAY_REMINDERS
});

export const getTodayRemindersSuccess = reminder => ({
  type: GET_TODAY_REMINDERS_SUCCESS,
  payload: reminder
});

export const getTodayRemindersFail = error => ({
  type: GET_TODAY_REMINDERS_FAIL,
  payload: error
});

export const getReminderCriteria = reminder => ({
  type: GET_REMINDER_CRITERIA,
  payload: reminder
});

export const getReminderCriteriaSuccess = reminder => ({
  type: GET_REMINDER_CRITERIA_SUCCESS,
  payload: reminder
});

export const getReminderCriteriaFail = error => ({
  type: GET_REMINDER_CRITERIA_FAIL,
  payload: error
});

export const getReminderCount = () => ({
  type: GET_REMINDER_COUNT
});

export const getReminderCountSuccess = count => ({
  type: GET_REMINDER_COUNT_SUCCESS,
  payload: count
});

export const getReminderCountFail = error => ({
  type: GET_REMINDER_COUNT_FAIL,
  payload: error
});