import {
    GET_APPOINTMENT_HISTORY,
    GET_APPOINTMENT_HISTORY_BY_FILTER,
    GET_APPOINTMENT_HISTORY_BY_FILTER_FAIL,
    GET_APPOINTMENT_HISTORY_BY_FILTER_SUCCESS,
    GET_APPOINTMENT_HISTORY_FAIL,
    GET_APPOINTMENT_HISTORY_SUCCESS,
    GET_CATEGORY_HISTORY,
    GET_CATEGORY_HISTORY_FAIL,
    GET_CATEGORY_HISTORY_SUCCESS,
    GET_LEAD_HISTORY,
    GET_LEAD_HISTORY_BY_FILTER,
    GET_LEAD_HISTORY_BY_FILTER_FAIL,
    GET_LEAD_HISTORY_BY_FILTER_SUCCESS,
    GET_LEAD_HISTORY_FAIL,
    GET_LEAD_HISTORY_SUCCESS,
    GET_NOTE_HISTORY,
    GET_NOTE_HISTORY_BY_FILTER,
    GET_NOTE_HISTORY_BY_FILTER_FAIL,
    GET_NOTE_HISTORY_BY_FILTER_SUCCESS,
    GET_NOTE_HISTORY_FAIL,
    GET_NOTE_HISTORY_SUCCESS,
    GET_REMINDER_HISTORY,
    GET_REMINDER_HISTORY_BY_FILTER,
    GET_REMINDER_HISTORY_BY_FILTER_FAIL,
    GET_REMINDER_HISTORY_BY_FILTER_SUCCESS,
    GET_REMINDER_HISTORY_FAIL,
    GET_REMINDER_HISTORY_SUCCESS
} from "./actionTypes";

export const getLeadHistory = () => ({
    type: GET_LEAD_HISTORY,
})

export const getLeadHistorySuccess = (res) => ({
    type: GET_LEAD_HISTORY_SUCCESS,
    payload: res,
})

export const getLeadHistoryFail = error => ({
    type: GET_LEAD_HISTORY_FAIL,
    payload: error,
})

export const getLeadHistoryByFilter = (req) => ({
    type: GET_LEAD_HISTORY_BY_FILTER,
    payload: req
})

export const getLeadHistoryByFilterSuccess = (res) => ({
    type: GET_LEAD_HISTORY_BY_FILTER_SUCCESS,
    payload: res,
})

export const getLeadHistoryByFilterFail = error => ({
    type: GET_LEAD_HISTORY_BY_FILTER_FAIL,
    payload: error,
})

export const getAppointmentHistory = () => ({
    type: GET_APPOINTMENT_HISTORY,
})

export const getAppointmentHistorySuccess = res => ({
    type: GET_APPOINTMENT_HISTORY_SUCCESS,
    payload: res,
})

export const getAppointmentHistoryFail = error => ({
    type: GET_APPOINTMENT_HISTORY_FAIL,
    payload: error,
})

export const getAppointmentHistoryByFilter = (req) => ({
    type: GET_APPOINTMENT_HISTORY_BY_FILTER,
    payload: req
})

export const getAppointmentHistoryByFilterSuccess = res => ({
    type: GET_APPOINTMENT_HISTORY_BY_FILTER_SUCCESS,
    payload: res,
})

export const getAppointmentHistoryByFilterFail = error => ({
    type: GET_APPOINTMENT_HISTORY_BY_FILTER_FAIL,
    payload: error,
})

export const getNoteHistory = () => ({
    type: GET_NOTE_HISTORY,
})

export const getNoteHistorySuccess = (res) => ({
    type: GET_NOTE_HISTORY_SUCCESS,
    payload: res,
})

export const getNoteHistoryFail = error => ({
    type: GET_NOTE_HISTORY_FAIL,
    payload: error,
})

export const getNoteHistoryByFilter = (req) => ({
    type: GET_NOTE_HISTORY_BY_FILTER,
    payload: req
})

export const getNoteHistoryByFilterSuccess = (res) => ({
    type: GET_NOTE_HISTORY_BY_FILTER_SUCCESS,
    payload: res,
})

export const getNoteHistoryByFilterFail = error => ({
    type: GET_NOTE_HISTORY_BY_FILTER_FAIL,
    payload: error,
})

export const getReminderHistory = () => ({
    type: GET_REMINDER_HISTORY,
})

export const getReminderHistorySuccess = (res) => ({
    type: GET_REMINDER_HISTORY_SUCCESS,
    payload: res,
})

export const getReminderHistoryFail = error => ({
    type: GET_REMINDER_HISTORY_FAIL,
    payload: error,
})


export const getReminderHistoryByFilter = (req) => ({
    type: GET_REMINDER_HISTORY_BY_FILTER,
    payload: req
})

export const getReminderHistoryByFilterSuccess = (res) => ({
    type: GET_REMINDER_HISTORY_BY_FILTER_SUCCESS,
    payload: res,
})

export const getReminderHistoryByFilterFail = error => ({
    type: GET_REMINDER_HISTORY_BY_FILTER_FAIL,
    payload: error,
})

export const getCategoryHistory = () => ({
    type: GET_CATEGORY_HISTORY
})

export const getCategoryHistorySuccess = (res) => ({
    type: GET_CATEGORY_HISTORY_SUCCESS,
    payload: res,
})

export const getCategoryHistoryFail = error => ({
    type: GET_CATEGORY_HISTORY_FAIL,
    payload: error,
})