import React, { useEffect, useState } from "react";

import {Card, Col, Input, InputGroup, Row} from "reactstrap";

import withRouter from "../../hooks/withRouter";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { getWithoutToken, postWithoutToken } from "../../helpers/axios_with_headers.js";
import { useParams } from "react-router-dom";
import { GET_SURVEY, SEND_SURVEY_RESPONSE } from "../../helpers/url_helper";
import { post } from "../../helpers/api_helper";
import { Rating } from "react-simple-star-rating";
// import images

const Survey = props => {
  const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  //meta title
  document.title = "Survey";
  const { hash } = useParams();

  const [surveyResponse, setSurveyResponse] = useState({});
  const [questions, setQuestions] = useState([]);
  const [apptId, setApptId] = useState("");
  const [answerList, setAnswerList] = useState([]);
  const [isSent, setIsSent] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [requiredOnes, setRequiredOnes] = useState([]);

  const handleChange = (que, e) => {
    const newMapped = answerList.map(item => {
      if (item.questionId === que.id) {
        let founded = item;
        founded.questionItemId = que.questionItemList[0].id;
        founded.questionItemBody = e.target.value;
        return founded;
      } else {
        return item;
      }
    });
    setAnswerList(newMapped);
  };

  useEffect(() => {
    getWithoutToken(process.env.REACT_APP_API_URL + GET_SURVEY + "/" + hash).then(r => {
      if (r.status === 200) {
        setSurveyResponse(r.data);
        setQuestions(r.data.questionList);
        const answers = r.data.questionList.map(item => ({
          questionId: item.id,
          questionBody: item.questionBody,
          questionItemId: null,
          questionItemBody: null,
          description: ""
        }));
        setAnswerList(answers);
        const required = r.data.questionList.map(item => {
          return item.isRequired === true ? item.id : null;
        });
        setRequiredOnes(required);
      }
    });
  }, []);

  useEffect(() => {
    if (requiredOnes) {
      const cond = answerList.some(ans => (ans.questionItemBody === null && requiredOnes.some(one => one === ans.questionId)));
      if (cond) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    }
  });

  const sendSurveyData = () => {
    const createSurveyRes = {
      isCompleted: true,
      surveyDefinitionId: surveyResponse.surveyDefinitionId || null,
      email: null,
      phoneNumber: null,
      surveyId: surveyResponse.surveyId,
      hash: hash,
      answerList: [
        {
          surveyId: surveyResponse.surveyId,
          surveyAnswerId: null,
          answerItemList: answerList
        }
      ]
    };

    postWithoutToken(process.env.REACT_APP_API_URL + SEND_SURVEY_RESPONSE, createSurveyRes).then(r => {
      if (r.status === 200) {
        setIsSent(true);
      }
    });
  };

  function approveAppointment() {

    const createSurveyRes = {
      // secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'"
      id: apptId //----302051, 302045
    };

    post(process.env.REACT_APP_API_URL + "/patientAppointmentSurvey/createTestSurveyForPatientAppointment", createSurveyRes).then(r => {
      if (r.status === 200) {
        console.log("asd", r);
      }
    });
  }

  function changeApptId(e) {
    setApptId(e.target.value);
  }

  function handleRating(questionInfo, e) {
    const newMapped = answerList.map(item => {
      if (item.questionId === questionInfo.id) {
        let founded = item;
        founded.questionItemId = questionInfo.questionItemList[0].id;
        founded.questionItemBody = e;

        return founded;
      } else {
        return item;
      }
    });
    setAnswerList(newMapped);
  }

  function handleCheck(questionInfo, answerInfo ,e ,type) {
    const newMapped = answerList.map(item => {
      if (item.questionId === questionInfo.id) {
        let founded = item;
        founded.questionItemId = answerInfo.id;
        founded.questionItemBody = answerInfo.questionItemBody;
        founded.description = answerInfo.isDescriptionRequired && type ==="desc" ? e.target.value:""
        return founded;
      } else {
        return item;
      }
    });
    setAnswerList(newMapped);
  }

  function handleOpenInput(que,item) {
    const input = document.getElementById("radio_input_"+item.id+"_"+que.id)
    return input && input.checked;
  }

  return (
    <div className="page-content-full justify-content-center">
      <div className="vertical-centered-div">
        {isSent === false ? (
          <>
            <Row className="m-2">
              <div className="mini-stats-wid">
                <div className="p-2 text-center">
                  <p className="text-dark font-size-14 fw-bold">Hoşgeldiniz.</p>
                  <p className="font-size-14">Memnuniyetiniz bizim için çok önemli, bu nedenle deneyiminiz hakkında geri bildirim almak isteriz.</p>
                  <p className="font-size-14">Bu memnuniyet anketi, sizin ve diğer hastaların kliniğimizde yaşadığı deneyimi iyileştirmemiz için önemli bir araçtır.</p>
                  <p className="font-size-14">Lütfen bu anketi doldurarak, sizin için neyin iyi çalıştığı ve neyin iyileştirilebileceği konusunda bize yardımcı olun. Anketin doldurulması sadece bir dakikanızı alacaktır ve tüm yanıtlarınız gizli tutulacaktır.</p>
                </div>
              </div>
            </Row>
            <Row className="m-2">
              {questions && questions.map((que, idx) => {
                switch (que.questionTypeCode) {
                  case "STAR":
                    return (
                      <div id={idx} className="p-3 text-center">
                        <div className="row">
                          <h6 className="font-16 m-b-15">
                            {que.questionBody} {que.isRequired && (
                            <i className="mdi mdi-alert-circle-outline error font-size-16 me-1" />)}
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-5" />
                          <div className="col-2">
                            <Rating
                              size={20}
                              fillColorArray={[
                                "#f14f45",
                                "#f16c45",
                                "#f18845",
                                "#f1b345",
                                "#f1d045"
                              ]}
                              transition
                              onClick={(e) => handleRating(que, e)}
                            />
                          </div>
                          <div className="col-5" />
                        </div>
                      </div>
                    );
                  case "OPTION":
                    return (
                      <div id={idx} className="p-3 text-center">
                        <div className="row">
                          <h6 className="font-16 m-b-15">
                            {que.questionBody} {que.isRequired && (
                            <i className="mdi mdi-alert-circle-outline error font-size-16 me-1" />)}
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-4" />
                          <div className="col-4 align-items-center text-center">
                            <div form="true">
                              {que.questionItemList.map((item,idx) =>{
                                return (
                                     <div key={item.id} className="form-radio-info row-cols-md-auto">
                                       <input
                                           className="ms-2 form-radio-info"
                                           type="radio"
                                           name={"radio"+que.id}
                                           id={"radio_input_"+item.id+"_"+que.id}
                                           value={item}
                                           checked={handleOpenInput(que,item)}
                                           onClick={(e) => handleCheck(que, item, e,"check")}
                                       />
                                       <label
                                           className="ms-2 form-check-label me-2"
                                           htmlFor={"radio_"+item.id+"_"+que.id}
                                       >
                                         {item.questionItemBody}
                                       </label>
                                       {item.isDescriptionRequired && handleOpenInput(que,item) && (<input className="ms-2 form-control-lg border-end-0 border-top-0 border-start-0 border-bottom border-dark font-size-12" type="text" onChange={(e) => handleCheck(que,item, e,"desc")}></input>)}
                                     </div>
                                )
                              })}
                            </div>
                          </div>
                          <div className="col-4" />
                        </div>
                      </div>
                    );
                  case "TEXT":
                    return (
                      <div id={idx} className="p-3 text-center">
                        <div className="row">
                          <h6 className="font-16 m-b-15">
                            {que.questionBody} {que.isRequired && (
                            <i className="mdi mdi-alert-circle-outline error font-size-16 me-1" />)}
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-5" />
                          <div className="col-2">
                            <input className="ms-2 form-control border-end-0 border-top-0 border-start-0 border-bottom border-dark-subtle"
                              id={idx}
                              value={answerList[idx] && answerList[idx].questionItemBody || ""}
                              onChange={(e) => handleChange(que, e)}
                            />
                          </div>
                          <div className="col-5" />
                        </div>
                      </div>
                    );
                }
              })}
            </Row>
            <Row className="m-2">
              <Col>
                <div className="text-center mt-2">
                  <button
                    disabled={disableButton}
                    id="save"
                    type="submit"
                    className="btn btn-outline-success"
                    onClick={() => sendSurveyData()}
                  >
                    Anketi Tamamla
                  </button>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <div className="page-content-full justify-content-center">
            <div className="vertical-centered-div">
              <p className="text-sm-center">
                <i className="mdi mdi-check-bold text-success display-1" />
                <p className="text-info font-size-24 fw-bold">Teşekkürler</p>
                <p className="text-dark font-size-16 fw-bold mt-2">Yanıtınızı aldık.</p>
                <p className="text-secondary font-size-8 mt-0">Memnuniyetiniz için çalışmaya devam edeceğiz...</p>
              </p>
            </div>
          </div>)}
      </div>
    </div>
  );
};

Survey.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(Survey));