import React from "react";

const id = (cell) => {
  return cell.value ? cell.value : "";
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

const Phone = (cell) => {
  return cell.value ? cell.value : "";
};

const Description = (cell) => {
  return cell.value ? cell.value : "";
};

const Address = (cell) => {
  return cell.value ? cell.value : "";
};

const Doctor = (cell) => {
  return cell.value ? cell.value.map(item => `${item.name} `) : "";
}

export {
  id,
  Name,
  Phone,
  Description,
  Doctor,
  Address
};