import { call, put, takeEvery } from "redux-saga/effects";

// UserAvailabilitys Redux States
import {
  ADD_NEW_USER_AVAILABILITY,
  DELETE_USER_AVAILABILITY,
  GET_USER_AVAILABILITY_BY_ID,
  GET_USER_AVAILABILITY,
  UPDATE_USER_AVAILABILITY
} from "./actionTypes";

import {
  addUserAvailabilityFail,
  addUserAvailabilitySuccess,
  deleteUserAvailabilityFail,
  deleteUserAvailabilitySuccess,
  getUserAvailabilityByIdFail,
  getUserAvailabilityByIdSuccess,
  getUserAvailabilityFail,
  getUserAvailabilitySuccess,
  updateUserAvailabilityFail,
  updateUserAvailabilitySuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchUserAvailabilitys() {
  try {
    const response = yield call(getUserAvailabilitys);
    yield put(getUserAvailabilitySuccess(response));
  } catch (error) {
    yield put(getUserAvailabilityFail(error));
  }
}

const getUserAvailabilitys = () => get(url.AVAILABILTY_API);

function* onUpdateUserAvailability({ payload: UserAvailability }) {
  try {
    const response = yield call(updateUserAvailability, UserAvailability);
    yield put(updateUserAvailabilitySuccess(response));
  } catch (error) {
    yield put(updateUserAvailabilityFail(error));
  }
}

const updateUserAvailability = (UserAvailability) => post(url.AVAILABILTY_API, UserAvailability);

function* onDeleteUserAvailability({ id }) {
  try {
    const response = yield call(deleteUserAvailability, id);
    if (response.status === 200) {
      yield put(deleteUserAvailabilitySuccess(response, id));
    } else {
      yield put(deleteUserAvailabilityFail(response));
    }
  } catch (error) {
    yield put(deleteUserAvailabilityFail(error));
  }
}

const deleteUserAvailability = (id) => del(`${url.AVAILABILTY_API}/${id}`);

function* onAddNewUserAvailability({ payload: UserAvailability }) {
  try {
    const response = yield call(addNewUserAvailability, UserAvailability);

    yield put(addUserAvailabilitySuccess(response));
  } catch (error) {
    yield put(addUserAvailabilityFail(error));
  }
}

const addNewUserAvailability = (UserAvailability) => post(url.AVAILABILTY_API, UserAvailability);

function* fetchUserAvailabilityById({ payload: id }) {
  try {
    const response = yield call(getUserAvailabilityById, id);
    yield put(getUserAvailabilityByIdSuccess(response));
  } catch (error) {
    yield put(getUserAvailabilityByIdFail(error));
  }
}

const getUserAvailabilityById = (id) => get(`${url.AVAILABILTY_API_BY_ID}/${id}`);

function* UserAvailabilitySaga() {
  yield takeEvery(GET_USER_AVAILABILITY, fetchUserAvailabilitys);
  yield takeEvery(ADD_NEW_USER_AVAILABILITY, onAddNewUserAvailability);
  yield takeEvery(UPDATE_USER_AVAILABILITY, onUpdateUserAvailability);
  yield takeEvery(DELETE_USER_AVAILABILITY, onDeleteUserAvailability);
  yield takeEvery(GET_USER_AVAILABILITY_BY_ID, fetchUserAvailabilityById);
}

export default UserAvailabilitySaga;
