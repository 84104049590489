import {
    GET_DESCRIPTION_TEMPLATES,
    GET_DESCRIPTION_TEMPLATES_FAIL,
    GET_DESCRIPTION_TEMPLATES_SUCCESS,
    ADD_NEW_DESCRIPTION_TEMPLATE,
    ADD_DESCRIPTION_TEMPLATE_SUCCESS,
    ADD_DESCRIPTION_TEMPLATE_FAIL,
    UPDATE_DESCRIPTION_TEMPLATE,
    UPDATE_DESCRIPTION_TEMPLATE_SUCCESS,
    UPDATE_DESCRIPTION_TEMPLATE_FAIL,
    DELETE_DESCRIPTION_TEMPLATE,
    DELETE_DESCRIPTION_TEMPLATE_SUCCESS,
    DELETE_DESCRIPTION_TEMPLATE_FAIL,
} from "./actionTypes"

export const getDescriptionTemplates = () => ({
    type: GET_DESCRIPTION_TEMPLATES,
})

export const getDescriptionTemplatesSuccess = descriptionTemplates => ({
    type: GET_DESCRIPTION_TEMPLATES_SUCCESS,
    payload: descriptionTemplates,
})

export const getDescriptionTemplatesFail = error => ({
    type: GET_DESCRIPTION_TEMPLATES_FAIL,
    payload: error,
})

export const addNewDescriptionTemplate = descriptionTemplate => ({
    type: ADD_NEW_DESCRIPTION_TEMPLATE,
    payload: descriptionTemplate,
})

export const addDescriptionTemplateSuccess = descriptionTemplate => ({
    type: ADD_DESCRIPTION_TEMPLATE_SUCCESS,
    payload: descriptionTemplate,
})

export const addDescriptionTemplateFail = error => ({
    type: ADD_DESCRIPTION_TEMPLATE_FAIL,
    payload: error,
})

export const updateDescriptionTemplate = descriptionTemplate => ({
    type: UPDATE_DESCRIPTION_TEMPLATE,
    payload: descriptionTemplate,
})

export const updateDescriptionTemplateSuccess = descriptionTemplate => ({
    type: UPDATE_DESCRIPTION_TEMPLATE_SUCCESS,
    payload: descriptionTemplate,
})

export const updateDescriptionTemplateFail = error => ({
    type: UPDATE_DESCRIPTION_TEMPLATE_FAIL,
    payload: error,
})

export const deleteDescriptionTemplate = id => ({
    type: DELETE_DESCRIPTION_TEMPLATE,
    id,
})

export const deleteDescriptionTemplateSuccess = (descriptionTemplate,req) => ({
    type: DELETE_DESCRIPTION_TEMPLATE_SUCCESS,
    payload: descriptionTemplate,
    req:req,
})

export const deleteDescriptionTemplateFail = error => ({
    type: DELETE_DESCRIPTION_TEMPLATE_FAIL,
    payload: error,
})