import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import withRouter from "../../hooks/withRouter";
import PropTypes from "prop-types";
import useAuth from "../../hooks/useAuth";
import {menu} from "../../helpers/menu_helper";

function Menu({t, handleAriaExpanded, callbackRendered}) {

    const {userMenu} = useAuth();

    const [permittedMenu, setPermittedMenu] = useState([]);

    useEffect(() => {
        if (userMenu && userMenu.length > 0) {
            setPermittedMenu(userMenu);
        }
    }, [userMenu]);

    useEffect(() => {
        callbackRendered(true);
    }, []);

    function check(menuItem) {
        if (permittedMenu && permittedMenu.length > 0) {
            for (let i = 0; i < permittedMenu.length; i++) {
                if (menuItem.children) {
                    for (let j = 0; j < menuItem.children.length; j++) {
                        if (permittedMenu[i] === menuItem.children[j].path) {
                            return true;
                        }
                    }
                } else {
                    if (permittedMenu[i] === menuItem.path) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const createChildren = (menuItem) => menuItem.children.map((child, childId) => check(child) && (
        <li key={"li_sub_" + childId} id={"li_sub_" + childId}>
            <Link key={"link_child_" + child.id + childId} id={"link_child_" + child.id + childId}
                  to={child.path}>
                <i key={"icon_child_" + child.id + childId} id={"icon_child_" + child.id + childId}
                   className={child.icon}></i>
                <span key={"span_child_" + child.id + childId}
                      id={"span_child_" + child.id + childId}>{t(child.label)}</span>
            </Link>
        </li>)
    );

    return <ul className="metismenu list-unstyled" id="side-menu">
        <li className="menu-title">{t("Menu")} </li>
        {menu.map((menuItem, idx) => {
            if (check(menuItem)) {
                if (menuItem.children !== undefined) {
                    return (
                        <li key={"li_parent_" + idx} id={"li_parent_" + idx}>
                            <Link key={"link_parent_" + idx} id={"link_parent_" + idx} to="/#" className="has-arrow"
                                  onClick={handleAriaExpanded}>
                                <i key={"icon_parent_" + idx} id={"icon_parent_" + idx}
                                   className={menuItem.icon}></i>
                                <span key={"span_parent_" + idx}
                                      id={"span_parent_" + idx}>{t(menuItem.label)}</span>
                            </Link>
                            <ul key={"ul_sub" + idx} id={"ul_sub" + idx} className="sub-menu mm-collapse">
                                {createChildren(menuItem)}
                            </ul>
                        </li>);
                } else {
                    return (
                        <li key={"li_parent_" + idx} id={"li_parent_" + idx}>
                            <Link key={"link_parent_" + idx} id={"link_parent_" + idx} to={menuItem.path}>
                                <i key={"icon_parent_" + idx} id={"icon_parent_" + idx}
                                   className={menuItem.icon}></i>
                                <span key={"span_parent_" + idx}
                                      id={"span_parent_" + idx}>{t(menuItem.label)}</span>
                            </Link>
                        </li>
                    );
                }
            }
        })}
    </ul>
}

Menu.propTypes = {
    menuArray: PropTypes.array,
    permittedMenu: PropTypes.array,
    t: PropTypes.any,
    handleAriaExpanded: PropTypes.any
};

export default withRouter(Menu);