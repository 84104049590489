import {
    ADD_DEPARTMENT_FAIL,
    ADD_DEPARTMENT_SUCCESS,
    DELETE_DEPARTMENT_FAIL,
    DELETE_DEPARTMENT_SUCCESS,
    GET_DEPARTMENTS_FAIL,
    GET_DEPARTMENTS_SUCCESS,
    UPDATE_DEPARTMENT_FAIL,
    UPDATE_DEPARTMENT_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    departments: [],
    error: {},
};

const Department = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                departments: action.payload,
            };

        case GET_DEPARTMENTS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_DEPARTMENT_SUCCESS:
            return {
                ...state,
                departments: [...state.departments, action.payload],
            };

        case ADD_DEPARTMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                departments: state.departments.map(department =>
                    department.id.toString() === action.payload.id.toString()
                        ? { department, ...action.payload }
                        : department
                ),
            };

        case UPDATE_DEPARTMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                departments: state.departments.filter(
                    department => department.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_DEPARTMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default Department;
