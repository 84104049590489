import { call, put, takeEvery } from "redux-saga/effects";

// Appointments Redux States
import {
  ADD_NEW_APPOINTMENT,
  DELETE_APPOINTMENT,
  GET_APPOINTMENTS,
  UPDATE_APPOINTMENT,
  GET_LEAD_APPOINTMENTS,
  GET_USER_APPOINTMENTS,
  GET_TODAY_APPOINTMENTS,
  GET_APPOINTMENT_CRITERIA
} from "./actionTypes";

import {
  addAppointmentFail,
  addAppointmentSuccess,
  deleteAppointmentFail,
  deleteAppointmentSuccess,
  getAppointmentsFail,
  getAppointmentsSuccess,
  updateAppointmentFail,
  updateAppointmentSuccess,
  getLeadAppointmentsFail,
  getLeadAppointmentsSuccess,
  getUserAppointmentsSuccess,
  getUserAppointmentsFail,
  getTodayAppointmentsSuccess,
  getTodayAppointmentsFail,
  getAppointmentCriteriaFail,
  getAppointmentCriteriaSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { get, post, del } from "../../helpers/api_helper";

function* fetchLeadAppointments({ id }) {
  try {
    const response = yield call(getLeadAppointments, id);
    yield put(getLeadAppointmentsSuccess(response));
  } catch (error) {
    yield put(getLeadAppointmentsFail(error));
  }
}

const getLeadAppointments = (id) => get(`${url.GET_LEAD_APPOINTMENT}/${id}`);

function* fetchAppointments() {
  try {
    const response = yield call(getAppointments);
    yield put(getAppointmentsSuccess(response));
  } catch (error) {
    yield put(getAppointmentsFail(error));
  }
}

const getAppointments = () => get(url.GET_APPOINTMENTS);

function* onUpdateAppointment({ payload: appointment }) {
  try {
    const response = yield call(updateAppointment, appointment);
    yield put(updateAppointmentSuccess(response));
  } catch (error) {
    yield put(updateAppointmentFail(error));
  }
}

const updateAppointment = (appointment) => post(url.UPDATE_APPOINTMENT, appointment);

function* onDeleteAppointment({ id }) {
  try {
    const response = yield call(deleteAppointment, id);
    yield put(deleteAppointmentSuccess(response,id));
  } catch (error) {
    yield put(deleteAppointmentFail(error));
  }
}

const deleteAppointment = (id) => del(`${url.DELETE_APPOINTMENT}/${id}`);

function* onAddNewAppointment({ payload: appointment }) {
  try {
    const response = yield call(addNewAppointment, appointment);

    yield put(addAppointmentSuccess(response));
  } catch (error) {
    yield put(addAppointmentFail(error));
  }
}

const addNewAppointment = (appointment) => post(url.ADD_NEW_APPOINTMENT, appointment);

function* fetchUserAppointments({ id }) {
  try {
    const response = yield call(getUserAppointments, id);
    yield put(getUserAppointmentsSuccess(response));
  } catch (error) {
    yield put(getUserAppointmentsFail(error));
  }
}

const getUserAppointments = (id) => get(`${url.GET_USER_APPOINTMENTS}/${id}`);

function* fetchTodayAppointments() {
  try {
    const response = yield call(getTodayAppointments);
    yield put(getTodayAppointmentsSuccess(response));
  } catch (error) {
    yield put(getTodayAppointmentsFail(error));
  }
}

const getTodayAppointments = () => get(url.GET_TODAY_APPOINTMENT);

function* fetchAppointmentCriteria({ payload: body }) {
  try {
    const response = yield call(getAppointmentCriteria, body);
    yield put(getAppointmentCriteriaSuccess(response));
  } catch (error) {
    yield put(getAppointmentCriteriaFail(error));
  }
}

const getAppointmentCriteria = (body) => post(url.APPOINTMENT_CRITERIA, body);

function* appointmentsSaga() {
  yield takeEvery(GET_APPOINTMENTS, fetchAppointments);
  yield takeEvery(ADD_NEW_APPOINTMENT, onAddNewAppointment);
  yield takeEvery(UPDATE_APPOINTMENT, onUpdateAppointment);
  yield takeEvery(DELETE_APPOINTMENT, onDeleteAppointment);
  yield takeEvery(GET_LEAD_APPOINTMENTS, fetchLeadAppointments);
  yield takeEvery(GET_USER_APPOINTMENTS, fetchUserAppointments);
  yield takeEvery(GET_TODAY_APPOINTMENTS, fetchTodayAppointments);
  yield takeEvery(GET_APPOINTMENT_CRITERIA, fetchAppointmentCriteria);
}

export default appointmentsSaga;
