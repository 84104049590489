
/* MEDICAL_OPERATIONS */
export const GET_MEDICAL_OPERATIONS = "GET_MEDICAL_OPERATIONS"
export const GET_MEDICAL_OPERATIONS_SUCCESS = "GET_MEDICAL_OPERATIONS_SUCCESS"
export const GET_MEDICAL_OPERATIONS_FAIL = "GET_MEDICAL_OPERATIONS_FAIL"

/**
 * add MEDICAL_OPERATION
 */
export const ADD_NEW_MEDICAL_OPERATION = "ADD_NEW_MEDICAL_OPERATION"
export const ADD_MEDICAL_OPERATION_SUCCESS = "ADD_MEDICAL_OPERATION_SUCCESS"
export const ADD_MEDICAL_OPERATION_FAIL = "ADD_MEDICAL_OPERATION_FAIL"

/**
 * Edit MEDICAL_OPERATION
 */
export const UPDATE_MEDICAL_OPERATION = "UPDATE_MEDICAL_OPERATION"
export const UPDATE_MEDICAL_OPERATION_SUCCESS = "UPDATE_MEDICAL_OPERATION_SUCCESS"
export const UPDATE_MEDICAL_OPERATION_FAIL = "UPDATE_MEDICAL_OPERATION_FAIL"

/**
 * Delete MEDICAL_OPERATION
 */
export const DELETE_MEDICAL_OPERATION = "DELETE_MEDICAL_OPERATION"
export const DELETE_MEDICAL_OPERATION_SUCCESS = "DELETE_MEDICAL_OPERATION_SUCCESS"
export const DELETE_MEDICAL_OPERATION_FAIL = "DELETE_MEDICAL_OPERATION_FAIL"
