import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, Row } from "reactstrap";
import CustomDualList from "../../components/Common/CustomDualList";
import { useDispatch, useSelector } from "react-redux";

import { getScreens } from "../../store/screen/actions";
import { getScreenActions } from "../../store/screenAction/actions";
import { privilegeActions } from "../../constants/privilegeEnum";

const Privilege = (props) => {
  const dispatch = useDispatch();

  const [existingPrivileges, setExistingPrivileges] = useState([]);
  const [otherPrivileges, setOtherPrivileges] = useState([]);
  const [screenPrivileges, setScreenPrivileges] = useState([]);
  const privileges = [
    { id: 0, value: "LEAD-ALL", label: "LEAD-ALL", isMain: true },
    { id: 1, value: "SELECT", label: "LEAD-SELECT", mainId: 0 },
    { id: 2, value: "UPDATE", label: "LEAD-UPDATE", mainId: 0 },
    { id: 3, value: "INSERT", label: "LEAD-INSERT", mainId: 0 },
    { id: 4, value: "DELETE", label: "LEAD-DELETE", mainId: 0 }
  ];

  const privileges2 = [
    { id: 50, value: "PATIENT-ALL", label: "PATIENT-ALL", isMain: true },
    { id: 5, value: "SELECT", label: "PATIENT-SELECT", mainId: 50 },
    { id: 6, value: "UPDATE", label: "PATIENT-UPDATE", mainId: 50 },
    { id: 7, value: "INSERT", label: "PATIENT-INSERT", mainId: 50 },
    { id: 8, value: "DELETE", label: "PATIENT-DELETE", mainId: 50 },
    { id: 20, value: "PRIV-ALL", label: "PRIV-ALL", isMain: true },
    { id: 5, value: "SELECT", label: "PRIV-SELECT", mainId: 20 },
    { id: 6, value: "UPDATE", label: "PRIV-UPDATE", mainId: 20 },
    { id: 7, value: "INSERT", label: "PRIV-INSERT", mainId: 20 },
    { id: 8, value: "DELETE", label: "PRIV-DELETE", mainId: 20 }
  ];

  const { screens, screenError } = useSelector(state => ({
    screens: state.Screen.screens,
    screenError: state.Screen.error
  }));

  const { screenActions, error } = useSelector(state => ({
    screenActions: state.ScreenAction.screenActions,
    error: state.ScreenAction.error
  }));

  useEffect(() => {
    dispatch(getScreens());
  }, []);

  useEffect(() => {
    dispatch(getScreenActions());
  }, []);

  useEffect(() => {
    const mappedScreenList = screens.map((screen)=>({ ...screen, isMain: true }));
    const mappedScreenActionList = mappedScreenList.map((screen)=>{
      const actionList = [];
      privilegeActions.forEach(action=>actionList.push({
        screenId: screen.id,
        screenActionName:action.name
      }))
    })
    setScreenPrivileges(screens);
  }, [screens]);

  return (
    <div className="page-content">
      <Col xl={12}>
        <Row>
          <Col xxl={3}>
            <Card>
              <CardBody>

              </CardBody>
            </Card>
          </Col>
          <Col xxl={9}>
            <CustomDualList leftList={privileges} rightList={privileges2} leftTitle={"Mevcut Yetkiler"}
                            rightTitle={"Verilen Yetkiler"} hasSub={true} />
          </Col>
        </Row>
      </Col>
    </div>
  );
};

Privilege.propTypes = {
  t: PropTypes.any,
  terms: PropTypes.any,
  policy: PropTypes.any,
  callbackHide: PropTypes.func
};
export default withTranslation()(Privilege);