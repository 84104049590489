import { call, put, takeEvery } from "redux-saga/effects";

// UserTerms Redux States
import {
  GET_LATEST_CONTRACTS,
  GET_USER_POLICY,
  GET_USER_TERMS,
  POST_USER_POLICY,
  POST_USER_TERMS, SAVE_ALL_CONTRACTS
} from "./actionTypes";

import {
  getLatestContractsFail,
  getLatestContractsSuccess,
  getUserPolicyFail,
  getUserPolicySuccess,
  getUserTermsFail,
  getUserTermsSuccess,
  postUserPolicyFail,
  postUserPolicySuccess,
  postUserTermsFail,
  postUserTermsSuccess, userContractsSaveAll, userContractsSaveAllFail, userContractsSaveAllSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { get, post } from "../../helpers/api_helper";
import { SAVE_CONTRACTS } from "../../helpers/url_helper";

function* fetchUserTerms({ id, version }) {
  try {
    const response = yield call(getUserTermsInfo, id, version);
    yield put(getUserTermsSuccess(response));
  } catch (error) {
    yield put(getUserTermsFail(error));
  }
}

const getUserTermsInfo = (id, version) => get(url.GET_TERMS_INFO + `/${id}/TERMS_CONDITIONS/${version}`);

function* fetchUserPolicy({ id, version }) {
  try {
    const response = yield call(getUserPolicyInfo, id, version);
    yield put(getUserPolicySuccess(response));
  } catch (error) {
    yield put(getUserPolicyFail(error));
  }
}

const getUserPolicyInfo = (id, version) => get(url.GET_PRIVACY_INFO + `/${id}/PRIVACY_POLICY/${version}`);

function* fetchPostUserTerms({ payload: req }) {
  try {
    const response = yield call(postUserTermsInfo, req);
    yield put(postUserTermsSuccess(response));
  } catch (error) {
    yield put(postUserTermsFail(error));
  }
}

const postUserTermsInfo = (req) => post(url.POST_TERMS_INFO, req);

function* fetchPostUserPolicy({ payload: req }) {
  try {
    const response = yield call(postUserPolicyInfo, req);
    yield put(postUserPolicySuccess(response));
  } catch (error) {
    yield put(postUserPolicyFail(error));
  }
}

const postUserPolicyInfo = (req) => post(url.POST_PRIVACY_INFO, req);

function* fetchLatestContracts() {
  try {
    const response = yield call(getLatestContracts);
    yield put(getLatestContractsSuccess(response));
  } catch (error) {
    yield put(getLatestContractsFail(error));
  }
}

const getLatestContracts = () => get(url.LATEST_CONTRACTS);

function* saveAllContracts({ payload: req }) {
  try {
    const response = yield call(saveAll, req);
    yield put(userContractsSaveAllSuccess(response));
  } catch (error) {
    yield put(userContractsSaveAllFail(error));
  }
}

const saveAll = (req) => post(url.SAVE_CONTRACTS, req);


function* UserTermsAndPolicySaga() {
  yield takeEvery(GET_USER_TERMS, fetchUserTerms);
  yield takeEvery(POST_USER_TERMS, fetchPostUserTerms);
  yield takeEvery(GET_USER_POLICY, fetchUserPolicy);
  yield takeEvery(POST_USER_POLICY, fetchPostUserPolicy);
  yield takeEvery(GET_LATEST_CONTRACTS, fetchLatestContracts);
  yield takeEvery(SAVE_ALL_CONTRACTS, saveAllContracts);
}

export default UserTermsAndPolicySaga;
