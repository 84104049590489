import {
  GET_APPOINTMENTS,
  GET_APPOINTMENTS_FAIL,
  GET_APPOINTMENTS_SUCCESS,
  ADD_NEW_APPOINTMENT,
  ADD_APPOINTMENT_SUCCESS,
  ADD_APPOINTMENT_FAIL,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAIL,
  DELETE_APPOINTMENT,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_FAIL,
  GET_LEAD_APPOINTMENTS,
  GET_LEAD_APPOINTMENTS_SUCCESS,
  GET_LEAD_APPOINTMENTS_FAIL,
  GET_USER_APPOINTMENTS,
  GET_USER_APPOINTMENTS_SUCCESS,
  GET_USER_APPOINTMENTS_FAIL,
  GET_TODAY_APPOINTMENTS_FAIL,
  GET_TODAY_APPOINTMENTS_SUCCESS,
  GET_TODAY_APPOINTMENTS,
  GET_APPOINTMENT_CRITERIA, GET_APPOINTMENT_CRITERIA_SUCCESS, GET_APPOINTMENT_CRITERIA_FAIL
} from "./actionTypes";

export const getAppointments = () => ({
  type: GET_APPOINTMENTS
});

export const getAppointmentsSuccess = appointments => ({
  type: GET_APPOINTMENTS_SUCCESS,
  payload: appointments
});

export const getAppointmentsFail = error => ({
  type: GET_APPOINTMENTS_FAIL,
  payload: error
});

export const addNewAppointment = appointment => ({
  type: ADD_NEW_APPOINTMENT,
  payload: appointment
});

export const addAppointmentSuccess = appointment => ({
  type: ADD_APPOINTMENT_SUCCESS,
  payload: appointment
});

export const addAppointmentFail = error => ({
  type: ADD_APPOINTMENT_FAIL,
  payload: error
});

export const updateAppointment = appointment => ({
  type: UPDATE_APPOINTMENT,
  payload: appointment
});

export const updateAppointmentSuccess = appointment => ({
  type: UPDATE_APPOINTMENT_SUCCESS,
  payload: appointment
});

export const updateAppointmentFail = error => ({
  type: UPDATE_APPOINTMENT_FAIL,
  payload: error
});

export const deleteAppointment = id => ({
  type: DELETE_APPOINTMENT,
  id
});

export const deleteAppointmentSuccess = (appointment,req) => ({
  type: DELETE_APPOINTMENT_SUCCESS,
  payload: appointment,
  req: req
});

export const deleteAppointmentFail = error => ({
  type: DELETE_APPOINTMENT_FAIL,
  payload: error
});

export const getLeadAppointments = id => ({
  type: GET_LEAD_APPOINTMENTS,
  id
});

export const getLeadAppointmentsSuccess = appointment => ({
  type: GET_LEAD_APPOINTMENTS_SUCCESS,
  payload: appointment
});

export const getLeadAppointmentsFail = error => ({
  type: GET_LEAD_APPOINTMENTS_FAIL,
  payload: error
});

export const getUserAppointments = id => ({
  type: GET_USER_APPOINTMENTS,
  id
});

export const getUserAppointmentsSuccess = appointment => ({
  type: GET_USER_APPOINTMENTS_SUCCESS,
  payload: appointment
});

export const getUserAppointmentsFail = error => ({
  type: GET_USER_APPOINTMENTS_FAIL,
  payload: error
});

export const getTodayAppointments = () => ({
  type: GET_TODAY_APPOINTMENTS
});

export const getTodayAppointmentsSuccess = appointment => ({
  type: GET_TODAY_APPOINTMENTS_SUCCESS,
  payload: appointment
});

export const getTodayAppointmentsFail = error => ({
  type: GET_TODAY_APPOINTMENTS_FAIL,
  payload: error
});


export const getAppointmentCriteria = appointment => ({
  type: GET_APPOINTMENT_CRITERIA,
  payload: appointment
});

export const getAppointmentCriteriaSuccess = appointment => ({
  type: GET_APPOINTMENT_CRITERIA_SUCCESS,
  payload: appointment
});

export const getAppointmentCriteriaFail = error => ({
  type: GET_APPOINTMENT_CRITERIA_FAIL,
  payload: error
});