import {call, put, takeEvery} from "redux-saga/effects";

// Sections Redux States
import {ADD_NEW_SECTION, DELETE_SECTION, GET_SECTIONS, UPDATE_SECTION} from "./actionTypes";

import {
    addSectionFail,
    addSectionSuccess,
    deleteSectionFail,
    deleteSectionSuccess,
    getSectionsFail,
    getSectionsSuccess,
    updateSectionFail,
    updateSectionSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import {del, get, post} from "../../helpers/api_helper";

const lang = localStorage.getItem("i18nextLng").toUpperCase();
function* fetchSections() {
    try {
        const response = yield call(getSections);
        yield put(getSectionsSuccess(response));
    } catch (error) {
        yield put(getSectionsFail(error));
    }
}

const getSections = () => get(url.SECTION_GET_ALL + "/" + lang)

function* onUpdateSection({payload: section}) {
    try {
        const response = yield call(updateSection, section);
        yield put(updateSectionSuccess(response));
    } catch (error) {
        yield put(updateSectionFail(error));
    }
}

const updateSection = (section) => post(url.SECTION_API, section)

function* onDeleteSection({id}) {
    try {
        const response = yield call(deleteSection, id);
        if (response.status === 200) {
            yield put(deleteSectionSuccess(response, id));
        } else {
            yield put(deleteSectionFail(response));
        }
    } catch (error) {
        yield put(deleteSectionFail(error));
    }
}

const deleteSection = (id) => del(`${url.SECTION_API}/${id}`);

function* onAddNewSection({payload: section}) {
    try {
        const response = yield call(addNewSection, section);

        yield put(addSectionSuccess(response));
    } catch (error) {
        yield put(addSectionFail(error));
    }
}

const addNewSection = (section) => post(url.SECTION_API, section);

function* sectionsSaga() {
    yield takeEvery(GET_SECTIONS, fetchSections);
    yield takeEvery(ADD_NEW_SECTION, onAddNewSection);
    yield takeEvery(UPDATE_SECTION, onUpdateSection);
    yield takeEvery(DELETE_SECTION, onDeleteSection);
}

export default sectionsSaga;
