import {
    GET_OFFER_ITEMS,
    GET_OFFER_ITEMS_FAIL,
    GET_OFFER_ITEMS_SUCCESS,
    ADD_NEW_OFFER_ITEM,
    ADD_OFFER_ITEM_SUCCESS,
    ADD_OFFER_ITEM_FAIL,
    UPDATE_OFFER_ITEM,
    UPDATE_OFFER_ITEM_SUCCESS,
    UPDATE_OFFER_ITEM_FAIL,
    DELETE_OFFER_ITEM,
    DELETE_OFFER_ITEM_SUCCESS,
    DELETE_OFFER_ITEM_FAIL, GET_TEMPLATE_OFFER_ITEMS, GET_TEMPLATE_OFFER_ITEMS_SUCCESS, GET_TEMPLATE_OFFER_ITEMS_FAIL
} from "./actionTypes";

export const getOfferItems = () => ({
    type: GET_OFFER_ITEMS,
})

export const getOfferItemsSuccess = operations => ({
    type: GET_OFFER_ITEMS_SUCCESS,
    payload: operations,
})

export const getOfferItemsFail = error => ({
    type: GET_OFFER_ITEMS_FAIL,
    payload: error,
})

export const getTemplateOfferItems = () => ({
    type: GET_TEMPLATE_OFFER_ITEMS,
})

export const getTemplateOfferItemsSuccess = operations => ({
    type: GET_TEMPLATE_OFFER_ITEMS_SUCCESS,
    payload: operations,
})

export const getTemplateOfferItemsFail = error => ({
    type: GET_TEMPLATE_OFFER_ITEMS_FAIL,
    payload: error,
})

export const addNewOfferItem = operation => ({
    type: ADD_NEW_OFFER_ITEM,
    payload: operation,
})

export const addOfferItemSuccess = operation => ({
    type: ADD_OFFER_ITEM_SUCCESS,
    payload: operation,
})

export const addOfferItemFail = error => ({
    type: ADD_OFFER_ITEM_FAIL,
    payload: error,
})

export const updateOfferItem = operation => ({
    type: UPDATE_OFFER_ITEM,
    payload: operation,
})

export const updateOfferItemSuccess = operation => ({
    type: UPDATE_OFFER_ITEM_SUCCESS,
    payload: operation,
})

export const updateOfferItemFail = error => ({
    type: UPDATE_OFFER_ITEM_FAIL,
    payload: error,
})

export const deleteOfferItem = id => ({
    type: DELETE_OFFER_ITEM,
    id,
})

export const deleteOfferItemSuccess = (operation,req) => ({
    type: DELETE_OFFER_ITEM_SUCCESS,
    payload: operation,
    req:req,
})

export const deleteOfferItemFail = error => ({
    type: DELETE_OFFER_ITEM_FAIL,
    payload: error,
})