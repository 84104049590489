import { call, put, takeEvery } from "redux-saga/effects";

// Doctors Redux States
import { ADD_NEW_DOCTOR, DELETE_DOCTOR, GET_DOCTORS, UPDATE_DOCTOR } from "./actionTypes";

import {
    addDoctorFail,
    addDoctorSuccess,
    deleteDoctorFail,
    deleteDoctorSuccess,
    getDoctorsFail,
    getDoctorsSuccess,
    updateDoctorFail,
    updateDoctorSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchDoctors() {
    try {
        const response = yield call(getDoctors);
        yield put(getDoctorsSuccess(response));
    } catch (error) {
        yield put(getDoctorsFail(error));
    }
}

const getDoctors = () => get(url.GET_DOCTORS)

function* onUpdateDoctor({ payload: doctor }) {
    try {
        const response = yield call(updateDoctor, doctor);
        yield put(updateDoctorSuccess(response));
    } catch (error) {
        yield put(updateDoctorFail(error));
    }
}

const updateDoctor = (doctor) => post(url.UPDATE_DOCTOR,doctor)

function* onDeleteDoctor({id}) {
    try {
        const response = yield call(deleteDoctor, id);
        if (response.status === 200){
            yield put(deleteDoctorSuccess(response,id));
        }else{
            yield put(deleteDoctorFail(response));
        }
    } catch (error) {
        yield put(deleteDoctorFail(error));
    }
}

const deleteDoctor = (id) => del(`${url.DELETE_DOCTOR}/${id}`);

function* onAddNewDoctor({ payload: doctor }) {
    try {
        const response = yield call(addNewDoctor, doctor);

        yield put(addDoctorSuccess(response));
    } catch (error) {
        yield put(addDoctorFail(error));
    }
}

const addNewDoctor = (doctor) => post(url.ADD_NEW_DOCTOR,doctor);

function* doctorsSaga() {
    yield takeEvery(GET_DOCTORS, fetchDoctors);
    yield takeEvery(ADD_NEW_DOCTOR, onAddNewDoctor);
    yield takeEvery(UPDATE_DOCTOR, onUpdateDoctor);
    yield takeEvery(DELETE_DOCTOR, onDeleteDoctor);
}

export default doctorsSaga;
