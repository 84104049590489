import React, { useEffect, useState } from "react";
import withRouter from "../../hooks/withRouter"
import { Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getLatestContracts } from "../../store/TermsAndPrivacy/actions";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const [policyTemplate, setPolicyTemplate] = useState({ __html: "" });

  useEffect(() => {
    dispatch(getLatestContracts());
  }, [dispatch]);

  const { contracts } = useSelector(state => ({
    contracts: state.TermsAndPolicy.contracts
  }));

  useEffect(() => {
    if (contracts.length > 0) {
      contracts.forEach(contract => {
        if (contract.contractType === "PRIVACY_POLICY") {
          setPolicyTemplate({ __html: contract.content });
        }
      });

    }
  }, [contracts]);

  //meta title
  document.title = "Privacy Policy";

  return (
    <React.Fragment>
      <Container>
        <div className="page-content">
          <Row className="justify-content-center">
            <span dangerouslySetInnerHTML={policyTemplate} />
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(PrivacyPolicy);

