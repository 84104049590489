import {
  ADD_TRANSLATE_FAIL,
  ADD_TRANSLATE_SUCCESS,
  DELETE_TRANSLATE_FAIL,
  DELETE_TRANSLATE_SUCCESS,
  GET_TRANSLATES_FAIL,
  GET_TRANSLATES_SUCCESS,
  SAVE_ALL_TRANSLATES_FAIL,
  SAVE_ALL_TRANSLATES_SUCCESS,
  TRANSLATE_CRITERIA_FAIL,
  TRANSLATE_CRITERIA_SUCCESS,
  UPDATE_TRANSLATE_FAIL,
  UPDATE_TRANSLATE_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  translates: [],
  error: {},
  page: 0,
  totalCount: 0,
  totalPages: 0
};

const Translates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSLATES_SUCCESS:
      return {
        ...state,
        translates: action.payload
      };

    case GET_TRANSLATES_FAIL:
      return {
        ...state,
        error: action.payload,
        translates: []
      };

    case ADD_TRANSLATE_SUCCESS:
      return {
        ...state,
        translates: [...state.translates, action.payload]
      };

    case ADD_TRANSLATE_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_TRANSLATE_SUCCESS:
      return {
        ...state,
        translates: state.translates.map(translate =>
          translate.id.toString() === action.payload.id.toString()
            ? action.payload
            : translate
        )
      };

    case UPDATE_TRANSLATE_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_TRANSLATE_SUCCESS:
      return {
        ...state,
        translates: state.translates.filter(
          translate => translate.id.toString() !== action.req.toString()
        )
      };

    case DELETE_TRANSLATE_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case SAVE_ALL_TRANSLATES_SUCCESS:
      return {
        ...state,
        translates: [...state.translates, ...action.payload]
      };

    case SAVE_ALL_TRANSLATES_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case TRANSLATE_CRITERIA_SUCCESS:
      return {
        ...state,
        translates: action.payload.content,
        page: action.payload.pageable.pageNumber ? action.payload.pageable.pageNumber : 0,
        totalCount: action.payload.totalElements,
        totalPages: (action.payload.totalPages - 1)
      };

    case TRANSLATE_CRITERIA_FAIL:
      return {
        ...state,
        error: action.payload.data,
        translates: []
      };
    default:
      return {
        ...state,
        error: {}
      };
  }
};

export default Translates;
