
/* PATIENTS */
export const GET_PATIENTS = "GET_PATIENTS"
export const GET_PATIENTS_SUCCESS = "GET_PATIENTS_SUCCESS"
export const GET_PATIENTS_FAIL = "GET_PATIENTS_FAIL"

/**
 * GET PATIENT
 */
export const GET_PATIENT_BY_ID = "GET_PATIENT_BY_ID"
export const GET_PATIENT_BY_ID_SUCCESS = "GET_PATIENT_BY_ID_SUCCESS"
export const GET_PATIENT_BY_ID_FAIL = "GET_PATIENT_BY_ID_FAIL"

/**
 * add PATIENT
 */
export const ADD_NEW_PATIENT = "ADD_NEW_PATIENT"
export const ADD_PATIENT_SUCCESS = "ADD_PATIENT_SUCCESS"
export const ADD_PATIENT_FAIL = "ADD_PATIENT_FAIL"

/**
 * Edit PATIENT
 */
export const UPDATE_PATIENT = "UPDATE_PATIENT"
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS"
export const UPDATE_PATIENT_FAIL = "UPDATE_PATIENT_FAIL"

/**
 * Delete PATIENT
 */
export const DELETE_PATIENT = "DELETE_PATIENT"
export const DELETE_PATIENT_SUCCESS = "DELETE_PATIENT_SUCCESS"
export const DELETE_PATIENT_FAIL = "DELETE_PATIENT_FAIL"

/**
 * ASSIGN PATIENT PATIENT
 */
export const ASSIGN_DOCTOR = "ASSIGN_DOCTOR"
export const ASSIGN_DOCTOR_SUCCESS = "ASSIGN_DOCTOR_SUCCESS"
export const ASSIGN_DOCTOR_FAIL = "ASSIGN_DOCTOR_FAIL"


/**
 * PATIENT Criteria
 */
export const PATIENT_CRITERIA = "PATIENT_CRITERIA"
export const PATIENT_CRITERIA_SUCCESS = "PATIENT_CRITERIA_SUCCESS"
export const PATIENT_CRITERIA_FAIL = "PATIENT_CRITERIA_FAIL"
