const currencies =[
  {
    "name": "USD",
    "code": "USD",
    "symbol": "$",
  },
  {
    "name": "EUR",
    "code": "EUR",
    "symbol": "€",
  },
  {
    "name": "TRY",
    "code": "TRY",
    "symbol": "₺",
  },
  {
    "name": "GBP",
    "code": "GBP",
    "symbol": "£",
  }
]

export {  currencies } ;