
/* LANGUAGES */
export const GET_LANGUAGES = "GET_LANGUAGES"
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS"
export const GET_LANGUAGES_FAIL = "GET_LANGUAGES_FAIL"

/**
 * add LANGUAGE
 */
export const ADD_NEW_LANGUAGE = "ADD_NEW_LANGUAGE"
export const ADD_LANGUAGE_SUCCESS = "ADD_LANGUAGE_SUCCESS"
export const ADD_LANGUAGE_FAIL = "ADD_LANGUAGE_FAIL"

/**
 * Edit LANGUAGE
 */
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE"
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS"
export const UPDATE_LANGUAGE_FAIL = "UPDATE_LANGUAGE_FAIL"

/**
 * Delete LANGUAGE
 */
export const DELETE_LANGUAGE = "DELETE_LANGUAGE"
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS"
export const DELETE_LANGUAGE_FAIL = "DELETE_LANGUAGE_FAIL"
