import {
    GET_LANGUAGES_FAIL,
    GET_LANGUAGES_SUCCESS,
    ADD_LANGUAGE_SUCCESS,
    ADD_LANGUAGE_FAIL,
    UPDATE_LANGUAGE_SUCCESS,
    UPDATE_LANGUAGE_FAIL,
    DELETE_LANGUAGE_SUCCESS,
    DELETE_LANGUAGE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    languages: [],
    error: {},
};

const Languages = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LANGUAGES_SUCCESS:
            return {
                ...state,
                languages: action.payload,
            };

        case GET_LANGUAGES_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_LANGUAGE_SUCCESS:
            return {
                ...state,
                languages: [...state.languages, action.payload],
            };

        case ADD_LANGUAGE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_LANGUAGE_SUCCESS:
            return {
                ...state,
                languages: state.languages.map(language =>
                    language.id.toString() === action.payload.id.toString()
                        ? { language, ...action.payload }
                        : language
                ),
            };

        case UPDATE_LANGUAGE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_LANGUAGE_SUCCESS:
            return {
                ...state,
                languages: state.languages.filter(
                    language => language.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_LANGUAGE_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default Languages;
