
/* PATIENT_APPOINTMENTS */
export const GET_PATIENT_APPOINTMENTS = "GET_PATIENT_APPOINTMENTS"
export const GET_PATIENT_APPOINTMENTS_SUCCESS = "GET_PATIENT_APPOINTMENTS_SUCCESS"
export const GET_PATIENT_APPOINTMENTS_FAIL = "GET_PATIENT_APPOINTMENTS_FAIL"

/**
 * add PATIENT_APPOINTMENT
 */
export const ADD_NEW_PATIENT_APPOINTMENT = "ADD_NEW_PATIENT_APPOINTMENT"
export const ADD_PATIENT_APPOINTMENT_SUCCESS = "ADD_PATIENT_APPOINTMENT_SUCCESS"
export const ADD_PATIENT_APPOINTMENT_FAIL = "ADD_PATIENT_APPOINTMENT_FAIL"

/**
 * Edit PATIENT_APPOINTMENT
 */
export const UPDATE_PATIENT_APPOINTMENT = "UPDATE_PATIENT_APPOINTMENT"
export const UPDATE_PATIENT_APPOINTMENT_SUCCESS = "UPDATE_PATIENT_APPOINTMENT_SUCCESS"
export const UPDATE_PATIENT_APPOINTMENT_FAIL = "UPDATE_PATIENT_APPOINTMENT_FAIL"

/**
 * Delete PATIENT_APPOINTMENT
 */
export const DELETE_PATIENT_APPOINTMENT = "DELETE_PATIENT_APPOINTMENT"
export const DELETE_PATIENT_APPOINTMENT_SUCCESS = "DELETE_PATIENT_APPOINTMENT_SUCCESS"
export const DELETE_PATIENT_APPOINTMENT_FAIL = "DELETE_PATIENT_APPOINTMENT_FAIL"

/**
 * Lead PATIENT_APPOINTMENT
 */
export const GET_LEAD_PATIENT_APPOINTMENTS = "GET_LEAD_PATIENT_APPOINTMENTS"
export const GET_LEAD_PATIENT_APPOINTMENTS_SUCCESS = "GET_LEAD_PATIENT_APPOINTMENTS_SUCCESS"
export const GET_LEAD_PATIENT_APPOINTMENTS_FAIL = "GET_LEAD_PATIENT_APPOINTMENTS_FAIL"


/**
 * Lead PATIENT_APPOINTMENT
 */
export const GET_USER_PATIENT_APPOINTMENTS = "GET_USER_PATIENT_APPOINTMENTS"
export const GET_USER_PATIENT_APPOINTMENTS_SUCCESS = "GET_USER_PATIENT_APPOINTMENTS_SUCCESS"
export const GET_USER_PATIENT_APPOINTMENTS_FAIL = "GET_USER_PATIENT_APPOINTMENTS_FAIL"

/**
 * Today PATIENT_APPOINTMENT
 */
export const GET_TODAY_PATIENT_APPOINTMENTS = "GET_TODAY_PATIENT_APPOINTMENTS"
export const GET_TODAY_PATIENT_APPOINTMENTS_SUCCESS = "GET_TODAY_PATIENT_APPOINTMENTS_SUCCESS"
export const GET_TODAY_PATIENT_APPOINTMENTS_FAIL = "GET_TODAY_PATIENT_APPOINTMENTS_FAIL"


/**
 * APPT CRITERIA
 */
export const GET_PATIENT_APPOINTMENT_CRITERIA = "GET_PATIENT_APPOINTMENT_CRITERIA"
export const GET_PATIENT_APPOINTMENT_CRITERIA_SUCCESS = "GET_PATIENT_APPOINTMENT_CRITERIA_SUCCESS"
export const GET_PATIENT_APPOINTMENT_CRITERIA_FAIL = "GET_PATIENT_APPOINTMENT_CRITERIA_FAIL"

/**
 * add PATIENT With APPT
 */
export const ADD_NEW_PATIENT_WITH_APPT = "ADD_NEW_PATIENT_WITH_APPT"
export const ADD_NEW_PATIENT_WITH_APPT_SUCCESS = "ADD_NEW_PATIENT_WITH_APPT_SUCCESS"
export const ADD_NEW_PATIENT_WITH_APPT_FAIL = "ADD_NEW_PATIENT_WITH_APPT_FAIL"