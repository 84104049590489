import React, { useEffect, useState } from "react";
import withRouter from "../../hooks/withRouter";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  addNewMeetingRoomReservation,
  getMeetingRoomReservations as onGetMeetingRoomReservations,
  getMeetingRoomReservationsByRoom,
  updateMeetingRoomReservation
} from "store/meetingRoomReservation/actions";

//redux
import { useDispatch, useSelector } from "react-redux";

// Column
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import FullCalendar from "@fullcalendar/react";
import BootstrapTheme from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timegridPlugin from "@fullcalendar/timegrid";
import { formatDate } from "../../common/commonFunctions";
import { reservationClass } from "../../components/Calendar/constant";
import Select from "react-select";
import { getMeetingRooms as onGetMeetingRooms } from "store/meetingRoom/actions";
import { userCriteria } from "../../store/users/actions";
import useAuth from "../../hooks/useAuth";

const MeetingRoomReservation = props => {

  //meta title
  document.title = `CRM | ${props.t("MeetingRoomReservations")}`;

  const dispatch = useDispatch();
  const { user } = useAuth();

  const [meetingRoomReservation, setMeetingRoomReservation] = useState(null);

  const locale = localStorage.getItem("I18N_LANGUAGE").toString();

  const initial = {
    startDate: meetingRoomReservation && meetingRoomReservation.startDate || "",
    endDate: meetingRoomReservation && meetingRoomReservation.endDate || "",
    description: meetingRoomReservation && meetingRoomReservation.description || "",
    status: meetingRoomReservation && meetingRoomReservation.status || "",//(AVAILABLE, BOOKED)
    meetingRoom: meetingRoomReservation && meetingRoomReservation.meetingRoom || "",
    user: meetingRoomReservation && meetingRoomReservation.user || "" //rezerve eden user (default loggedin user gelebilir buraya)
  };

  const statusOptions = [
    { label: props.t("BOOKED"), value: "BOOKED" },
    { label: props.t("AVAILABLE"), value: "AVAILABLE" }
  ];

  const { meetingRoomReservations, error } = useSelector(state => ({
    meetingRoomReservations: state.MeetingRoomReservation.meetingRoomReservations,
    error: state.MeetingRoomReservation.error
  }));

  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [slotDuration, setSlotDuration] = useState("00:30");
  const [slotMinTime, setSlotMinTime] = useState("09:00");
  const [slotMaxTime, setSlotMaxTime] = useState("20:00");
  const [disableTimes, setDisableTimes] = useState({});
  const [hiddenDays, setHiddenDays] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedDayEnd, setSelectedDayEnd] = useState("");
  const [warningModal, setWarningModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [meetingRoomOptions, setMeetingRoomOptions] = useState([]);
  const [selectedMeetingRoom, setSelectedMeetingRoom] = useState();
  const [description, setDescription] = useState();
  const [selectedUser, setSelectedUser] = useState({ label: user.fullName, value: user });
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const [id, setId] = useState(null);
  const [selectedFilterMeetingRoom, setSelectedFilterMeetingRoom] = useState();

  const handleRefresh = () => {
    dispatch(onGetMeetingRoomReservations());
  };

  useEffect(() => {
    dispatch(onGetMeetingRoomReservations());
  }, []);

  useEffect(() => {
    dispatch(onGetMeetingRooms());
  }, []);

  useEffect(() => {
    let userReq = Object.assign({ notRole: "ROLE_MODERATOR" });
    dispatch(userCriteria(userReq));
  }, []);

  const { users } = useSelector(state => ({
    users: state.User.users
  }));

  const userOptions = [];
  users.map((item) => {
    userOptions.push({ label: item.fullName, value: item });
  });

  const { meetingRooms } = useSelector(state => ({
    meetingRooms: state.MeetingRoom.meetingRooms
  }));

  useEffect(() => {
    const options = meetingRooms.map((item) => {
      return { label: item.name, value: item };
    });
    setMeetingRoomOptions(options);
  }, [meetingRooms]);

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (meetingRoomReservations) {
      const mapReservationData = async () => {
        const result = meetingRoomReservations.map((item) => {
          return ({
            id: item.id,
            type: "RESERVATION",
            title: item.meetingRoom.name,
            meetingRoom: item.meetingRoom,
            user: item.user,
            description: item.description,
            status: item.status,
            start: new Date(item.startDate),
            end: new Date(item.endDate),
            className: reservationClass[item.status]
          });
        });
        setEvents(result);
      };
      mapReservationData();
    }
  }, [meetingRoomReservations]);

  function checkOverlap(selected) {
    const existingEvents = events;
    const newEvent = selected;
    for (let i = 0; i < existingEvents.length; i++) {
      const existingStart = new Date(existingEvents[i].start);
      const existingEnd = new Date(existingEvents[i].end);
      const newStart = new Date(newEvent.start);
      const newEnd = new Date(newEvent.end);

      if ((newStart > existingStart && newStart < existingEnd) ||
        (newEnd > existingStart && newEnd < existingEnd) ||
        (newStart < existingEnd && existingStart < newEnd) ||
        (newStart < existingStart && newEnd > existingEnd) ||
        (newEnd.getTime() === existingStart.getTime() - 60000) ||
        (newStart.getTime() === existingEnd.getTime() - 60000)) {
        setWarningModal(true);
        return false;
      }
    }
    return true;
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setSelectedDay("");
      setSelectedDayEnd("");
      setIsEdit(false);
    } else {
      setModal(true);
    }
  };

  function handleSelect(arg) {
    const convertedDateStart = formatDate(arg.startStr);
    const convertedDateEnd = formatDate(arg.endStr);
    setSelectedDay(convertedDateStart);
    setSelectedDayEnd(convertedDateEnd);

    if (props.external) {
      props.callbackSelectedDateStart(convertedDateStart);
      props.callbackSelectedDateEnd(convertedDateEnd);
    }
    toggle();
  }

  const handleEventClick = arg => {
    setIsEdit(true);
    const event = arg.event;
    const filtered = events.filter(item => item.id && item.id.toString() === event._def.publicId)[0];
    setSelectedDay(formatDate(filtered.start));
    setSelectedDayEnd(formatDate(filtered.end));
    setSelectedStatus({ label: props.t(filtered.status), value: filtered.status });
    setSelectedUser({ label: filtered.user.fullName, value: filtered.user });
    setSelectedMeetingRoom({ label: filtered.meetingRoom.name, value: filtered.meetingRoom });
    setId(filtered.id);
    setDescription(filtered.description);
    toggle();
  };

  function handleChangeStart(e) {
    const convertedDate = formatDate(e.target.value);
    setSelectedDay(convertedDate);
  }

  function handleChangeEnd(e) {
    const convertedDate = formatDate(e.target.value);
    setSelectedDayEnd(convertedDate);
  }

  function handleChangeMeetingRoomOption(e) {
    setSelectedMeetingRoom(e);
  }

  function handleChangeFilterMeetingRoomOption(e) {
    setSelectedFilterMeetingRoom(e);
  }

  function handleChangeDescription(e) {
    setDescription(e.target.value);
  }

  function handleChangeUser(event) {
    setSelectedUser(event);
  }

  function saveAppointment() {
    if (isEdit) {
      const req = {
        id: id,
        meetingRoom: selectedMeetingRoom.value,
        description: description,
        user: selectedUser.value,
        startDate: selectedDay,
        endDate: selectedDayEnd,
        status: selectedStatus.value
      };
      dispatch(updateMeetingRoomReservation(req));
    } else {
      const req = {
        meetingRoom: selectedMeetingRoom.value,
        description: description,
        user: selectedUser.value,
        startDate: selectedDay,
        endDate: selectedDayEnd,
        status: selectedStatus.value
      };
      dispatch(addNewMeetingRoomReservation(req));
    }
    toggle();
  }

  function handleChangeStatus(event) {
    setSelectedStatus(event);
  }

  function getSelectedRoomReservations() {
    dispatch(getMeetingRoomReservationsByRoom(selectedFilterMeetingRoom.value.id));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Meeting Reservations")} />
          <Row>
            <Card>
              <CardBody>
                <Row>
                  <Col className="col-4">
                    <div className="mb-2">
                      <Label
                        className="form-label">{props.t("Meeting Room")}</Label>
                      <Select
                        value={selectedFilterMeetingRoom}
                        onChange={(event) => handleChangeFilterMeetingRoomOption(event)}
                        options={meetingRoomOptions}
                        className="bs-select"
                        isClearable={true}
                      />
                    </div>
                  </Col>
                  <Col className="col-5"></Col>
                  <Col className="col-3">
                    <div className="ms-5 mt-4 me-0">
                      <button
                        id="getSelectedRoomReservations"
                        type="submit"
                        className="btn btn-outline-primary"
                        onClick={() => getSelectedRoomReservations()}
                        disabled={!(selectedFilterMeetingRoom && selectedFilterMeetingRoom.value)}
                      >
                        {props.t("Get Selected Room Reservations")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Modal isOpen={modal} className={props.className}>
              <ModalHeader toggle={toggle} tag="h4">
                {isEdit ? props.t("Event Detail") : props.t("New Event")}
              </ModalHeader>
              <ModalBody>
                {props.external ? (
                  props.eventComponent
                ) : (
                  <Row className="m-2">
                    <Col className="col-12">
                      <div className="mb-2">
                        <Label
                          className="form-label">{props.t("Meeting Room")}</Label>
                        <Select
                          value={selectedMeetingRoom}
                          onChange={(event) => handleChangeMeetingRoomOption(event)}
                          options={meetingRoomOptions}
                          className="bs-select"
                          isClearable={true}
                        />
                      </div>
                      <Col className="col-12 mb-3">
                        <Label className="form-label">{props.t("Start")}</Label>
                        <Input
                          name="start"
                          type="datetime-local"
                          onChange={handleChangeStart}
                          value={selectedDay || ""}
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <Label className="form-label">{props.t("End")}</Label>
                        <Input
                          name="end"
                          type="datetime-local"
                          onChange={handleChangeEnd}
                          value={selectedDayEnd || ""}
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <Label className="form-label">{props.t("User")}</Label>
                        <Select
                          value={selectedUser}
                          onChange={(event) => handleChangeUser(event)}
                          options={userOptions}
                          className="bs-select"
                          isClearable={true}
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <Label className="form-label">{props.t("Status")}</Label>
                        <Select
                          value={selectedStatus}
                          onChange={(event) => handleChangeStatus(event)}
                          options={statusOptions}
                          className="bs-select"
                          isClearable={true}
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <Label className="form-label">{props.t("Description")}</Label>
                        <Input
                          id="description"
                          name="description"
                          type="text"
                          onChange={handleChangeDescription}
                          value={description || ""}
                        />
                      </Col>
                    </Col>
                    <Row className="m-2">
                      <Col>
                        <div className="text-end mt-2">
                          <button
                            id="save"
                            type="submit"
                            className="btn btn-success"
                            onClick={() => saveAppointment()}
                          >
                            {props.t("Save")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                )}
              </ModalBody>
            </Modal>
            <Card>
              <CardBody>
                <FullCalendar
                  plugins={[
                    BootstrapTheme,
                    dayGridPlugin,
                    interactionPlugin,
                    timegridPlugin
                  ]}
                  themeSystem="bootstrap"
                  initialView="timeGridWeek"
                  slotDuration={slotDuration}
                  handleWindowResize={true}
                  expandRows={true}
                  displayEventTime={true}
                  displayEventEnd={true}
                  eventTextColor="black"
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay"
                  }}
                  buttonText={{
                    today: props.t("Today"),
                    month: props.t("Month"),
                    week: props.t("Week"),
                    day: props.t("Day")
                  }}
                  selectConstraint={disableTimes}
                  allDaySlot={false}
                  slotMinTime={slotMinTime || "07:30"}
                  slotMaxTime={slotMaxTime || "22:00"}
                  locales="allLocales"
                  locale={locale || "en"}
                  firstDay={1} //Pazartesi manasında
                  hiddenDays={hiddenDays}// hide Tuesdays and Thursdays
                  weekends={true}
                  events={events}
                  eventResizableFromStart={true}
                  editable={false}
                  droppable={false}
                  selectable={true}
                  eventClick={handleEventClick}
                  select={handleSelect}
                  selectAllow={checkOverlap}
                  eventOverlap={false}
                />
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
MeetingRoomReservation.propTypes = {
  meetingRoomReservations: PropTypes.array,
  onGetMeetingRoomReservations: PropTypes.func,
  onAddNewMeetingRoomReservation: PropTypes.func,
  onDeleteMeetingRoomReservation: PropTypes.func,
  onUpdateMeetingRoomReservation: PropTypes.func
};

export default withRouter(withTranslation()(MeetingRoomReservation));
