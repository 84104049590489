import React, { useEffect, useState } from "react";
import { Button, Container, Input, Label } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CardWithBars from "../../components/Common/CardWithBars";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as url from "../../helpers/url_helper";
import { post } from "../../helpers/axios_with_headers";

const Report = (props) => {

  const [reports, setReports] = useState([]);

  const [filterCreateDateStart, setFilterCreateDateStart] = useState("");
  const [filterCreateDateEnd, setFilterCreateDateEnd] = useState("");
  const [filterUpdateDateStart, setFilterUpdateDateStart] = useState("");
  const [filterUpdateDateEnd, setFilterUpdateDateEnd] = useState("");

  const body = { createDateStart: filterCreateDateStart, createDateEnd: filterCreateDateEnd, updateDateStart: filterUpdateDateStart, updateDateEnd: filterUpdateDateEnd };

  useEffect(() => {
    post(url.LEAD_REPORTS_BY_DATE_CRITERIA, body).then(response => setReports(response.data));
  }, []);

  function handleFilterCreateDateStart(e) {
    setFilterCreateDateStart(e.target.value);
  }

  function handleFilterCreateDateEnd(e) {
    setFilterCreateDateEnd(e.target.value);
  }

  function handleFilterUpdateDateStart(e) {
    setFilterUpdateDateStart(e.target.value);
  }

  function handleFilterUpdateDateEnd(e) {
    setFilterUpdateDateEnd(e.target.value);
  }

  const handleFilteredLeadData = () => {
    post(url.LEAD_REPORTS_BY_DATE_CRITERIA, body).then(response => setReports(response.data));
  };

  const handleClearFilterInputs = () => {
    setFilterCreateDateStart("");
    setFilterCreateDateEnd("");
    setFilterUpdateDateStart("");
    setFilterUpdateDateEnd("");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("User Reports")} />
          <div className="row">
            <div className="mb-3 col-2">
              <Label className="form-label">{props.t("Create Date Start")}</Label>
              <Input
                id="create-date-start"
                name="create-date-start"
                type="date"
                onChange={e => handleFilterCreateDateStart(e)}
                value={filterCreateDateStart}
              />
            </div>
            <div className="mb-3 col-2">
              <Label className="form-label">{props.t("Create Date End")}</Label>
              <Input
                id="create-date-end"
                name="create-date-end"
                type="date"
                onChange={e => handleFilterCreateDateEnd(e)}
                value={filterCreateDateEnd}
              />
            </div>
            <div className="mb-3 col-2">
              <Label className="form-label">{props.t("Update Date Start")}</Label>
              <Input
                id="update-date-start"
                name="update-date-start"
                type="date"
                onChange={e => handleFilterUpdateDateStart(e)}
                value={filterUpdateDateStart}
              />
            </div>
            <div className="mb-3 col-2">
              <Label className="form-label">{props.t("Update Date End")}</Label>
              <Input
                id="update-date-end"
                name="update-date-end"
                type="date"
                onChange={e => handleFilterUpdateDateEnd(e)}
                value={filterUpdateDateEnd}
              />
            </div>
            <div className="mt-4 col-3">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-0"
                onClick={() => handleFilteredLeadData()}
              >
                <i className="mdi mdi-search-web me-1" />
                {props.t("Filter")}
              </Button>
              <Button
                type="button"
                color="warning"
                className="btn-rounded mb-0"
                onClick={() => handleClearFilterInputs()}
              >
                <i className="mdi mdi-format-clear me-1" />
                {props.t("Clear All Filters")}
              </Button>
            </div>
          </div>
          {reports && reports.length > 0 ? reports.map(report => {
              return (
                // eslint-disable-next-line react/jsx-key
                <CardWithBars
                  title={report.user.fullName}
                  segmentCounts={report.segmentCounts}
                  categoryCounts={report.categoryCounts}
                  resourceCounts={report.resourceCount}
                  referenceCounts={report.referenceCount}
                />
              );
            }
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};
Report.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Report);