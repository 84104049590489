import { call, put, takeEvery } from "redux-saga/effects";

// Notes Redux States
import { ADD_NEW_NOTE, DELETE_NOTE, GET_NOTES, UPDATE_NOTE, GET_LEAD_NOTES } from "./actionTypes";

import {
  addNoteFail,
  addNoteSuccess,
  deleteNoteFail,
  deleteNoteSuccess,
  getNotesFail,
  getNotesSuccess,
  updateNoteFail,
  updateNoteSuccess,getLeadNotesFail, getLeadNotesSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { get, post, del } from "../../helpers/api_helper";

function* fetchLeadNotes({id}) {
  try {
    const response = yield call(getLeadNotes,id);
    yield put(getLeadNotesSuccess(response));
  } catch (error) {
    yield put(getLeadNotesFail(error));
  }
}

const getLeadNotes = (id) => get(`${url.GET_LEAD_NOTES}/${id}`);

function* fetchNotes() {
  try {
    const response = yield call(getNotes);
    yield put(getNotesSuccess(response));
  } catch (error) {
    yield put(getNotesFail(error));
  }
}

const getNotes = () => get(url.GET_NOTES)

function* onUpdateNote({ payload: note }) {
  try {
    const response = yield call(updateNote, note);
    yield put(updateNoteSuccess(response));
  } catch (error) {
    yield put(updateNoteFail(error));
  }
}

const updateNote = (note) => post(url.UPDATE_NOTE,note)

function* onDeleteNote({id}) {
  try {
    const response = yield call(deleteNote, id);
    yield put(deleteNoteSuccess(response, id));
  } catch (error) {
    yield put(deleteNoteFail(error));
  }
}

const deleteNote = (id) => del(`${url.DELETE_NOTE}/${id}`);

function* onAddNewNote({ payload: note }) {
  try {
    const response = yield call(addNewNote, note);

    yield put(addNoteSuccess(response));
  } catch (error) {
    yield put(addNoteFail(error));
  }
}

const addNewNote = (note) => post(url.ADD_NEW_NOTE,note);

function* notesSaga() {
  yield takeEvery(GET_NOTES, fetchNotes);
  yield takeEvery(ADD_NEW_NOTE, onAddNewNote);
  yield takeEvery(UPDATE_NOTE, onUpdateNote);
  yield takeEvery(DELETE_NOTE, onDeleteNote);
  yield takeEvery(GET_LEAD_NOTES, fetchLeadNotes);
}

export default notesSaga;
