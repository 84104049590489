import {
    GET_SEGMENTS,
    GET_SEGMENTS_FAIL,
    GET_SEGMENTS_SUCCESS,
    ADD_NEW_SEGMENT,
    ADD_SEGMENT_SUCCESS,
    ADD_SEGMENT_FAIL,
    UPDATE_SEGMENT,
    UPDATE_SEGMENT_SUCCESS,
    UPDATE_SEGMENT_FAIL,
    DELETE_SEGMENT,
    DELETE_SEGMENT_SUCCESS,
    DELETE_SEGMENT_FAIL,
} from "./actionTypes"

export const getSegments = () => ({
    type: GET_SEGMENTS,
})

export const getSegmentsSuccess = segments => ({
    type: GET_SEGMENTS_SUCCESS,
    payload: segments,
})

export const getSegmentsFail = error => ({
    type: GET_SEGMENTS_FAIL,
    payload: error,
})

export const addNewSegment = segment => ({
    type: ADD_NEW_SEGMENT,
    payload: segment,
})

export const addSegmentSuccess = segment => ({
    type: ADD_SEGMENT_SUCCESS,
    payload: segment,
})

export const addSegmentFail = error => ({
    type: ADD_SEGMENT_FAIL,
    payload: error,
})

export const updateSegment = segment => ({
    type: UPDATE_SEGMENT,
    payload: segment,
})

export const updateSegmentSuccess = segment => ({
    type: UPDATE_SEGMENT_SUCCESS,
    payload: segment,
})

export const updateSegmentFail = error => ({
    type: UPDATE_SEGMENT_FAIL,
    payload: error,
})

export const deleteSegment = id => ({
    type: DELETE_SEGMENT,
    id,
})

export const deleteSegmentSuccess = (segment,req) => ({
    type: DELETE_SEGMENT_SUCCESS,
    payload: segment,
    req:req,
})

export const deleteSegmentFail = error => ({
    type: DELETE_SEGMENT_FAIL,
    payload: error,
})