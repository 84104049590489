import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Collapse, Input, Label, Modal, ModalBody, Row, Table, UncontrolledTooltip } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-select";
import { deleteOffer, getTemplateOffers as onGetOffers } from "../../../store/offer/actions";
import { getTemplateOfferItems as onGetOfferItems } from "../../../store/offerItem/actions";
import { updateLead as onUpdateLead } from "../../../store/leads/actions";
import { getDescriptionTemplates as onGetDescriptionTemplate } from "../../../store/descriptionTemplate/actions";
import BasicTable from "../../../components/Common/BasicTable";
import { CreateDate, CreateUser } from "../CustCol";
import { currencies } from "../../../constants/CurrenciesEnum";
import OfferTemplate from "./OfferTemplate";
import WarningModal from "../../../components/Common/WarningModal";
import { findSymbol } from "../../../common/commonFunctions";
import useAuth from "../../../hooks/useAuth";

const LeadOffer = props => {
  const { authUser } = useAuth();

  const dispatch = useDispatch();

  const initialOfferItem = {
    name: "",
    totalAmount: 0,
    amount: 0,
    taxAmount: 0,
    taxPercentage: 0
  };

  const [offer, setOffer] = useState("");
  const [selectedOffer, setSelectedOffer] = useState();
  const [lead, setLead] = useState(props.lead);
  const [operations, setOperations] = useState([]);
  const [offerHistoryOpen, setOfferHistoryOpen] = useState(false);
  const [offerItemList, setOfferItemList] = useState([]);
  const [templateModal, setTemplateModal] = useState(false);
  const [offerList, setOfferList] = useState(props.lead.offers);
  const [selectedMulti, setSelectedMulti] = useState(null);
  const [offerItem, setOfferItem] = useState(initialOfferItem);
  const [selectedOfferItem, setSelectedOfferItem] = useState(null);
  const [selectedTax, setSelectedTax] = useState({ label: "", value: "" });
  const [selectedOfferCurr, setSelectedOfferCurr] = useState(null);
  const [descriptionTemplate, setDescriptionTemplate] = useState();
  const [shownOffer, setShownOffer] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const toggleOfferInfo = () => setOfferHistoryOpen(!offerHistoryOpen);

  const addOfferItemToListAndCreateEmptyOne = () => {
    const list = offerItemList;
    list.push(offerItem);
    setOfferItemList(list);

    setOffer({
      ...offer,
      offerItems: offerItemList
    });
    setSelectedTax({ label: "", value: "" });
    setOfferItem(initialOfferItem);
    setSelectedOfferItem(null);
  };

  const toggleOffer = () => {
    setOfferItem(initialOfferItem);
    setOffer({});
    setSelectedOffer();
    setOperations([]);
    setOfferItemList([]);
    setSelectedMulti(null);
    setSelectedOfferItem(null);
    setSelectedTax({ label: "", value: "" });
    setSelectedOfferCurr(null);
  };

  useEffect(() => {
    setLead(props.lead);
  }, [props.lead]);

  useEffect(() => {
    setOfferList(lead.offers);
  }, [lead && lead.offers]);

  const onClickRemoveFromList = (item) => {
    setOfferItemList(offerItemList.filter(listItem => listItem !== item));
    setOffer({
      ...offer,
      offerItems: offerItemList.filter(listItem => listItem !== item)
    });
  };

  useEffect(() => {
    calculateAllOfferFields();
  }, [offerItemList.length]);

  useEffect(() => {
    dispatch(onGetOffers());
  }, []);

  useEffect(() => {
    dispatch(onGetOfferItems());
  }, []);

  useEffect(() => {
    dispatch(onGetDescriptionTemplate());
  }, []);

  const onChangeTaxPercentage = (e) => {
    setSelectedTax(e);
    if (e !== "" && e !== null) {
      setOfferItem({
        ...offerItem,
        ["taxPercentage"]: e.value
      });
    } else {
      setOfferItem({
        ...offerItem,
        ["taxPercentage"]: 0
      });
    }
  };

  const onChangeOffer = (e) => {
    setSelectedOffer(e);
    if (e !== "" && e !== null) {
      setOffer(e.value);
      setSelectedOfferCurr({ label: e.value.currency, value: e.value.currency });
      if (e.value && e.value.descriptionTemplate){
        setDescriptionTemplate({ label: e.value.descriptionTemplate.name, value: e.value.descriptionTemplate });
      }
    }
  };

  const onChangeOfferItem = (e) => {
    setSelectedOfferItem(e);
    if (e !== "" && e !== null) {
      setOfferItem(e.value);
      setSelectedTax({ label: e.value.taxPercentage, value: e.value.taxPercentage });
    } else {
      setOfferItem(initialOfferItem);
      setSelectedTax(null);
    }
  };

  const onSelectOfferCurr = (e) => {
    setSelectedOfferCurr(e);
    if (e !== "" && e !== null) {
      setOffer({
        ...offer,
        currency: e.value.code
      });
    }
  };

  const { offerItems } = useSelector(state => ({
    offerItems: state.OfferItem.offerItems
  }));

  const offerItemOptions = [{ label: `${props.t("Create")}`, value: initialOfferItem }];
  offerItems.forEach((item) => {
    offerItemOptions.push({ label: item.name, value: { ...item, isTemplate: false } });
  });

  useEffect(() => {
    if (offer && offer.offerItems) {
      setOfferItemList(offer.offerItems);
    }
  }, [offer && offer.offerItems]);

  const toggleTemplateModal = (offerData) => {
    if (templateModal) {
      setTemplateModal(false);
    } else {
      setTemplateModal(true);
      setShownOffer(offerData);
    }
  };

  const { leads } = useSelector(state => ({
    leads: state.Lead.leads
  }));

  useEffect(() => {
    const filtered = leads.filter(lead => lead.id === props.lead.id);
    setLead(filtered[0]);
    toggleOffer();
  }, [dispatch, leads]);

  const { offers } = useSelector(state => ({
    offers: state.Offer.offers
  }));

  const { descriptionTemplates } = useSelector(state => ({
    descriptionTemplates: state.DescriptionTemplate.descriptionTemplates
  }));

  const offerOptions = [{ label: `${props.t("Create")}`, value: {} }];
  offers.forEach((item) => {
    offerOptions.push({ label: item.name, value: { ...item, isTemplate: false } });
  });

  const descriptionTemplateOptions = [];
  descriptionTemplates.forEach(item => {
    descriptionTemplateOptions.push({
      label: item.name,
      value: item
    });
  });

  const handleChangeOfferField = (e) => {
    setOffer({
      ...offer,
      [e.target.name]: e.target.value
    });
    setSelectedOffer({
      label: `${props.t("Create")}`,
      value: {
        ...offer.value,
        [e.target.name]: e.target.value
      }
    });
  };

  function calculateAllOfferFields() {
    if (offer !== undefined && offer.offerItems && offer.offerItems.length >= 0) {
      let amount = 0;
      let totalAmount = 0;
      let taxPercentage = 0;
      let taxAmount = 0;
      offer.offerItems.forEach(offerItem => {
        amount = +offerItem.amount + +amount;
        totalAmount = +offerItem.totalAmount + +totalAmount;
        taxPercentage = offerItem.taxPercentage;
        taxAmount = +offerItem.taxAmount + +taxAmount;
      });

      setOffer({
        ...offer,
        ["amount"]: amount,
        ["totalAmount"]: totalAmount,
        ["taxPercentage"]: taxPercentage,
        ["taxAmount"]: taxAmount
      });
    }
  };

  useEffect(() => {
    if (offerItem.taxPercentage !== 0 && offerItem.amount !== 0 || offerItem.amount !== 0 && offerItem.taxAmount !== 0) {
      const taxAmount = ((offerItem.taxPercentage / 100) * offerItem.amount);
      const totalAmount = (+offerItem.amount + +taxAmount);
      setOfferItem({
        ...offerItem,
        ["taxAmount"]: taxAmount,
        ["totalAmount"]: totalAmount
      });
      setSelectedOfferItem({
        label: offerItem.name !== "" && offerItem.name !== undefined ? offerItem.name : `${props.t("Create")}`,
        value: {
          ...offerItem.value,
          ["taxAmount"]: taxAmount,
          ["totalAmount"]: totalAmount
        }
      });
    } else {
      setOfferItem({
        ...offerItem,
        ["totalAmount"]: offerItem.amount
      });
      setSelectedOfferItem({
        label: `${props.t("Create")}`,
        value: {
          ...offerItem.value,
          ["totalAmount"]: offerItem.amount
        }
      });
    }
  }, [offerItem.taxAmount, offerItem.taxPercentage, offerItem.amount]);

  const handleChangeOfferItemField = (e) => {
    setOfferItem({
      ...offerItem,
      [e.target.name]: e.target.value
    });
    setSelectedOfferItem({
      label: `${props.t("Create")}`,
      value: {
        ...offerItem.value,
        [e.target.name]: e.target.value
      }
    });
  };

  function saveLeadAndChanges() {
    let updateLead = Object.assign({}, lead);
    updateLead.offers = [...lead.offers, offer];
    dispatch(onUpdateLead(updateLead));
  }

  function handleMulti(eventValue) {
    setSelectedMulti(eventValue);
    setOffer({
      ...offer,
      medicalOperations: eventValue.map((select) => select.value)
    });
  }

  const offerColumns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Amount")}`,
        Header: "Amount",
        accessor: "amount",
        filterable: true,
        Cell: (cellProps) => {
          return <CreateUser {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Tax amount")}`,
        Header: "Tax amount",
        accessor: "taxAmount",
        filterable: true,
        Cell: (cellProps) => {
          return <CreateUser {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Total amount")}`,
        Header: "Total amount",
        accessor: "totalAmount",
        filterable: true,
        Cell: (cellProps) => {
          return <CreateUser {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Currency")}`,
        Header: "Currency",
        accessor: "currency",
        filterable: true,
        Cell: (cellProps) => {
          return <CreateUser {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        className: "w-25",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Button
                onClick={() => {
                  const offerData = cellProps.row.original;
                  toggleTemplateModal(offerData);
                }}
                color="success"
                outline
              >
                <i className="fa fa-arrow-circle-up me-2" id="show-template" />
                {props.t("Show Offer Template")} & {props.t("Download")}
              </Button>
              <Button
                color="danger"
                outline
                onClick={() => {
                  const offerData = cellProps.row.original;
                  onClickDelete(offerData);
                }}
                disabled={authUser && authUser.role && authUser.role.name === "ROLE_USER"}
              >
                <i className="mdi mdi-delete font-size-14" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Button>
            </div>
          );
        }
      }
    ],
    [offers]
  );

  const handleChangeDescriptionTemplate = (e) => {
    setDescriptionTemplate(e);
    if (e !== null) {
      setOffer({
        ...offer,
        descriptionTemplate: e.value
      });
    }
  };

  useEffect(() => {
    if (lead.medicalOperations !== undefined) {
      const operationArr = lead.medicalOperations.length > 0 && lead.medicalOperations.map((item, key) => {
        return ({
          label: `${item.operationDefinition.name} (${item.operationDate})`,
          value: item
        });
      });
      setOperations(operationArr);
    }
  }, [lead.medicalOperations]);

  const onClickDelete = (data) => {
    setOffer(data);
    setDeleteModal(true);
  };

  const handleDelete = () => {
    if (offer.id) {
      const otherOffers = lead.offers.filter(prev => prev.id !== offer.id);
      let updateLead = Object.assign({}, lead);
      updateLead.offers = otherOffers;
      dispatch(deleteOffer(offer.id));
      dispatch(onUpdateLead(updateLead));
      setDeleteModal(false);
    }
  };

  return (
    <div className="container-fluid p-3">
      <div className="row m-2">
        <WarningModal
          show={deleteModal}
          onApproveClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Row className="mb-3">
          <Row className="mb-3">
            <Col className="col-sm-12 col-md-6 col-xl-6">
              <Row>
                <Col className="col-sm-12 col-md-4 col-xl-4 mt-2">
                  <Label className="bs-select">{props.t("Choose Offer")} :</Label>
                </Col>
                <Col className="col-sm-12 col-md-8 col-xl-8">
                  <Select
                    value={selectedOffer}
                    onChange={(event) => onChangeOffer(event)}
                    options={offerOptions}
                    className="bs-select"
                    isClearable={true}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-sm-12 col-md-6 col-xl-6">
              <Row>
                <Col className="col-sm-12 col-md-4 col-xl-4 mt-2">
                  <Label className="bs-select">{props.t("Choose Operation")} :</Label>
                </Col>
                <Col className="col-sm-12 col-md-8 col-xl-8">
                  <Select
                    options={operations && operations.length > 0 && operations || []}
                    value={selectedMulti}
                    onChange={(event) => handleMulti(event)}
                    className="bs-select"
                    isClearable={true}
                    isMulti={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="card border-0 shadow-none mt-3 mb-2">
            <Row className="col-md-12 col-xs-12">
              <div className="mb-3 col-md-4 col-xs-12">
                <Label className="form-label">{props.t("Name")}</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={handleChangeOfferField}
                  value={offer && offer.name || ""}
                  invalid={offer.name === undefined || offer.name === ""}
                />
              </div>

              <div className="mb-3 col-md-2 col-xs-12">
                <Label className="form-label">{props.t("Amount")}</Label>
                <Input
                  name="amount"
                  type="number"
                  onChange={handleChangeOfferField}
                  disabled={true}
                  value={offer && offer.amount || 0}
                />
              </div>

              <Col className="mb-3 col-md-2 col-xs-12">
                <Label className="form-label">{props.t("Tax Amount")}</Label>
                <Input
                  name="taxAmount"
                  type="number"
                  onChange={handleChangeOfferField}
                  disabled={true}
                  value={offer && offer.taxAmount || 0}
                />
              </Col>

              <div className="mb-3 col-md-2 col-xs-12">
                <Label className="form-label">{props.t("Total Amount")}</Label>
                <Input
                  name="total"
                  type="number"
                  disabled={true}
                  onChange={handleChangeOfferField}
                  value={offer && offer.totalAmount || 0}
                />
              </div>

              <Col className="mb-3 col-md-2 col-xs-12">
                <Label className="form-label">{props.t("Currency")} <i
                  className="mdi mdi-alert-circle-outline error font-size-16 me-1" /></Label>
                <Select
                  value={selectedOfferCurr}
                  onChange={(event) => onSelectOfferCurr(event)}
                  options={currencies.map(curr => {
                    return { label: curr.code, value: curr };
                  })}
                  className="bs-select"
                  isClearable={true}
                />
              </Col>

              <div className="mb-3 col-md-6 col-xs-12">
                <Label className="form-label">{props.t("Note")}</Label>
                <Input
                  name="description"
                  type="text"
                  placeholder={props.t("This field will not be shown in the offer")}
                  onChange={handleChangeOfferField}
                  value={offer && offer.description || ""}
                />
              </div>

              <div className="mb-3 col-md-6 col-xs-12">
                <Label className="form-label">{props.t("Description Template")}</Label>
                <Select
                  value={descriptionTemplate}
                  onChange={(event) => handleChangeDescriptionTemplate(event)}
                  options={descriptionTemplateOptions}
                  className="bs-select"
                  isClearable={true}
                />
              </div>
            </Row>
          </div>
          <Row className="col-sm-12 col-md-12 col-xl-12">
            <div className="table-nowrap">
              {offerItemList && offerItemList.length > 0 && (
                <Table className="table-centered table-nowrap">
                  <thead>
                  <tr>
                    <th scope="col">{props.t("Item")}</th>
                    <th scope="col">{props.t("Amount")}</th>
                    <th scope="col">{props.t("Tax Amount")}</th>
                    <th scope="col">{props.t("Total Amount")}</th>
                    <th scope="col-1">{props.t("Action")}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {offerItemList.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{item.name}</td>
                        <td>{item.amount} {offer.currency && findSymbol(offer.currency) || ""}</td>
                        <td>{item.taxAmount} {offer.currency && findSymbol(offer.currency) || ""}</td>
                        <td>{item.totalAmount} {offer.currency && findSymbol(offer.currency) || ""}</td>
                        <td>
                          <div className="flex-md-wrap">
                            <Button
                              color="danger"
                              outline
                              onClick={() => onClickRemoveFromList(item)}
                            >
                              <i className="mdi mdi-minus-circle font-size-16" id="removeTooltip" />
                              <UncontrolledTooltip placement="top" target="removeTooltip">
                                {props.t("Remove From List")}
                              </UncontrolledTooltip>
                            </Button>
                          </div>
                        </td>
                      </tr>);
                  })
                  }
                  </tbody>
                </Table>
              )}
              <Row>
                <Col className="col-2">
                  <Label className="bs-select">{props.t("Choose Offer Item")} :</Label>
                  <Select
                    value={selectedOfferItem}
                    onChange={(event) => onChangeOfferItem(event)}
                    options={offerItemOptions}
                    className="bs-select"
                    isClearable={true}
                  />
                </Col>
                <Col className="col-9">
                  <Row>
                    <Col className="col-3">
                      <Label className="form-label">{props.t("Name")}</Label>
                      <Input
                        name="name"
                        onChange={handleChangeOfferItemField}
                        value={offerItem && offerItem.name || ""}
                        invalid={offerItem.name === ""}
                      />
                    </Col>

                    <Col className="col-2">
                      <Label className="form-label">{props.t("Amount")}</Label>
                      <Input
                        name="amount"
                        type="text"
                        onChange={handleChangeOfferItemField}
                        value={offerItem && offerItem.amount || ""}
                      />
                    </Col>

                    <Col className="col-2">
                      <Label className="form-label">{props.t("Tax Percentage")} (%)</Label>
                      <Select
                        value={selectedTax}
                        onChange={(event) => onChangeTaxPercentage(event)}
                        options={[{ label: "10", value: 10 }, { label: "20", value: 20 }]}
                        className="bs-select"
                        isClearable={true}
                      />
                    </Col>

                    <Col className="col-2">
                      <Label className="form-label">{props.t("Tax Amount")}</Label>
                      <Input
                        name="taxAmount"
                        type="text"
                        onChange={handleChangeOfferItemField}
                        disabled={true}
                        value={offerItem && offerItem.taxAmount || ""}
                      />
                    </Col>

                    <Col className="col-3">
                      <Label className="form-label">{props.t("Total Amount")}</Label>
                      <Input
                        name="totalAmount"
                        type="text"
                        onChange={handleChangeOfferItemField}
                        value={offerItem && offerItem.totalAmount || ""}
                        invalid={offerItem.totalAmount === 0}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="col-1 text-end mt-4">
                  <Button
                    color="warning"
                    outline
                    onClick={addOfferItemToListAndCreateEmptyOne}
                    disabled={offerItem.name === "" || offerItem.totalAmount === ""}
                  >
                    <i className="mdi mdi-plus-circle font-size-16" id="addToList">
                      {props.t("Add")}
                    </i>
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
        </Row>
      </div>
      <div className="row m-4">
        <Button
          color="success bg-success-subtle"
          outline
          onClick={saveLeadAndChanges}
          disabled={offerItemList.length === 0 || selectedOfferCurr === null || offer.name === ""}
        >
          <i className="mdi mdi-content-save-all font-size-16" id="addTooltip">
            {props.t("Save")}
          </i>
        </Button>
      </div>
      <div className="col-md-12 col-xs-12 m-2">
        <div className="custom-accordion">
          <Link
            className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
            onClick={toggleOfferInfo}
            to="#"
          >
            {props.t("Previous Offers")}
            <i
              className={
                offerHistoryOpen
                  ? "mdi mdi-chevron-up accor-down-icon ms-2"
                  : "mdi mdi-chevron-down accor-down-icon ms-2"
              }
            />
          </Link>
          <Collapse isOpen={offerHistoryOpen}>
            {offerList && offerList.length > 0 && (
              <div className="row mt-3">
                <div className="col-xs-12 col-md-12 mt-4 me-3">
                  <BasicTable
                    id="offers"
                    customPageSize={20}
                    columns={offerColumns}
                    data={offerList}
                    className="table-of-contents"
                  />
                </div>
              </div>
            )}
          </Collapse>
        </div>
      </div>
      <Modal toggle={toggleTemplateModal} isOpen={templateModal} size="xl">
        <ModalBody>
          <div className="print-div-custom">
            <OfferTemplate
              lead={lead}
              offerDetail={shownOffer}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

LeadOffer.propTypes = {
  lead: PropTypes.any,
  saveChanges: PropTypes.func,
  sendNewOperations: PropTypes.any,
  updatedLead: PropTypes.any
};

export default withTranslation()(LeadOffer);
