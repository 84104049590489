import {
  ADD_NEW_MEETING_ROOM_RESERVATION,
  ADD_MEETING_ROOM_RESERVATION_FAIL,
  ADD_MEETING_ROOM_RESERVATION_SUCCESS,
  DELETE_MEETING_ROOM_RESERVATION,
  DELETE_MEETING_ROOM_RESERVATION_FAIL,
  DELETE_MEETING_ROOM_RESERVATION_SUCCESS,
  GET_MEETING_ROOM_RESERVATIONS,
  GET_MEETING_ROOM_RESERVATIONS_FAIL,
  GET_MEETING_ROOM_RESERVATIONS_SUCCESS,
  GET_MEETING_ROOM_RESERVATIONS_BY_ROOM_SUCCESS,
  GET_MEETING_ROOM_RESERVATIONS_BY_ROOM_FAIL,
  GET_MEETING_ROOM_RESERVATIONS_BY_ROOM,
  GET_USER_MEETING_ROOM_RESERVATIONS,
  GET_USER_MEETING_ROOM_RESERVATIONS_FAIL,
  GET_USER_MEETING_ROOM_RESERVATIONS_SUCCESS,
  UPDATE_MEETING_ROOM_RESERVATION,
  UPDATE_MEETING_ROOM_RESERVATION_FAIL,
  UPDATE_MEETING_ROOM_RESERVATION_SUCCESS
} from "./actionTypes";

export const getMeetingRoomReservations = () => ({
  type: GET_MEETING_ROOM_RESERVATIONS
});

export const getMeetingRoomReservationsSuccess = meetingRoomReservations => ({
  type: GET_MEETING_ROOM_RESERVATIONS_SUCCESS,
  payload: meetingRoomReservations
});

export const getMeetingRoomReservationsFail = error => ({
  type: GET_MEETING_ROOM_RESERVATIONS_FAIL,
  payload: error
});

export const addNewMeetingRoomReservation = meetingRoomReservation => ({
  type: ADD_NEW_MEETING_ROOM_RESERVATION,
  payload: meetingRoomReservation
});

export const addMeetingRoomReservationSuccess = meetingRoomReservation => ({
  type: ADD_MEETING_ROOM_RESERVATION_SUCCESS,
  payload: meetingRoomReservation
});

export const addMeetingRoomReservationFail = error => ({
  type: ADD_MEETING_ROOM_RESERVATION_FAIL,
  payload: error
});

export const updateMeetingRoomReservation = meetingRoomReservation => ({
  type: UPDATE_MEETING_ROOM_RESERVATION,
  payload: meetingRoomReservation
});

export const updateMeetingRoomReservationSuccess = meetingRoomReservation => ({
  type: UPDATE_MEETING_ROOM_RESERVATION_SUCCESS,
  payload: meetingRoomReservation
});

export const updateMeetingRoomReservationFail = error => ({
  type: UPDATE_MEETING_ROOM_RESERVATION_FAIL,
  payload: error
});

export const deleteMeetingRoomReservation = id => ({
  type: DELETE_MEETING_ROOM_RESERVATION,
  id
});

export const deleteMeetingRoomReservationSuccess = (meetingRoomReservation,req) => ({
  type: DELETE_MEETING_ROOM_RESERVATION_SUCCESS,
  payload: meetingRoomReservation,
  req:req,
});

export const deleteMeetingRoomReservationFail = error => ({
  type: DELETE_MEETING_ROOM_RESERVATION_FAIL,
  payload: error
});

export const getUserMeetingRoomReservations = id => ({
  type: GET_USER_MEETING_ROOM_RESERVATIONS,
  id
});

export const getUserMeetingRoomReservationsSuccess = meetingRoomReservation => ({
  type: GET_USER_MEETING_ROOM_RESERVATIONS_SUCCESS,
  payload: meetingRoomReservation
});

export const getUserMeetingRoomReservationsFail = error => ({
  type: GET_USER_MEETING_ROOM_RESERVATIONS_FAIL,
  payload: error
});

export const getMeetingRoomReservationsByRoom = id => ({
  type: GET_MEETING_ROOM_RESERVATIONS_BY_ROOM,
  id
});

export const getMeetingRoomReservationsByRoomSuccess = meetingRoomReservation => ({
  type: GET_MEETING_ROOM_RESERVATIONS_BY_ROOM_SUCCESS,
  payload: meetingRoomReservation
});

export const getMeetingRoomReservationsByRoomFail = error => ({
  type: GET_MEETING_ROOM_RESERVATIONS_BY_ROOM_FAIL,
  payload: error
});
