import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAuthLogs } from "../../store/authLog/actions";
import useAuth from "../../hooks/useAuth";

const LastLogins = (props) => {
  const dispatch = useDispatch();

  const { authUser } = useAuth();

  useEffect(() => {
    if (authUser && authUser.id !== undefined) {
      const body = { page: 0, size: 5 };
      dispatch(getAuthLogs(body));
    }
  }, []);

  const [response, setResponse] = useState([]);

  const { authLogCriteria, error } = useSelector(state => ({
    authLogCriteria: state.AuthLog.authLogCriteria,
    error: state.AuthLog.error
  }));

  useEffect(() => {
    if (authLogCriteria.content) {
      setResponse(authLogCriteria.content);
    }
  }, [authLogCriteria]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">{props.t("Latest Login Attempts")}</CardTitle>
          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
          </div>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-wrap">
              <tbody>
              {response !== undefined && response.length > 0 ? response.map((res, key) => {
                return (
                  <tr key={key} id={key}>
                    <td style={{ width: "35%" }}>
                      <p className="mb-0">{res.loginDate.split(" ")[0]}</p>
                    </td>
                    <td style={{ width: "15%" }}>
                      <p className="mb-0">{res.loginDate.split(" ")[1].slice(0, 5)}</p>
                    </td>
                    <td style={{ width: "15%" }}>
                      <p className="mb-0">{res.loggedInUser !== null ? res.loggedInUser.fullName : res.username}</p>
                    </td>
                    <td style={{ width: "25%" }}>
                      <p className="mb-0">{res.ipAddress}</p>
                    </td>
                    <td style={{ width: "10%" }}>
                      {res.status !== null ? (res.status !== "SUCCESS" ? (
                        <i className="bx bx-x text-danger font-size-22" />) : (
                        <i className="bx bx-check text-success font-size-22" />)) : ("")}
                    </td>
                  </tr>
                );
              }) : ""}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default LastLogins;
