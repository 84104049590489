import { call, put, takeEvery } from "redux-saga/effects";

// ScreenActions Redux States
import { ADD_NEW_SCREEN_ACTION, DELETE_SCREEN_ACTION, GET_SCREEN_ACTIONS, UPDATE_SCREEN_ACTION } from "./actionTypes";

import {
  addScreenActionFail,
  addScreenActionSuccess,
  deleteScreenActionFail,
  deleteScreenActionSuccess,
  getScreenActionsFail,
  getScreenActionsSuccess,
  updateScreenActionFail,
  updateScreenActionSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { get, post, del } from "../../helpers/api_helper";

function* fetchScreenActions() {
  try {
    const response = yield call(getScreenActions);
    yield put(getScreenActionsSuccess(response));
  } catch (error) {
    yield put(getScreenActionsFail(error));
  }
}

const getScreenActions = () => get(url.GET_SCREEN_ACTIONS)

function* onUpdateScreenAction({ payload: screenAction }) {
  try {
    const response = yield call(updateScreenAction, screenAction);
    yield put(updateScreenActionSuccess(response));
  } catch (error) {
    yield put(updateScreenActionFail(error));
  }
}

const updateScreenAction = (screenAction) => post(url.UPDATE_SCREEN_ACTION,screenAction)

function* onDeleteScreenAction({id}) {
  try {
    const response = yield call(deleteScreenAction, id);
    yield put(deleteScreenActionSuccess(response, id));
  } catch (error) {
    yield put(deleteScreenActionFail(error));
  }
}

const deleteScreenAction = (id) => del(`${url.DELETE_SCREEN_ACTION}/${id}`);

function* onAddNewScreenAction({ payload: screenAction }) {
  try {
    const response = yield call(addNewScreenAction, screenAction);

    yield put(addScreenActionSuccess(response));
  } catch (error) {
    yield put(addScreenActionFail(error));
  }
}

const addNewScreenAction = (screenAction) => post(url.ADD_NEW_SCREEN_ACTION,screenAction);

function* screenActionsSaga() {
  yield takeEvery(GET_SCREEN_ACTIONS, fetchScreenActions);
  yield takeEvery(ADD_NEW_SCREEN_ACTION, onAddNewScreenAction);
  yield takeEvery(UPDATE_SCREEN_ACTION, onUpdateScreenAction);
  yield takeEvery(DELETE_SCREEN_ACTION, onDeleteScreenAction);
}

export default screenActionsSaga;
