import { call, put, takeEvery } from "redux-saga/effects";

// PatientReminders Redux States
import {
  ADD_NEW_PATIENT_REMINDER,
  DELETE_PATIENT_REMINDER,
  GET_PATIENT_REMINDER_COUNT,
  GET_PATIENT_REMINDERS,
  GET_USER_PATIENT_REMINDERS,
  UPDATE_PATIENT_REMINDER
} from "./actionTypes";

import {
  addPatientReminderFail,
  addPatientReminderSuccess,
  deletePatientReminderFail,
  deletePatientReminderSuccess,
  getPatientReminderCountFail,
  getPatientReminderCountSuccess,
  getPatientRemindersFail,
  getPatientRemindersSuccess,
  getUserPatientRemindersFail,
  getUserPatientRemindersSuccess,
  updatePatientReminderFail,
  updatePatientReminderSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchLeadPatientReminders({ id }) {
  try {
    const response = yield call(getLeadPatientReminders, id);
    yield put(getPatientRemindersSuccess(response));
  } catch (error) {
    yield put(getPatientRemindersFail(error));
  }
}

const getLeadPatientReminders = (id) => get(`${url.GET_PATIENT_REMINDER}/${id}`);

function* onUpdatePatientReminder({ payload: patientReminder }) {
  try {
    const response = yield call(updatePatientReminder, patientReminder);
    yield put(updatePatientReminderSuccess(response));
  } catch (error) {
    yield put(updatePatientReminderFail(error));
  }
}

const updatePatientReminder = (patientReminder) => post(url.UPDATE_PATIENT_REMINDER, patientReminder);

function* onDeletePatientReminder({ id }) {
  try {
    const response = yield call(deletePatientReminder, id);
    yield put(deletePatientReminderSuccess(response, id));
  } catch (error) {
    yield put(deletePatientReminderFail(error));
  }
}

const deletePatientReminder = (id) => del(`${url.DELETE_PATIENT_REMINDER}/${id}`);

function* onAddNewPatientReminder({ payload: patientReminder }) {
  try {
    const response = yield call(addNewPatientReminder, patientReminder);
    yield put(addPatientReminderSuccess(response));
  } catch (error) {
    yield put(addPatientReminderFail(error));
  }
}

const addNewPatientReminder = (patientReminder) => post(url.ADD_NEW_PATIENT_REMINDER, patientReminder);

function* fetchUserPatientReminders({ id }) {
  try {
    const response = yield call(getUserPatientReminders, id);
    yield put(getUserPatientRemindersSuccess(response));
  } catch (error) {
    yield put(getUserPatientRemindersFail(error));
  }
}

const getUserPatientReminders = (id) => get(`${url.GET_USER_PATIENT_REMINDERS}/${id}`);

function* fetchPatientReminderCount() {
  try {
    const response = yield call(getPatientReminderCount);
    yield put(getPatientReminderCountSuccess(response));
  } catch (error) {
    yield put(getPatientReminderCountFail(error));
  }
}

const getPatientReminderCount = () => get(url.GET_NOTIFICATION_COUNT);

function* patientRemindersSaga() {
  yield takeEvery(ADD_NEW_PATIENT_REMINDER, onAddNewPatientReminder);
  yield takeEvery(UPDATE_PATIENT_REMINDER, onUpdatePatientReminder);
  yield takeEvery(DELETE_PATIENT_REMINDER, onDeletePatientReminder);
  yield takeEvery(GET_PATIENT_REMINDERS, fetchLeadPatientReminders);
  yield takeEvery(GET_USER_PATIENT_REMINDERS, fetchUserPatientReminders);
  yield takeEvery(GET_PATIENT_REMINDER_COUNT, fetchPatientReminderCount);
}

export default patientRemindersSaga;
