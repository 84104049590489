import {
  ADD_USER,
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_BY_ROLE,
  GET_USERS_BY_ROLE_FAIL,
  GET_USERS_BY_ROLE_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  RESET_PASSWORD, RESET_PASSWORD_FAIL, RESET_PASSWORD_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  USER_CRITERIA,
  USER_CRITERIA_FAIL,
  USER_CRITERIA_SUCCESS
} from "./actionTypes";

export const getUsers = () => ({
  type: GET_USERS
});

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users
});

export const addNewUser = user => ({
  type: ADD_USER,
  payload: user
});

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user
});

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error
});

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error
});

export const getUserProfile = (id) => ({
  type: GET_USER_PROFILE,
  payload: id
});

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile
});

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error
});

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user
});

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user
});

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error
});

export const deleteUser = id => ({
  type: DELETE_USER,
  payload: id
});

export const deleteUserSuccess = (user, req) => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
  req: req
});

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error
});


export const getUsersByRole = role => ({
  type: GET_USERS_BY_ROLE,
  payload: role
});

export const getUsersByRoleSuccess = role => ({
  type: GET_USERS_BY_ROLE_SUCCESS,
  payload: role
});

export const getUsersByRoleFail = error => ({
  type: GET_USERS_BY_ROLE_FAIL,
  payload: error
});

export const changePassword = user => ({
  type: CHANGE_PASSWORD,
  payload: user
});

export const changePasswordSuccess = user => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: user
});

export const changePasswordFail = error => ({
  type: CHANGE_PASSWORD_FAIL,
  payload: error
});

export const userCriteria = res => ({
  type: USER_CRITERIA,
  payload: res
});

export const userCriteriaSuccess = (res) => ({
  type: USER_CRITERIA_SUCCESS,
  payload: res
});

export const userCriteriaFail = error => ({
  type: USER_CRITERIA_FAIL,
  payload: error
});


export const resetPassword = user => ({
  type: RESET_PASSWORD,
  payload: user
});

export const resetPasswordSuccess = user => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: user
});

export const resetPasswordFail = error => ({
  type: RESET_PASSWORD_FAIL,
  payload: error
});