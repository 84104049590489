import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../hooks/withRouter"
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";


import {
  addNewForm as onAddNewForm,
  deleteForm as onDeleteForm,
  getForms as onGetForms,
  updateForm as onUpdateForm
} from "store/IntegrationDefinition/actions";

import { getPlatforms as onGetPlatforms } from "store/platform/actions";
import { getCategories as onGetCategories } from "store/category/actions";
import { getFacebookForms as onGetFacebookForms } from "store/FacebookForms/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Category, Language, Name, Platform } from "./integrationDefinitionCol";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { getLanguages as onGetLanguages } from "../../store/language/actions";
import WarningModal from "../../components/Common/WarningModal";
import Notification from "../../components/Notification";

const IntegrationDefinition = props => {

  //meta title
  document.title = `CRM | ${props.t("Forms")}`;

  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [socialForms, setSocialForms] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [socialForm, setSocialForm] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedFormName, setSelectedFormName] = useState(null);
  const [language, setLanguage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [selectedCategory, setSelectedCategory] = useState(null);

  // validation
  const validation = {

    values: {
      facebookForm: (socialForm && socialForm.facebookForm) || null,
      language: (socialForm && socialForm.language) || null,
      platform: (socialForm && socialForm.platform) || null,
      category: (socialForm && socialForm.category) || null
    },

    handleSubmit: (values) => {
      if (isEdit) {
        const updateForm = {
          id: socialForm ? socialForm.id : 0,
          facebookForm: values.facebookForm,
          platform: values.platform,
          language: values.language,
          category: values.category
        };
        dispatch(onUpdateForm(updateForm));
      } else {
        const newSocialForm = {
          facebookForm: values["facebookForm"],
          platform: values["platform"],
          language: values["language"],
          category: values["category"]
        };
        // save new form
        dispatch(onAddNewForm(newSocialForm));
      }
      toggle();
    }
  };

  const { languages, error } = useSelector(state => ({
    languages: state.Language.languages,
    error: state.Language.error
  }));

  const languageOptions = [];
  languages.map((item) => {
    languageOptions.push({ label: item.name, value: item });
  });

  const { platforms } = useSelector(state => ({
    platforms: state.Platform.platforms
  }));

  const platformOptions = [];
  platforms.map((item) => {
    platformOptions.push({ label: item.name, value: item });
  });

  const { facebookForms } = useSelector(state => ({
    facebookForms: state.FacebookForm.facebookForms
  }));

  const formNameOptions = [];
  facebookForms.map((item) => {
    formNameOptions.push({ label: item.formName, value: item });
  });

  const { categories } = useSelector(state => ({
    categories: state.Category.categories
  }));

  const categoryOptions = [];
  categories.map((item) => {
    categoryOptions.push({ label: item.translatedName ? item.translatedName : item.name, value: item });
  });

  function handleChangeFormName(event) {
    setSocialForm({
      ...socialForm,
      ["facebookForm"]: event.value
    });
    setSelectedFormName(event);
  }

  function handleLanguage(selected) {
    setSocialForm({
      ...socialForm,
      ["language"]: selected.value
    });
    setLanguage(selected);
  }

  function handleChangePlatform(event) {
    setSocialForm({
      ...socialForm,
      ["platform"]: event.value
    });
    setSelectedPlatform(event);
  }

  function handleChangeCategory(event) {
    setSocialForm({
      ...socialForm,
      ["category"]: event.value
    });
    setSelectedCategory(event);
  }

  const handleFormClick = arg => {
    const form = arg.original;

    setSocialForm({
      id: form.id,
      platform: form.platform,
      language: form.language,
      category: form.category,
      facebookForm: form.facebookForm
    });
    if (form && form.facebookForm) {
      setSelectedFormName({ label: form.facebookForm.formName, value: form.facebookForm });
    }
    if (form && form.platform) {
      setSelectedPlatform({ label: form.platform.name, value: form.platform });
    }
    if (form && form.language) {
      setLanguage({ label: form.language.name, value: form.language });
    }
    if (form && form.category) {
      setSelectedCategory({ label: form.category.name, value: form.category });
    }

    setIsEdit(true);
    toggle();
  };

  // Customber Column
  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Form Name")}`,
        Header: "İsim",
        accessor: "facebookForm.formName",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Platform")}`,
        Header: "Kod",
        accessor: "platform.name",
        filterable: true,
        Cell: (cellProps) => {
          return <Platform {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Category")}`,
        Header: "Category",
        accessor: "category.name",
        filterable: true,
        Cell: (cellProps) => {
          return <Category {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Language")}`,
        Header: "Languages",
        accessor: "language.name",
        filterable: true,
        Cell: cellProps => {
          return <Language {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const formData = cellProps.row.original;
                  onClickDelete(formData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setSocialForm(null);
      setSelectedPlatform(null);
      setLanguage(null);
      setSelectedCategory(null);
      setSelectedFormName(null);
    } else {
      setModal(true);
    }
  };


  //delete form
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (socialForm) => {
    setSocialForm(socialForm);
    setDeleteModal(true);
  };

  const handleDeleteForm = () => {
    if (socialForm.id) {
      dispatch(onDeleteForm(socialForm.id));
      setDeleteModal(false);
    }
  };

  const { forms } = useSelector(state => ({
    forms: state.IntegrationDefinition.forms
  }));


  useEffect(() => {
    if (firstCall) {
      dispatch(onGetForms());
      setFirstCall(false);
    }
  }, [dispatch, forms]);

  useEffect(() => {
    dispatch(onGetLanguages());
  }, []);

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetPlatforms());
      setFirstCall(false);
    }
  }, [dispatch, platforms]);

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetCategories());
      setFirstCall(false);
    }
  }, [dispatch, categories]);

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetFacebookForms());
      setFirstCall(false);
    }
  }, [dispatch, facebookForms]);

  const handleFormClicks = () => {
    setSocialForms("");
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetForms());
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteForm}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Integration Definition")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={forms}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleFormClicks}
                    handleRowClick={handleFormClick}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Form Name")}
                                {(selectedFormName === null) &&
                                  <span style={{ "fontSize": "10px", "color": "darkred" }}> * </span>}
                              </Label>
                              <Select
                                value={selectedFormName}
                                onChange={(event) => {
                                  handleChangeFormName(event);
                                }}
                                options={formNameOptions}
                                className="select2-selection"
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Platform")}</Label>
                              <Select
                                value={selectedPlatform}
                                onChange={(event) => {
                                  handleChangePlatform(event);
                                }}
                                options={platformOptions}
                                className="select2-selection"
                              />
                            </div>
                            <div className="mb-3">
                              <label className="control-label">
                                {props.t("CSV Import Language")}
                              </label>
                              <Select
                                value={language}
                                onChange={(event) => {
                                  handleLanguage(event);
                                }}
                                options={languageOptions}
                                className="select2-selection"
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Category")}</Label>
                              <Select
                                value={selectedCategory}
                                onChange={(event) => {
                                  handleChangeCategory(event);
                                }}
                                options={categoryOptions}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-lead"
                                disabled={ selectedFormName === null || selectedFormName !== null && selectedFormName.value === ""}
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
IntegrationDefinition.propTypes = {
  forms: PropTypes.array,
  onGetForms: PropTypes.func,
  onAddNewForm: PropTypes.func,
  onDeleteForm: PropTypes.func,
  onUpdateForm: PropTypes.func
};

export default withRouter(withTranslation()(IntegrationDefinition));
