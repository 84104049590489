import { call, put, takeEvery } from "redux-saga/effects";

// Payments Redux States
import {
  ADD_NEW_PAYMENT,
  ANNUAL_PAYMENT,
  DELETE_PAYMENT,
  GET_PAYMENTS,
  GET_UNPAID_PAYMENTS, INSTANT_USER_PAYMENT,
  PAYMENT_PROCESS,
  UPDATE_PAYMENT,
  UPDATE_USER_COUNT_AND_PRICE
} from "./actionTypes";

import {
  addPaymentFail,
  addPaymentSuccess,
  createAnnualPaymentFail,
  createAnnualPaymentSuccess, createInstantUserPaymentFail, createInstantUserPaymentSuccess,
  deletePaymentFail,
  deletePaymentSuccess,
  getPaymentsFail,
  getPaymentsSuccess,
  getUnpaidPaymentsFail,
  getUnpaidPaymentsSuccess,
  paymentProcessFail,
  paymentProcessSuccess,
  updatePaymentFail,
  updatePaymentSuccess,
  updateUserCountAndPriceFail,
  updateUserCountAndPriceSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";
import { ADD_USER_PAYMENT } from "../../helpers/url_helper";
import {deleteSegmentSuccess} from "../segment/actions";

function* fetchPayments() {
  try {
    const response = yield call(getPayments);
    yield put(getPaymentsSuccess(response));
  } catch (error) {
    yield put(getPaymentsFail(error));
  }
}

const getPayments = () => get(url.GET_PAYMENTS);

function* onUpdatePayment({ payload: payment }) {
  try {
    const response = yield call(updatePayment, payment);
    yield put(updatePaymentSuccess(response));
  } catch (error) {
    yield put(updatePaymentFail(error));
  }
}

const updatePayment = (payment) => post(url.UPDATE_PAYMENT, payment);

function* onDeletePayment({ id }) {
  try {
    const response = yield call(deletePayment, id);
    yield put(deletePaymentSuccess(response,id));
  } catch (error) {
    yield put(deletePaymentFail(error));
  }
}

const deletePayment = (id) => del(`${url.DELETE_PAYMENT}/${id}`);

function* onAddNewPayment({ payload: payment }) {
  try {
    const response = yield call(addNewPayment, payment);

    yield put(addPaymentSuccess(response));
  } catch (error) {
    yield put(addPaymentFail(error));
  }
}

const addNewPayment = (payment) => post(url.ADD_NEW_PAYMENT, payment);

function* fetchUnpaidPayments() {
  try {
    const response = yield call(getUnpaidPayments);
    yield put(getUnpaidPaymentsSuccess(response));
  } catch (error) {
    yield put(getUnpaidPaymentsFail(error));
  }
}

const getUnpaidPayments = () => get(url.UNPAID_PAYMENTS);

function* fetchUpdateUserCountPrice({ payload: payments }) {
  try {
    const response = yield call(updatePriceAndUserCount, payments);
    yield put(updateUserCountAndPriceSuccess(response));
  } catch (error) {
    yield put(updateUserCountAndPriceFail(error));
  }
}

const updatePriceAndUserCount = (payments) => post(url.UPDATE_USER_COUNT_PRICE, payments);

function* fetchPaymentProcess({ payload: request }) {
  try {
    const response = yield call(sendPaymentProcess, request);
    yield put(paymentProcessSuccess(response));
  } catch (error) {
    yield put(paymentProcessFail(error));
  }
}

const sendPaymentProcess = (payments) => post(url.PAYMENT_PROCESS_URL, payments);

function* fetchAnnualPayment({ payload: request }) {
  try {
    const response = yield call(createAnnualPayments, request);
    yield put(createAnnualPaymentSuccess(response));
  } catch (error) {
    yield put(createAnnualPaymentFail(error));
  }
}

const createAnnualPayments = (req) => get(url.CREATE_PAYMENT + "/" + req);


function* fetchInstantPayment({ payload: request }) {
  try {
    const response = yield call(createInstantPayments, request);
    yield put(createInstantUserPaymentSuccess(response));
  } catch (error) {
    yield put(createInstantUserPaymentFail(error));
  }
}

const createInstantPayments = (req) => get(url.ADD_USER_PAYMENT + "/" + req);

function* paymentsSaga() {
  yield takeEvery(GET_PAYMENTS, fetchPayments);
  yield takeEvery(ADD_NEW_PAYMENT, onAddNewPayment);
  yield takeEvery(UPDATE_PAYMENT, onUpdatePayment);
  yield takeEvery(DELETE_PAYMENT, onDeletePayment);
  yield takeEvery(GET_UNPAID_PAYMENTS, fetchUnpaidPayments);
  yield takeEvery(UPDATE_USER_COUNT_AND_PRICE, fetchUpdateUserCountPrice);
  yield takeEvery(ANNUAL_PAYMENT, fetchAnnualPayment);
  yield takeEvery(PAYMENT_PROCESS, fetchPaymentProcess);
  yield takeEvery(INSTANT_USER_PAYMENT, fetchInstantPayment);
}

export default paymentsSaga;
