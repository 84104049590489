
/* PATIENT_REMINDERS */
export const GET_PATIENT_REMINDERS = "GET_PATIENT_REMINDERS"
export const GET_PATIENT_REMINDERS_SUCCESS = "GET_PATIENT_REMINDERS_SUCCESS"
export const GET_PATIENT_REMINDERS_FAIL = "GET_PATIENT_REMINDERS_FAIL"

/**
 * add PATIENT_REMINDER
 */
export const ADD_NEW_PATIENT_REMINDER = "ADD_NEW_PATIENT_REMINDER"
export const ADD_PATIENT_REMINDER_SUCCESS = "ADD_PATIENT_REMINDER_SUCCESS"
export const ADD_PATIENT_REMINDER_FAIL = "ADD_PATIENT_REMINDER_FAIL"

/**
 * Edit PATIENT_REMINDER
 */
export const UPDATE_PATIENT_REMINDER = "UPDATE_PATIENT_REMINDER"
export const UPDATE_PATIENT_REMINDER_SUCCESS = "UPDATE_PATIENT_REMINDER_SUCCESS"
export const UPDATE_PATIENT_REMINDER_FAIL = "UPDATE_PATIENT_REMINDER_FAIL"

/**
 * Delete PATIENT_REMINDER
 */
export const DELETE_PATIENT_REMINDER = "DELETE_PATIENT_REMINDER"
export const DELETE_PATIENT_REMINDER_SUCCESS = "DELETE_PATIENT_REMINDER_SUCCESS"
export const DELETE_PATIENT_REMINDER_FAIL = "DELETE_PATIENT_REMINDER_FAIL"


/**
 * Lead PATIENT_REMINDER
 */
export const GET_USER_PATIENT_REMINDERS = "GET_USER_PATIENT_REMINDERS"
export const GET_USER_PATIENT_REMINDERS_SUCCESS = "GET_USER_PATIENT_REMINDERS_SUCCESS"
export const GET_USER_PATIENT_REMINDERS_FAIL = "GET_USER_PATIENT_REMINDERS_FAIL"

/**
 * Today PATIENT_REMINDER
 */
export const GET_TODAY_PATIENT_REMINDERS = "GET_TODAY_PATIENT_REMINDERS"
export const GET_TODAY_PATIENT_REMINDERS_SUCCESS = "GET_TODAY_PATIENT_REMINDERS_SUCCESS"
export const GET_TODAY_PATIENT_REMINDERS_FAIL = "GET_TODAY_PATIENT_REMINDERS_FAIL"

/**
 * PATIENT_REMINDER CRITERIA
 */
export const GET_PATIENT_REMINDER_CRITERIA = "GET_PATIENT_REMINDER_CRITERIA"
export const GET_PATIENT_REMINDER_CRITERIA_SUCCESS = "GET_PATIENT_REMINDER_CRITERIA_SUCCESS"
export const GET_PATIENT_REMINDER_CRITERIA_FAIL = "GET_PATIENT_REMINDER_CRITERIA_FAIL"

/**
 * PATIENT_REMINDER COUNT
 */
export const GET_PATIENT_REMINDER_COUNT = "GET_PATIENT_REMINDER_COUNT"
export const GET_PATIENT_REMINDER_COUNT_SUCCESS = "GET_PATIENT_REMINDER_COUNT_SUCCESS"
export const GET_PATIENT_REMINDER_COUNT_FAIL = "GET_PATIENT_REMINDER_COUNT_FAIL"