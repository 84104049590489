import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "reactstrap";

//Import Breadcrumb
//redux
import { useDispatch } from "react-redux";

// Column
import { withTranslation } from "react-i18next";
import { AppointmentDesc, CreateDate } from "../CustCol";
import { formatDate } from "../../../common/commonFunctions";
import { get, post } from "../../../helpers/axios_with_headers";
import * as url from "../../../helpers/url_helper";
import CustomDatePicker from "../../../components/Common/CustomDatePicker";
import BasicTable from "../../../components/Common/BasicTable";

const LeadAppointment = props => {

  const dispatch = useDispatch();

  const [apptDate, setApptDate] = useState(null);
  const [apptDescription, setApptDescription] = useState("");
  const [selectedAppt, setSelectedAppt] = useState(false);
  const [custAppt, setCustAppt] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [lead, setLead] = useState({});

  const appointmentColumns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Date")}`,
        Header: "Tarih",
        accessor: "appointmentDate",
        filterable: true,
        className: "w-25",
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Açıklama",
        accessor: "description",
        filterable: true,
        className: "w-25",
        Cell: (cellProps) => {
          return <AppointmentDesc {...cellProps} />;
        }
      }

    ],
    []
  );

  useEffect(() => {
    setLead(props.lead);
  }, [props.lead]);

  const saveAppt = (e) => {
    const createAppt = {
      appointmentDate: formatDate(apptDate),
      description: apptDescription,
      lead: props.lead
    };
    post(url.ADD_NEW_APPOINTMENT, createAppt).then((res) => {
      setForceUpdate(true);
    });
    setApptDate(null);
    setApptDescription("");
    setSelectedAppt(false);
  };

  useEffect(() => {
    if (forceUpdate && lead.id || lead.id) {
      get(`${url.GET_LEAD_APPOINTMENT}/${lead.id}`).then(res => setCustAppt(res.data));
      setForceUpdate(false);
    }
  }, [forceUpdate, lead.id]);

  const handleChangeApptDescription = (e) => {
    setApptDescription(e.target.value);
  };

  const handleChangeApptDate = (e) => {
    setSelectedAppt(true);
    setApptDate(e);
  };

  return (
    <React.Fragment>
      <div className="mt-3">
        <div className="row">
          <div className="col-xs-12 col-md-4">
           <div className="col">
             <label>{props.t("Appointment Time")}</label>
           </div>
            <div className="col">
              <CustomDatePicker
                  id="apptDate"
                  selected={apptDate}
                  callback={handleChangeApptDate}
                  showTimeSelect={true}
                  showTimeSelectOnly={false}
                  showTimeInput={true}
              />
            </div>
          </div>
          <div className="col-xs-12 col-md-7">
            <label>{props.t("Description")}</label>
            <Input
              id="apptDesc"
              value={apptDescription}
              onChange={handleChangeApptDescription}
            />
          </div>
          <div className="col">
            <div className="mt-4">
              <Button
                id="saveAppt"
                type="button"
                color="success"
                onClick={saveAppt}
                disabled={!selectedAppt}
              >
                {props.t("Add")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        {custAppt.length > 0 ? (
          <BasicTable
            id="custAppt"
            customPageSize={10}
            columns={appointmentColumns}
            data={custAppt}
            className="custom-header-basic-css"
          />) : null}
      </div>
    </React.Fragment>
  );
};
LeadAppointment.propTypes = {
  lead: PropTypes.any
};

export default withTranslation()(LeadAppointment);
