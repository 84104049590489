
/* PAYMENTS */
export const GET_PAYMENTS = "GET_PAYMENTS"
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS"
export const GET_PAYMENTS_FAIL = "GET_PAYMENTS_FAIL"

/**
 * add PAYMENT
 */
export const ADD_NEW_PAYMENT = "ADD_NEW_PAYMENT"
export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS"
export const ADD_PAYMENT_FAIL = "ADD_PAYMENT_FAIL"

/**
 * Edit PAYMENT
 */
export const UPDATE_PAYMENT = "UPDATE_PAYMENT"
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS"
export const UPDATE_PAYMENT_FAIL = "UPDATE_PAYMENT_FAIL"

/**
 * Delete PAYMENT
 */
export const DELETE_PAYMENT = "DELETE_PAYMENT"
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS"
export const DELETE_PAYMENT_FAIL = "DELETE_PAYMENT_FAIL"

/**
 * GET UNPAID PAYMENT
 */
export const GET_UNPAID_PAYMENTS = "GET_UNPAID_PAYMENTS"
export const GET_UNPAID_PAYMENTS_SUCCESS = "GET_UNPAID_PAYMENTS_SUCCESS"
export const GET_UNPAID_PAYMENTS_FAIL = "GET_UNPAID_PAYMENTS_FAIL"

/**
 * UPDATE_USER_COUNT_AND_PRICE
 */
export const UPDATE_USER_COUNT_AND_PRICE = "UPDATE_USER_COUNT_AND_PRICE"
export const UPDATE_USER_COUNT_AND_PRICE_SUCCESS = "UPDATE_USER_COUNT_AND_PRICE_SUCCESS"
export const UPDATE_USER_COUNT_AND_PRICE_FAIL = "UPDATE_USER_COUNT_AND_PRICE_FAIL"

/**
 * PAYMENT_PROCESS
 */
export const PAYMENT_PROCESS = "PAYMENT_PROCESS"
export const PAYMENT_PROCESS_SUCCESS = "PAYMENT_PROCESS_SUCCESS"
export const PAYMENT_PROCESS_FAIL = "PAYMENT_PROCESS_FAIL"


/**
 * ANNUAL_PAYMENT
 */
export const ANNUAL_PAYMENT = "ANNUAL_PAYMENT"
export const ANNUAL_PAYMENT_SUCCESS = "ANNUAL_PAYMENT_SUCCESS"
export const ANNUAL_PAYMENT_FAIL = "ANNUAL_PAYMENT_FAIL"

/**
 * INSTANT_USER_PAYMENT
 */
export const INSTANT_USER_PAYMENT = "INSTANT_USER_PAYMENT"
export const INSTANT_USER_PAYMENT_SUCCESS = "INSTANT_USER_PAYMENT_SUCCESS"
export const INSTANT_USER_PAYMENT_FAIL = "INSTANT_USER_PAYMENT_FAIL"
