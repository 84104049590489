
/* PATIENT_NOTES */
export const GET_PATIENT_NOTES = "GET_PATIENT_NOTES"
export const GET_PATIENT_NOTES_SUCCESS = "GET_PATIENT_NOTES_SUCCESS"
export const GET_PATIENT_NOTES_FAIL = "GET_PATIENT_NOTES_FAIL"

/**
 * add PATIENT_NOTE
 */
export const ADD_NEW_PATIENT_NOTE = "ADD_NEW_PATIENT_NOTE"
export const ADD_PATIENT_NOTE_SUCCESS = "ADD_PATIENT_NOTE_SUCCESS"
export const ADD_PATIENT_NOTE_FAIL = "ADD_PATIENT_NOTE_FAIL"

/**
 * Edit PATIENT_NOTE
 */
export const UPDATE_PATIENT_NOTE = "UPDATE_PATIENT_NOTE"
export const UPDATE_PATIENT_NOTE_SUCCESS = "UPDATE_PATIENT_NOTE_SUCCESS"
export const UPDATE_PATIENT_NOTE_FAIL = "UPDATE_PATIENT_NOTE_FAIL"

/**
 * Delete PATIENT_NOTE
 */
export const DELETE_PATIENT_NOTE = "DELETE_PATIENT_NOTE"
export const DELETE_PATIENT_NOTE_SUCCESS = "DELETE_PATIENT_NOTE_SUCCESS"
export const DELETE_PATIENT_NOTE_FAIL = "DELETE_PATIENT_NOTE_FAIL"

/**
 * Lead PATIENT_NOTE
 */
export const GET_LEAD_PATIENT_NOTES = "GET_LEAD_PATIENT_NOTES"
export const GET_LEAD_PATIENT_NOTES_SUCCESS = "GET_LEAD_PATIENT_NOTES_SUCCESS"
export const GET_LEAD_PATIENT_NOTES_FAIL = "GET_LEAD_PATIENT_NOTES_FAIL"
