
/* DESCRIPTION_TEMPLATES */
export const GET_DESCRIPTION_TEMPLATES = "GET_DESCRIPTION_TEMPLATES"
export const GET_DESCRIPTION_TEMPLATES_SUCCESS = "GET_DESCRIPTION_TEMPLATES_SUCCESS"
export const GET_DESCRIPTION_TEMPLATES_FAIL = "GET_DESCRIPTION_TEMPLATES_FAIL"

/**
 * add DESCRIPTION_TEMPLATE
 */
export const ADD_NEW_DESCRIPTION_TEMPLATE = "ADD_NEW_DESCRIPTION_TEMPLATE"
export const ADD_DESCRIPTION_TEMPLATE_SUCCESS = "ADD_DESCRIPTION_TEMPLATE_SUCCESS"
export const ADD_DESCRIPTION_TEMPLATE_FAIL = "ADD_DESCRIPTION_TEMPLATE_FAIL"

/**
 * Edit DESCRIPTION_TEMPLATE
 */
export const UPDATE_DESCRIPTION_TEMPLATE = "UPDATE_DESCRIPTION_TEMPLATE"
export const UPDATE_DESCRIPTION_TEMPLATE_SUCCESS = "UPDATE_DESCRIPTION_TEMPLATE_SUCCESS"
export const UPDATE_DESCRIPTION_TEMPLATE_FAIL = "UPDATE_DESCRIPTION_TEMPLATE_FAIL"

/**
 * Delete DESCRIPTION_TEMPLATE
 */
export const DELETE_DESCRIPTION_TEMPLATE = "DELETE_DESCRIPTION_TEMPLATE"
export const DELETE_DESCRIPTION_TEMPLATE_SUCCESS = "DELETE_DESCRIPTION_TEMPLATE_SUCCESS"
export const DELETE_DESCRIPTION_TEMPLATE_FAIL = "DELETE_DESCRIPTION_TEMPLATE_FAIL"
