import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Collapse, Row } from "reactstrap";

//Import Breadcrumb

import classnames from "classnames";
import axios from "axios";
import { post } from "../../helpers/axios_with_headers";

function CustomAccordion(props) {
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [isChecked, setIsChecked] = useState(props.isPermissionGranted == "YES");

  const t_col1 = () => {
    setcol1(!col1);
    if (col1 === true) {
      setcol2(true);
    } else {
      setcol2(false);
    }
    setcol3(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    if (col2 === true) {
      setcol3(true);
    } else {
      setcol3(false);
    }
    setcol1(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
  };

  useEffect(() => {
    if (props.isPermissionGranted === "YES") {
      setIsChecked(props.isPermissionGranted === "YES");
    } else {
      setIsChecked(isChecked);
    }
  }, [isChecked, props.isPermissionGranted]);

  function sendAllowMergeDataToBackend() {
    if (props.user && props.user.authResponse !== undefined) {
      const userObj = Object.assign({
        userId: props.user.authResponse.userID,
        shortUserAccessToken: props.user.authResponse.accessToken,
        targetUrl: process.env.REACT_APP_API_URL + "/leadIntegration/facebook",
        oldDataPermission: isChecked
      });

      axios.post("https://integration.techlabcrm.com.tr/INTEGRATION/facebookDefinition/oldDataPermission", userObj).then(r => {
        if (r.status === 200) {
          console.log("backendRes", r);
        }
      });
      const systemParameterObj = Object.assign({
        code: "GET_OLD_FACEBOOK_LEADS",
        value: isChecked === true ? "YES" : "NO"
      });
      post(process.env.REACT_APP_API_URL + "/systemParameter/saveOrUpdateByCode", systemParameterObj).then(r => {
        if (r.status === 200) {
          console.log("backendRes", r);
        }
      });
    }
  };

  return (
    <Row>
      <Card>
        <CardBody>
          <Row>
            <Col lg={12}>
              <div className="accordion" id="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        { collapsed: !col1 }
                      )}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer" }}
                    >
                      <h3>Facebook Lead Integration to TechLabCRM Guide</h3>
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        <ol>
                          <li>Once you click the
                            <button className="facebook-btn"> Continue with Facebook </button>
                            button, a pop-up window will open and the CRM App will ask for your Facebook login
                            credentials. Enter your
                            credentials and click Next.
                          </li>
                          <li>The Meta/Facebook pages of the logged-in user will be listed.</li>
                          <li>Select the pages you want TechLabCRM to manage and receive your lead data to integrate
                            into
                            your CRM, and click
                            Next.
                          </li>
                          <li>To manage your page, TechLabCRM will ask for the following
                            permissions: <code>pages_read_engagement</code>,
                            <code>leads_retrieval</code>, <code>ads_management</code>, <code>pages_manage_ads</code>, <code>pages_manage_metadata</code>.
                            These permissions are needed to subscribe to the CRM App webhook service and automatically
                            integrate leads gained
                            from your Facebook advertisement campaigns into your TechLabCRM App. Click the "Done" button
                            and continue.
                          </li>
                          <li>You will see a message that says, "You've now linked TechLabCRM to Facebook." Click OK,
                            and
                            the pop-up window
                            will be closed.
                          </li>
                          <li>Once you have connected the TechLabCRM App to Facebook, use the left-side navigation and
                            click the "Leads"
                            button.
                          </li>
                          <li>Once you've clicked, you will see a button called "Lead List." Click the "Lead
                            List"
                            button.
                          </li>
                          <li>On the right side of the left-side navigation bar, you will see a table form lead list
                            data.
                          </li>
                          <li>Your lead lead data gained from your Facebook advertisement campaigns, which belongs
                            to
                            the previously
                            selected Facebook page (check article 3), will be automatically added to the "Lead
                            List."
                          </li>
                          <li>You can use the <a href="https://developers.facebook.com/tools/lead-ads-testing"
                                                 target="_blank" rel="noopener noreferrer">Meta
                            Lead Ads Testing Tool </a> to create test lead data for your selected page (check article 3)
                            and test integration.
                          </li>
                          <li>To remove or edit the TechLabCRM app access to your CRM, please go to your <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/settings?tab=business_tools&ref=business_login_reentry">"Facebook
                            Business
                            integrations settings" </a></li>
                        </ol>
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        { collapsed: !col2 }
                      )}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer" }}
                    >
                      <h3>Connect With Facebook</h3>
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        {props.childComponent}
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        { collapsed: !col3 }
                      )}
                      type="button"
                      onClick={t_col3}
                      style={{ cursor: "pointer" }}
                    >
                      <h3>Additional Settings</h3>
                    </button>
                  </h2>
                  <Collapse isOpen={col3} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        <div className="form-check form-check-left mb-3">
                          <label
                            className="form-check-label"
                            onClick={() => setIsChecked(!isChecked)}
                          >
                            <input
                              type="checkbox"
                              id="allowMergeDatas"
                              //onChange={() => setIsChecked(!isChecked)}
                              checked={isChecked}
                            />
                            Allow Merging Previous Datas
                          </label>
                        </div>
                        <div className="form-check form-check-left mb-3">
                          {props.user && (
                            <Button
                              type="button"
                              color="primary"
                              className="btn-rounded mb-2 me-2"
                              disabled={!isChecked || props.user.authResponse === undefined}
                              onClick={() => sendAllowMergeDataToBackend()}
                            >
                              Save Settings
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
}

export default CustomAccordion;