
/* OPERATION_CATEGORIES */
export const GET_OPERATION_CATEGORIES = "GET_OPERATION_CATEGORIES"
export const GET_OPERATION_CATEGORIES_SUCCESS = "GET_OPERATION_CATEGORIES_SUCCESS"
export const GET_OPERATION_CATEGORIES_FAIL = "GET_OPERATION_CATEGORIES_FAIL"

/**
 * add OPERATION_CATEGORY
 */
export const ADD_NEW_OPERATION_CATEGORY = "ADD_NEW_OPERATION_CATEGORY"
export const ADD_OPERATION_CATEGORY_SUCCESS = "ADD_OPERATION_CATEGORY_SUCCESS"
export const ADD_OPERATION_CATEGORY_FAIL = "ADD_OPERATION_CATEGORY_FAIL"

/**
 * Edit OPERATION_CATEGORY
 */
export const UPDATE_OPERATION_CATEGORY = "UPDATE_OPERATION_CATEGORY"
export const UPDATE_OPERATION_CATEGORY_SUCCESS = "UPDATE_OPERATION_CATEGORY_SUCCESS"
export const UPDATE_OPERATION_CATEGORY_FAIL = "UPDATE_OPERATION_CATEGORY_FAIL"

/**
 * Delete OPERATION_CATEGORY
 */
export const DELETE_OPERATION_CATEGORY = "DELETE_OPERATION_CATEGORY"
export const DELETE_OPERATION_CATEGORY_SUCCESS = "DELETE_OPERATION_CATEGORY_SUCCESS"
export const DELETE_OPERATION_CATEGORY_FAIL = "DELETE_OPERATION_CATEGORY_FAIL"
