import {
  GET_FACEBOOK_FORMS,
  GET_FACEBOOK_FORMS_FAIL,
  GET_FACEBOOK_FORMS_SUCCESS,
  ADD_NEW_FACEBOOK_FORM,
  ADD_FACEBOOK_FORM_SUCCESS,
  ADD_FACEBOOK_FORM_FAIL,
  UPDATE_FACEBOOK_FORM,
  UPDATE_FACEBOOK_FORM_SUCCESS,
  UPDATE_FACEBOOK_FORM_FAIL,
  DELETE_FACEBOOK_FORM,
  DELETE_FACEBOOK_FORM_SUCCESS,
  DELETE_FACEBOOK_FORM_FAIL
} from "./actionTypes";

export const getFacebookForms = () => ({
  type: GET_FACEBOOK_FORMS
});

export const getFacebookFormsSuccess = facebookFacebookForms => ({
  type: GET_FACEBOOK_FORMS_SUCCESS,
  payload: facebookFacebookForms
});

export const getFacebookFormsFail = error => ({
  type: GET_FACEBOOK_FORMS_FAIL,
  payload: error
});

export const addNewFacebookForm = facebookFacebookForm => ({
  type: ADD_NEW_FACEBOOK_FORM,
  payload: facebookFacebookForm
});

export const addFacebookFormSuccess = facebookFacebookForm => ({
  type: ADD_FACEBOOK_FORM_SUCCESS,
  payload: facebookFacebookForm
});

export const addFacebookFormFail = error => ({
  type: ADD_FACEBOOK_FORM_FAIL,
  payload: error
});

export const updateFacebookForm = facebookFacebookForm => ({
  type: UPDATE_FACEBOOK_FORM,
  payload: facebookFacebookForm
});

export const updateFacebookFormSuccess = facebookFacebookForm => ({
  type: UPDATE_FACEBOOK_FORM_SUCCESS,
  payload: facebookFacebookForm
});

export const updateFacebookFormFail = error => ({
  type: UPDATE_FACEBOOK_FORM_FAIL,
  payload: error
});

export const deleteFacebookForm = id => ({
  type: DELETE_FACEBOOK_FORM,
  id
});

export const deleteFacebookFormSuccess = (facebookFacebookForm, req) => ({
  type: DELETE_FACEBOOK_FORM_SUCCESS,
  payload: facebookFacebookForm,
  req: req
});

export const deleteFacebookFormFail = error => ({
  type: DELETE_FACEBOOK_FORM_FAIL,
  payload: error
});