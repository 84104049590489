import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import { Button, Col, Row, Table } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import { withTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

const CustomSelectableTable = ({
                                 columns,
                                 data,
                                 isAddOptions,
                                 handleAddClick,
                                 handleRefreshClick,
                                 customPageSize,
                                 className,
                                 refreshButton,
                                 handleRowClick,
                                 handleChangeSelect,
                                 handleChangeSelectAll,
                                 t,
                                 toolbar,
                                 clearAllSelected,
                                 isExportEnabled
                               }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize
      },
      autoResetPage: true
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    setPageSize(customPageSize);
  }, [customPageSize]);

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  useEffect(() => {
    const headers = [];
    columns.forEach(column => {
      if (column.Header !== "Action") {
        headers.push({ label: column.HeaderLabel.replaceAll("\"", ""), key: column.accessor.replaceAll("\"", "") });
      }
    });
    setHeaders(headers);
  }, [columns]);

  useEffect(() => {
    if (clearAllSelected) {
      setSelectedRows([]);
      const checkbox = document.getElementById("mastercheck");
      if (checkbox) {
        checkbox.checked = false;
        data.forEach(item => {
          const checkbox = document.getElementById(item.id);
          checkbox !== null ? checkbox.checked = false : "";
        });
      }
    }
  }, [clearAllSelected]);

  function handleSelectAll(e, data) {
    let selectedArray = [];
    if (e.target.checked) {
      data.forEach(item => {
        const checkbox = document.getElementById(item.id);
        checkbox.checked = true;
      });
      selectedArray = data;
      setSelectedRows(selectedArray);
      handleChangeSelectAll(selectedArray);
    } else {
      data.forEach(item => {
        const checkbox = document.getElementById(item.id);
        checkbox.checked = false;
      });
      setSelectedRows([]);
      handleChangeSelectAll([]);
    }
  }

  function handleSelect(e, data) {
    const selectedArray = selectedRows;
    if (e.target.checked) {
      selectedArray.push(data);
      setSelectedRows(selectedArray);
      handleChangeSelect(selectedArray);
    } else {
      const filteredArray = selectedRows.filter(row => row.id !== data.id);
      setSelectedRows(filteredArray);
      handleChangeSelect(filteredArray);
    }
  }

  return (
    <Fragment>
      <Row className="mt-4 mb-2">
        <Col sm="9">{toolbar}</Col>
        <Col sm="3">
          <div className="text-sm-end">
            {refreshButton && (
              <Button
                type="button"
                color="primary"
                className="btn-rounded mb-2 me-2"
                onClick={handleRefreshClick}
              >
                <i className="mdi mdi-refresh" />
              </Button>
            )}

            {isAddOptions && (
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleAddClick}
              >
                <i className="mdi mdi-plus me-1" />
                {t("Create")}
              </Button>
            )}
            {isExportEnabled && (
              <CSVLink
                data={data}
                headers={headers}
                filename={"Lead.csv"}
                className="btn btn-outline-primary btn-rounded mb-2 me-2">
                {t("Export data")}
              </CSVLink>
            )}
          </div>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        {data.length > 0 && (
          <Table hover {...getTableProps()} className={className}>
            <thead className="table-nowrap bg-transparent mb-0">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  if (column.Header === "Action") {
                    return (
                      <th key={column.id}
                          onClick={handleRowClick ? (e) => handleSelectAll(e, page.map(row => row.original)) : null}>
                        <div className="mb-0">
                          {column.render("HeaderLabel")}
                        </div>
                      </th>
                    );
                  } else {
                    return (
                      <th key={column.id}>
                        <div className="mb-0" {...column.getSortByToggleProps()}>
                          {column.render("HeaderLabel")}
                          {generateSortingIndicator(column)}
                        </div>
                      </th>
                    );
                  }
                })}
              </tr>
            ))}
            </thead>

            <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      if (cell.column.Header === "Action") {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}
                              onClick={handleRowClick ? (e) => handleSelect(e, row.original) : null}>
                            {cell.render("Cell")}
                          </td>
                        );
                      } else {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}
                              onClick={typeof handleRowClick === "function" ? () => handleRowClick(row) : null}>
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                    })}
                  </tr>
                </Fragment>
              );
            })}
            </tbody>
          </Table>
        )}
      </div>
    </Fragment>
  );
};

CustomSelectableTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any
};
export default withTranslation()(CustomSelectableTable);
