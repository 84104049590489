import {
  ADD_MEETING_ROOM_RESERVATION_FAIL,
  ADD_MEETING_ROOM_RESERVATION_SUCCESS,
  DELETE_MEETING_ROOM_RESERVATION_FAIL,
  DELETE_MEETING_ROOM_RESERVATION_SUCCESS,
  GET_MEETING_ROOM_RESERVATIONS_BY_ROOM_FAIL,
  GET_MEETING_ROOM_RESERVATIONS_BY_ROOM_SUCCESS,
  GET_MEETING_ROOM_RESERVATIONS_FAIL,
  GET_MEETING_ROOM_RESERVATIONS_SUCCESS,
  GET_USER_MEETING_ROOM_RESERVATIONS_FAIL,
  GET_USER_MEETING_ROOM_RESERVATIONS_SUCCESS,
  UPDATE_MEETING_ROOM_RESERVATION_FAIL,
  UPDATE_MEETING_ROOM_RESERVATION_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  meetingRoomReservations: [],
  meetingRoomReservationCount: 0,
  error: {}
};

const MeetingRoomReservation = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEETING_ROOM_RESERVATIONS_SUCCESS:
      return {
        ...state,
        meetingRoomReservations: action.payload
      };

    case GET_MEETING_ROOM_RESERVATIONS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_USER_MEETING_ROOM_RESERVATIONS_SUCCESS:
      return {
        ...state,
        meetingRoomReservations: action.payload
      };

    case GET_USER_MEETING_ROOM_RESERVATIONS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_MEETING_ROOM_RESERVATION_SUCCESS:
      return {
        ...state,
        meetingRoomReservations: [...state.meetingRoomReservations, action.payload]
      };

    case ADD_MEETING_ROOM_RESERVATION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_MEETING_ROOM_RESERVATION_SUCCESS:
      return {
        ...state,
        meetingRoomReservations: state.meetingRoomReservations.map(meetingRoomReservation =>
          meetingRoomReservation.id.toString() === action.payload.id.toString()
            ? { meetingRoomReservation, ...action.payload }
            : meetingRoomReservation
        )
      };

    case UPDATE_MEETING_ROOM_RESERVATION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_MEETING_ROOM_RESERVATION_SUCCESS:
      return {
        ...state,
        meetingRoomReservations: state.meetingRoomReservations.filter(
          meetingRoomReservation => meetingRoomReservation.id.toString() !== action.req.toString()
        )
      };

    case DELETE_MEETING_ROOM_RESERVATION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_MEETING_ROOM_RESERVATIONS_BY_ROOM_SUCCESS:
      return {
        ...state,
        meetingRoomReservations: action.payload
      };

    case GET_MEETING_ROOM_RESERVATIONS_BY_ROOM_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return {
        ...state
      };
  }
};

export default MeetingRoomReservation;
