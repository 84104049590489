import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  getAppointmentHistory,
  getAppointmentHistoryByFilter,
  getLeadHistory,
  getLeadHistoryByFilter,
  getNoteHistory,
  getNoteHistoryByFilter,
  getReminderHistory,
  getReminderHistoryByFilter
} from "../../store/history/actions";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CustomDataTable from "../../components/Common/CustomDataTable";
import { userCriteria } from "../../store/users/actions";
import { DateToString } from "../../common/commonFunctions";

const LeadHistory = (props) => {
  const dispatch = useDispatch();

  const [leadHistories, setLeadHistories] = useState([]);
  const [appointmentHistories, setAppointmentHistories] = useState([]);
  const [reminderHistories, setReminderHistories] = useState([]);
  const [noteHistories, setNoteHistories] = useState([]);
  const [filterPhone, setFilterPhone] = useState("");
  const [selectedOption, setSelectedOption] = useState({ label: `${props.t("Lead")}`, value: "lead" });
  const [filterUpdateDateStart, setFilterUpdateDateStart] = useState(DateToString(new Date(Date.now()), "YYYY-MM-DD", "-"));
  const [filterUpdateDateEnd, setFilterUpdateDateEnd] = useState(DateToString(new Date(Date.now()), "YYYY-MM-DD", "-"));
  const [user, setUser] = useState({ label: "", value: "" });
  const [onlyFacebook, setOnlyFacebook] = useState(false);

  let body = { };

  useEffect(() => {
    let userReq = Object.assign({ notRole: "ROLE_MODERATOR", sort: "updateDate" });
    dispatch(userCriteria(userReq));
  }, []);

  const options = [
    { label: `${props.t("Lead")}`, value: "lead" },
    { label: `${props.t("Appointment")}`, value: "appointment" },
    { label: `${props.t("Note")}`, value: "note" },
    { label: `${props.t("Reminder")}`, value: "reminder" }];

  const { leadHistory, appointmentHistory, reminderHistory, noteHistory } = useSelector(state => ({
    leadHistory: state.History.leadHistory,
    appointmentHistory: state.History.appointmentHistory,
    reminderHistory: state.History.reminderHistory,
    noteHistory: state.History.noteHistory
  }));

  const { users } = useSelector(state => ({
    users: state.User.users
  }));

  const userOptions = [];
  users.map((item) => {
    userOptions.push({ label: item.fullName, value: item });
  });

  function handleFilterUpdateDateStart(e) {
    setFilterUpdateDateStart(e.target.value);
  }

  function handleFilterCreateDateEnd(e) {
    setFilterUpdateDateEnd(e.target.value);
  }

  const onChangeSelect = (e) => {
    setSelectedOption(e);
  };

  const handlePhoneChange = (e) => {
    setFilterPhone(e.target.value);
  };

  const onChangeUser = (e) => {
    setUser(e);
  };

  const getFilteredDatas = () => {
    let filterReq = false;
    if (filterPhone !== "") {
      body = { ...body, "phoneNumber": filterPhone };
      filterReq = true;
    }
    if (user !== null && user.value !== "" && user.value.id !== null) {
      body = { ...body, "userId": user.value.id.toString() };
      filterReq = true;
    }
    if (filterUpdateDateStart !== "" && filterUpdateDateStart !== undefined) {
      body = { ...body, "updateDateStart": filterUpdateDateStart };
      filterReq = true;
    }
    if (filterUpdateDateEnd !== "" && filterUpdateDateEnd !== undefined) {
      body = { ...body, "updateDateEnd": filterUpdateDateEnd };
      filterReq = true;
    }
    if (onlyFacebook !== undefined) {
      body = { ...body, "onlyFacebook": onlyFacebook };
      filterReq = true;
    }

    if (selectedOption.value === "lead") {
      if (filterReq) {
        dispatch(getLeadHistoryByFilter(body));
      } else {
        dispatch(getLeadHistory());
      }
    } else if (selectedOption.value === "appointment") {
      if (filterReq) {
        dispatch(getAppointmentHistoryByFilter(body));
      } else {
        dispatch(getAppointmentHistory());
      }
    } else if (selectedOption.value === "reminder") {
      if (filterReq) {
        dispatch(getReminderHistoryByFilter(body));
      } else {
        dispatch(getReminderHistory());
      }
    } else if (selectedOption.value === "note") {
      if (filterReq) {
        dispatch(getNoteHistoryByFilter(body));
      } else {
        dispatch(getNoteHistory());
      }
    }
  };

  useEffect(() => {
    if (selectedOption.value === "lead") {
      setLeadHistories(leadHistory);
      setNoteHistories([]);
      setAppointmentHistories([]);
      setReminderHistories([]);
    } else if (selectedOption.value === "appointment") {
      setAppointmentHistories(appointmentHistory);
      setNoteHistories([]);
      setLeadHistories([]);
      setReminderHistories([]);
    } else if (selectedOption.value === "reminder") {
      setReminderHistories(reminderHistory);
      setNoteHistories([]);
      setLeadHistories([]);
      setAppointmentHistories([]);
    } else if (selectedOption.value === "note") {
      setNoteHistories(noteHistory);
      setAppointmentHistories([]);
      setLeadHistories([]);
      setReminderHistories([]);
    }
  }, [dispatch, leadHistory, reminderHistory, appointmentHistory, noteHistory]);

  const leadHistoryColumns = useMemo(
    () => [
      {
        dataField: "action",
        text: `${props.t("Action")}`,
        sort: true
      },
      {
        dataField: "updateUserName",
        text: `${props.t("Action By")}`,
        sort: true
      },
      {
        dataField: "updateDate",
        text: `${props.t("Action Date")}`,
        sort: true
      },
      {
        dataField: "salesRepresentativeName",
        text: `${props.t("Sales Representative")}`,
        sort: true
      },
      {
        dataField: "fullName",
        text: `${props.t("Fullname")}`,
        sort: true
      },
      {
        dataField: "phoneNumber",
        text: `${props.t("Phone")}`,
        sort: true
      },
      {
        dataField: "segment.name",
        text: `${props.t("Segment")}`,
        sort: true
      },
      {
        dataField: "resource.name",
        text: `${props.t("Resource")}`,
        sort: true
      },
      {
        dataField: "category.name",
        text: `${props.t("Category")}`,
        sort: true
      },
      {
        dataField: "reference.name",
        text: `${props.t("Reference")}`,
        sort: true
      },
      {
        dataField: "createDate",
        text: `${props.t("Create Date")}`,
        sort: true
      },
      {
        dataField: "facebookCreateDate",
        text: `${props.t("Facebook Date")}`,
        sort: true
      },
      {
        dataField: "facebookFormName",
        text: `${props.t("Form Name")}`,
        sort: true
      }
    ],
    []
  );

  const appointmentHistoryColumns = useMemo(
    () => [
      {
        dataField: "action",
        text: `${props.t("Action")}`,
        sort: true
      },
      {
        dataField: "appointmentUserName",
        text: `${props.t("Action By")}`,
        sort: true
      },
      {
        dataField: "updateDate",
        text: `${props.t("Action Date")}`,
        sort: true
      },
      {
        dataField: "appointmentDate",
        text: `${props.t("Appointment Date")}`,
        sort: true
      },
      {
        dataField: "description",
        text: `${props.t("Description")}`,
        sort: true
      },
      {
        dataField: "phoneNumber",
        text: `${props.t("Phone")}`,
        sort: true
      }
    ],
    []
  );

  const noteHistoryColumns = useMemo(
    () => [
      {
        dataField: "action",
        text: `${props.t("Action")}`,
        sort: true
      },
      {
        dataField: "updateUserName",
        text: `${props.t("Action By")}`,
        sort: true
      },
      {
        dataField: "updateDate",
        text: `${props.t("Action Date")}`,
        sort: true
      },
      {
        dataField: "phoneNumber",
        text: `${props.t("Phone")}`,
        sort: true
      },
      {
        dataField: "note",
        text: `${props.t("Note")}`,
        sort: true
      }
    ],
    []
  );

  const reminderHistoryColumns = useMemo(
    () => [
      {
        dataField: "action",
        text: `${props.t("Action")}`,
        sort: true
      },
      {
        dataField: "updateUserName",
        text: `${props.t("Action By")}`,
        sort: true
      },
      {
        dataField: "updateDate",
        text: `${props.t("Action Date")}`,
        sort: true
      },
      {
        dataField: "phoneNumber",
        text: `${props.t("Phone")}`,
        sort: true
      },
      {
        dataField: "reminderDate",
        text: `${props.t("Reminder Date")}`,
        sort: true
      },
      {
        dataField: "description",
        text: `${props.t("Description")}`,
        sort: true
      }
    ],
    []
  );

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={props.t("Lead History")} />
        <Row className="m-3">
          <div className="col-xs-6 col-md-4 mb-3">
            <Label className="form-label">{props.t("History Type")}</Label>
            <Select
              id="historyEntity"
              value={selectedOption}
              onChange={(e) => {
                onChangeSelect(e);
              }}
              options={options}
              className="select2-selection"
            />
          </div>
          <div className="col-xs-6 col-md-4 mb-3">
            <Label className="form-label">{props.t("Phone")}</Label>
            <Input
              id="update-date-end"
              name="update-date-end"
              type="text"
              onChange={e => handlePhoneChange(e)}
              value={filterPhone}
            />
          </div>
          <div className="col-xs-6 col-md-4 mb-3">
            <Label className="form-label">{props.t("Action By")}</Label>
            <Select
              id="representHistory"
              className="bs-select"
              value={user}
              onChange={(e) => onChangeUser(e)}
              options={userOptions}
              isClearable={true}
            />
          </div>
          <div className="col-xs-6 col-md-4 mb-3">
            <Label className="form-label">{props.t("Action Date Start")}</Label>
            <Input
              id="start-date"
              name="start-date"
              type="date"
              onChange={e => handleFilterUpdateDateStart(e)}
              value={filterUpdateDateStart}
            />
          </div>
          <div className="col-xs-6 col-md-4 mb-3">
            <Label className="form-label">{props.t("Action Date End")}</Label>
            <Input
              id="end-date"
              name="end-date"
              type="date"
              onChange={e => handleFilterCreateDateEnd(e)}
              value={filterUpdateDateEnd}
            />
          </div>
          <div className="col-xs-6 col-md-2">
            <div className="form-check form-check-left mt-4">
              <label
                className="form-check-label"
                onClick={() => setOnlyFacebook(!onlyFacebook)}
              >
                <input
                  type="checkbox"
                  id="forFacebook"
                  className="form-check-input"
                  onChange={() => setOnlyFacebook(onlyFacebook)}
                  checked={onlyFacebook}
                />
                {props.t("Only Facebook Leads")}
              </label>
            </div>
          </div>
          <div className="mt-4 col-xs-6 col-md-2">
            <Button
              type="button"
              color="success"
              className="btn-rounded mb-0"
              onClick={() => getFilteredDatas()}
            >
              <i className="mdi mdi-search-web me-1" />
              {props.t("Get")}
            </Button>
          </div>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <CardBody className="table-responsive table-light">
                  {leadHistories.length > 0 && (
                    <CustomDataTable
                      id="lead-history"
                      title={props.t("Lead History")}
                      columns={leadHistoryColumns}
                      data={leadHistories}
                      selectRow={false}
                      export={false}
                      sortingAlg={[{
                        dataField: "updateDate",
                        order: "desc"
                      }]}
                    />
                  )}
                  {appointmentHistories.length > 0 && (
                    <CustomDataTable
                      id="appointment-history"
                      title={props.t("Appointment History")}
                      columns={appointmentHistoryColumns}
                      data={appointmentHistories}
                      selectRow={false}
                      export={false}
                      sortingAlg={[{
                        dataField: "updateDate",
                        order: "desc"
                      }]}
                    />
                  )}
                  {reminderHistories.length > 0 && (
                    <CustomDataTable
                      id="reminder-history"
                      title={props.t("Reminder History")}
                      columns={reminderHistoryColumns}
                      data={reminderHistories}
                      selectRow={false}
                      export={false}
                      sortingAlg={[{
                        dataField: "updateDate",
                        order: "desc"
                      }]}
                    />
                  )}
                  {noteHistories.length > 0 && (
                    <CustomDataTable
                      id="note-history"
                      title={props.t("Note History")}
                      columns={noteHistoryColumns}
                      data={noteHistories}
                      selectRow={false}
                      export={false}
                      sortingAlg={[{
                        dataField: "updateDate",
                        order: "desc"
                      }]}
                    />
                  )}
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
LeadHistory.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(LeadHistory);