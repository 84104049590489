import {
    GET_DOCTORS,
    GET_DOCTORS_FAIL,
    GET_DOCTORS_SUCCESS,
    ADD_NEW_DOCTOR,
    ADD_DOCTOR_SUCCESS,
    ADD_DOCTOR_FAIL,
    UPDATE_DOCTOR,
    UPDATE_DOCTOR_SUCCESS,
    UPDATE_DOCTOR_FAIL,
    DELETE_DOCTOR,
    DELETE_DOCTOR_SUCCESS,
    DELETE_DOCTOR_FAIL,
} from "./actionTypes"

export const getDoctors = () => ({
    type: GET_DOCTORS,
})

export const getDoctorsSuccess = doctors => ({
    type: GET_DOCTORS_SUCCESS,
    payload: doctors,
})

export const getDoctorsFail = error => ({
    type: GET_DOCTORS_FAIL,
    payload: error,
})

export const addNewDoctor = doctor => ({
    type: ADD_NEW_DOCTOR,
    payload: doctor,
})

export const addDoctorSuccess = doctor => ({
    type: ADD_DOCTOR_SUCCESS,
    payload: doctor,
})

export const addDoctorFail = error => ({
    type: ADD_DOCTOR_FAIL,
    payload: error,
})

export const updateDoctor = doctor => ({
    type: UPDATE_DOCTOR,
    payload: doctor,
})

export const updateDoctorSuccess = doctor => ({
    type: UPDATE_DOCTOR_SUCCESS,
    payload: doctor,
})

export const updateDoctorFail = error => ({
    type: UPDATE_DOCTOR_FAIL,
    payload: error,
})

export const deleteDoctor = id => ({
    type: DELETE_DOCTOR,
    id,
})

export const deleteDoctorSuccess = (doctor,req) => ({
    type: DELETE_DOCTOR_SUCCESS,
    payload: doctor,
    req:req,
})

export const deleteDoctorFail = error => ({
    type: DELETE_DOCTOR_FAIL,
    payload: error,
})