import React from "react";

const groupId = (cell) => {
  return cell.value ? cell.value : "";
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

const Role = (cell) => {
  return cell.value ? cell.value.replace("_"," ") : "";
};

const Description = (cell) => {
  return cell.value ? cell.value : "";
};

export {
  groupId,
  Name,
  Role,
  Description
};