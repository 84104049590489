import {
    GET_HOSPITALS,
    GET_HOSPITALS_FAIL,
    GET_HOSPITALS_SUCCESS,
    ADD_NEW_HOSPITAL,
    ADD_HOSPITAL_SUCCESS,
    ADD_HOSPITAL_FAIL,
    UPDATE_HOSPITAL,
    UPDATE_HOSPITAL_SUCCESS,
    UPDATE_HOSPITAL_FAIL,
    DELETE_HOSPITAL,
    DELETE_HOSPITAL_SUCCESS,
    DELETE_HOSPITAL_FAIL,
} from "./actionTypes"

export const getHospitals = () => ({
    type: GET_HOSPITALS,
})

export const getHospitalsSuccess = hospitals => ({
    type: GET_HOSPITALS_SUCCESS,
    payload: hospitals,
})

export const getHospitalsFail = error => ({
    type: GET_HOSPITALS_FAIL,
    payload: error,
})

export const addNewHospital = hospital => ({
    type: ADD_NEW_HOSPITAL,
    payload: hospital,
})

export const addHospitalSuccess = hospital => ({
    type: ADD_HOSPITAL_SUCCESS,
    payload: hospital,
})

export const addHospitalFail = error => ({
    type: ADD_HOSPITAL_FAIL,
    payload: error,
})

export const updateHospital = hospital => ({
    type: UPDATE_HOSPITAL,
    payload: hospital,
})

export const updateHospitalSuccess = hospital => ({
    type: UPDATE_HOSPITAL_SUCCESS,
    payload: hospital,
})

export const updateHospitalFail = error => ({
    type: UPDATE_HOSPITAL_FAIL,
    payload: error,
})

export const deleteHospital = id => ({
    type: DELETE_HOSPITAL,
    id,
})

export const deleteHospitalSuccess = (hospital,req) => ({
    type: DELETE_HOSPITAL_SUCCESS,
    payload: hospital,
    req:req,
})

export const deleteHospitalFail = error => ({
    type: DELETE_HOSPITAL_FAIL,
    payload: error,
})