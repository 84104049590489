import { call, put, takeEvery } from "redux-saga/effects";

// LeadIntegrationDefinitions Redux States
import {
    ADD_LEAD_INTEGRATION_DEFINITION,
    DELETE_LEAD_INTEGRATION_DEFINITION,
    GET_LEAD_INTEGRATION_DEFINITION,
    UPDATE_LEAD_INTEGRATION_DEFINITION
} from "./actionTypes";

import {
    addLeadIntegrationDefinitionFail,
    addLeadIntegrationDefinitionSuccess,
    deleteLeadIntegrationDefinitionFail,
    deleteLeadIntegrationDefinitionSuccess,
    getLeadIntegrationDefinitionsFail,
    getLeadIntegrationDefinitionsSuccess,
    updateLeadIntegrationDefinitionFail,
    updateLeadIntegrationDefinitionSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchLeadIntegrationDefinitions() {
  try {
    const response = yield call(getLeadIntegrationDefinitions);
    yield put(getLeadIntegrationDefinitionsSuccess(response));
  } catch (error) {
    yield put(getLeadIntegrationDefinitionsFail(error));
  }
}

const getLeadIntegrationDefinitions = () => get(`${url.LEAD_INT_DEF}/getAll`);

function* onUpdateLeadIntegrationDefinition({ payload: leadIntegrationDefinition }) {
  try {
    const response = yield call(updateLeadIntegrationDefinition, leadIntegrationDefinition);
    yield put(updateLeadIntegrationDefinitionSuccess(response));
  } catch (error) {
    yield put(updateLeadIntegrationDefinitionFail(error));
  }
}

const updateLeadIntegrationDefinition = (leadIntegrationDefinition) => post(url.LEAD_INT_DEF, leadIntegrationDefinition);

function* onDeleteLeadIntegrationDefinition({ id }) {
  try {
    const response = yield call(deleteLeadIntegrationDefinition, id);
    if (response.status === 200) {
      yield put(deleteLeadIntegrationDefinitionSuccess(response, id));
    } else {
      yield put(deleteLeadIntegrationDefinitionFail(response));
    }
  } catch (error) {
    yield put(deleteLeadIntegrationDefinitionFail(error));
  }
}

const deleteLeadIntegrationDefinition = (id) => del(`${url.LEAD_INT_DEF}/${id}`);

function* onAddNewLeadIntegrationDefinition({ payload: leadIntegrationDefinition }) {
  try {
    const response = yield call(addNewLeadIntegrationDefinition, leadIntegrationDefinition);
    yield put(addLeadIntegrationDefinitionSuccess(response));
  } catch (error) {
    yield put(addLeadIntegrationDefinitionFail(error));
  }
}

const addNewLeadIntegrationDefinition = (leadIntegrationDefinition) => post(url.LEAD_INT_DEF, leadIntegrationDefinition);

function* leadIntegrationDefinitionsSaga() {
  yield takeEvery(GET_LEAD_INTEGRATION_DEFINITION, fetchLeadIntegrationDefinitions);
  yield takeEvery(ADD_LEAD_INTEGRATION_DEFINITION, onAddNewLeadIntegrationDefinition);
  yield takeEvery(UPDATE_LEAD_INTEGRATION_DEFINITION, onUpdateLeadIntegrationDefinition);
  yield takeEvery(DELETE_LEAD_INTEGRATION_DEFINITION, onDeleteLeadIntegrationDefinition);
}

export default leadIntegrationDefinitionsSaga;
