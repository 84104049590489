import React, { useEffect, useState } from "react";

import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";

const WelcomeComp = (props) => {
  const { user } = useAuth();
  const [viewProfileButton, setViewProfileButton] = useState(true);

  useEffect(() => {
    setViewProfileButton(props.viewProfileButton);
  }, [props.viewProfileButton]);

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="8">
              <div className="text-primary p-3">
                <h5 className="text-primary"></h5>
                <p>{props.t("Welcome Back :)")}</p>
              </div>
            </Col>
            <Col xs="4" className="align-self-end">
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="8">
              <div className="avatar-md profile-user-wid mb-4">
                {user.photo !== null && user.photo !== undefined ? (
                  <img
                    src={user.photo.toString()}
                    alt=""
                    className="img-thumbnail avatar-md"
                  />
                ) : (
                  <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle">
                        {user.firstName ? user.firstName.charAt(0) + user.lastName.charAt(0) : "?"}
                    </span>
                  </div>
                )}

              </div>
              <h5 className="font-size-15 text-truncate">{user.firstName + " " + user.lastName}</h5>
            </Col>

            {viewProfileButton === true ? (
              <Col sm="4">
                <div className="mt-4">
                  <Link
                    to="/user-profile"
                    className="btn btn-primary  btn-sm"
                  >
                    {props.t("View Profile")} <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </Col>
            ) : null}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

WelcomeComp.propTypes = {
  t: PropTypes.any,
  getUserAppointments: PropTypes.func,
  viewProfileButton: PropTypes.bool
};

export default withTranslation()(WelcomeComp);
