import {
    GET_PARAMETERS,
    GET_PARAMETERS_FAIL,
    GET_PARAMETERS_SUCCESS,
    ADD_NEW_PARAMETER,
    ADD_PARAMETER_SUCCESS,
    ADD_PARAMETER_FAIL,
    UPDATE_PARAMETER,
    UPDATE_PARAMETER_SUCCESS,
    UPDATE_PARAMETER_FAIL,
    DELETE_PARAMETER,
    DELETE_PARAMETER_SUCCESS,
    DELETE_PARAMETER_FAIL,
} from "./actionTypes"

export const getParameters = () => ({
    type: GET_PARAMETERS,
})

export const getParametersSuccess = parameters => ({
    type: GET_PARAMETERS_SUCCESS,
    payload: parameters,
})

export const getParametersFail = error => ({
    type: GET_PARAMETERS_FAIL,
    payload: error,
})

export const addNewParameter = parameter => ({
    type: ADD_NEW_PARAMETER,
    payload: parameter,
})

export const addParameterSuccess = parameter => ({
    type: ADD_PARAMETER_SUCCESS,
    payload: parameter,
})

export const addParameterFail = error => ({
    type: ADD_PARAMETER_FAIL,
    payload: error,
})

export const updateParameter = parameter => ({
    type: UPDATE_PARAMETER,
    payload: parameter,
})

export const updateParameterSuccess = parameter => ({
    type: UPDATE_PARAMETER_SUCCESS,
    payload: parameter,
})

export const updateParameterFail = error => ({
    type: UPDATE_PARAMETER_FAIL,
    payload: error,
})

export const deleteParameter = id => ({
    type: DELETE_PARAMETER,
    id,
})

export const deleteParameterSuccess = parameter => ({
    type: DELETE_PARAMETER_SUCCESS,
    payload: parameter,
})

export const deleteParameterFail = error => ({
    type: DELETE_PARAMETER_FAIL,
    payload: error,
})