import {
  GET_PATIENT_NOTES,
  GET_PATIENT_NOTES_FAIL,
  GET_PATIENT_NOTES_SUCCESS,
  ADD_NEW_PATIENT_NOTE,
  ADD_PATIENT_NOTE_SUCCESS,
  ADD_PATIENT_NOTE_FAIL,
  UPDATE_PATIENT_NOTE,
  UPDATE_PATIENT_NOTE_SUCCESS,
  UPDATE_PATIENT_NOTE_FAIL,
  DELETE_PATIENT_NOTE,
  DELETE_PATIENT_NOTE_SUCCESS,
  DELETE_PATIENT_NOTE_FAIL, GET_LEAD_PATIENT_NOTES, GET_LEAD_PATIENT_NOTES_SUCCESS, GET_LEAD_PATIENT_NOTES_FAIL
} from "./actionTypes";

export const getPatientNotes = () => ({
  type: GET_PATIENT_NOTES
});

export const getPatientNotesSuccess = patientPatientNotes => ({
  type: GET_PATIENT_NOTES_SUCCESS,
  payload: patientPatientNotes
});

export const getPatientNotesFail = error => ({
  type: GET_PATIENT_NOTES_FAIL,
  payload: error
});

export const addNewPatientNote = patientPatientNote => ({
  type: ADD_NEW_PATIENT_NOTE,
  payload: patientPatientNote
});

export const addPatientNoteSuccess = patientPatientNote => ({
  type: ADD_PATIENT_NOTE_SUCCESS,
  payload: patientPatientNote
});

export const addPatientNoteFail = error => ({
  type: ADD_PATIENT_NOTE_FAIL,
  payload: error
});

export const updatePatientNote = patientPatientNote => ({
  type: UPDATE_PATIENT_NOTE,
  payload: patientPatientNote
});

export const updatePatientNoteSuccess = patientPatientNote => ({
  type: UPDATE_PATIENT_NOTE_SUCCESS,
  payload: patientPatientNote
});

export const updatePatientNoteFail = error => ({
  type: UPDATE_PATIENT_NOTE_FAIL,
  payload: error
});

export const deletePatientNote = id => ({
  type: DELETE_PATIENT_NOTE,
  id
});

export const deletePatientNoteSuccess = (patientPatientNote, req) => ({
  type: DELETE_PATIENT_NOTE_SUCCESS,
  payload: patientPatientNote,
  req: req
});

export const deletePatientNoteFail = error => ({
  type: DELETE_PATIENT_NOTE_FAIL,
  payload: error
});

export const getLeadPatientNotes = id => ({
  type: GET_LEAD_PATIENT_NOTES,
  id
});

export const getLeadPatientNotesSuccess = patientPatientNote => ({
  type: GET_LEAD_PATIENT_NOTES_SUCCESS,
  payload: patientPatientNote
});

export const getLeadPatientNotesFail = error => ({
  type: GET_LEAD_PATIENT_NOTES_FAIL,
  payload: error
});