import {
  ADD_PAYMENT_FAIL,
  ADD_PAYMENT_SUCCESS,
  ANNUAL_PAYMENT_FAIL,
  ANNUAL_PAYMENT_SUCCESS,
  DELETE_PAYMENT_FAIL,
  DELETE_PAYMENT_SUCCESS,
  GET_PAYMENTS_FAIL,
  GET_PAYMENTS_SUCCESS,
  GET_UNPAID_PAYMENTS_FAIL,
  GET_UNPAID_PAYMENTS_SUCCESS, INSTANT_USER_PAYMENT_FAIL, INSTANT_USER_PAYMENT_SUCCESS,
  PAYMENT_PROCESS_FAIL,
  PAYMENT_PROCESS_SUCCESS,
  UPDATE_PAYMENT_FAIL,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_USER_COUNT_AND_PRICE_FAIL,
  UPDATE_USER_COUNT_AND_PRICE_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  payments: [],
  unpaidPayments: [],
  processResponse: {},
  error: {},
};

const Payment = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload
      };

    case GET_PAYMENTS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: [...state.payments, action.payload]
      };

    case ADD_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: state.payments.map(payment =>
          payment.id.toString() === action.payload.id.toString()
            ? { payment, ...action.payload }
            : payment
        )
      };

    case UPDATE_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: state.payments.filter(
          payment => payment.id.toString() !== action.req.toString()
        )
      };

    case DELETE_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_UNPAID_PAYMENTS_SUCCESS:
      return {
        ...state,
        unpaidPayments: action.payload
      };

    case GET_UNPAID_PAYMENTS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_USER_COUNT_AND_PRICE_SUCCESS:
      return {
        ...state,
        payments: state.payments.map(payment => {
          if (action.payload.some(pay => pay.id === payment.id)) {
            const filtered = { ...action.payload.filter(pay => pay.id === payment.id) };
            return filtered[0];
          } else {
            return payment;
          }
        }),
        unpaidPayments: state.unpaidPayments.map(unpaid => {
          if (action.payload.some(pay => pay.id === unpaid.id)) {
            const filtered = { ...action.payload.filter(pay => pay.id === unpaid.id && pay.month === unpaid.month) };
            return filtered[0];
          } else {
            return unpaid;
          }
        })
      };

    case UPDATE_USER_COUNT_AND_PRICE_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case PAYMENT_PROCESS_SUCCESS:
      return {
        ...state,
        processResponse: action.payload
      };

    case PAYMENT_PROCESS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ANNUAL_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: action.payload,
        unpaidPayments: action.payload
      };

    case ANNUAL_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case INSTANT_USER_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: action.payload,
        unpaidPayments: action.payload
      };

    case INSTANT_USER_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return {
        ...state,
        processResponse: {},
        error: {}
      };
  }
};

export default Payment;
