import { call, put, takeEvery } from "redux-saga/effects";

// Groups Redux States
import { ADD_NEW_GROUP, DELETE_GROUP, GET_GROUPS, UPDATE_GROUP } from "./actionTypes";

import {
    addGroupFail,
    addGroupSuccess,
    deleteGroupFail,
    deleteGroupSuccess,
    getGroupsFail,
    getGroupsSuccess,
    updateGroupFail,
    updateGroupSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";
import { FIND_DOCTORS_WITH_GROUPS } from "../../helpers/url_helper";

function* fetchGroups() {
    try {
        const response = yield call(getGroups);
        yield put(getGroupsSuccess(response));
    } catch (error) {
        yield put(getGroupsFail(error));
    }
}

const getGroups = () => get(url.GET_GROUPS)

function* onUpdateGroup({ payload: group }) {
    try {
        const response = yield call(updateGroup, group);
        yield put(updateGroupSuccess(response));
    } catch (error) {
        yield put(updateGroupFail(error));
    }
}

const updateGroup = (group) => post(url.GROUP_API,group)

function* onDeleteGroup({id}) {
    try {
        const response = yield call(deleteGroup, id);
        if (response.status === 200){
            yield put(deleteGroupSuccess(response,id));
        }else{
            yield put(deleteGroupFail(response));
        }
    } catch (error) {
        yield put(deleteGroupFail(error));
    }
}

const deleteGroup = (id) => del(`${url.GROUP_API}/${id}`);

function* onAddNewGroup({ payload: group }) {
    try {
        const response = yield call(addNewGroup, group);

        yield put(addGroupSuccess(response));
    } catch (error) {
        yield put(addGroupFail(error));
    }
}

const addNewGroup = (group) => post(url.GROUP_API,group);

function* onGetFindDoctorsWithGroups() {
    try {
        const response = yield call(getDoctorClinicInfos);

        yield put(addGroupSuccess(response));
    } catch (error) {
        yield put(addGroupFail(error));
    }
}

const getDoctorClinicInfos = () => get(url.FIND_DOCTORS_WITH_GROUPS);

function* groupsSaga() {
    yield takeEvery(GET_GROUPS, fetchGroups);
    yield takeEvery(ADD_NEW_GROUP, onAddNewGroup);
    yield takeEvery(UPDATE_GROUP, onUpdateGroup);
    yield takeEvery(DELETE_GROUP, onDeleteGroup);
    yield takeEvery(FIND_DOCTORS_WITH_GROUPS, onGetFindDoctorsWithGroups);
}

export default groupsSaga;
