import {
  GET_SCREENS,
  GET_SCREENS_FAIL,
  GET_SCREENS_SUCCESS,
  ADD_NEW_SCREEN,
  ADD_SCREEN_SUCCESS,
  ADD_SCREEN_FAIL,
  UPDATE_SCREEN,
  UPDATE_SCREEN_SUCCESS,
  UPDATE_SCREEN_FAIL,
  DELETE_SCREEN,
  DELETE_SCREEN_SUCCESS,
  DELETE_SCREEN_FAIL
} from "./actionTypes";

export const getScreens = () => ({
  type: GET_SCREENS
});

export const getScreensSuccess = screens => ({
  type: GET_SCREENS_SUCCESS,
  payload: screens
});

export const getScreensFail = error => ({
  type: GET_SCREENS_FAIL,
  payload: error
});

export const addNewScreen = screen => ({
  type: ADD_NEW_SCREEN,
  payload: screen
});

export const addScreenSuccess = screen => ({
  type: ADD_SCREEN_SUCCESS,
  payload: screen
});

export const addScreenFail = error => ({
  type: ADD_SCREEN_FAIL,
  payload: error
});

export const updateScreen = screen => ({
  type: UPDATE_SCREEN,
  payload: screen
});

export const updateScreenSuccess = screen => ({
  type: UPDATE_SCREEN_SUCCESS,
  payload: screen
});

export const updateScreenFail = error => ({
  type: UPDATE_SCREEN_FAIL,
  payload: error
});

export const deleteScreen = id => ({
  type: DELETE_SCREEN,
  id
});

export const deleteScreenSuccess = (screen, req) => ({
  type: DELETE_SCREEN_SUCCESS,
  payload: screen,
  req: req
});

export const deleteScreenFail = error => ({
  type: DELETE_SCREEN_FAIL,
  payload: error
});