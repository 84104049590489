import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Collapse,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { getOperationDefinitions as onGetOperationDefinitions } from "../../../store/operationDefinition/actions";
import { getDoctors as onGetDoctors } from "../../../store/doctor/actions";
import { getHospitals as onGetHospitals } from "../../../store/hospital/actions";
import { updateLead as onUpdateLead } from "../../../store/leads/actions";
import { Link } from "react-router-dom";
import { CreateDate, CreateUser } from "../CustCol";
import BasicTable from "../../../components/Common/BasicTable";
import Notification from "../../../components/Notification";
import { deleteMedicalOperation } from "../../../store/medicalOperation/actions";
import WarningModal from "../../../components/Common/WarningModal";

const LeadOperation = props => {

  const dispatch = useDispatch();

  const defaultDate = `${new Date().getFullYear()}-${`${new Date().getMonth() +
  1}`.padStart(2, 0)}-${`${new Date().getDate()}`.padStart(2, 0)} 09:00:00`;

  const initialOperation = {
    doctor: null,
    hospital: null,
    operationDate: defaultDate,
    hospitalizationStartDate: defaultDate,
    hospitalizationEndDate: defaultDate,
    operationDefinition: null,
    description: ""
  };

  const [lead, setLead] = useState({});
  const [doctor, setDoctor] = useState(null);
  const [hospital, setHospital] = useState(null);
  const [operationDefinition, setOperationDefinition] = useState(null);
  const [operation, setOperation] = useState(initialOperation);
  const [operations, setOperations] = useState(props.lead.medicalOperations);
  const [openOperationHistory, setOpenOperationHistory] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [medicalOperation, setMedicalOperation] = useState({});
  const [medicalDoctor, setMedicalDoctor] = useState();
  const [medicalHospital, setMedicalHospital] = useState();
  const [medicalOperationDefinition, setMedicalOperationDefinition] = useState();
  const [operationDefinitionOptions, setOperationDefinitionOptions] = useState([]);

  const toggleOperationHistory = () => setOpenOperationHistory(!openOperationHistory);

  const toggleNewOperation = () => {
    setOperation(initialOperation);
    setDoctor(null);
    setHospital(null);
    setOperationDefinition(null);
  };

  const onClickDelete = (data) => {
    setMedicalOperation(data);
    setDeleteModal(true);
  };

  const handleDelete = () => {
    if (medicalOperation.id) {
      const otherOperations = lead.medicalOperations.filter(operation => operation.id !== medicalOperation.id);
      let updateLead = Object.assign({}, lead);
      updateLead.medicalOperations = otherOperations;
      dispatch(deleteMedicalOperation(medicalOperation.id));
      dispatch(onUpdateLead(updateLead));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setLead(props.lead);
  }, [props.lead]);

  useEffect(() => {
    setOperations(lead.medicalOperations);
  }, [lead && lead.medicalOperations]);

  function handleChangeDoctor(event) {
    setDoctor(event);
    setOperation({
      ...operation,
      ["doctor"]: event !== null ? event.value : null
    });
  }

  function handleChangeHospital(event) {
    setHospital(event);
    setOperation({
      ...operation,
      ["hospital"]: event !== null ? event.value : null
    });
  }

  function handleChangeOperationDefinition(event) {
    handleSelect();
    setOperationDefinition(event);
    setOperation({
      ...operation,
      ["operationDefinition"]: event !== null ? event.value : null
    });
  }

  const { hospitals, errorHospital } = useSelector(state => ({
    hospitals: state.Hospital.hospitals,
    errorHospital: state.Hospital.error
  }));

  const { doctors, errorDoctor } = useSelector(state => ({
    doctors: state.Doctor.doctors,
    errorDoctor: state.Doctor.error
  }));

  const { operationDefinitions } = useSelector(state => ({
    operationDefinitions: state.OperationDefinition.operationDefinitions
  }));

  const { processResponse, error, leads } = useSelector(state => ({
    processResponse: state.Lead.processResponse,
    error: state.Lead.error,
    leads: state.Lead.leads
  }));

  const hospitalOptions = [];
  hospitals.forEach((item) => {
    hospitalOptions.push({ label: item.name, value: item });
  });

  const doctorOptions = [];
  doctors.forEach((item) => {
    doctorOptions.push({ label: item.fullName, value: item });
  });

  useEffect(() => {
    const options = [];
    operationDefinitions.forEach((item) => {
      options.push({ label: item.name, value: item });
    });
    setOperationDefinitionOptions(options);
  }, [operationDefinitions]);

  useEffect(() => {
    dispatch(onGetDoctors());
  }, []);

  useEffect(() => {
    dispatch(onGetHospitals());
  }, []);

  useEffect(() => {
    dispatch(onGetOperationDefinitions());
  }, [refresh]);

  const handleChange = (e) => {
    if (e.target.type === "datetime-local") {
      const convertedDate = e.target.value !== "" ? e.target.value.replace("T", " ") + ":00" : null;
      setOperation({
        ...operation,
        [e.target.name]: convertedDate
      });
    } else if (e.target.type === "date") {
      const convertedDate = e.target.value !== "" ? e.target.value + " 00:00:00" : null;
      setOperation({
        ...operation,
        [e.target.name]: convertedDate
      });
    } else {
      setOperation({
        ...operation,
        [e.target.name]: e.target.value
      });
    }
  };

  const saveLeadAndChanges = () => {
    let updateLead = Object.assign({}, lead);
    updateLead.medicalOperations = [...lead.medicalOperations, operation];
    dispatch(onUpdateLead(updateLead));
  };

  useEffect(() => {
    const filtered = leads.filter(lead => lead.id === props.lead.id);
    setLead(filtered[0]);
    toggleNewOperation();
  }, [dispatch, leads]);

  useEffect(() => {
    if (processResponse !== true && error.length > 0) {
      setMessage(error.message);
      setNotificationType("Danger");
      setShowNotification(true);
    }
  }, [dispatch, processResponse, error]);

  const operationColumns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Date")}`,
        Header: "Tarih",
        accessor: "operationDate",
        filterable: true,
        className: "w-25",
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Operation")}`,
        Header: "Operation",
        accessor: "operationDefinition.name",
        filterable: true,
        className: "w-25",
        Cell: (cellProps) => {
          return <CreateUser {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Doctor")}`,
        Header: "Doctor",
        accessor: "doctor.fullName",
        filterable: true,
        className: "w-25",
        Cell: (cellProps) => {
          return <CreateUser {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Hospital")}`,
        Header: "Hospital",
        accessor: "hospital.name",
        filterable: true,
        className: "w-25",
        Cell: (cellProps) => {
          return <CreateUser {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Hospitalization Date Start")}`,
        Header: "Hospitalization Date Start",
        accessor: "hospitalizationStartDate",
        filterable: true,
        className: "w-25",
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Hospitalization Date End")}`,
        Header: "Hospitalization Date End",
        accessor: "hospitalizationEndDate",
        filterable: true,
        className: "w-25",
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        className: "w-25",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Button
                color="warning"
                outline
                onClick={() => {
                  const operationData = cellProps.row.original;
                  handleEditClick(operationData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-14" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Button>
              <Button
                color="danger"
                outline
                onClick={() => {
                  const operationData = cellProps.row.original;
                  onClickDelete(operationData);
                }}
              >
                <i className="mdi mdi-delete font-size-14" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Button>
            </div>
          );
        }
      }
    ],
    [operations]
  );

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  const handleEditClick = (operationData) => {
    setModal(true);
    setMedicalOperation(operationData);
    setMedicalDoctor({
      label: operationData.doctor.fullName,
      value: operationData.doctor
    });

    setMedicalHospital({
      label: operationData.hospital.name,
      value: operationData.hospital
    });

    setMedicalOperationDefinition({
      label: operationData.operationDefinition.name,
      value: operationData.operationDefinition
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setMedicalOperation("");
    } else {
      setModal(true);
    }
  };

  const saveOperation = () => {
    const otherOperations = lead.medicalOperations.filter(operation => operation.id !== medicalOperation.id);
    let updateLead = Object.assign({}, lead);
    updateLead.medicalOperations = [...otherOperations, medicalOperation];
    dispatch(onUpdateLead(updateLead));
    toggle();
  };

  const handleChangeMedicalOperation = (e) => {
    if (e.target.type === "datetime-local") {
      const convertedDate = e.target.value !== "" ? e.target.value.replace("T", " ") + ":00" : null;
      setMedicalOperation({
        ...medicalOperation,
        [e.target.name]: convertedDate
      });
    } else if (e.target.type === "date") {
      const convertedDate = e.target.value !== "" ? e.target.value + " 00:00:00" : null;
      setMedicalOperation({
        ...medicalOperation,
        [e.target.name]: convertedDate
      });
    } else {
      setMedicalOperation({
        ...medicalOperation,
        [e.target.name]: e.target.value
      });
    }
  };

  function handleChangeMedicalDoctor(event) {
    setMedicalDoctor(event);
    setMedicalOperation({
      ...medicalOperation,
      ["doctor"]: event.value
    });
  }

  function handleChangeMedicalHospital(event) {
    setMedicalHospital(event);
    setMedicalOperation({
      ...medicalOperation,
      ["hospital"]: event.value
    });
  }

  function handleChangeMedicalOperationDefinition(event) {
    setMedicalOperationDefinition(event);
    setMedicalOperation({
      ...medicalOperation,
      ["operationDefinition"]: event.value
    });
  }

  const handleSelect = () => setRefresh(!refresh);

  return (
    <div className="container-fluid p-3">
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Notification
        message={message}
        show={showNotification}
        callbackShow={handleClose}
        type={notificationType}
      />
      <div className="row m-2">
        <Row className="col-md-12 col-xs-12 m-2">
          <Row className="col-md-12 col-xs-12">
            <div className="mb-3 col-md-3 col-xs-12">
              <Label className="form-label">{props.t("Doctor")} <i
                className="mdi mdi-alert-circle-outline error font-size-16 me-1" /></Label>
              <Select
                value={doctor}
                onChange={(event) => {
                  handleChangeDoctor(event);
                }}
                options={doctorOptions}
                className="select2-selection"
                isClearable={true}
              />
            </div>

            <div className="mb-3 col-md-3 col-xs-12">
              <Label className="form-label">{props.t("Hospital")} <i
                className="mdi mdi-alert-circle-outline error font-size-16 me-1" /></Label>
              <Select
                value={hospital}
                onChange={(event) => {
                  handleChangeHospital(event);
                }}
                options={hospitalOptions}
                className="select2-selection"
                isClearable={true}
              />
            </div>

            <div className="mb-3 col-md-3 col-xs-12">
              <Label className="form-label">{props.t("Operation Definition")} <i
                className="mdi mdi-alert-circle-outline error font-size-16 me-1" /></Label>
              <Select
                value={operationDefinition}
                onChange={(event) => {
                  handleChangeOperationDefinition(event);
                }}
                onMenuOpen={handleSelect}
                options={operationDefinitionOptions}
                className="select2-selection"
                isClearable={true}
              />
            </div>

            <div className="mb-3 col-md-3 col-xs-12">
              <Label className="form-label">{props.t("Operation Date")} <i
                className="mdi mdi-alert-circle-outline error font-size-16 me-1" /></Label>
              <Input
                id="operationDate"
                name="operationDate"
                type="datetime-local"
                onChange={e => handleChange(e)}
                value={operation && operation.operationDate || ""}
              />
            </div>

            <div className="mb-3 col-md-3 col-xs-12">
              <Label className="form-label">{props.t("Hospitalization Date Start")}</Label>
              <Input
                id="hospitalizationStartDate"
                name="hospitalizationStartDate"
                type="datetime-local"
                onChange={e => handleChange(e)}
                value={operation && operation.hospitalizationStartDate || ""}
              />
            </div>

            <div className="mb-3 col-md-3 col-xs-12">
              <Label className="form-label">{props.t("Hospitalization Date End")}</Label>
              <Input
                id="hospitalizationEndDate"
                name="hospitalizationEndDate"
                type="datetime-local"
                onChange={e => handleChange(e)}
                value={operation && operation.hospitalizationEndDate || ""}
              />
            </div>

            <div className="mb-3 col-md-6 col-xs-12">
              <Label className="form-label">{props.t("Description")}</Label>
              <Input
                name="description"
                type="text"
                onChange={handleChange}
                value={operation && operation.description || ""}
              />
            </div>
          </Row>
        </Row>

        <Row className="col-md-12 col-xs-12 m-2">
          <Button
            color="success bg-success-subtle"
            outline
            disabled={!operation.hospital || !operation.doctor || !operation.operationDefinition}
            onClick={saveLeadAndChanges}>
            <i className="mdi mdi-content-save-all font-size-16" id="addTooltip">
              {props.t("Save")}
            </i>
          </Button>
        </Row>

        <Row className="col-md-12 col-xs-12 m-2">
          <div className="custom-accordion">
            <Link
              className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
              onClick={toggleOperationHistory}
              to="#"
            >
              {props.t("Previous Operations")}
              <i
                className={
                  openOperationHistory
                    ? "mdi mdi-chevron-up accor-down-icon ms-2"
                    : "mdi mdi-chevron-down accor-down-icon ms-2"
                }
              />
            </Link>
            <Collapse isOpen={openOperationHistory}>
              {operations && operations.length > 0 && (
                <div className="row mt-3">
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {props.t("Edit")}
                    </ModalHeader>
                    <ModalBody>
                      <div form>
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Doctor")}</Label>
                              <Select
                                value={medicalDoctor}
                                onChange={(event) => handleChangeMedicalDoctor(event)}
                                options={doctorOptions}
                                className="select2-selection"
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Hospital")}</Label>
                              <Select
                                value={medicalHospital}
                                onChange={(event) => handleChangeMedicalHospital(event)}
                                options={hospitalOptions}
                                className="select2-selection"
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Operation Definition")}</Label>
                              <Select
                                value={medicalOperationDefinition}
                                onChange={(event) => handleChangeMedicalOperationDefinition(event)}
                                options={operationDefinitionOptions}
                                className="select2-selection"
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Description")}</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={handleChangeMedicalOperation}
                                value={medicalOperation.description || ""}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Operation Date")}</Label>
                              <Input
                                id="operationDate"
                                name="operationDate"
                                type="datetime-local"
                                onChange={handleChangeMedicalOperation}
                                value={medicalOperation.operationDate || ""}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Hospitalization Date Start")}</Label>
                              <Input
                                id="hospitalizationStartDate"
                                name="hospitalizationStartDate"
                                type="datetime-local"
                                onChange={handleChangeMedicalOperation}
                                value={medicalOperation.hospitalizationStartDate || ""}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Hospitalization Date End")}</Label>
                              <Input
                                id="hospitalizationEndDate"
                                name="hospitalizationEndDate"
                                type="datetime-local"
                                onChange={handleChangeMedicalOperation}
                                value={medicalOperation.hospitalizationEndDate || ""}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-lead"
                                onClick={saveOperation}
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </ModalBody>
                  </Modal>
                  <div className="col-xs-12 col-md-12 mt-4">
                    <BasicTable
                      id="operations"
                      customPageSize={20}
                      columns={operationColumns}
                      data={operations}
                      className="custom-header-css"
                    />
                  </div>
                </div>
              )}
            </Collapse>
          </div>
        </Row>
      </div>
    </div>
  );
};
LeadOperation.propTypes = {
  lead: PropTypes.any,
  saveChanges: PropTypes.func,
  sendNewOperations: PropTypes.func
};

export default withTranslation()(LeadOperation);
