import {
    GET_FIRM_INFOS,
    GET_FIRM_INFOS_FAIL,
    GET_FIRM_INFOS_SUCCESS,
    ADD_NEW_FIRM_INFO,
    ADD_FIRM_INFO_SUCCESS,
    ADD_FIRM_INFO_FAIL,
    UPDATE_FIRM_INFO,
    UPDATE_FIRM_INFO_SUCCESS,
    UPDATE_FIRM_INFO_FAIL,
    DELETE_FIRM_INFO,
    DELETE_FIRM_INFO_SUCCESS,
    DELETE_FIRM_INFO_FAIL
} from "./actionTypes";

export const getFirmInfos = () => ({
    type: GET_FIRM_INFOS,
})

export const getFirmInfosSuccess = operations => ({
    type: GET_FIRM_INFOS_SUCCESS,
    payload: operations,
})

export const getFirmInfosFail = error => ({
    type: GET_FIRM_INFOS_FAIL,
    payload: error,
})

export const addNewFirmInfo = operation => ({
    type: ADD_NEW_FIRM_INFO,
    payload: operation,
})

export const addFirmInfoSuccess = operation => ({
    type: ADD_FIRM_INFO_SUCCESS,
    payload: operation,
})

export const addFirmInfoFail = error => ({
    type: ADD_FIRM_INFO_FAIL,
    payload: error,
})

export const updateFirmInfo = operation => ({
    type: UPDATE_FIRM_INFO,
    payload: operation,
})

export const updateFirmInfoSuccess = operation => ({
    type: UPDATE_FIRM_INFO_SUCCESS,
    payload: operation,
})

export const updateFirmInfoFail = error => ({
    type: UPDATE_FIRM_INFO_FAIL,
    payload: error,
})

export const deleteFirmInfo = id => ({
    type: DELETE_FIRM_INFO,
    id,
})

export const deleteFirmInfoSuccess = (operation,req) => ({
    type: DELETE_FIRM_INFO_SUCCESS,
    payload: operation,
    req:req,
})

export const deleteFirmInfoFail = error => ({
    type: DELETE_FIRM_INFO_FAIL,
    payload: error,
})