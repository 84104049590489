
/* SEGMENTS */
export const GET_SEGMENTS = "GET_SEGMENTS"
export const GET_SEGMENTS_SUCCESS = "GET_SEGMENTS_SUCCESS"
export const GET_SEGMENTS_FAIL = "GET_SEGMENTS_FAIL"

/**
 * add SEGMENT
 */
export const ADD_NEW_SEGMENT = "ADD_NEW_SEGMENT"
export const ADD_SEGMENT_SUCCESS = "ADD_SEGMENT_SUCCESS"
export const ADD_SEGMENT_FAIL = "ADD_SEGMENT_FAIL"

/**
 * Edit SEGMENT
 */
export const UPDATE_SEGMENT = "UPDATE_SEGMENT"
export const UPDATE_SEGMENT_SUCCESS = "UPDATE_SEGMENT_SUCCESS"
export const UPDATE_SEGMENT_FAIL = "UPDATE_SEGMENT_FAIL"

/**
 * Delete SEGMENT
 */
export const DELETE_SEGMENT = "DELETE_SEGMENT"
export const DELETE_SEGMENT_SUCCESS = "DELETE_SEGMENT_SUCCESS"
export const DELETE_SEGMENT_FAIL = "DELETE_SEGMENT_FAIL"
