
/* FORMS */
export const GET_FORMS = "GET_FORMS"
export const GET_FORMS_SUCCESS = "GET_FORMS_SUCCESS"
export const GET_FORMS_FAIL = "GET_FORMS_FAIL"

/**
 * add FORM
 */
export const ADD_NEW_FORM = "ADD_NEW_FORM"
export const ADD_FORM_SUCCESS = "ADD_FORM_SUCCESS"
export const ADD_FORM_FAIL = "ADD_FORM_FAIL"

/**
 * Edit FORM
 */
export const UPDATE_FORM = "UPDATE_FORM"
export const UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS"
export const UPDATE_FORM_FAIL = "UPDATE_FORM_FAIL"

/**
 * Delete FORM
 */
export const DELETE_FORM = "DELETE_FORM"
export const DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS"
export const DELETE_FORM_FAIL = "DELETE_FORM_FAIL"
