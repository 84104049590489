import {
  ADD_NEW_PATIENT_APPOINTMENT,
  ADD_NEW_PATIENT_WITH_APPT,
  ADD_NEW_PATIENT_WITH_APPT_FAIL,
  ADD_NEW_PATIENT_WITH_APPT_SUCCESS,
  ADD_PATIENT_APPOINTMENT_FAIL,
  ADD_PATIENT_APPOINTMENT_SUCCESS,
  DELETE_PATIENT_APPOINTMENT,
  DELETE_PATIENT_APPOINTMENT_FAIL,
  DELETE_PATIENT_APPOINTMENT_SUCCESS,
  GET_LEAD_PATIENT_APPOINTMENTS,
  GET_LEAD_PATIENT_APPOINTMENTS_FAIL,
  GET_LEAD_PATIENT_APPOINTMENTS_SUCCESS,
  GET_PATIENT_APPOINTMENT_CRITERIA,
  GET_PATIENT_APPOINTMENT_CRITERIA_FAIL,
  GET_PATIENT_APPOINTMENT_CRITERIA_SUCCESS,
  GET_PATIENT_APPOINTMENTS,
  GET_PATIENT_APPOINTMENTS_FAIL,
  GET_PATIENT_APPOINTMENTS_SUCCESS,
  GET_TODAY_PATIENT_APPOINTMENTS,
  GET_TODAY_PATIENT_APPOINTMENTS_FAIL,
  GET_TODAY_PATIENT_APPOINTMENTS_SUCCESS,
  GET_USER_PATIENT_APPOINTMENTS,
  GET_USER_PATIENT_APPOINTMENTS_FAIL,
  GET_USER_PATIENT_APPOINTMENTS_SUCCESS,
  UPDATE_PATIENT_APPOINTMENT,
  UPDATE_PATIENT_APPOINTMENT_FAIL,
  UPDATE_PATIENT_APPOINTMENT_SUCCESS
} from "./actionTypes";

export const getPatientAppointments = () => ({
  type: GET_PATIENT_APPOINTMENTS
});

export const getPatientAppointmentsSuccess = patientAppointments => ({
  type: GET_PATIENT_APPOINTMENTS_SUCCESS,
  payload: patientAppointments
});

export const getPatientAppointmentsFail = error => ({
  type: GET_PATIENT_APPOINTMENTS_FAIL,
  payload: error
});

export const addNewPatientAppointment = patientAppointment => ({
  type: ADD_NEW_PATIENT_APPOINTMENT,
  payload: patientAppointment
});

export const addPatientAppointmentSuccess = patientAppointment => ({
  type: ADD_PATIENT_APPOINTMENT_SUCCESS,
  payload: patientAppointment
});

export const addPatientAppointmentFail = error => ({
  type: ADD_PATIENT_APPOINTMENT_FAIL,
  payload: error
});

export const updatePatientAppointment = patientAppointment => ({
  type: UPDATE_PATIENT_APPOINTMENT,
  payload: patientAppointment
});

export const updatePatientAppointmentSuccess = patientAppointment => ({
  type: UPDATE_PATIENT_APPOINTMENT_SUCCESS,
  payload: patientAppointment
});

export const updatePatientAppointmentFail = error => ({
  type: UPDATE_PATIENT_APPOINTMENT_FAIL,
  payload: error
});

export const deletePatientAppointment = id => ({
  type: DELETE_PATIENT_APPOINTMENT,
  id
});

export const deletePatientAppointmentSuccess = (patientAppointment,req) => ({
  type: DELETE_PATIENT_APPOINTMENT_SUCCESS,
  payload: patientAppointment,
  req: req
});

export const deletePatientAppointmentFail = error => ({
  type: DELETE_PATIENT_APPOINTMENT_FAIL,
  payload: error
});

export const getLeadPatientAppointments = id => ({
  type: GET_LEAD_PATIENT_APPOINTMENTS,
  id
});

export const getLeadPatientAppointmentsSuccess = patientAppointment => ({
  type: GET_LEAD_PATIENT_APPOINTMENTS_SUCCESS,
  payload: patientAppointment
});

export const getLeadPatientAppointmentsFail = error => ({
  type: GET_LEAD_PATIENT_APPOINTMENTS_FAIL,
  payload: error
});

export const getUserPatientAppointments = id => ({
  type: GET_USER_PATIENT_APPOINTMENTS,
  id
});

export const getUserPatientAppointmentsSuccess = patientAppointment => ({
  type: GET_USER_PATIENT_APPOINTMENTS_SUCCESS,
  payload: patientAppointment
});

export const getUserPatientAppointmentsFail = error => ({
  type: GET_USER_PATIENT_APPOINTMENTS_FAIL,
  payload: error
});

export const getTodayPatientAppointments = () => ({
  type: GET_TODAY_PATIENT_APPOINTMENTS
});

export const getTodayPatientAppointmentsSuccess = patientAppointment => ({
  type: GET_TODAY_PATIENT_APPOINTMENTS_SUCCESS,
  payload: patientAppointment
});

export const getTodayPatientAppointmentsFail = error => ({
  type: GET_TODAY_PATIENT_APPOINTMENTS_FAIL,
  payload: error
});


export const getPatientAppointmentCriteria = patientAppointment => ({
  type: GET_PATIENT_APPOINTMENT_CRITERIA,
  payload: patientAppointment
});

export const getPatientAppointmentCriteriaSuccess = patientAppointment => ({
  type: GET_PATIENT_APPOINTMENT_CRITERIA_SUCCESS,
  payload: patientAppointment
});

export const getPatientAppointmentCriteriaFail = error => ({
  type: GET_PATIENT_APPOINTMENT_CRITERIA_FAIL,
  payload: error
});

export const addNewPatientWithAppointment = res => ({
  type: ADD_NEW_PATIENT_WITH_APPT,
  payload: res
});

export const addNewPatientWithAppointmentSuccess = (res) => ({
  type: ADD_NEW_PATIENT_WITH_APPT_SUCCESS,
  payload: res
});

export const addNewPatientWithAppointmentFail = error => ({
  type: ADD_NEW_PATIENT_WITH_APPT_FAIL,
  payload: error
});