import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {withTranslation} from "react-i18next";
import {Button, Card, CardBody, CardFooter, CardHeader, Row} from "reactstrap";
import {useNavigate} from "react-router-dom";
import ChangePassword from "../../components/Common/ChangePassword";
import useAuth from "../../hooks/useAuth";
import withRouter from "../../hooks/withRouter";
import PropTypes from "prop-types";
import {userContractsSaveAll} from "../../store/TermsAndPrivacy/actions";

const Guard = props => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        termsContract,
        privacyContract,
        passwordChangeRequired,
        user,
        setNavigateGuard,
        authUser,
        setAuthUser,
        dashboard
    } = useAuth();

    const [showContracts, setShowContracts] = useState(false);
    const [showChangePass, setShowChangePass] = useState(false);
    const [termsReaded, setTermsReaded] = useState(false);
    const [privacyReaded, setPrivacyReaded] = useState(false);
    const [termsTemplate, setTermsTemplate] = useState({__html: termsContract && termsContract.content});
    const [policyTemplate, setPolicyTemplate] = useState({__html: privacyContract && privacyContract.content});

    useEffect(() => {
        if (privacyContract !== undefined || termsContract !== undefined) {
            setShowContracts(true);
        }

        if (passwordChangeRequired === true) {
            setShowChangePass(true);
        }
    }, [props]);

    useEffect(() => {
        if (privacyContract) {
            setPolicyTemplate({__html: privacyContract && privacyContract.content});
        }
        if (termsContract) {
            setTermsTemplate({__html: termsContract && termsContract.content});
        }
    }, [privacyContract, termsContract]);

    function sendApproveResponseToBackend() {
        const userTermsReq = termsTemplate && termsReaded === true && Object.assign({
            user: user,
            contract: termsContract
        });
        const userPolicyReq = policyTemplate && privacyReaded === true && Object.assign({
            user: user,
            contract: privacyContract
        });

        setShowContracts(false);

        dispatch(userContractsSaveAll([userTermsReq, userPolicyReq]));
        if (showChangePass === false) {
            setAuthUser({...authUser, ["contracts"]: []});
            localStorage.setItem("authUser", JSON.stringify({...authUser, ["contracts"]: []}));
            setNavigateGuard(false);
            navigate(dashboard);
        }
    }

    function toggleChangePass(val) {
        setShowChangePass(!val);
        if (showContracts === false) {
            setNavigateGuard(false);
            navigate("/login");
        }
    }

    return (
        <div className="page-content">
            {showContracts ? (
                <div className="container-lg">
                    <Card>
                        <CardHeader className="text-wrap text-center bg-white bg-gradient">
                            <div className="avatar-md mx-auto mb-4">
                                <div className="avatar-title bg-light  rounded-circle text-primary h1">
                                    <i className="dripicons-contract-2"></i>
                                </div>
                            </div>
                            <span className="text-info">
            {props.t("You have to accept Terms & Conditions, Privacy Policy to continue!")}
          </span>
                        </CardHeader>
                        <CardBody>
                            <div className="card-body">
                                <div className="text-center mb-4">
                                    <Row className="p-2">
                                        <div className="custom-accordion align-items-center">
                                            <div className="card border-1 shadow-none mb-0">
                                                {policyTemplate && (
                                                    <div className="row mt-2">
                                                        <div className="row justify-content-center">
                                                            <div className="col-xl-12">
                                                                <div className="mt-70 text-center">
                                                                    <span dangerouslySetInnerHTML={policyTemplate}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="me-5 text-center font-size-16 border border-4 border-info-subtle">
                                                            <input type="checkbox"
                                                                   id="customCheckcolor4"
                                                                   onClick={() => setPrivacyReaded(!privacyReaded)}
                                                                   checked={privacyReaded}
                                                            /> {props.t("I read")} <span
                                                            className="font-size-24 bold text-center">{props.t("Privacy Policy")}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Row>
                                    <hr/>
                                </div>
                            </div>
                        </CardBody>
                        <CardBody>
                            <div className="card-body">
                                <div className="text-center mb-4">
                                    <Row className="p-2">
                                        <div className="custom-accordion align-items-center">
                                            <div className="card border-1 shadow-none mb-0">
                                                {termsTemplate && (
                                                    <div className="row mt-2">
                                                        <div className="row justify-content-center">
                                                            <div className="col-xl-12">
                                                                <div className="mt-70 text-center">
                                                                    <span dangerouslySetInnerHTML={termsTemplate}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="me-5 text-center font-size-16 border border-4 border-info-subtle">
                                                            <input type="checkbox"
                                                                   id="customCheckcolor4"
                                                                   onClick={() => setTermsReaded(!termsReaded)}
                                                                   checked={termsReaded}
                                                            />{props.t("I read")}<span
                                                            className="font-size-24 bold text-center">{props.t("Terms & Conditions")}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Row>
                                    <hr/>
                                </div>
                            </div>
                        </CardBody>
                        <CardFooter>
                            <div className="row">
                                <Button
                                    color="primary"
                                    className="btn-rounded me-2"
                                    onClick={sendApproveResponseToBackend}
                                    disabled={termsTemplate && !termsReaded || policyTemplate && !privacyReaded}
                                >{props.t("Approve")}</Button>
                            </div>
                        </CardFooter>
                    </Card>
                </div>
            ) : (showChangePass ? (
                <div className="container-lg">
                    <ChangePassword user={user}
                                    callbackSomething={toggleChangePass}
                                    message={"To continue CRM, you must first update your password. You will then be directed to the login page to log in with your new password."}/>
                </div>
            ) : null)}
        </div>
    );
};

Guard.propTypes = {
    t: PropTypes.any,
    terms: PropTypes.any,
    policy: PropTypes.any,
    callbackHide: PropTypes.func,
    passwordChangeRequired: PropTypes.bool
};
export default withRouter(withTranslation()(Guard));