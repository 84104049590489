import { call, put, takeEvery } from "redux-saga/effects";

// OfferItems Redux States
import {
    ADD_NEW_OFFER_ITEM,
    DELETE_OFFER_ITEM,
    GET_OFFER_ITEMS,
    GET_TEMPLATE_OFFER_ITEMS,
    UPDATE_OFFER_ITEM
} from "./actionTypes";

import {
    addOfferItemFail,
    addOfferItemSuccess,
    deleteOfferItemFail,
    deleteOfferItemSuccess,
    getOfferItemsFail,
    getOfferItemsSuccess,
    getTemplateOfferItemsFail,
    getTemplateOfferItemsSuccess,
    updateOfferItemFail,
    updateOfferItemSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchOfferItems() {
    try {
        const response = yield call(getOfferItems);
        yield put(getOfferItemsSuccess(response));
    } catch (error) {
        yield put(getOfferItemsFail(error));
    }
}

const getOfferItems = () => get(url.GET_OFFER_ITEMS)

function* fetchTemplateOfferItems() {
    try {
        const response = yield call(getTemplateOfferItemList);
        yield put(getTemplateOfferItemsSuccess(response));
    } catch (error) {
        yield put(getTemplateOfferItemsFail(error));
    }
}

const getTemplateOfferItemList = () => get(url.OFFER_ITEM_TEMPLATES)

function* onUpdateOfferItem({ payload: operationDefinition }) {
    try {
        const response = yield call(updateOfferItem, operationDefinition);
        yield put(updateOfferItemSuccess(response));
    } catch (error) {
        yield put(updateOfferItemFail(error));
    }
}

const updateOfferItem = (operationDefinition) => post(url.UPDATE_OFFER_ITEM,operationDefinition)

function* onDeleteOfferItem({id}) {
    try {
        const response = yield call(deleteOfferItem, id);
        if (response.status === 200){
            yield put(deleteOfferItemSuccess(response,id));
        }else{
            yield put(deleteOfferItemFail(response));
        }
    } catch (error) {
        yield put(deleteOfferItemFail(error));
    }
}

const deleteOfferItem = (id) => del(`${url.DELETE_OFFER_ITEM}/${id}`);

function* onAddNewOfferItem({ payload: operationDefinition }) {
    try {
        const response = yield call(addNewOfferItem, operationDefinition);
        yield put(addOfferItemSuccess(response));
    } catch (error) {
        yield put(addOfferItemFail(error));
    }
}

const addNewOfferItem = (operationDefinition) => post(url.ADD_NEW_OFFER_ITEM,operationDefinition);

function* offerItemSaga() {
    yield takeEvery(GET_OFFER_ITEMS, fetchOfferItems);
    yield takeEvery(GET_TEMPLATE_OFFER_ITEMS, fetchTemplateOfferItems);
    yield takeEvery(ADD_NEW_OFFER_ITEM, onAddNewOfferItem);
    yield takeEvery(UPDATE_OFFER_ITEM, onUpdateOfferItem);
    yield takeEvery(DELETE_OFFER_ITEM, onDeleteOfferItem);
}

export default offerItemSaga;
