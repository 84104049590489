
/**
 * EXCEL Import
 */
export const IMPORT_EXCEL = "IMPORT_EXCEL"
export const IMPORT_EXCEL_SUCCESS = "IMPORT_EXCEL_SUCCESS"
export const IMPORT_EXCEL_ERROR = "IMPORT_EXCEL_ERROR"

/**
 * CSV Import
 */
export const IMPORT_CSV = "IMPORT_CSV"
export const IMPORT_CSV_SUCCESS = "IMPORT_CSV_SUCCESS"
export const IMPORT_CSV_ERROR = "IMPORT_CSV_ERROR"