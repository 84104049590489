import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Form, Row } from "reactstrap";
import Dropzone from "react-dropzone";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { fileToBase64 } from "../../common/commonFunctions";
import WarningModal from "./WarningModal";

const FormUpload = props => {

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSend, setIsSend] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalTriggered, setModelTriggered] = useState(false);

  function handleAcceptedFiles(files) {
    files.map(file => {
        fileToBase64(file).then(response => {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            convertedBase64: response
          });
        });
      }
    );
    setSelectedFiles(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };


  const handleSendFiles = () => {
    if (selectedFiles.length > 0) {
      setIsSend(true);
      props.sendFile(selectedFiles);
    }
  };

  useEffect(() => {
      if (props.callbackClose) {
        setSelectedFiles([]);
        setIsSend(false);
      }
    }, [props.callbackClose]
  );

  useEffect(() => {
      if (props.modal) {
        setModelTriggered(true);
      }
    }, [props.modal]
  );

  return (
    <Card>
      {modalTriggered && (
        <WarningModal
          show={deleteModal}
          onApproveClick={handleSendFiles}
          onCloseClick={() => setDeleteModal(false)}
          title={props.t("Are you sure ?")}
          message={props.message}
        />
      )}
      <CardBody>
        {props.title && (<CardTitle className="h4">{props.t("Upload File")}</CardTitle>)}
        <Form>
          <Dropzone
            onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="customdropzone">
                <div
                  className="dz-message needsclick"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-6 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>{props.t("Select file to import")}</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <div
            className="dropzone-previews mt-3"
            id="file-previews"
          >
            {selectedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col>
                        <Link
                          to="#"
                          className="text-muted font-weight-bold"
                        >
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })}
          </div>
        </Form>

        <div className="text-center mt-4">
          <button
            type="button"
            className="btn btn-primary"
            disabled={isSend || selectedFiles.length === 0}
            onClick={modalTriggered !== true ? handleSendFiles : () => setDeleteModal(true)}
          >
            {props.t("Import")}
          </button>
        </div>
      </CardBody>
    </Card>
  );
};

FormUpload.propTypes = {
  t: PropTypes.any,
  fetchImportFile: PropTypes.func,
  fetchImportExcel: PropTypes.func,
  fetchImportCSV: PropTypes.func,
  sendFile: PropTypes.func,
  title: PropTypes.any,
  callbackClose: PropTypes.bool,
  modal: PropTypes.bool,
  message: PropTypes.any
};

export default withTranslation()(FormUpload);
