import PropTypes from "prop-types";
import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

const WarningModal = ({ show, onApproveClick, onCloseClick, message, title, t, approveText, rejectText }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              />
              <h2>{title ? title : "Emin misiniz?"}</h2>
              <h4>{message ? message : "Bu işlem geri alınamaz !"}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={onApproveClick}
              >
                {approveText ? t(approveText) : t("Approve")}
              </button>
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={onCloseClick}
              >
                {rejectText ? t(rejectText) : t("Cancel")}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

WarningModal.propTypes = {
  t: PropTypes.any,
  onCloseClick: PropTypes.func,
  onApproveClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.string,
  title: PropTypes.string,
  approveText: PropTypes.string,
  rejectText: PropTypes.string
};

export default withTranslation()(WarningModal);
