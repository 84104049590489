import { call, put, takeEvery } from "redux-saga/effects";

// FirmInfos Redux States
import { ADD_NEW_FIRM_INFO, DELETE_FIRM_INFO, GET_FIRM_INFOS, UPDATE_FIRM_INFO } from "./actionTypes";

import {
    addFirmInfoFail,
    addFirmInfoSuccess,
    deleteFirmInfoFail,
    deleteFirmInfoSuccess,
    getFirmInfosFail,
    getFirmInfosSuccess,
    updateFirmInfoFail,
    updateFirmInfoSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchFirmInfos() {
    try {
        const response = yield call(getFirmInfos);
        yield put(getFirmInfosSuccess(response));
    } catch (error) {
        yield put(getFirmInfosFail(error));
    }
}

const getFirmInfos = () => get(url.GET_FIRM_INFO)

function* onUpdateFirmInfo({ payload: operationDefinition }) {
    try {
        const response = yield call(updateFirmInfo, operationDefinition);
        yield put(updateFirmInfoSuccess(response));
    } catch (error) {
        yield put(updateFirmInfoFail(error));
    }
}

const updateFirmInfo = (operationDefinition) => post(url.UPDATE_FIRM_INFO,operationDefinition)

function* onDeleteFirmInfo({id}) {
    try {
        const response = yield call(deleteFirmInfo, id);
        if (response.status === 200){
            yield put(deleteFirmInfoSuccess(response,id));
        }else{
            yield put(deleteFirmInfoFail(response));
        }
    } catch (error) {
        yield put(deleteFirmInfoFail(error));
    }
}

const deleteFirmInfo = (id) => del(`${url.DELETE_FIRM_INFO}/${id}`);

function* onAddNewFirmInfo({ payload: operationDefinition }) {
    try {
        const response = yield call(addNewFirmInfo, operationDefinition);
        yield put(addFirmInfoSuccess(response));
    } catch (error) {
        yield put(addFirmInfoFail(error));
    }
}

const addNewFirmInfo = (operationDefinition) => post(url.ADD_NEW_FIRM_INFO,operationDefinition);

function* firmInfoSaga() {
    yield takeEvery(GET_FIRM_INFOS, fetchFirmInfos);
    yield takeEvery(ADD_NEW_FIRM_INFO, onAddNewFirmInfo);
    yield takeEvery(UPDATE_FIRM_INFO, onUpdateFirmInfo);
    yield takeEvery(DELETE_FIRM_INFO, onDeleteFirmInfo);
}

export default firmInfoSaga;
