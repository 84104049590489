import { call, put, takeEvery } from "redux-saga/effects";

// Translate Redux States
import {
    ADD_NEW_TRANSLATE,
    DELETE_TRANSLATE,
    GET_TRANSLATES,
    SAVE_ALL_TRANSLATES,
    TRANSLATE_CRITERIA,
    UPDATE_TRANSLATE
} from "./actionTypes";

import {
    addTranslateFail,
    addTranslateSuccess,
    deleteTranslateFail,
    deleteTranslateSuccess,
    getTranslatesFail,
    getTranslatesSuccess,
    translateCriteriaFail,
    translateCriteriaSuccess,
    translatesSaveAllFail,
    translatesSaveAllSuccess,
    updateTranslateFail,
    updateTranslateSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchTranslates() {
    try {
        const response = yield call(getTranslates);
        yield put(getTranslatesSuccess(response));
    } catch (error) {
        yield put(getTranslatesFail(error));
    }
}

const getTranslates = () => get(url.TRANSLATE_GET_ALL)

function* onUpdateTranslate({ payload: translate }) {
    try {
        const response = yield call(updateTranslate, translate);
        yield put(updateTranslateSuccess(response));
    } catch (error) {
        yield put(updateTranslateFail(error));
    }
}

const updateTranslate = (translate) => post(url.TRANSLATE_API,translate)

function* onDeleteTranslate({ id}) {
    try {
        const response = yield call(deleteTranslate, id);
        yield put(deleteTranslateSuccess(response ,id));
    } catch (error) {
        yield put(deleteTranslateFail(error));
    }
}

const deleteTranslate = (id) => del(`${url.TRANSLATE_API}/${id}`);

function* onAddNewTranslate({ payload: translate }) {
    try {
        const response = yield call(addNewTranslate, translate);

        yield put(addTranslateSuccess(response));
    } catch (error) {
        yield put(addTranslateFail(error));
    }
}

const addNewTranslate = (translate) => post(url.TRANSLATE_API,translate);

function* fetchTranslateCriteria({ payload: req }) {

    try {
        const response = yield call(getTranslateCriteria, req);
        yield put(translateCriteriaSuccess(response, req));
    } catch (error) {
        yield put(translateCriteriaFail(error));
    }
}

const getTranslateCriteria = (req) => post(url.TRANSLATE_CRITERIA_API, req);

function* saveAllTranslates({ payload: req }) {
    try {
        const response = yield call(saveAll, req);
        yield put(translatesSaveAllSuccess(response));
    } catch (error) {
        yield put(translatesSaveAllFail(error));
    }
}

const saveAll = (req) => post(url.TRANSLATE_SAVE_ALL, req);

function* translatesSaga() {
    yield takeEvery(GET_TRANSLATES, fetchTranslates);
    yield takeEvery(ADD_NEW_TRANSLATE, onAddNewTranslate);
    yield takeEvery(UPDATE_TRANSLATE, onUpdateTranslate);
    yield takeEvery(DELETE_TRANSLATE, onDeleteTranslate);
    yield takeEvery(TRANSLATE_CRITERIA, fetchTranslateCriteria);
    yield takeEvery(SAVE_ALL_TRANSLATES, saveAllTranslates);
}

export default translatesSaga;
