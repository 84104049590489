
/* NOTES */
export const GET_NOTES = "GET_NOTES"
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS"
export const GET_NOTES_FAIL = "GET_NOTES_FAIL"

/**
 * add NOTE
 */
export const ADD_NEW_NOTE = "ADD_NEW_NOTE"
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS"
export const ADD_NOTE_FAIL = "ADD_NOTE_FAIL"

/**
 * Edit NOTE
 */
export const UPDATE_NOTE = "UPDATE_NOTE"
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS"
export const UPDATE_NOTE_FAIL = "UPDATE_NOTE_FAIL"

/**
 * Delete NOTE
 */
export const DELETE_NOTE = "DELETE_NOTE"
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS"
export const DELETE_NOTE_FAIL = "DELETE_NOTE_FAIL"

/**
 * Lead NOTE
 */
export const GET_LEAD_NOTES = "GET_LEAD_NOTES"
export const GET_LEAD_NOTES_SUCCESS = "GET_LEAD_NOTES_SUCCESS"
export const GET_LEAD_NOTES_FAIL = "GET_LEAD_NOTES_FAIL"
