import { call, put, takeEvery } from "redux-saga/effects";

// Roles Redux States
import { ADD_NEW_ROLE, DELETE_ROLE, GET_ROLES, UPDATE_ROLE } from "./actionTypes";

import {
  addRoleFail,
  addRoleSuccess,
  deleteRoleFail,
  deleteRoleSuccess,
  getRolesFail,
  getRolesSuccess,
  updateRoleFail,
  updateRoleSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { get, post, del } from "../../helpers/api_helper";

function* fetchRoles() {
  try {
    const response = yield call(getRoles);
    yield put(getRolesSuccess(response));
  } catch (error) {
    yield put(getRolesFail(error));
  }
}

const getRoles = () => get(url.GET_ROLES);

function* onUpdateRole({ payload: role }) {
  try {
    const response = yield call(updateRole, role);
    yield put(updateRoleSuccess(response));
  } catch (error) {
    yield put(updateRoleFail(error));
  }
}

const updateRole = (role) => post(url.UPDATE_ROLE, role);

function* onDeleteRole({ id }) {
  try {
    const response = yield call(deleteRole, id);
    yield put(deleteRoleSuccess(response, id));
  } catch (error) {
    yield put(deleteRoleFail(error));
  }
}

const deleteRole = (id) => del(`${url.DELETE_ROLE}/${id}`);

function* onAddNewRole({ payload: role }) {
  try {
    const response = yield call(addNewRole, role);

    yield put(addRoleSuccess(response));
  } catch (error) {
    yield put(addRoleFail(error));
  }
}

const addNewRole = (role) => post(url.ADD_NEW_ROLE, role);

function* rolesSaga() {
  yield takeEvery(GET_ROLES, fetchRoles);
  yield takeEvery(ADD_NEW_ROLE, onAddNewRole);
  yield takeEvery(UPDATE_ROLE, onUpdateRole);
  yield takeEvery(DELETE_ROLE, onDeleteRole);
}

export default rolesSaga;
