import React from "react";
import { Card, CardBody, CardTitle, Progress, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const CardWithBars = (props) => {

  let totalSegmentCount = 0;
  props.segmentCounts.forEach(item => {
    totalSegmentCount = (item.count + totalSegmentCount);
  });
  let totalCategoryCounts = 0;
  props.categoryCounts.forEach(item => {
    totalCategoryCounts = item.count + totalCategoryCounts;
  });
  let totalResourceCounts = 0;
  props.resourceCounts.forEach(item => {
    totalResourceCounts = item.count + totalResourceCounts;
  });
  let totalReferenceCounts = 0;
  props.referenceCounts.forEach(item => {
    totalReferenceCounts = item.count + totalReferenceCounts;
  });

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-2 text-center text-capitalize">{props.title}</CardTitle>
        <div className="mt-2">
          <table className="table align-middle table-nowrap">
            <tbody className="mt-2">
            <Row>
              <Col md={3}>
                <h6 className="text-info m-2">{props.t("Segments")}</h6>
                {props.segmentCounts.map(segment => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <tr>
                      <td style={{ width: "45%" }}>
                        <p className="m-2">{segment.name}</p>
                      </td>
                      <td style={{ width: "20%" }}>
                        <h5 className="m-2">{segment.count}</h5>
                      </td>
                      <td style={{ width: "45%" }}>
                        <Progress
                          value={(segment.count * 100) / totalSegmentCount}
                          color="primary"
                          className="bg-transparent progress-sm"
                          size="sm"
                        />
                      </td>
                    </tr>
                  );
                })}
              </Col>
              <Col md={3}>
                <h6 className="text-success m-2">{props.t("Categories")}</h6>
                {props.categoryCounts.map(category => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <tr>
                      <td style={{ width: "45%" }}>
                        <p className="m-2">{category.name}</p>
                      </td>
                      <td style={{ width: "20%" }}>
                        <h5 className="m-2">{category.count}</h5>
                      </td>
                      <td style={{ width: "45%" }}>
                        <Progress
                          value={category.count * 100 / totalCategoryCounts}
                          color="success"
                          className="bg-transparent progress-sm"
                          size="sm"
                        />
                      </td>
                    </tr>
                  );
                })}
              </Col>
              <Col md={3}>
                <h6 className="text-warning m-2">{props.t("Resources")}</h6>
                {props.resourceCounts.map(resource => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <tr>
                      <td style={{ width: "45%" }}>
                        <p className="m-2">{resource.name}</p>
                      </td>
                      <td style={{ width: "20%" }}>
                        <h5 className="m-2">{resource.count}</h5>
                      </td>
                      <td style={{ width: "45%" }}>
                        <Progress
                          value={resource.count * 100 / totalResourceCounts}
                          color="warning"
                          className="bg-transparent progress-sm"
                          size="sm"
                        />
                      </td>
                    </tr>
                  );
                })}
              </Col>
              <Col md={3}>
                <h6 className="text-danger m-2">{props.t("References")}</h6>
                {props.referenceCounts.map(reference => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <tr>
                      <td style={{ width: "45%" }}>
                        <p className="m-2">{reference.name}</p>
                      </td>
                      <td style={{ width: "20%" }}>
                        <h5 className="m-2">{reference.count}</h5>
                      </td>
                      <td style={{ width: "45%" }}>
                        <Progress
                          value={reference.count * 100 / totalReferenceCounts}
                          color="danger"
                          className="bg-transparent progress-sm"
                          size="sm"
                        />
                      </td>
                    </tr>
                  );
                })}
              </Col>
            </Row>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

CardWithBars.propTypes = {
  t: PropTypes.any,
  title: PropTypes.string,
  segmentCounts: PropTypes.any,
  categoryCounts: PropTypes.any,
  referenceCounts: PropTypes.any,
  resourceCounts: PropTypes.any
};

export default withTranslation()(CardWithBars);
