import { call, put, takeEvery } from "redux-saga/effects";

// Screens Redux States
import { ADD_NEW_SCREEN, DELETE_SCREEN, GET_SCREENS, UPDATE_SCREEN } from "./actionTypes";

import {
  addScreenFail,
  addScreenSuccess,
  deleteScreenFail,
  deleteScreenSuccess,
  getScreensFail,
  getScreensSuccess,
  updateScreenFail,
  updateScreenSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchScreens() {
  try {
    const response = yield call(getScreens);
    yield put(getScreensSuccess(response));
  } catch (error) {
    yield put(getScreensFail(error));
  }
}

const getScreens = () => get(url.GET_SCREENS)

function* onUpdateScreen({ payload: screen }) {
  try {
    const response = yield call(updateScreen, screen);
    yield put(updateScreenSuccess(response));
  } catch (error) {
    yield put(updateScreenFail(error));
  }
}

const updateScreen = (screen) => post(url.UPDATE_SCREEN,screen)

function* onDeleteScreen({id}) {
  try {
    const response = yield call(deleteScreen, id);
    yield put(deleteScreenSuccess(response, id));
  } catch (error) {
    yield put(deleteScreenFail(error));
  }
}

const deleteScreen = (id) => del(`${url.DELETE_SCREEN}/${id}`);

function* onAddNewScreen({ payload: screen }) {
  try {
    const response = yield call(addNewScreen, screen);

    yield put(addScreenSuccess(response));
  } catch (error) {
    yield put(addScreenFail(error));
  }
}

const addNewScreen = (screen) => post(url.ADD_NEW_SCREEN,screen);

function* screensSaga() {
  yield takeEvery(GET_SCREENS, fetchScreens);
  yield takeEvery(ADD_NEW_SCREEN, onAddNewScreen);
  yield takeEvery(UPDATE_SCREEN, onUpdateScreen);
  yield takeEvery(DELETE_SCREEN, onDeleteScreen);
}

export default screensSaga;
