import {
  ADD_LEAD_FAIL,
  ADD_LEAD_SUCCESS,
  ADD_NEW_LEAD,
  ASSIGN_REPRESENTATIVE,
  ASSIGN_REPRESENTATIVE_FAIL,
  ASSIGN_REPRESENTATIVE_SUCCESS,
  ASSIGN_SEGMENT,
  ASSIGN_SEGMENT_FAIL,
  ASSIGN_SEGMENT_SUCCESS,
  DELETE_LEAD,
  DELETE_LEAD_FAIL,
  DELETE_LEAD_SUCCESS,
  GET_LEAD_BY_ID,
  GET_LEAD_BY_ID_FAIL,
  GET_LEAD_BY_ID_SUCCESS,
  GET_LEADS,
  GET_LEADS_FAIL,
  GET_LEADS_SUCCESS,
  LEAD_CRITERIA,
  LEAD_CRITERIA_FAIL,
  LEAD_CRITERIA_SUCCESS, TRANSFER_LEAD_DATA, TRANSFER_LEAD_DATA_FAIL, TRANSFER_LEAD_DATA_SUCCESS,
  UPDATE_LEAD,
  UPDATE_LEAD_FAIL,
  UPDATE_LEAD_SUCCESS
} from "./actionTypes";

export const getLeads = () => ({
  type: GET_LEADS
});

export const getLeadsSuccess = leads => ({
  type: GET_LEADS_SUCCESS,
  payload: leads
});

export const getLeadsFail = error => ({
  type: GET_LEADS_FAIL,
  payload: error
});

export const addNewLead = lead => ({
  type: ADD_NEW_LEAD,
  payload: lead
});

export const addLeadSuccess = lead => ({
  type: ADD_LEAD_SUCCESS,
  payload: lead
});

export const addLeadFail = error => ({
  type: ADD_LEAD_FAIL,
  payload: error
});

export const updateLead = lead => ({
  type: UPDATE_LEAD,
  payload: lead
});

export const updateLeadSuccess = lead => ({
  type: UPDATE_LEAD_SUCCESS,
  payload: lead
});

export const updateLeadFail = error => ({
  type: UPDATE_LEAD_FAIL,
  payload: error
});

export const deleteLead = lead => ({
  type: DELETE_LEAD,
  payload: lead
});

export const deleteLeadSuccess = lead => ({
  type: DELETE_LEAD_SUCCESS,
  payload: lead
});

export const deleteLeadFail = error => ({
  type: DELETE_LEAD_FAIL,
  payload: error
});

export const getLeadById = id => ({
  type: GET_LEAD_BY_ID,
  id
});

export const getLeadByIdSuccess = lead => ({
  type: GET_LEAD_BY_ID_SUCCESS,
  payload: lead
});

export const getLeadByIdFail = error => ({
  type: GET_LEAD_BY_ID_FAIL,
  payload: error
});

export const assignRepresentative = req => ({
  type: ASSIGN_REPRESENTATIVE,
  payload: req
});

export const assignRepresentativeSuccess = lead => ({
  type: ASSIGN_REPRESENTATIVE_SUCCESS,
  payload: lead
});

export const assignRepresentativeFail = error => ({
  type: ASSIGN_REPRESENTATIVE_FAIL,
  payload: error
});

export const assignSegment = req => ({
  type: ASSIGN_SEGMENT,
  payload: req
});

export const assignSegmentSuccess = lead => ({
  type: ASSIGN_SEGMENT_SUCCESS,
  payload: lead
});

export const assignSegmentFail = error => ({
  type: ASSIGN_SEGMENT_FAIL,
  payload: error
});

export const leadCriteria = res => ({
  type: LEAD_CRITERIA,
  payload: res
});

export const leadCriteriaSuccess = (res) => ({
  type: LEAD_CRITERIA_SUCCESS,
  payload: res
});

export const leadCriteriaFail = error => ({
  type: LEAD_CRITERIA_FAIL,
  payload: error
});

export const transferData = req => ({
  type: TRANSFER_LEAD_DATA,
  payload: req
});

export const transferDataSuccess = lead => ({
  type: TRANSFER_LEAD_DATA_SUCCESS,
  payload: lead
});

export const transferDataFail = error => ({
  type: TRANSFER_LEAD_DATA_FAIL,
  payload: error
});