
/* LEAD_INTEGRATION_DEFINITIONS */
export const GET_LEAD_INTEGRATION_DEFINITION= "GET_LEAD_INTEGRATION_DEFINITION"
export const GET_LEAD_INTEGRATION_DEFINITION_SUCCESS = "GET_LEAD_INTEGRATION_DEFINITION_SUCCESS"
export const GET_LEAD_INTEGRATION_DEFINITION_FAIL = "GET_LEAD_INTEGRATION_DEFINITION_FAIL"

/**
 * add LEAD_INTEGRATION_DEFINITION
 */
export const ADD_LEAD_INTEGRATION_DEFINITION = "ADD_LEAD_INTEGRATION_DEFINITION"
export const ADD_LEAD_INTEGRATION_DEFINITION_SUCCESS = "ADD_LEAD_INTEGRATION_DEFINITION_SUCCESS"
export const ADD_LEAD_INTEGRATION_DEFINITION_FAIL = "ADD_LEAD_INTEGRATION_DEFINITION_FAIL"

/**
 * Edit LEAD_INTEGRATION_DEFINITION
 */
export const UPDATE_LEAD_INTEGRATION_DEFINITION = "UPDATE_LEAD_INTEGRATION_DEFINITION"
export const UPDATE_LEAD_INTEGRATION_DEFINITION_SUCCESS = "UPDATE_LEAD_INTEGRATION_DEFINITION_SUCCESS"
export const UPDATE_LEAD_INTEGRATION_DEFINITION_FAIL = "UPDATE_LEAD_INTEGRATION_DEFINITION_FAIL"

/**
 * Delete LEAD_INTEGRATION_DEFINITION
 */
export const DELETE_LEAD_INTEGRATION_DEFINITION = "DELETE_LEAD_INTEGRATION_DEFINITION"
export const DELETE_LEAD_INTEGRATION_DEFINITION_SUCCESS = "DELETE_LEAD_INTEGRATION_DEFINITION_SUCCESS"
export const DELETE_LEAD_INTEGRATION_DEFINITION_FAIL = "DELETE_LEAD_INTEGRATION_DEFINITION_FAIL"
