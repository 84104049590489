export const GET_LAST_LOGINS_FOR_USER = "GET_LAST_LOGINS_FOR_USER"
export const GET_LAST_LOGINS_FOR_USER_SUCCESS = "GET_LAST_LOGINS_FOR_USER_SUCCESS"
export const GET_LAST_LOGINS_FOR_USER_ERROR = "GET_LAST_LOGINS_FOR_USER_ERROR"

export const GET_LAST_LOGINS = "GET_LAST_LOGINS"
export const GET_LAST_LOGINS_SUCCESS = "GET_LAST_LOGINS_SUCCESS"
export const GET_LAST_LOGINS_ERROR = "GET_LAST_LOGINS_ERROR"

export const GET_AUTH_LOGS = "GET_AUTH_LOGS"
export const GET_AUTH_LOGS_SUCCESS = "GET_AUTH_LOGS_SUCCESS"
export const GET_AUTH_LOGS_ERROR = "GET_AUTH_LOGS_ERROR"

export const GET_FACEBOOK_AUTH_LOGS = "GET_FACEBOOK_AUTH_LOGS"
export const GET_FACEBOOK_AUTH_LOGS_SUCCESS = "GET_FACEBOOK_AUTH_LOGS_SUCCESS"
export const GET_FACEBOOK_AUTH_LOGS_ERROR = "GET_FACEBOOK_AUTH_LOGS_ERROR"