import { call, put, takeEvery } from "redux-saga/effects";

// MedicalOperations Redux States
import {
    ADD_NEW_MEDICAL_OPERATION,
    DELETE_MEDICAL_OPERATION,
    GET_MEDICAL_OPERATIONS,
    UPDATE_MEDICAL_OPERATION
} from "./actionTypes";

import {
    addMedicalOperationFail,
    addMedicalOperationSuccess,
    deleteMedicalOperationFail,
    deleteMedicalOperationSuccess,
    getMedicalOperationsFail,
    getMedicalOperationsSuccess,
    updateMedicalOperationFail,
    updateMedicalOperationSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchMedicalOperations() {
    try {
        const response = yield call(getMedicalOperations);
        yield put(getMedicalOperationsSuccess(response));
    } catch (error) {
        yield put(getMedicalOperationsFail(error));
    }
}

const getMedicalOperations = () => get(url.GET_MEDICAL_OPERATION)

function* onUpdateMedicalOperation({ payload: operationDefinition }) {
    try {
        const response = yield call(updateMedicalOperation, operationDefinition);
        yield put(updateMedicalOperationSuccess(response));
    } catch (error) {
        yield put(updateMedicalOperationFail(error));
    }
}

const updateMedicalOperation = (operationDefinition) => post(url.UPDATE_MEDICAL_OPERATION,operationDefinition)

function* onDeleteMedicalOperation({id}) {
    try {
        const response = yield call(deleteMedicalOperation, id);
        if (response.status === 200){
            yield put(deleteMedicalOperationSuccess(response,id));
        }else{
            yield put(deleteMedicalOperationFail(response));
        }
    } catch (error) {
        yield put(deleteMedicalOperationFail(error));
    }
}

const deleteMedicalOperation = (id) => del(`${url.DELETE_MEDICAL_OPERATION}/${id}`);

function* onAddNewMedicalOperation({ payload: operationDefinition }) {
    try {
        const response = yield call(addNewMedicalOperation, operationDefinition);

        yield put(addMedicalOperationSuccess(response));
    } catch (error) {
        yield put(addMedicalOperationFail(error));
    }
}

const addNewMedicalOperation = (operationDefinition) => post(url.ADD_NEW_MEDICAL_OPERATION,operationDefinition);

function* medicalOperationSaga() {
    yield takeEvery(GET_MEDICAL_OPERATIONS, fetchMedicalOperations);
    yield takeEvery(ADD_NEW_MEDICAL_OPERATION, onAddNewMedicalOperation);
    yield takeEvery(UPDATE_MEDICAL_OPERATION, onUpdateMedicalOperation);
    yield takeEvery(DELETE_MEDICAL_OPERATION, onDeleteMedicalOperation);
}

export default medicalOperationSaga;
