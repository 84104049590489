import { call, put, takeEvery } from "redux-saga/effects";

// Platform Redux States
import { ADD_NEW_PLATFORM, DELETE_PLATFORM, GET_PLATFORMS, UPDATE_PLATFORM } from "./actionTypes";

import {
    addPlatformFail,
    addPlatformSuccess,
    deletePlatformFail,
    deletePlatformSuccess,
    getPlatformsFail,
    getPlatformsSuccess,
    updatePlatformFail,
    updatePlatformSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchPlatforms() {
    try {
        const response = yield call(getPlatforms);
        yield put(getPlatformsSuccess(response));
    } catch (error) {
        yield put(getPlatformsFail(error));
    }
}

const getPlatforms = () => get(url.GET_PLATFORMS)

function* onUpdatePlatform({ payload: platform }) {
    try {
        const response = yield call(updatePlatform, platform);
        yield put(updatePlatformSuccess(response));
    } catch (error) {
        yield put(updatePlatformFail(error));
    }
}

const updatePlatform = (form) => post(url.UPDATE_PLATFORM,form)

function* onDeletePlatform({ id }) {
    try {
        const response = yield call(deletePlatform, id);
        if (response.status === 200){
            yield put(deletePlatformSuccess(response,id));
        }else{
            yield put(deletePlatformFail(response));
        }
    } catch (error) {
        yield put(deletePlatformFail(error));
    }
}

const deletePlatform = (id) => del(`${url.DELETE_PLATFORM}/${id}`);

function* onAddNewPlatform({ payload: platform }) {
    try {
        const response = yield call(addNewPlatform, platform);

        yield put(addPlatformSuccess(response));
    } catch (error) {
        yield put(addPlatformFail(error));
    }
}

const addNewPlatform = (form) => post(url.ADD_NEW_PLATFORM,form);

function* platformsSaga() {
    yield takeEvery(GET_PLATFORMS, fetchPlatforms);
    yield takeEvery(ADD_NEW_PLATFORM, onAddNewPlatform);
    yield takeEvery(UPDATE_PLATFORM, onUpdatePlatform);
    yield takeEvery(DELETE_PLATFORM, onDeletePlatform);
}

export default platformsSaga;
