import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Input, Label } from "reactstrap";

// Column
import { withTranslation } from "react-i18next";
import { AppointmentDesc, CreateDate } from "../PatientCol";
import { formatDate, formatDateScreen, formatTimeScreen, StringToDate } from "../../../common/commonFunctions";
import BasicTable from "../../../components/Common/BasicTable";
import Select from "react-select";
import { post } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
import Notification from "../../../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { addNewPatientAppointment } from "../../../store/patientAppointment/actions";
import { postWithoutToken } from "../../../helpers/axios_with_headers";

const PatientAppointment = props => {

  const dispatch = useDispatch();

  const [apptDescription, setApptDescription] = useState("");
  const [selectedAppt, setSelectedAppt] = useState(false);
  const [custAppt, setCustAppt] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [patient, setPatient] = useState({});
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [sectionOptions, setSectionOptions] = useState(props.sectionOptions);
  const [clinicOptions, setClinicOptions] = useState();
  const [selectedSection, setSelectedSection] = useState(null);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [dateArray, setDateArray] = useState([]);
  const [timeArray, setTimeArray] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedTime, setSelectedTime] = useState([]);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("09:00");

  const patientAppointmentBody = {
    size: 100,
    page: 0,
    patient: patient.id,
    status: "APPROVED"
  };

  const appointmentColumns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Date")}`,
        accessor: "appointmentDate",
        filterable: true,
        className: "col-3",
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Specialist")}`,
        accessor: "appointmentUser.fullName",
        filterable: true,
        className: "col-3",
        Cell: (cellProps) => {
          return <AppointmentDesc {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        accessor: "description",
        filterable: true,
        className: "col-6",
        Cell: (cellProps) => {
          return <AppointmentDesc {...cellProps} />;
        }
      }
    ],
    []
  );

  useEffect(() => {
    setForceUpdate(props.forceUpdate);
  }, [props.forceUpdate]);

  useEffect(() => {
    setPatient(props.patient);
  }, [props.patient]);

  useEffect(() => {
    setSectionOptions(props.sectionOptions);
  }, [props.sectionOptions]);

  const saveAppt = () => {
    const filteredDoctor = props.doctors && props.doctors.find(item => item.id === selectedDoctor.value);
    const createAppt = {
      appointmentDate: formatDate(StringToDate(date + " " + time, "DDMMYYYY", "-")),
      description: apptDescription,
      patient: props.patient,
      appointmentUser: filteredDoctor
    };

    dispatch(addNewPatientAppointment(createAppt));
    setForceUpdate(true);
    setSelectedDate(null);
    setSelectedTime(null);
    setApptDescription("");
    setSelectedAppt(false);
  };

  const { error, success } = useSelector(state => ({
    error: state.PatientAppointment.error,
    success: state.PatientAppointment.success
  }));

  useEffect(() => {
    if (Object.entries(error).length > 0) {
      setShowNotification(true);
      setMessage(error.data.message);
      setNotificationType("Danger");
    } else if (success) {
      setShowNotification(true);
      setMessage(props.t("Appointment request has been received."));
      setNotificationType("Success");
    }
  }, [dispatch, error, success]);

  useEffect(() => {
    if (forceUpdate && patient.id || patient.id) {
      post(url.GET_PATIENT_APPOINTMENT_CRITERIA, patientAppointmentBody).then(res => {
        setCustAppt(res.content);
      });
      setForceUpdate(false);
    }
  }, [forceUpdate]);

  const handleChangeApptDescription = (e) => {
    setApptDescription(e.target.value);
  };

  function onChangeClinicSelect(event) {
    if (event !== null) {
      setSelectedClinic(event);
      setSelectedDoctor(null);
      setDateArray([]);
      setTimeArray([]);
      setSelectedDate(null);
      setSelectedTime(null);
    } else {
      setSelectedClinic(null);
      setSelectedDoctor(null);
      setDateArray([]);
      setTimeArray([]);
      setSelectedDate(null);
      setSelectedTime(null);
    }
  }

  function onChangeSectionSelect(event) {
    if (event !== null) {
      setSelectedSection(event);
      setSelectedClinic(null);
      setSelectedDoctor(null);
      setSelectedDate(null);
      setSelectedTime(null);
    } else {
      setSelectedSection(null);
      setSelectedClinic(null);
      setSelectedDoctor(null);
      setDateArray([]);
      setTimeArray([]);
      setSelectedDate(null);
      setSelectedTime(null);
    }
  }

  useEffect(() => {
    if (selectedSection && selectedSection.value) {
      const clinicList = selectedSection && selectedSection.value.departments && selectedSection.value.departments.map(item => ({
        label: item.departmentName,
        value: item
      }));
      setClinicOptions(clinicList);
    }
  }, [selectedSection]);

  const getAppointmentDataFromBackend = (id) => {
    const getReq = {
      secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'",
      doctorId: id
    };

    postWithoutToken(process.env.REACT_APP_API_URL + "/leadIntegration/findAvailableTimes", getReq).then(response => {
      if (response.status === 200 && response.data) {
        setDateArray(response.data.map((event) => ({ label: formatDateScreen(event.date), value: event })));
      }
    });
  };

  function onChangeDoctorSelect(event) {
    if (event !== null) {
      setSelectedDoctor(event);
      getAppointmentDataFromBackend(event.value);
    } else {
      setSelectedDoctor(null);
      setDateArray([]);
      setTimeArray([]);
    }
  }

  useEffect(() => {
    const doctorList = selectedClinic && selectedClinic.value.users && selectedClinic.value.users.map((doctor) => ({
      label: doctor.fullName,
      value: doctor.id
    }));
    setDoctorOptions(doctorList || []);
  }, [selectedClinic]);

  const callBackClose = (val) => {
    setMessage("");
    setShowNotification(val);
  };

  function onChangeDate(e) {
    setSelectedDate(e);
    setSelectedAppt(true);
    setDate(e && e.value ? e.value.date : null);
    setSelectedTime(null);
    const timeOptions = e && e.value && e.value.hours.map((hour) => ({ label: formatTimeScreen(hour), value: hour }));
    setTimeArray(timeOptions);
  }

  function onChangeTime(e) {
    setSelectedTime(e);
    setSelectedAppt(true);
    setTime(e && e.value ? e.value : null);
  }

  return (
    <React.Fragment>
      <Notification
        type={notificationType}
        message={message}
        show={showNotification}
        callbackShow={callBackClose}
      />
      <div className="row mt-3">
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <label>{props.t("Section")}</label>
            <Select
              className="bs-select"
              value={selectedSection}
              onChange={(e) => onChangeSectionSelect(e)}
              options={sectionOptions}
            />
          </div>
          <div className="col-xs-12 col-md-3">
            <label>{props.t("Clinic")}</label>
            <Select
              className="bs-select"
              value={selectedClinic}
              onChange={(e) => onChangeClinicSelect(e)}
              options={clinicOptions}
            />
          </div>
          <div className="col-xs-12 col-md-3">
            <label>{props.t("Specialist")}</label>
            <Select
              className="bs-select"
              value={selectedDoctor}
              onChange={(e) => onChangeDoctorSelect(e)}
              options={doctorOptions}
            />
          </div>
          <div className="col-xs-12 col-md-3">
            <div className=" row row-cols-2">
              <div className="col-md-6">
                <Label>{props.t("Date")}</Label>
                <Select
                  id="date"
                  className="bs-select"
                  value={selectedDate}
                  onChange={(e) => onChangeDate(e)}
                  options={dateArray}
                  isClearable={true}
                />
              </div>
              <div className="col-md-6">
                <Label>{props.t("Time")}</Label>
                <Select
                  id="representHistory"
                  className="bs-select"
                  value={selectedTime}
                  onChange={(e) => onChangeTime(e)}
                  options={timeArray}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-12">
            <label>{props.t("Description")}</label>
            <Input
              id="apptDesc"
              value={apptDescription}
              onChange={handleChangeApptDescription}
            />
          </div>
        </div>
        <div className="row">
          <div className="mt-4 text-end">
            <Button
              id="saveAppt"
              type="button"
              color="success"
              onClick={saveAppt}
              disabled={!selectedAppt}
            >
              {props.t("Create")}
            </Button>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        {custAppt && custAppt.length > 0 ? (
          <BasicTable
            id="custAppt"
            customPageSize={10}
            columns={appointmentColumns}
            data={custAppt}
            className="custom-header-basic-css"
          />) : null}
      </div>
    </React.Fragment>
  );
};
PatientAppointment.propTypes = {
  doctors: PropTypes.any,
  patient: PropTypes.any,
  sectionOptions: PropTypes.any
};

export default withTranslation()(PatientAppointment);
