import {
    ADD_USER_WORK_SCHEDULE_FAIL,
    ADD_USER_WORK_SCHEDULE_SUCCESS,
    DELETE_USER_WORK_SCHEDULE_FAIL,
    DELETE_USER_WORK_SCHEDULE_SUCCESS,
    GET_USER_WORK_SCHEDULE_BY_ID_FAIL,
    GET_USER_WORK_SCHEDULE_BY_ID_SUCCESS,
    GET_USER_WORK_SCHEDULES,
    GET_USER_WORK_SCHEDULES_FAIL,
    GET_USER_WORK_SCHEDULES_SUCCESS,
    UPDATE_USER_WORK_SCHEDULE_FAIL,
    UPDATE_USER_WORK_SCHEDULE_SUCCESS
} from "./actionTypes";
import { GET_USER_PROFILE_FAIL, GET_USER_PROFILE_SUCCESS } from "../users/actionTypes";

const INIT_STATE = {
    userWorkSchedules: {},
    error: {},
};

const UserWorkSchedule = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_USER_WORK_SCHEDULES_SUCCESS:
            return {
                ...state,
                userWorkSchedules: action.payload,
            };

        case GET_USER_WORK_SCHEDULES_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_USER_WORK_SCHEDULE_SUCCESS:
            return {
                ...state,
                userWorkSchedules: action.payload,
            };

        case ADD_USER_WORK_SCHEDULE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_USER_WORK_SCHEDULE_SUCCESS:
            return {
                ...state,
                userWorkSchedules: state.userWorkSchedules.map(userWorkSchedule =>
                    userWorkSchedule.id.toString() === action.payload.id.toString()
                        ? { userWorkSchedule, ...action.payload }
                        : userWorkSchedule
                ),
            };

        case UPDATE_USER_WORK_SCHEDULE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_USER_WORK_SCHEDULE_SUCCESS:
            return {
                ...state,
                userWorkSchedules: state.userWorkSchedules.filter(
                    userWorkSchedule => userWorkSchedule.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_USER_WORK_SCHEDULE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case GET_USER_WORK_SCHEDULE_BY_ID_SUCCESS:
            return {
                ...state,
                userWorkSchedules: action.payload
            };

        case GET_USER_WORK_SCHEDULE_BY_ID_FAIL:
            return {
                ...state,
                error: action.payload
            };

        default:
            return {
                ...state,
                error: {},
                userWorkSchedules: {},
            };
    }
};

export default UserWorkSchedule;
