import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../hooks/withRouter"
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";
import {
  addNewDoctor as onAddNewDoctor,
  deleteDoctor as onDeleteDoctor,
  getDoctors as onGetDoctors,
  updateDoctor as onUpdateDoctor
} from "store/doctor/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Description, Hospital, Name, Phone } from "./doctorCol";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import Select from "react-select";
import { getHospitals as onGetHospitals } from "../../store/hospital/actions";

const Doctor = props => {

  //meta title
  document.title = `CRM | ${props.t("Doctors")}`;

  const dispatch = useDispatch();

  const { doctors, error } = useSelector(state => ({
    doctors: state.Doctor.doctors,
    error: state.Doctor.error
  }));

  const { hospitals } = useSelector(state => ({
    hospitals: state.Hospital.hospitals,
    error: state.Hospital.error
  }));

  const [modal, setModal] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [doctor, setDoctor] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [selectedMulti, setselectedMulti] = useState(null);

  // validation
  const validation = {

    values: {
      fullName: (doctor && doctor.fullName) || "",
      phoneNumber: (doctor && doctor.phoneNumber) || "",
      description: (doctor && doctor.description) || "",
      hospitals: (doctor && doctor.hospitals) || ""
    },
    handleSubmit: (values) => {
      if (isEdit) {
        const updateDoctor = {
          id: doctor ? doctor.id : 0,
          fullName: values.fullName,
          phoneNumber: values.phoneNumber,
          description: values.description,
          hospitals: values.hospitals
        };
        // update doctor
        dispatch(onUpdateDoctor(updateDoctor));
      } else {
        const newDoctor = {
          fullName: values["fullName"],
          phoneNumber: values["phoneNumber"],
          description: values["description"],
          hospitals: values["hospitals"]
        };
        // save new doctor
        dispatch(onAddNewDoctor(newDoctor));
      }
      toggle();
    }
  };

  function handleMulti(selectedMulti) {
    const selectCodes = selectedMulti.map((select) => select.value);
    setselectedMulti(selectedMulti);
    setDoctor({
      ...doctor,
      ["hospitals"]: selectCodes
    });
  }

  useEffect(() => {
    const hospitals = (doctor !== null && doctor.hospitals && doctor.hospitals.length > 0) ? doctor.hospitals.map(lang => ({
      label: lang.name,
      value: lang
    })) : null;
    setselectedMulti(hospitals);
  }, [doctor]);

  const handleDoctorClick = arg => {
    const doctor = arg;

    setDoctor({
      id: doctor.id,
      fullName: doctor.fullName,
      phoneNumber: doctor.phoneNumber,
      description: doctor.description,
      hospitals: doctor.hospitals
    });

    setIsEdit(true);
    toggle();
  };

  // Customber Column
  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        Header: "FullName",
        accessor: "fullName",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Phone")}`,
        Header: "Phone",
        accessor: "phoneNumber",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Hospital")}`,
        Header: "Hospital",
        accessor: "hospitals",
        filterable: true,
        Cell: (cellProps) => {
          return <Hospital {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Açıklama",
        accessor: "description",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const doctorData = cellProps.row.original;
                  handleDoctorClick(doctorData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const doctorData = cellProps.row.original;
                  onClickDelete(doctorData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const handleChange = (e) => {
    setDoctor({
      ...doctor,
      [e.target.name]: e.target.value
    });
  };

  const hospitalOptions = [];
  hospitals.forEach((item) => {
    hospitalOptions.push({ label: item.name, value: item });
  });


  const toggle = () => {
    if (modal) {
      setModal(false);
      setDoctor(null);
      setselectedMulti("");
    } else {
      setModal(true);
    }
  };

  //delete doctor
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (doctor) => {
    setDoctor(doctor);
    setDeleteModal(true);
  };

  const handleDeleteDoctor = () => {
    if (doctor.id) {
      dispatch(onDeleteDoctor(doctor.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    dispatch(onGetHospitals());
  }, [dispatch]);

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetDoctors());
      setFirstCall(false);
    }
  }, [dispatch, doctors]);

  useEffect(() => {
    setDoctorList(doctors);
  }, [doctors]);

  useEffect(() => {
    if (!isEmpty(doctors)) {
      setDoctorList(doctors);
    }
  }, [doctors]);

  const handleDoctorClicks = () => {
    setDoctorList("");
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetDoctors());
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteDoctor}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Doctors")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={doctors}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleDoctorClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Name")}</Label>
                              <Input
                                name="fullName"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.fullName || ""}
                                invalid={!validation.values.fullName}
                                required={true}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Phone")}</Label>
                              <Input
                                name="phoneNumber"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.phoneNumber || ""}
                                invalid={!validation.values.phoneNumber}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Hospitals")} <i
                                className="mdi mdi-alert-circle-outline error font-size-16 me-1" /></Label>
                              <Select
                                value={selectedMulti}
                                isMulti={true}
                                onChange={(event) => handleMulti(event)}
                                options={hospitalOptions}
                                className="select2-selection"
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Description")}</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.description || ""}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-lead"
                                disabled={selectedMulti === null || selectedMulti === "" || selectedMulti && selectedMulti.length <= 0 || validation.values.phoneNumber === "" || validation.values.name === ""}
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
Doctor.propTypes = {
  doctors: PropTypes.array,
  onGetDoctors: PropTypes.func,
  onAddNewDoctor: PropTypes.func,
  onDeleteDoctor: PropTypes.func,
  onUpdateDoctor: PropTypes.func
};

export default withRouter(withTranslation()(Doctor));
