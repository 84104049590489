import {
  ADD_USER_AVAILABILITY_FAIL,
  ADD_USER_AVAILABILITY_SUCCESS,
  DELETE_USER_AVAILABILITY_FAIL,
  DELETE_USER_AVAILABILITY_SUCCESS,
  GET_USER_AVAILABILITY_BY_ID_FAIL,
  GET_USER_AVAILABILITY_BY_ID_SUCCESS,
  GET_USER_AVAILABILITY_FAIL,
  GET_USER_AVAILABILITY_SUCCESS,
  UPDATE_USER_AVAILABILITY_FAIL,
  UPDATE_USER_AVAILABILITY_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  userAvailability: [],
  error: {}
};

const UserAvailability = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_AVAILABILITY_SUCCESS:
      return {
        ...state,
        userAvailability: action.payload
      };

    case GET_USER_AVAILABILITY_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_USER_AVAILABILITY_SUCCESS:
      return {
        ...state,
        userAvailability: [...state.userAvailability, ...action.payload]
      };

    case ADD_USER_AVAILABILITY_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_USER_AVAILABILITY_SUCCESS:
      return {
        ...state,
        userAvailability: state.userAvailability.map(userAvailability =>
          userAvailability.id.toString() === action.payload.id.toString()
            ? { userAvailability, ...action.payload }
            : userAvailability
        )
      };

    case UPDATE_USER_AVAILABILITY_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_USER_AVAILABILITY_SUCCESS:
      return {
        ...state,
        userAvailability: state.userAvailability.filter(
          userAvailability => userAvailability.id.toString() !== action.req.toString()
        )
      };

    case DELETE_USER_AVAILABILITY_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_USER_AVAILABILITY_BY_ID_SUCCESS:
      return {
        ...state,
        userAvailability: action.payload
      };

    case GET_USER_AVAILABILITY_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default UserAvailability;
