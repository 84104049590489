import {
  GET_LEAD_PATIENT_NOTES,
  GET_LEAD_PATIENT_NOTES_SUCCESS,
  GET_LEAD_PATIENT_NOTES_FAIL,
  ADD_PATIENT_NOTE_SUCCESS,
  ADD_PATIENT_NOTE_FAIL,
  UPDATE_PATIENT_NOTE_SUCCESS,
  UPDATE_PATIENT_NOTE_FAIL,
  DELETE_PATIENT_NOTE_SUCCESS,
  DELETE_PATIENT_NOTE_FAIL
} from "./actionTypes";

const INIT_STATE = {
  patientNotes: [],
  error: {},
};

const PatientNote = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_PATIENT_NOTE_SUCCESS:
      return {
        ...state,
        patientNotes: [...state.patientNotes, action.payload],
      };

    case ADD_PATIENT_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_PATIENT_NOTE_SUCCESS:
      return {
        ...state,
        patientNotes: state.patientNotes.map(patientNote =>
          patientNote.id.toString() === action.payload.id.toString()
            ? { patientNote, ...action.payload }
            : patientNote
        ),
      };

    case UPDATE_PATIENT_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PATIENT_NOTE_SUCCESS:
      return {
        ...state,
        patientNotes: state.patientNotes.filter(
          patientNote => patientNote.id.toString() !== action.req.toString()
        ),
      };

    case DELETE_PATIENT_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default PatientNote;
