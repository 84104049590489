
/* FACEBOOK_FORMS */
export const GET_FACEBOOK_FORMS = "GET_FACEBOOK_FORMS"
export const GET_FACEBOOK_FORMS_SUCCESS = "GET_FACEBOOK_FORMS_SUCCESS"
export const GET_FACEBOOK_FORMS_FAIL = "GET_FACEBOOK_FORMS_FAIL"

/**
 * add FACEBOOK_FORM
 */
export const ADD_NEW_FACEBOOK_FORM = "ADD_NEW_FACEBOOK_FORM"
export const ADD_FACEBOOK_FORM_SUCCESS = "ADD_FACEBOOK_FORM_SUCCESS"
export const ADD_FACEBOOK_FORM_FAIL = "ADD_FACEBOOK_FORM_FAIL"

/**
 * Edit FACEBOOK_FORM
 */
export const UPDATE_FACEBOOK_FORM = "UPDATE_FACEBOOK_FORM"
export const UPDATE_FACEBOOK_FORM_SUCCESS = "UPDATE_FACEBOOK_FORM_SUCCESS"
export const UPDATE_FACEBOOK_FORM_FAIL = "UPDATE_FACEBOOK_FORM_FAIL"

/**
 * Delete FACEBOOK_FORM
 */
export const DELETE_FACEBOOK_FORM = "DELETE_FACEBOOK_FORM"
export const DELETE_FACEBOOK_FORM_SUCCESS = "DELETE_FACEBOOK_FORM_SUCCESS"
export const DELETE_FACEBOOK_FORM_FAIL = "DELETE_FACEBOOK_FORM_FAIL"
