import {
    GET_HOSPITALS_FAIL,
    GET_HOSPITALS_SUCCESS,
    ADD_HOSPITAL_SUCCESS,
    ADD_HOSPITAL_FAIL,
    UPDATE_HOSPITAL_SUCCESS,
    UPDATE_HOSPITAL_FAIL,
    DELETE_HOSPITAL_SUCCESS,
    DELETE_HOSPITAL_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    hospitals: [],
    error: {},
};

const Hospital = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_HOSPITALS_SUCCESS:
            return {
                ...state,
                hospitals: action.payload,
            };

        case GET_HOSPITALS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_HOSPITAL_SUCCESS:
            return {
                ...state,
                hospitals: [...state.hospitals, action.payload],
            };

        case ADD_HOSPITAL_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_HOSPITAL_SUCCESS:
            return {
                ...state,
                hospitals: state.hospitals.map(hospital =>
                    hospital.id.toString() === action.payload.id.toString()
                        ? { hospital, ...action.payload }
                        : hospital
                ),
            };

        case UPDATE_HOSPITAL_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_HOSPITAL_SUCCESS:
            return {
                ...state,
                hospitals: state.hospitals.filter(
                    hospital => hospital.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_HOSPITAL_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default Hospital;
