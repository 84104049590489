import {
  ADD_SCREEN_ACTION_SUCCESS,
  ADD_SCREEN_ACTION_FAIL,
  UPDATE_SCREEN_ACTION_SUCCESS,
  UPDATE_SCREEN_ACTION_FAIL,
  DELETE_SCREEN_ACTION_SUCCESS,
  DELETE_SCREEN_ACTION_FAIL
} from "./actionTypes";

const INIT_STATE = {
  screenActions: [],
  error: {},
};

const ScreenAction = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_SCREEN_ACTION_SUCCESS:
      return {
        ...state,
        screenActions: [...state.screenActions, action.payload],
      };

    case ADD_SCREEN_ACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_SCREEN_ACTION_SUCCESS:
      return {
        ...state,
        screenActions: state.screenActions.map(screenAction =>
          screenAction.id.toString() === action.payload.id.toString()
            ? { screenAction, ...action.payload }
            : screenAction
        ),
      };

    case UPDATE_SCREEN_ACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_SCREEN_ACTION_SUCCESS:
      return {
        ...state,
        screenActions: state.screenActions.filter(
          screenAction => screenAction.id.toString() !== action.req.toString()
        ),
      };

    case DELETE_SCREEN_ACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ScreenAction;
