import {
    GET_MAIL_INFOS,
    GET_MAIL_INFOS_FAIL,
    GET_MAIL_INFOS_SUCCESS,
    ADD_NEW_MAIL_INFO,
    ADD_MAIL_INFO_SUCCESS,
    ADD_MAIL_INFO_FAIL,
    UPDATE_MAIL_INFO,
    UPDATE_MAIL_INFO_SUCCESS,
    UPDATE_MAIL_INFO_FAIL,
    DELETE_MAIL_INFO,
    DELETE_MAIL_INFO_SUCCESS,
    DELETE_MAIL_INFO_FAIL
} from "./actionTypes";

export const getMailInfos = () => ({
    type: GET_MAIL_INFOS,
})

export const getMailInfosSuccess = operations => ({
    type: GET_MAIL_INFOS_SUCCESS,
    payload: operations,
})

export const getMailInfosFail = error => ({
    type: GET_MAIL_INFOS_FAIL,
    payload: error,
})

export const addNewMailInfo = operation => ({
    type: ADD_NEW_MAIL_INFO,
    payload: operation,
})

export const addMailInfoSuccess = operation => ({
    type: ADD_MAIL_INFO_SUCCESS,
    payload: operation,
})

export const addMailInfoFail = error => ({
    type: ADD_MAIL_INFO_FAIL,
    payload: error,
})

export const updateMailInfo = operation => ({
    type: UPDATE_MAIL_INFO,
    payload: operation,
})

export const updateMailInfoSuccess = operation => ({
    type: UPDATE_MAIL_INFO_SUCCESS,
    payload: operation,
})

export const updateMailInfoFail = error => ({
    type: UPDATE_MAIL_INFO_FAIL,
    payload: error,
})

export const deleteMailInfo = id => ({
    type: DELETE_MAIL_INFO,
    id,
})

export const deleteMailInfoSuccess = (operation,req) => ({
    type: DELETE_MAIL_INFO_SUCCESS,
    payload: operation,
    req:req,
})

export const deleteMailInfoFail = error => ({
    type: DELETE_MAIL_INFO_FAIL,
    payload: error,
})