import React, { useEffect, useState } from "react";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "react-modal-video/scss/modal-video.scss";

// import image
import PropTypes from "prop-types";
//Import Breadcrumb
import { get } from "../../helpers/axios_with_headers";
import * as url from "../../helpers/url_helper";

const CustomLightBox = props => {

  const [photoIndex, setphotoIndex] = useState(0);
  const [isGallery, setisGallery] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const convertedPhotos = [];
    get(`${url.GET_PHOTOS}/${props.custId}`).then(response => {
      if (response.data.length > 0) {
        response.data.forEach(photo => convertedPhotos.push(photo.content));
      }
      setImages(convertedPhotos);
    });
  }, []);

  return (
    <div>
      {isGallery ? (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          enableZoom={true}
          onCloseRequest={() => {
            setisGallery(false);
          }}
          onMovePrevRequest={() => {
            setphotoIndex((photoIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setphotoIndex((photoIndex + 1) % images.length);
          }}
          imageCaption={"Img " + parseFloat(photoIndex + 1)}
        />
      ) : null}

      <div className="popup-gallery d-flex flex-wrap">
        {images.map((photo, key) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="m-2 img-fluid float-left">
              <img
                src={photo}
                onClick={() => {
                  setisGallery(true);
                  setphotoIndex(key);
                }}
                alt=""
                width="120"
                height="100"
              />
            </div>
          )
        })}
      </div>
    </div>
  );
};

CustomLightBox.propTypes = {
  custId: PropTypes.any
};

export default CustomLightBox;
