import {
    GET_REFERENCES,
    GET_REFERENCES_FAIL,
    GET_REFERENCES_SUCCESS,
    ADD_NEW_REFERENCE,
    ADD_REFERENCE_SUCCESS,
    ADD_REFERENCE_FAIL,
    UPDATE_REFERENCE,
    UPDATE_REFERENCE_SUCCESS,
    UPDATE_REFERENCE_FAIL,
    DELETE_REFERENCE,
    DELETE_REFERENCE_SUCCESS,
    DELETE_REFERENCE_FAIL,
} from "./actionTypes"

export const getReferences = () => ({
    type: GET_REFERENCES,
})

export const getReferencesSuccess = references => ({
    type: GET_REFERENCES_SUCCESS,
    payload: references,
})

export const getReferencesFail = error => ({
    type: GET_REFERENCES_FAIL,
    payload: error,
})

export const addNewReference = reference => ({
    type: ADD_NEW_REFERENCE,
    payload: reference,
})

export const addReferenceSuccess = reference => ({
    type: ADD_REFERENCE_SUCCESS,
    payload: reference,
})

export const addReferenceFail = error => ({
    type: ADD_REFERENCE_FAIL,
    payload: error,
})

export const updateReference = reference => ({
    type: UPDATE_REFERENCE,
    payload: reference,
})

export const updateReferenceSuccess = reference => ({
    type: UPDATE_REFERENCE_SUCCESS,
    payload: reference,
})

export const updateReferenceFail = error => ({
    type: UPDATE_REFERENCE_FAIL,
    payload: error,
})

export const deleteReference = id => ({
    type: DELETE_REFERENCE,
    id,
})

export const deleteReferenceSuccess = (reference,req) => ({
    type: DELETE_REFERENCE_SUCCESS,
    payload: reference,
    req: req,
})

export const deleteReferenceFail = error => ({
    type: DELETE_REFERENCE_FAIL,
    payload: error,
})