import {
  GET_SCREEN_ACTIONS,
  GET_SCREEN_ACTIONS_FAIL,
  GET_SCREEN_ACTIONS_SUCCESS,
  ADD_NEW_SCREEN_ACTION,
  ADD_SCREEN_ACTION_SUCCESS,
  ADD_SCREEN_ACTION_FAIL,
  UPDATE_SCREEN_ACTION,
  UPDATE_SCREEN_ACTION_SUCCESS,
  UPDATE_SCREEN_ACTION_FAIL,
  DELETE_SCREEN_ACTION,
  DELETE_SCREEN_ACTION_SUCCESS,
  DELETE_SCREEN_ACTION_FAIL
} from "./actionTypes";

export const getScreenActions = () => ({
  type: GET_SCREEN_ACTIONS
});

export const getScreenActionsSuccess = screenActions => ({
  type: GET_SCREEN_ACTIONS_SUCCESS,
  payload: screenActions
});

export const getScreenActionsFail = error => ({
  type: GET_SCREEN_ACTIONS_FAIL,
  payload: error
});

export const addNewScreenAction = screenAction => ({
  type: ADD_NEW_SCREEN_ACTION,
  payload: screenAction
});

export const addScreenActionSuccess = screenAction => ({
  type: ADD_SCREEN_ACTION_SUCCESS,
  payload: screenAction
});

export const addScreenActionFail = error => ({
  type: ADD_SCREEN_ACTION_FAIL,
  payload: error
});

export const updateScreenAction = screenAction => ({
  type: UPDATE_SCREEN_ACTION,
  payload: screenAction
});

export const updateScreenActionSuccess = screenAction => ({
  type: UPDATE_SCREEN_ACTION_SUCCESS,
  payload: screenAction
});

export const updateScreenActionFail = error => ({
  type: UPDATE_SCREEN_ACTION_FAIL,
  payload: error
});

export const deleteScreenAction = id => ({
  type: DELETE_SCREEN_ACTION,
  id
});

export const deleteScreenActionSuccess = (screenAction, req) => ({
  type: DELETE_SCREEN_ACTION_SUCCESS,
  payload: screenAction,
  req: req
});

export const deleteScreenActionFail = error => ({
  type: DELETE_SCREEN_ACTION_FAIL,
  payload: error
});