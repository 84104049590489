import {
    GET_PAYMENTS,
    GET_PAYMENTS_FAIL,
    GET_PAYMENTS_SUCCESS,
    ADD_NEW_PAYMENT,
    ADD_PAYMENT_SUCCESS,
    ADD_PAYMENT_FAIL,
    UPDATE_PAYMENT,
    UPDATE_PAYMENT_SUCCESS,
    UPDATE_PAYMENT_FAIL,
    DELETE_PAYMENT,
    DELETE_PAYMENT_SUCCESS,
    DELETE_PAYMENT_FAIL,
    GET_UNPAID_PAYMENTS,
    GET_UNPAID_PAYMENTS_SUCCESS,
    GET_UNPAID_PAYMENTS_FAIL,
    UPDATE_USER_COUNT_AND_PRICE,
    UPDATE_USER_COUNT_AND_PRICE_FAIL,
    UPDATE_USER_COUNT_AND_PRICE_SUCCESS,
    PAYMENT_PROCESS,
    PAYMENT_PROCESS_SUCCESS,
    PAYMENT_PROCESS_FAIL,
    ANNUAL_PAYMENT,
    ANNUAL_PAYMENT_SUCCESS,
    ANNUAL_PAYMENT_FAIL,
    INSTANT_USER_PAYMENT_FAIL, INSTANT_USER_PAYMENT_SUCCESS, INSTANT_USER_PAYMENT
} from "./actionTypes";

export const getPayments = () => ({
    type: GET_PAYMENTS,
})

export const getPaymentsSuccess = payments => ({
    type: GET_PAYMENTS_SUCCESS,
    payload: payments,
})

export const getPaymentsFail = error => ({
    type: GET_PAYMENTS_FAIL,
    payload: error,
})

export const addNewPayment = payment => ({
    type: ADD_NEW_PAYMENT,
    payload: payment,
})

export const addPaymentSuccess = payment => ({
    type: ADD_PAYMENT_SUCCESS,
    payload: payment,
})

export const addPaymentFail = error => ({
    type: ADD_PAYMENT_FAIL,
    payload: error,
})

export const updatePayment = payment => ({
    type: UPDATE_PAYMENT,
    payload: payment,
})

export const updatePaymentSuccess = payment => ({
    type: UPDATE_PAYMENT_SUCCESS,
    payload: payment,
})

export const updatePaymentFail = error => ({
    type: UPDATE_PAYMENT_FAIL,
    payload: error,
})

export const deletePayment = id => ({
    type: DELETE_PAYMENT,
    id,
})

export const deletePaymentSuccess = (payment,req) => ({
    type: DELETE_PAYMENT_SUCCESS,
    payload: payment,
    req:req,
})

export const deletePaymentFail = error => ({
    type: DELETE_PAYMENT_FAIL,
    payload: error,
})
export const getUnpaidPayments = () => ({
    type: GET_UNPAID_PAYMENTS,
})

export const getUnpaidPaymentsSuccess = payments => ({
    type: GET_UNPAID_PAYMENTS_SUCCESS,
    payload: payments,
})

export const getUnpaidPaymentsFail = error => ({
    type: GET_UNPAID_PAYMENTS_FAIL,
    payload: error,
})

export const updateUserCountAndPrice = (req) => ({
    type: UPDATE_USER_COUNT_AND_PRICE,
    payload: req
})

export const updateUserCountAndPriceSuccess = payments => ({
    type: UPDATE_USER_COUNT_AND_PRICE_SUCCESS,
    payload: payments,
})

export const updateUserCountAndPriceFail = error => ({
    type: UPDATE_USER_COUNT_AND_PRICE_FAIL,
    payload: error,
})

export const paymentProcess = (req) => ({
    type: PAYMENT_PROCESS,
    payload: req
})

export const paymentProcessSuccess = payments => ({
    type: PAYMENT_PROCESS_SUCCESS,
    payload: payments,
})

export const paymentProcessFail = error => ({
    type: PAYMENT_PROCESS_FAIL,
    payload: error,
})

export const createAnnualPayment = (req) => ({
    type: ANNUAL_PAYMENT,
    payload: req
})

export const createAnnualPaymentSuccess = payments => ({
    type: ANNUAL_PAYMENT_SUCCESS,
    payload: payments,
})

export const createAnnualPaymentFail = error => ({
    type: ANNUAL_PAYMENT_FAIL,
    payload: error,
})

export const createInstantUserPayment = (req) => ({
    type: INSTANT_USER_PAYMENT,
    payload: req
})

export const createInstantUserPaymentSuccess = payments => ({
    type: INSTANT_USER_PAYMENT_SUCCESS,
    payload: payments,
})

export const createInstantUserPaymentFail = error => ({
    type: INSTANT_USER_PAYMENT_FAIL,
    payload: error,
})