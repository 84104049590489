import { call, put, takeEvery } from "redux-saga/effects";

// Resources Redux States
import {
  GET_APPOINTMENT_HISTORY, GET_APPOINTMENT_HISTORY_BY_FILTER,
  GET_LEAD_HISTORY,
  GET_LEAD_HISTORY_BY_FILTER,
  GET_NOTE_HISTORY, GET_NOTE_HISTORY_BY_FILTER,
  GET_REMINDER_HISTORY, GET_REMINDER_HISTORY_BY_FILTER
} from "./actionTypes";

import {
  getAppointmentHistoryByFilterFail,
  getAppointmentHistoryByFilterSuccess,
  getAppointmentHistoryFail,
  getAppointmentHistorySuccess, getLeadHistoryByFilterFail, getLeadHistoryByFilterSuccess,
  getLeadHistoryFail,
  getLeadHistorySuccess, getNoteHistoryByFilterFail, getNoteHistoryByFilterSuccess,
  getNoteHistoryFail,
  getNoteHistorySuccess, getReminderHistoryByFilterFail, getReminderHistoryByFilterSuccess,
  getReminderHistoryFail,
  getReminderHistorySuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { get, post } from "../../helpers/api_helper";

function* fetchLeadHistories() {
  try {
    const response = yield call(getLeadHistory);
    yield put(getLeadHistorySuccess(response));
  } catch (error) {
    yield put(getLeadHistoryFail(error));
  }
}

const getLeadHistory = () => get(url.LEAD_HISTORY);

function* fetchLeadHistoriesByFilter({ payload: req }) {
  try {
    const response = yield call(getLeadHistoriesByFilter, req);
    yield put(getLeadHistoryByFilterSuccess(response));
  } catch (error) {
    yield put(getLeadHistoryByFilterFail(error));
  }
}

const getLeadHistoriesByFilter = (req) => post(url.LEAD_HISTORY_BY_FILTER, req);

function* fetchAppointmentHistories() {
  try {
    const response = yield call(getAppointmentHistory);
    yield put(getAppointmentHistorySuccess(response));
  } catch (error) {
    yield put(getAppointmentHistoryFail(error));
  }
}

const getAppointmentHistory = () => get(url.APPOINTMENT_HISTORY);

function* fetchAppointmentHistoriesByFilter({ payload: req }) {
  try {
    const response = yield call(getAppointmentHistoryByFilter, req);
    yield put(getAppointmentHistoryByFilterSuccess(response));
  } catch (error) {
    yield put(getAppointmentHistoryByFilterFail(error));
  }
}

const getAppointmentHistoryByFilter = (req) => post(url.APPOINTMENT_HISTORY_BY_FILTER, req);

function* fetchReminderHistories() {
  try {
    const response = yield call(getReminderHistory);
    yield put(getReminderHistorySuccess(response));
  } catch (error) {
    yield put(getReminderHistoryFail(error));
  }
}

const getReminderHistory = () => get(url.REMINDER_HISTORY);

function* fetchReminderHistoriesByFilter({ payload: req }) {
  try {
    const response = yield call(getReminderHistoryByFilter, req);
    yield put(getReminderHistoryByFilterSuccess(response));
  } catch (error) {
    yield put(getReminderHistoryByFilterFail(error));
  }
}

const getReminderHistoryByFilter = (req) => post(url.REMINDER_HISTORY_BY_FILTER, req);

function* fetchNoteHistories() {
  try {
    const response = yield call(getNoteHistory);
    yield put(getNoteHistorySuccess(response));
  } catch (error) {
    yield put(getNoteHistoryFail(error));
  }
}

const getNoteHistory = () => get(url.NOTE_HISTORY);

function* fetchNoteHistoriesByFilter({ payload: req }) {
  try {
    const response = yield call(getNoteHistoryByFilter, req);
    yield put(getNoteHistoryByFilterSuccess(response));
  } catch (error) {
    yield put(getNoteHistoryByFilterFail(error));
  }
}

const getNoteHistoryByFilter = (req) => post(url.NOTE_HISTORY_BY_FILTER, req);

function* historySaga() {
  yield takeEvery(GET_LEAD_HISTORY, fetchLeadHistories);
  yield takeEvery(GET_LEAD_HISTORY_BY_FILTER, fetchLeadHistoriesByFilter);
  yield takeEvery(GET_APPOINTMENT_HISTORY, fetchAppointmentHistories);
  yield takeEvery(GET_APPOINTMENT_HISTORY_BY_FILTER, fetchAppointmentHistoriesByFilter);
  yield takeEvery(GET_REMINDER_HISTORY, fetchReminderHistories);
  yield takeEvery(GET_REMINDER_HISTORY_BY_FILTER, fetchReminderHistoriesByFilter);
  yield takeEvery(GET_NOTE_HISTORY, fetchNoteHistories);
  yield takeEvery(GET_NOTE_HISTORY_BY_FILTER, fetchNoteHistoriesByFilter);
}

export default historySaga;
