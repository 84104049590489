import React from "react";

const formId = (cell) => {
  return cell.value ? cell.value : "";
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

const Platform = (cell) => {
  return cell.value ? cell.value : "";
};

const Category = (cell) => {
  return cell.value ? cell.value : "";
};

const Language = (cell) => {
  return cell.value ? cell.value : "";
};

export {
  formId,
  Name,
  Platform,
  Language,
  Category
};