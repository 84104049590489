
/* LEADS */
export const GET_LEADS = "GET_LEADS"
export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS"
export const GET_LEADS_FAIL = "GET_LEADS_FAIL"

/**
 * GET LEAD
 */
export const GET_LEAD_BY_ID = "GET_LEAD_BY_ID"
export const GET_LEAD_BY_ID_SUCCESS = "GET_LEAD_BY_ID_SUCCESS"
export const GET_LEAD_BY_ID_FAIL = "GET_LEAD_BY_ID_FAIL"

/**
 * add LEAD
 */
export const ADD_NEW_LEAD = "ADD_NEW_LEAD"
export const ADD_LEAD_SUCCESS = "ADD_LEAD_SUCCESS"
export const ADD_LEAD_FAIL = "ADD_LEAD_FAIL"

/**
 * Edit LEAD
 */
export const UPDATE_LEAD = "UPDATE_LEAD"
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS"
export const UPDATE_LEAD_FAIL = "UPDATE_LEAD_FAIL"

/**
 * Delete LEAD
 */
export const DELETE_LEAD = "DELETE_LEAD"
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS"
export const DELETE_LEAD_FAIL = "DELETE_LEAD_FAIL"

/**
 * ASSIGN REPRESENTATIVE LEAD
 */
export const ASSIGN_REPRESENTATIVE = "ASSIGN_REPRESENTATIVE"
export const ASSIGN_REPRESENTATIVE_SUCCESS = "ASSIGN_REPRESENTATIVE_SUCCESS"
export const ASSIGN_REPRESENTATIVE_FAIL = "ASSIGN_REPRESENTATIVE_FAIL"

/**
 * ASSIGN SEGMENT LEAD
 */
export const ASSIGN_SEGMENT = "ASSIGN_SEGMENT"
export const ASSIGN_SEGMENT_SUCCESS = "ASSIGN_SEGMENT_SUCCESS"
export const ASSIGN_SEGMENT_FAIL = "ASSIGN_SEGMENT_FAIL"

/**
 * Lead Criteria
 */
export const LEAD_CRITERIA = "LEAD_CRITERIA"
export const LEAD_CRITERIA_SUCCESS = "LEAD_CRITERIA_SUCCESS"
export const LEAD_CRITERIA_FAIL = "LEAD_CRITERIA_FAIL"

/**
 * Transfer Data
 */
export const TRANSFER_LEAD_DATA = "TRANSFER_LEAD_DATA"
export const TRANSFER_LEAD_DATA_SUCCESS = "TRANSFER_LEAD_DATA_SUCCESS"
export const TRANSFER_LEAD_DATA_FAIL = "TRANSFER_LEAD_DATA_FAIL"
