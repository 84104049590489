import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import { dayArray, monthArray } from "../../constants/calendarConstants";
import { addDays, addMonths, getDay, getMonth, getYear, setHours, setMinutes, subDays, subMonths } from "date-fns";
import { range } from "../../common/commonFunctions";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Input } from "reactstrap";
import tr from "date-fns/locale/tr";
import enGB from "date-fns/locale/en-GB";

const lang = localStorage.getItem("i18nextLng");

// For Doc https://reactdatepicker.com/
const CustomDatePicker = props => {

  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [hour, setHour] = useState(setHours(setMinutes(new Date(), 0), 9));

  useEffect(() => {
    if (lang === "tr") {
      registerLocale("locale", tr);
    } else if (lang === "en") {
      registerLocale("locale", enGB);
    }
  }, [lang]);

  const handleChange = (e) => {
    setDate(e);
    props.callback(e);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setDate(null);
  };

  const excludeTimeArray = () => {
    if (date && date.getDate() >= 20) {
      return (
        [
          setHours(setMinutes(new Date(), 0), 17),
          setHours(setMinutes(new Date(), 0), 19),
          setHours(setMinutes(new Date(), 30), 18)
        ]
      );
    } else {
      return (
        [
          setHours(setMinutes(new Date(), 30), 19),
          setHours(setMinutes(new Date(), 30), 17)
        ]
      );
    }
  };

  const years = range(getYear(new Date()), getYear(new Date()) + 15, 1);
  const months = monthArray.map(item => props.t(item));
  const days = dayArray.map(item => props.t(item));

  const renderDayContents = (day, date) => {
    const tooltipText = `Tooltip for date: ${date}`;
    return <span title={tooltipText}>{date.getDate()}</span>;
  };

  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-info";
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-custom-1": [
        subDays(new Date(), 4),
        subDays(new Date(), 3),
        subDays(new Date(), 2),
        subDays(new Date(), 1)
      ]
    },
    {
      "react-datepicker__day--highlighted-custom-2": [
        addDays(new Date(), 1),
        addDays(new Date(), 2),
        addDays(new Date(), 3),
        addDays(new Date(), 4)
      ]
    }
  ];

  return (!props.showTimeSelectOnly ? (
      <DatePicker
        customInput={<Input onChange={handleChange} value={date} />}
        // calenderContainer={<MyContainer className="react-datepicker-wrapper"/>}
        selected={date}
        // filterDate={isWeekday}
        closeOnScroll={false}
        //  showPopperArrow={false}
        onChange={handleChange}
        onCalenderClose={handleClose}
        isClearable={true}
        startDate={startDate}
        locale="locale"
        endDate={endDate}
        minDate={subMonths(new Date(), 3)}
        maxDate={addMonths(new Date(), 12)}
        showMonthDropdown={false}
        showMonthYearDropdown={false}
        openToDate={new Date()}
        //showTimeSelect={props.showTimeSelect}
        popperPlacement="top-start"
        excludeTimes={excludeTimeArray()}
        timeIntervals={30}
        timeCaption={props.t("Time")}
        dateFormat={props.showTimeSelect ? "dd/MM/yyyy HH:mm" : "dd/MM/yyy"}
        timeFormat="HH:mm"
        //    dateFormatCalendar="MMMM"
        yearDropdownItemNumber={15}
        // includeDateIntervals={[
        //   { start: subDays(new Date(), 7), end: addDays(new Date(), 30) }
        // ]}
        selectsRange={false} // ikili olcaksa birine selectsEnd birine selectsStart diyoruz
        // popperClassName="react-datepicker-wrapper"
        // renderDayContents={renderDayContents}
        showDisabledMonthNavigation={true} // max dateden sonrasını disable ediyor
        // timeClassName={handleColor}
        //        minTime={setHours(setMinutes(new Date(), 0), 8)}
        //        maxTime={setHours(setMinutes(new Date(), 30), 20)}
        showTimeInput={props.showTimeInput}
        timeInputLabel={props.t("Time")}
        // excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
        // selectsDisabledDaysInRange
        disabled={false}
        disabledKeyboardNavigation={false}
        shouldCloseOnSelect={false}
        days={days}
        // excludeDateIntervals={[{ start: subDays(new Date(), 5), end: addDays(new Date(), 5) }]}
        showWeekNumbers={false}
        excludeTimeIntervals={[{
          start: setHours(setMinutes(new Date(), 0), 20),
          end: setHours(setMinutes(new Date(), 30), 8)
        }]}
        fixedHeight={true}
        popperModifiers={[
          props.showTimeSelect
            ? {
              name: "preventOverflow",
              options: {
                padding: { right: 90, left: 0 }
              }
            }
            : {}
        ]}
      />
    ) : (
      <DatePicker
        selected={hour}
        onChange={setHour}
        showTimeSelect={false}
        showTimeSelectOnly={true}
        showPopperArrow={false}
        timeIntervals={5}
        timeCaption={props.t("Time")}
        dateFormat={props.showTimeSelectOnly ? "hh:mm" : "dd/MM/yyyy"}
        timeFormat="HH:mm"
        timeInputLabel={props.t("Appointment Time")}
        popperClassName="react-datepicker-popper"
        popperPlacement="top-end"
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 10]
            }
          },
          {
            name: "preventOverflow",
            options: {
              rootBoundary: "viewport",
              tether: false,
              altAxis: true
            }
          }
        ]}
        timeClassName={handleColor}
        // showTimeInput={true}
        //  customTimeInput={<ExampleCustomTimeInput value={hour} onChange={setHour} />}
        disabledKeyboardNavigation={false}
        shouldCloseOnSelect={true}
        minTime={setHours(setMinutes(new Date(), 0), 8)}
        maxTime={setHours(setMinutes(new Date(), 30), 20)}
        fixedHeight={true}
      />)
  );
};


CustomDatePicker.propTypes = {
  t: PropTypes.any,
  showTimeSelectOnly: PropTypes.any,
  showTimeSelect: PropTypes.any,
  callback: PropTypes.func,
  showTimeInput: PropTypes.bool
};

export default withTranslation()(CustomDatePicker);