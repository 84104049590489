import {
    GET_PARAMETERS_FAIL,
    GET_PARAMETERS_SUCCESS,
    ADD_PARAMETER_SUCCESS,
    ADD_PARAMETER_FAIL,
    UPDATE_PARAMETER_SUCCESS,
    UPDATE_PARAMETER_FAIL,
    DELETE_PARAMETER_SUCCESS,
    DELETE_PARAMETER_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    parameters: [],
    error: {},
};

const Parameter = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PARAMETERS_SUCCESS:
            return {
                ...state,
                parameters: action.payload,
            };

        case GET_PARAMETERS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_PARAMETER_SUCCESS:
            return {
                ...state,
                parameters: [...state.parameters, action.payload],
            };

        case ADD_PARAMETER_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_PARAMETER_SUCCESS:
            return {
                ...state,
                parameters: state.parameters.map(parameter =>
                    parameter.id.toString() === action.payload.id.toString()
                        ? { parameter, ...action.payload }
                        : parameter
                ),
            };

        case UPDATE_PARAMETER_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_PARAMETER_SUCCESS:
            return {
                ...state,
                parameters: state.parameters.filter(
                    parameter => parameter.id.toString() !== action.payload.id.toString()
                ),
            };

        case DELETE_PARAMETER_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default Parameter;
