export const accessControlMiddleware = store => next => action => {
  if (action.type === "LOGIN_SUCCESS" && action.payload) {
    console.log("accessControlMiddleware kontrolleri", action.payload);

    if (!userHasAccessToPage(action.payload, action.payload.page)) {
      // Kullanıcının erişim izni yoksa, başka bir sayfaya yönlendirebiliriz.
      // Örnek: history.push('/unauthorized');
    }
  }
  return next(action);
};

export const contractMiddleware = store => next => action => {
  if (action.type === "LOGIN_SUCCESS" && action.payload) {
    console.log("contractMiddleware Kontrolleri", action.payload);
    if (!userHasAccessToPage(action.payload, action.payload.page)) {
      // Kullanıcının erişim izni yoksa, başka bir sayfaya yönlendirebiliriz.
      // Örnek: history.push('/unauthorized');
    }
  }
  return next(action);
};

export const paymentMiddleware = store => next => action => {
  if (action.type === "LOGIN_SUCCESS" && action.payload) {
    // Kullanıcı giriş yaptı, erişim kontrolü yapabiliriz.
    console.log("paymentMiddleware Kontrolleri", action.payload);
    if (!userHasAccessToPage(action.payload, action.payload.page)) {
      // Kullanıcının erişim izni yoksa, başka bir sayfaya yönlendirebiliriz.
      // Örnek: history.push('/unauthorized');
    }
  }
  return next(action);
};

function userHasAccessToPage(user, page) {
  console.log("userHasAccessToPage page", page);
  // Kullanıcının erişim izni varsa true, yoksa false döndürün.
  // Örnek bir kontrol mantığı:
  if (user && user.role.name === "ROLE_ADMIN") {
    return true;
  } else if (user && user.role === "user" && page === "userPage") {
    return true;
  }
  return false;
}

export default { accessControlMiddleware, contractMiddleware, paymentMiddleware };