
/* REFERENCES */
export const GET_REFERENCES = "GET_REFERENCES"
export const GET_REFERENCES_SUCCESS = "GET_REFERENCES_SUCCESS"
export const GET_REFERENCES_FAIL = "GET_REFERENCES_FAIL"

/**
 * add REFERENCE
 */
export const ADD_NEW_REFERENCE = "ADD_NEW_REFERENCE"
export const ADD_REFERENCE_SUCCESS = "ADD_REFERENCE_SUCCESS"
export const ADD_REFERENCE_FAIL = "ADD_REFERENCE_FAIL"

/**
 * Edit REFERENCE
 */
export const UPDATE_REFERENCE = "UPDATE_REFERENCE"
export const UPDATE_REFERENCE_SUCCESS = "UPDATE_REFERENCE_SUCCESS"
export const UPDATE_REFERENCE_FAIL = "UPDATE_REFERENCE_FAIL"

/**
 * Delete REFERENCE
 */
export const DELETE_REFERENCE = "DELETE_REFERENCE"
export const DELETE_REFERENCE_SUCCESS = "DELETE_REFERENCE_SUCCESS"
export const DELETE_REFERENCE_FAIL = "DELETE_REFERENCE_FAIL"
