
/* SCREEN_ACTIONS */
export const GET_SCREEN_ACTIONS = "GET_SCREEN_ACTIONS"
export const GET_SCREEN_ACTIONS_SUCCESS = "GET_SCREEN_ACTIONS_SUCCESS"
export const GET_SCREEN_ACTIONS_FAIL = "GET_SCREEN_ACTIONS_FAIL"

/**
 * add SCREEN_ACTION
 */
export const ADD_NEW_SCREEN_ACTION = "ADD_NEW_SCREEN_ACTION"
export const ADD_SCREEN_ACTION_SUCCESS = "ADD_SCREEN_ACTION_SUCCESS"
export const ADD_SCREEN_ACTION_FAIL = "ADD_SCREEN_ACTION_FAIL"

/**
 * Edit SCREEN_ACTION
 */
export const UPDATE_SCREEN_ACTION = "UPDATE_SCREEN_ACTION"
export const UPDATE_SCREEN_ACTION_SUCCESS = "UPDATE_SCREEN_ACTION_SUCCESS"
export const UPDATE_SCREEN_ACTION_FAIL = "UPDATE_SCREEN_ACTION_FAIL"

/**
 * Delete SCREEN_ACTION
 */
export const DELETE_SCREEN_ACTION = "DELETE_SCREEN_ACTION"
export const DELETE_SCREEN_ACTION_SUCCESS = "DELETE_SCREEN_ACTION_SUCCESS"
export const DELETE_SCREEN_ACTION_FAIL = "DELETE_SCREEN_ACTION_FAIL"

