import {
    GET_OPERATION_CATEGORIES_FAIL,
    GET_OPERATION_CATEGORIES_SUCCESS,
    ADD_OPERATION_CATEGORY_SUCCESS,
    ADD_OPERATION_CATEGORY_FAIL,
    UPDATE_OPERATION_CATEGORY_SUCCESS,
    UPDATE_OPERATION_CATEGORY_FAIL,
    DELETE_OPERATION_CATEGORY_SUCCESS,
    DELETE_OPERATION_CATEGORY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    operationCategories: [],
    error: {},
};

const OperationCategory = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_OPERATION_CATEGORIES_SUCCESS:
            return {
                ...state,
                operationCategories: action.payload,
            };

        case GET_OPERATION_CATEGORIES_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_OPERATION_CATEGORY_SUCCESS:
            return {
                ...state,
                operationCategories: [...state.operationCategories, action.payload],
            };

        case ADD_OPERATION_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_OPERATION_CATEGORY_SUCCESS:
            return {
                ...state,
                operationCategories: state.operationCategories.map(operationCategory =>
                    operationCategory.id.toString() === action.payload.id.toString()
                        ? { operationCategory, ...action.payload }
                        : operationCategory
                ),
            };

        case UPDATE_OPERATION_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_OPERATION_CATEGORY_SUCCESS:
            return {
                ...state,
                operationCategories: state.operationCategories.filter(
                    operationCategory => operationCategory.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_OPERATION_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default OperationCategory;
