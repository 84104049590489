import {
  IMPORT_CSV,
  IMPORT_CSV_ERROR,
  IMPORT_CSV_SUCCESS,
  IMPORT_EXCEL,
  IMPORT_EXCEL_ERROR,
  IMPORT_EXCEL_SUCCESS
} from "./actionTypes";

export const importExcel = request => ({
  type: IMPORT_EXCEL,
  request
});

export const importExcelSuccess = request => ({
  type: IMPORT_EXCEL_SUCCESS,
  request
});

export const importExcelFail = error => ({
  type: IMPORT_EXCEL_ERROR,
  payload: error
});

export const importCSV = request => ({
  type: IMPORT_CSV,
  payload: request,
})

export const importCSVSuccess = response => ({
  type: IMPORT_CSV_SUCCESS,
  response,
});

export const importCSVFail = error => ({
  type: IMPORT_CSV_ERROR,
  payload: error
});