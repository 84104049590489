import {
  GET_LEAD_NOTES,
  GET_LEAD_NOTES_SUCCESS,
  GET_LEAD_NOTES_FAIL,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_FAIL,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAIL,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAIL
} from "./actionTypes";

const INIT_STATE = {
  notes: [],
  error: {},
};

const Note = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEAD_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload,
      };

    case GET_LEAD_NOTES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_NOTE_SUCCESS:
      return {
        ...state,
        notes: [...state.notes, action.payload],
      };

    case ADD_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        notes: state.notes.map(note =>
          note.id.toString() === action.payload.id.toString()
            ? { note, ...action.payload }
            : note
        ),
      };

    case UPDATE_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_NOTE_SUCCESS:
      return {
        ...state,
        notes: state.notes.filter(
          note => note.id.toString() !== action.req.toString()
        ),
      };

    case DELETE_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Note;
