import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";


import { Name } from "./departmentCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import WarningModal from "components/Common/WarningModal";

import {
  addNewDepartment as onAddNewDepartment,
  deleteDepartment as onDeleteDepartment,
  getDepartments as onGetDepartments,
  updateDepartment as onUpdateDepartment
} from "store/department/actions";

import { getSections as onGetSections } from "store/section/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import withRouter from "../../hooks/withRouter";
import Notification from "../../components/Notification";
import Select from "react-select";

const Department = props => {

  //meta title
  document.title = `CRM | ${props.t("Clinics")}`;

  const initalCont = {
    name: "",
    section: null,
    description: ""
  };

  const dispatch = useDispatch();
  const [role, setRole] = useState({ label: "", value: "" });
  const [deleteDepartment, setDeleteDepartment] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [department, setDepartment] = useState(initalCont);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [selectedSection, setSelectedSection] = useState(initalCont);
  const [showNotification, setShowNotification] = useState(false);
  const [visible, setVisible] = useState({});
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");

  const { departments, error } = useSelector(state => ({
    departments: state.Department.departments,
    error: state.Department.error
  }));

  useEffect(() => {
    dispatch(onGetDepartments());
  }, []);

  const { sections, sectionError } = useSelector(state => ({
    sections: state.Section.sections,
    sectionError: state.Section.error
  }));

  useEffect(() => {
    dispatch(onGetSections());
  }, []);

  useEffect(() => {
    if (sections && sections.length > 0) {
      setSectionOptions(sections.map((section) => ({ label: section.name, value: section })));
    }
  }, [sections]);

  const handleRefresh = () => {
    dispatch(onGetDepartments());
  };

  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Section")}`,
        accessor: "section.name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Visible")}`,
        accessor: "visible",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.visible === false ? props.t("NO") : props.t("YES")}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        accessor: "description",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-warning"
                onClick={() => {
                  setDepartment(cellProps.row.original),
                    handleDepartmentClick(cellProps.row);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const departmentData = cellProps.row.original;
                  onClickDelete(departmentData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setDepartment(initalCont);
      setRole({ label: "", value: "" });
      setModal(false);
      setIsEdit(false);
    } else {
      setModal(true);
    }
  };

  const handleDepartmentClick = arg => {
    const department = arg.original;
    setIsEdit(true);
    setDepartment({
      id: department.id,
      name: department.name,
      section: department.section,
      description: department.description,
      visible: department.visible
    });
    setVisible({ label: props.t(department.visible === true ? "YES" : "NO"), value: department.visible });
    setSelectedSection({ label: department.section && department.section.name, value: department.section });
    toggle();
  };

  //TODO: Arrayleri düzelt

  //delete lead
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = department => {
    setDeleteDepartment(department);
    setDeleteModal(true);
  };

  const handleDeleteDepartment = () => {
    dispatch(onDeleteDepartment(deleteDepartment.id));
    setDeleteModal(false);
  };

  const handleChange = (e) => {
    setDepartment({
      ...department,
      [e.target.name]: e.target.value
    });
  };

  const onChangeSection = (e) => {
    setSelectedSection(e);
    setDepartment({
      ...department,
      ["section"]: e.value
    });
  };

  const onChangeVisibility = (e) => {
    setVisible(e);
    setDepartment({
      ...department,
      ["visible"]: e.value
    });
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  const saveDepartment = () => {
    if (isEdit) {
      dispatch(onUpdateDepartment(department));
    } else {
      if (department.name === "") {
        setMessage(`${props.t("Please fill mandatory fields")}`);
        setShowNotification(true);
        setNotificationType("Warning");
      } else {
        dispatch(onAddNewDepartment(department));
      }
    }
    toggle();
  };

  useEffect(() => {
    if (Object.entries(error).length > 0) {
      setMessage(error.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteDepartment}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Notification
        message={message}
        show={showNotification}
        callbackShow={handleClose}
        type={notificationType}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Clinics")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={departments}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={toggle}
                    customPageSize={25}
                    className="custom-header-css"
                  />
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? `${props.t("Edit")}` : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Row form="true">
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Name")}</Label>
                            <Input
                              name="name"
                              type="text"
                              onChange={handleChange}
                              value={department.name || ""}
                              invalid={!department.name}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Section")}</Label>
                            <Select
                              id="section"
                              value={selectedSection}
                              onChange={(e) => {
                                onChangeSection(e);
                              }}
                              options={sectionOptions}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Visible")}</Label>
                            <Select
                              id="visible"
                              value={visible}
                              onChange={(e) => {
                                onChangeVisibility(e);
                              }}
                              options={[{ label: props.t("YES"), value: true }, { label: props.t("NO"), value: false }]}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Description")}</Label>
                            <Input
                              name="description"
                              type="text"
                              onChange={handleChange}
                              value={department.description || ""}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-department"
                              onClick={saveDepartment}
                            >
                              {props.t("Save")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(Department));
