const privilegeActions = [
  {
    "name": "SELECT",
    "id": "SELECT",
  },
  {
    "name": "INSERT",
    "id": "INSERT",
  },
  {
    "name": "UPDATE",
    "id": "UPDATE",
  },
  {
    "name": "DELETE",
    "id": "DELETE",
  },
];

export { privilegeActions };