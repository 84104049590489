import { call, put, takeEvery } from "redux-saga/effects";

// OperationDefinitions Redux States
import { ADD_NEW_OPERATION_DEFINITION, DELETE_OPERATION_DEFINITION, GET_OPERATION_DEFINITIONS, UPDATE_OPERATION_DEFINITION } from "./actionTypes";

import {
    addOperationDefinitionFail,
    addOperationDefinitionSuccess,
    deleteOperationDefinitionFail,
    deleteOperationDefinitionSuccess,
    getOperationDefinitionsFail,
    getOperationDefinitionsSuccess,
    updateOperationDefinitionFail,
    updateOperationDefinitionSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchOperationDefinitions() {
    try {
        const response = yield call(getOperationDefinitions);
        yield put(getOperationDefinitionsSuccess(response));
    } catch (error) {
        yield put(getOperationDefinitionsFail(error));
    }
}

const getOperationDefinitions = () => get(url.GET_OPERATION_DEFINITIONS)

function* onUpdateOperationDefinition({ payload: operationDefinition }) {
    try {
        const response = yield call(updateOperationDefinition, operationDefinition);
        yield put(updateOperationDefinitionSuccess(response));
    } catch (error) {
        yield put(updateOperationDefinitionFail(error));
    }
}

const updateOperationDefinition = (operationDefinition) => post(url.UPDATE_OPERATION_DEFINITION,operationDefinition)

function* onDeleteOperationDefinition({id}) {
    try {
        const response = yield call(deleteOperationDefinition, id);
        if (response.status === 200){
            yield put(deleteOperationDefinitionSuccess(response,id));
        }else{
            yield put(deleteOperationDefinitionFail(response));
        }
    } catch (error) {
        yield put(deleteOperationDefinitionFail(error));
    }
}

const deleteOperationDefinition = (id) => del(`${url.DELETE_OPERATION_DEFINITION}/${id}`);

function* onAddNewOperationDefinition({ payload: operationDefinition }) {
    try {
        const response = yield call(addNewOperationDefinition, operationDefinition);

        yield put(addOperationDefinitionSuccess(response));
    } catch (error) {
        yield put(addOperationDefinitionFail(error));
    }
}

const addNewOperationDefinition = (operationDefinition) => post(url.ADD_NEW_OPERATION_DEFINITION,operationDefinition);

function* operationDefinitionsSaga() {
    yield takeEvery(GET_OPERATION_DEFINITIONS, fetchOperationDefinitions);
    yield takeEvery(ADD_NEW_OPERATION_DEFINITION, onAddNewOperationDefinition);
    yield takeEvery(UPDATE_OPERATION_DEFINITION, onUpdateOperationDefinition);
    yield takeEvery(DELETE_OPERATION_DEFINITION, onDeleteOperationDefinition);
}

export default operationDefinitionsSaga;
