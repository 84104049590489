import { call, put, takeEvery } from "redux-saga/effects";

// Leads Redux States

import * as url from "../../helpers/url_helper";
import { get } from "../../helpers/api_helper";
import {
  getLeadsBySegmentCriteriaFail,
  getLeadsBySegmentCriteriaSuccess, getLeadsCriteria,
  getLeadsCriteriaFail,
  getLeadsCriteriaSuccess
} from "./actions";
import { GET_LEADS_CRITERIA, GET_LEADS_WITH_SEGMENT_CRITERIA } from "./actionTypes";

function* onGetLeadsByCriteriaSegment({ payload: id }) {
  try {
    const response = yield call(getLeadsBySegmentCriteria, id);
    yield put(getLeadsBySegmentCriteriaSuccess(response));
  } catch (error) {
    yield put(getLeadsBySegmentCriteriaFail(error));
  }
}

const getLeadsBySegmentCriteria = (id) => get(`${url.GET_LEADS_CRITERIA_WITH_SEGMENT}?segmentId.equals=${id}`);

function* onGetLeadsByCriteria() {
  try {
    const response = yield call(getLeadsByCriteria);
    yield put(getLeadsCriteriaSuccess(response));
  } catch (error) {
    yield put(getLeadsCriteriaFail(error));
  }
}

const getLeadsByCriteria = () => get(url.LEADS_CRITERIA);

function* criteriaSaga() {
  yield takeEvery(GET_LEADS_WITH_SEGMENT_CRITERIA, onGetLeadsByCriteriaSegment);
  yield takeEvery(GET_LEADS_CRITERIA, onGetLeadsByCriteria);
}

export default criteriaSaga;
