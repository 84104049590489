import {
    GET_FORMS_FAIL,
    GET_FORMS_SUCCESS,
    ADD_FORM_SUCCESS,
    ADD_FORM_FAIL,
    UPDATE_FORM_SUCCESS,
    UPDATE_FORM_FAIL,
    DELETE_FORM_SUCCESS,
    DELETE_FORM_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    forms: [],
    error: {},
};

const IntegrationDefinition = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FORMS_SUCCESS:
            return {
                ...state,
                forms: action.payload,
            };

        case GET_FORMS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_FORM_SUCCESS:
            return {
                ...state,
                forms: [...state.forms, action.payload],
            };

        case ADD_FORM_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_FORM_SUCCESS:
            return {
                ...state,
                forms: state.forms.map(form =>
                    form.id.toString() === action.payload.id.toString()
                        ? { form, ...action.payload }
                        : form
                ),
            };

        case UPDATE_FORM_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_FORM_SUCCESS:
            return {
                ...state,
                forms: state.forms.filter(
                    form => form.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_FORM_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default IntegrationDefinition;
