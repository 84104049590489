const colorArray = "[\"#0c296b\",\"#097e7b\",\"#c15b4e\",\"##fff68f\",\"#c4c4fc\",\"#483d8b\",\"#00cc99\", \"#6495ed\",\"#7f1734\",\"#4a2668\",\"#8ccaaa\",\"#cb214e\",\"#c6e2ff\",\"#bf94e4\",\"#483d8b\", \"#e8f4ff\",\"#b9c3cc\",\"#546997\",\"#065856\",\"#7e090c\",\"#095184\",\"#6f8165\", \"#517697\",\"#daf8e3\",\"#00c2c7\",\"#00c2c7\",\"#97ebdb\",\"#ff6f69\",\"#103486\"]";

const getChartColorsArray = () => {
  const colors = JSON.parse(colorArray);
  return colors.map(function(value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);

      if (color.indexOf("#") !== -1)
        color = color.replace(" ", "");
      if (color) return color;
      else return newValue;
    } else {
      var val = value.split(",");
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
};

export { colorArray, getChartColorsArray };