import React from "react";

const id = (cell) => {
  return cell.value ? cell.value : "";
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

const Phone = (cell) => {
  return cell.value ? cell.value : "";
};

const Description = (cell) => {
  return cell.value ? cell.value : "";
};

const Hospital = (cell) => {
  return cell.value ? cell.value.map(item => `${item.name} `) : "";
};

export {
  id,
  Name,
  Phone,
  Description,
  Hospital
};