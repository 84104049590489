import {
  GET_LEAD_APPOINTMENTS,
  GET_LEAD_APPOINTMENTS_SUCCESS,
  GET_LEAD_APPOINTMENTS_FAIL,
  ADD_APPOINTMENT_SUCCESS,
  ADD_APPOINTMENT_FAIL,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAIL,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_FAIL,
  GET_USER_APPOINTMENTS_SUCCESS,
  GET_USER_APPOINTMENTS_FAIL,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENTS_FAIL,
  GET_TODAY_APPOINTMENTS_SUCCESS,
  GET_TODAY_APPOINTMENTS_FAIL,
  GET_APPOINTMENT_CRITERIA_FAIL,
  GET_APPOINTMENT_CRITERIA_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  appointments: [],
  error: {},
};

const Appointment = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointments: action.payload,
      };

    case GET_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_LEAD_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointments: action.payload,
      };

    case GET_LEAD_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_USER_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointments: action.payload,
      };

    case GET_USER_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointments: [...state.appointments, action.payload],
      };

    case ADD_APPOINTMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointments: state.appointments.map(appointment =>
          appointment.id.toString() === action.payload.id.toString()
            ? { appointment, ...action.payload }
            : appointment
        ),
      };

    case UPDATE_APPOINTMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointments: state.appointments.filter(
          appointment => appointment.id.toString() !== action.req.toString()
        ),
      };

    case DELETE_APPOINTMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_TODAY_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointments: action.payload,
      };

    case GET_TODAY_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
      
    case GET_APPOINTMENT_CRITERIA_SUCCESS:
      return {
        ...state,
        appointments: action.payload.content
      };

    case GET_APPOINTMENT_CRITERIA_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default Appointment;
