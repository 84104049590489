import React, {useEffect, useState} from "react";
import {Navigate, useLocation} from "react-router-dom";
import useAuth from "../hooks/useAuth";

const Authmiddleware = (props) => {
    const path = props.path;
    const {authUser, navigateGuard, navigatePayment, navigateLocked, screenList, dashboard} = useAuth();
    const location = useLocation();
    const [permissions,setPermissions] = useState([]);

    useEffect(()=>{
        setPermissions(screenList)
    },[screenList])

    const checkUserPermissions = (authUser, permissions, path) => {
        return authUser.role.name === "ROLE_MODERATOR" || permissions && path && permissions.some(item => item.screenUrl === path || path.includes("/:") && path.split("/:")[0] === item.screenUrl);
    };

    if (authUser && Object.entries(authUser).length > 0) {
        if (navigateGuard && authUser.role.name !== "ROLE_MODERATOR") {
            return <Navigate to="/guard" replace/>;
        } else if (navigateLocked && authUser.role.name !== "ROLE_MODERATOR") {
            return <Navigate to="/locked" replace/>;
        } else if (navigatePayment && authUser.role.name !== "ROLE_MODERATOR") {
            return <Navigate to="/guard-payment" replace/>;
        } else {
           // console.log("checkUserPermissions(authUser, screenList, path)", checkUserPermissions(authUser, permissions, path), path, screenList);
            if (checkUserPermissions(authUser, permissions, path) || path === "/" || path === "/translate") {
                return (
                    <React.Fragment>
                        {props.children}
                    </React.Fragment>
                );
            } else if (!(permissions.length > 0) && dashboard) {
                return <Navigate to={dashboard.screenUrl} state={{from: location}} replace/>;
            } else {
                return <Navigate to="/404" state={{from: location}} replace/>;
            }
        }
    } else {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }
};

export default Authmiddleware;
