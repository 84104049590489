
/* LEAD */
export const GET_LEAD_HISTORY = "GET_LEAD_HISTORY"
export const GET_LEAD_HISTORY_SUCCESS = "GET_LEAD_HISTORY_SUCCESS"
export const GET_LEAD_HISTORY_FAIL = "GET_LEAD_HISTORY_FAIL"

/* LEAD */
export const GET_LEAD_HISTORY_BY_FILTER = "GET_LEAD_HISTORY_BY_FILTER"
export const GET_LEAD_HISTORY_BY_FILTER_SUCCESS = "GET_LEAD_HISTORY_BY_FILTER_SUCCESS"
export const GET_LEAD_HISTORY_BY_FILTER_FAIL = "GET_LEAD_HISTORY_BY_FILTER_FAIL"

/**
 * APPOINTMENT
 */
export const GET_APPOINTMENT_HISTORY = "GET_APPOINTMENT_HISTORY"
export const GET_APPOINTMENT_HISTORY_SUCCESS = "GET_APPOINTMENT_HISTORY_SUCCESS"
export const GET_APPOINTMENT_HISTORY_FAIL = "GET_APPOINTMENT_HISTORY_FAIL"

/* APPOINTMENT */
export const GET_APPOINTMENT_HISTORY_BY_FILTER = "GET_APPOINTMENT_HISTORY_BY_FILTER"
export const GET_APPOINTMENT_HISTORY_BY_FILTER_SUCCESS = "GET_APPOINTMENT_HISTORY_BY_FILTER_SUCCESS"
export const GET_APPOINTMENT_HISTORY_BY_FILTER_FAIL = "GET_APPOINTMENT_HISTORY_BY_FILTER_FAIL"

/**
 * NOTE
 */
export const GET_NOTE_HISTORY = "GET_NOTE_HISTORY"
export const GET_NOTE_HISTORY_SUCCESS = "GET_NOTE_HISTORY_SUCCESS"
export const GET_NOTE_HISTORY_FAIL = "GET_NOTE_HISTORY_FAIL"

/* NOTE */
export const GET_NOTE_HISTORY_BY_FILTER = "GET_NOTE_HISTORY_BY_FILTER"
export const GET_NOTE_HISTORY_BY_FILTER_SUCCESS = "GET_NOTE_HISTORY_BY_FILTER_SUCCESS"
export const GET_NOTE_HISTORY_BY_FILTER_FAIL = "GET_NOTE_HISTORY_BY_FILTER_FAIL"

/**
 * NOTE
 */
export const GET_REMINDER_HISTORY = "GET_REMINDER_HISTORY"
export const GET_REMINDER_HISTORY_SUCCESS = "GET_REMINDER_HISTORY_SUCCESS"
export const GET_REMINDER_HISTORY_FAIL = "GET_REMINDER_HISTORY_FAIL"

/* NOTE */
export const GET_REMINDER_HISTORY_BY_FILTER = "GET_REMINDER_HISTORY_BY_FILTER"
export const GET_REMINDER_HISTORY_BY_FILTER_SUCCESS = "GET_REMINDER_HISTORY_BY_FILTER_SUCCESS"
export const GET_REMINDER_HISTORY_BY_FILTER_FAIL = "GET_REMINDER_HISTORY_BY_FILTER_FAIL"

/**
 * CATEGORY
 */
export const GET_CATEGORY_HISTORY = "GET_CATEGORY_HISTORY"
export const GET_CATEGORY_HISTORY_SUCCESS = "GET_CATEGORY_HISTORY_SUCCESS"
export const GET_CATEGORY_HISTORY_FAIL = "GET_CATEGORY_HISTORY_FAIL"

