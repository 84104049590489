import { call, put, takeEvery } from "redux-saga/effects";

// Hospitals Redux States
import { ADD_NEW_HOSPITAL, DELETE_HOSPITAL, GET_HOSPITALS, UPDATE_HOSPITAL } from "./actionTypes";

import {
    addHospitalFail,
    addHospitalSuccess,
    deleteHospitalFail,
    deleteHospitalSuccess,
    getHospitalsFail,
    getHospitalsSuccess,
    updateHospitalFail,
    updateHospitalSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchHospitals() {
    try {
        const response = yield call(getHospitals);
        yield put(getHospitalsSuccess(response));
    } catch (error) {
        yield put(getHospitalsFail(error));
    }
}

const getHospitals = () => get(url.GET_HOSPITALS)

function* onUpdateHospital({ payload: hospital }) {
    try {
        const response = yield call(updateHospital, hospital);
        yield put(updateHospitalSuccess(response));
    } catch (error) {
        yield put(updateHospitalFail(error));
    }
}

const updateHospital = (hospital) => post(url.UPDATE_HOSPITAL,hospital)

function* onDeleteHospital({id}) {
    try {
        const response = yield call(deleteHospital, id);
        if (response.status === 200){
            yield put(deleteHospitalSuccess(response,id));
        }else{
            yield put(deleteHospitalFail(response));
        }
    } catch (error) {
        yield put(deleteHospitalFail(error));
    }
}

const deleteHospital = (id) => del(`${url.DELETE_HOSPITAL}/${id}`);

function* onAddNewHospital({ payload: hospital }) {
    try {
        const response = yield call(addNewHospital, hospital);

        yield put(addHospitalSuccess(response));
    } catch (error) {
        yield put(addHospitalFail(error));
    }
}

const addNewHospital = (hospital) => post(url.ADD_NEW_HOSPITAL,hospital);

function* hospitalsSaga() {
    yield takeEvery(GET_HOSPITALS, fetchHospitals);
    yield takeEvery(ADD_NEW_HOSPITAL, onAddNewHospital);
    yield takeEvery(UPDATE_HOSPITAL, onUpdateHospital);
    yield takeEvery(DELETE_HOSPITAL, onDeleteHospital);
}

export default hospitalsSaga;
