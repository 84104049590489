import {
  ADD_SECTION_FAIL,
  ADD_SECTION_SUCCESS,
  DELETE_SECTION_FAIL,
  DELETE_SECTION_SUCCESS,
  GET_SECTIONS_FAIL,
  GET_SECTIONS_SUCCESS,
  UPDATE_SECTION_FAIL,
  UPDATE_SECTION_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  sections: [],
  error: {}
};

const Section = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SECTIONS_SUCCESS:
      return {
        ...state,
        sections: action.payload
      };

    case GET_SECTIONS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_SECTION_SUCCESS:
      return {
        ...state,
        sections: [...state.sections, action.payload]
      };

    case ADD_SECTION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_SECTION_SUCCESS:
      return {
        ...state,
        sections: state.sections.map(section =>
          section.id.toString() === action.payload.id.toString()
            ? { section, ...action.payload }
            : section
        )
      };

    case UPDATE_SECTION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_SECTION_SUCCESS:
      return {
        ...state,
        sections: state.sections.filter(
          section => section.id.toString() !== action.req.toString()
        )
      };

    case DELETE_SECTION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default Section;
