import {
    GET_FACEBOOK_FORMS_FAIL,
    GET_FACEBOOK_FORMS_SUCCESS,
    ADD_FACEBOOK_FORM_SUCCESS,
    ADD_FACEBOOK_FORM_FAIL,
    UPDATE_FACEBOOK_FORM_SUCCESS,
    UPDATE_FACEBOOK_FORM_FAIL,
    DELETE_FACEBOOK_FORM_SUCCESS,
    DELETE_FACEBOOK_FORM_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    facebookForms: [],
    error: {},
};

const FacebookForm = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FACEBOOK_FORMS_SUCCESS:
            return {
                ...state,
                facebookForms: action.payload,
            };

        case GET_FACEBOOK_FORMS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_FACEBOOK_FORM_SUCCESS:
            return {
                ...state,
                facebookForms: [...state.facebookForms, action.payload],
            };

        case ADD_FACEBOOK_FORM_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_FACEBOOK_FORM_SUCCESS:
            return {
                ...state,
                facebookForms: state.facebookForms.map(facebookForm =>
                    facebookForm.id.toString() === action.payload.id.toString()
                        ? { facebookForm, ...action.payload }
                        : facebookForm
                ),
            };

        case UPDATE_FACEBOOK_FORM_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_FACEBOOK_FORM_SUCCESS:
            return {
                ...state,
                facebookForms: state.facebookForms.filter(
                    facebookForm => facebookForm.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_FACEBOOK_FORM_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default FacebookForm;
