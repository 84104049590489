import React from "react";

const leadIntDefId = (cell) => {
  return cell.value ? cell.value : "";
};

const Type = (cell) => {
  return cell.value ? cell.value : "";
};

const URL = (cell) => {
  return cell.value ? cell.value : "";
};

const SecretKey = (cell) => {
  return cell.value ? cell.value : "";
};

export {
  leadIntDefId,
  Type,
  URL,
  SecretKey
};