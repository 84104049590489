import {
  ADD_PATIENT_FAIL,
  ADD_PATIENT_SUCCESS,
  ASSIGN_DOCTOR_FAIL,
  ASSIGN_DOCTOR_SUCCESS,
  DELETE_PATIENT,
  DELETE_PATIENT_FAIL,
  DELETE_PATIENT_SUCCESS,
  GET_PATIENT_BY_ID_FAIL,
  GET_PATIENT_BY_ID_SUCCESS,
  GET_PATIENTS_FAIL,
  GET_PATIENTS_SUCCESS,
  PATIENT_CRITERIA_FAIL,
  PATIENT_CRITERIA_SUCCESS,
  UPDATE_PATIENT_FAIL,
  UPDATE_PATIENT_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  patients: [],
  patient: {},
  error: {},
  processResponse: false,
  reqObject: {}
};

const Patient = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.payload
      };

    case GET_PATIENTS_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case ADD_PATIENT_SUCCESS:
      return {
        ...state,
        patients: [action.payload, ...state.patients],
        processResponse: true
      };

    case ADD_PATIENT_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        patients: state.patients.map(patient =>
          patient.id.toString() === action.payload.id.toString()
            ? { patient, ...action.payload }
            : patient
        ),
        patient: action.payload,
        processResponse: true
      };

    case UPDATE_PATIENT_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case DELETE_PATIENT:
      return {
        ...state,
        patient: action.payload
      };

    case DELETE_PATIENT_SUCCESS:
      return {
        ...state,
        patients: state.patients.filter(
          patient => patient.id.toString() !== action.payload.id.toString()
        ),
        processResponse: true
      };

    case DELETE_PATIENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_PATIENT_BY_ID_SUCCESS:
      return {
        ...state,
        patient: action.payload
      };

    case GET_PATIENT_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case ASSIGN_DOCTOR_SUCCESS:
      return {
        ...state,
        patients: state.patients.map(patient => {
          if (action.payload.some(pay => pay.id === patient.id)) {
            const filtered = { ...action.payload.filter(pay => pay.id === patient.id) };
            return filtered[0];
          } else {
            return patient;
          }
        }),
        processResponse: true
      };

    case ASSIGN_DOCTOR_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case PATIENT_CRITERIA_SUCCESS:
      return {
        ...state,
        patients: action.payload.content,
        page: action.payload.pageable.pageNumber ? action.payload.pageable.pageNumber : 0,
        totalCount: action.payload.totalElements,
        totalPages: (action.payload.totalPages - 1)
      };

    case
    PATIENT_CRITERIA_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    default:
      return {
        ...state,
        error: {},
        processResponse: false
      };
  }
};

export default Patient;
