import React from "react";

const id = (cell) => {
  return cell.value ? cell.value : "";
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};


const Description = (cell) => {
  return cell.value ? cell.value : "";
};

export {
  id,
  Name,
  Description
};