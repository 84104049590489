import {
  ADD_NEW_TRANSLATE,
  ADD_TRANSLATE_FAIL,
  ADD_TRANSLATE_SUCCESS,
  DELETE_TRANSLATE,
  DELETE_TRANSLATE_FAIL,
  DELETE_TRANSLATE_SUCCESS,
  GET_TRANSLATES,
  GET_TRANSLATES_FAIL,
  GET_TRANSLATES_SUCCESS,
  SAVE_ALL_TRANSLATES,
  SAVE_ALL_TRANSLATES_FAIL,
  SAVE_ALL_TRANSLATES_SUCCESS,
  TRANSLATE_CRITERIA,
  TRANSLATE_CRITERIA_FAIL,
  TRANSLATE_CRITERIA_SUCCESS,
  UPDATE_TRANSLATE,
  UPDATE_TRANSLATE_FAIL,
  UPDATE_TRANSLATE_SUCCESS
} from "./actionTypes";

export const getTranslates = () => ({
  type: GET_TRANSLATES
});

export const getTranslatesSuccess = translates => ({
  type: GET_TRANSLATES_SUCCESS,
  payload: translates
});

export const getTranslatesFail = error => ({
  type: GET_TRANSLATES_FAIL,
  payload: error
});

export const addNewTranslate = translate => ({
  type: ADD_NEW_TRANSLATE,
  payload: translate
});

export const addTranslateSuccess = translate => ({
  type: ADD_TRANSLATE_SUCCESS,
  payload: translate
});

export const addTranslateFail = error => ({
  type: ADD_TRANSLATE_FAIL,
  payload: error
});

export const updateTranslate = translate => ({
  type: UPDATE_TRANSLATE,
  payload: translate
});

export const updateTranslateSuccess = translate => ({
  type: UPDATE_TRANSLATE_SUCCESS,
  payload: translate
});

export const updateTranslateFail = error => ({
  type: UPDATE_TRANSLATE_FAIL,
  payload: error
});

export const deleteTranslate = id => ({
  type: DELETE_TRANSLATE,
  id
});

export const deleteTranslateSuccess = (translate, req) => ({
  type: DELETE_TRANSLATE_SUCCESS,
  payload: translate,
  req: req
});

export const deleteTranslateFail = error => ({
  type: DELETE_TRANSLATE_FAIL,
  payload: error
});

export const translateCriteria = res => ({
  type: TRANSLATE_CRITERIA,
  payload: res
});

export const translateCriteriaSuccess = (res) => ({
  type: TRANSLATE_CRITERIA_SUCCESS,
  payload: res
});

export const translateCriteriaFail = error => ({
  type: TRANSLATE_CRITERIA_FAIL,
  payload: error
});

export const translatesSaveAll = (req) => ({
  type: SAVE_ALL_TRANSLATES,
  payload: req
});

export const translatesSaveAllSuccess = req => ({
  type: SAVE_ALL_TRANSLATES_SUCCESS,
  payload: req
});

export const translatesSaveAllFail = error => ({
  type: SAVE_ALL_TRANSLATES_FAIL,
  payload: error
});
