
/* DOCTORS */
export const GET_DOCTORS = "GET_DOCTORS"
export const GET_DOCTORS_SUCCESS = "GET_DOCTORS_SUCCESS"
export const GET_DOCTORS_FAIL = "GET_DOCTORS_FAIL"

/**
 * add DOCTOR
 */
export const ADD_NEW_DOCTOR = "ADD_NEW_DOCTOR"
export const ADD_DOCTOR_SUCCESS = "ADD_DOCTOR_SUCCESS"
export const ADD_DOCTOR_FAIL = "ADD_DOCTOR_FAIL"

/**
 * Edit DOCTOR
 */
export const UPDATE_DOCTOR = "UPDATE_DOCTOR"
export const UPDATE_DOCTOR_SUCCESS = "UPDATE_DOCTOR_SUCCESS"
export const UPDATE_DOCTOR_FAIL = "UPDATE_DOCTOR_FAIL"

/**
 * Delete DOCTOR
 */
export const DELETE_DOCTOR = "DELETE_DOCTOR"
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS"
export const DELETE_DOCTOR_FAIL = "DELETE_DOCTOR_FAIL"
