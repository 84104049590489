import React from "react";

const parameterId = (cell) => {
  return cell.value ? cell.value : "";
};

const Value = (cell) => {
  return cell.value ? cell.value : "";
};

const Code = (cell) => {
  return cell.value ? cell.value : "";
};

const Description = (cell) => {
  return cell.value ? cell.value : "";
};

export {
  parameterId,
  Value,
  Code,
  Description
};