import {call, put, takeEvery} from "redux-saga/effects";

// Resources Redux States
import {ADD_NEW_RESOURCE, DELETE_RESOURCE, GET_RESOURCES, UPDATE_RESOURCE} from "./actionTypes";

import {
    addResourceFail,
    addResourceSuccess,
    deleteResourceFail,
    deleteResourceSuccess,
    getResourcesFail,
    getResourcesSuccess,
    updateResourceFail,
    updateResourceSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import {del, get, post} from "../../helpers/api_helper";

const lang = localStorage.getItem("i18nextLng").toUpperCase();

function* fetchResources() {
    try {
        const response = yield call(getResources);
        yield put(getResourcesSuccess(response));
    } catch (error) {
        yield put(getResourcesFail(error));
    }
}

const getResources = () => get(url.GET_RESOURCES + "/" + lang)

function* onUpdateResource({payload: resource}) {
    try {
        const response = yield call(updateResource, resource);
        yield put(updateResourceSuccess(response));
    } catch (error) {
        yield put(updateResourceFail(error));
    }
}

const updateResource = (resource) => post(url.UPDATE_RESOURCE, resource)

function* onDeleteResource({id}) {
    try {
        const response = yield call(deleteResource, id);
        if (response.status === 200) {
            yield put(deleteResourceSuccess(response, id));
        } else {
            yield put(deleteResourceFail(response));
        }
    } catch (error) {
        yield put(deleteResourceFail(error));
    }
}

const deleteResource = (id) => del(`${url.DELETE_RESOURCE}/${id}`);

function* onAddNewResource({payload: resource}) {
    try {
        const response = yield call(addNewResource, resource);

        yield put(addResourceSuccess(response));
    } catch (error) {
        yield put(addResourceFail(error));
    }
}

const addNewResource = (resource) => post(url.ADD_NEW_RESOURCE, resource);

function* resourcesSaga() {
    yield takeEvery(GET_RESOURCES, fetchResources);
    yield takeEvery(ADD_NEW_RESOURCE, onAddNewResource);
    yield takeEvery(UPDATE_RESOURCE, onUpdateResource);
    yield takeEvery(DELETE_RESOURCE, onDeleteResource);
}

export default resourcesSaga;
