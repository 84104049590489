import { call, put, takeEvery } from "redux-saga/effects";

// OperationCategories Redux States
import { ADD_NEW_OPERATION_CATEGORY, DELETE_OPERATION_CATEGORY, GET_OPERATION_CATEGORIES, UPDATE_OPERATION_CATEGORY } from "./actionTypes";

import {
    addOperationCategoryFail,
    addOperationCategorySuccess,
    deleteOperationCategoryFail,
    deleteOperationCategorySuccess,
    getOperationCategoriesFail,
    getOperationCategoriesSuccess,
    updateOperationCategoryFail,
    updateOperationCategorySuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchOperationCategories() {
    try {
        const response = yield call(getOperationCategories);
        yield put(getOperationCategoriesSuccess(response));
    } catch (error) {
        yield put(getOperationCategoriesFail(error));
    }
}

const getOperationCategories = () => get(url.GET_OPERATION_CATEGORY)

function* onUpdateOperationCategory({ payload: operationDefinition }) {
    try {
        const response = yield call(updateOperationCategory, operationDefinition);
        yield put(updateOperationCategorySuccess(response));
    } catch (error) {
        yield put(updateOperationCategoryFail(error));
    }
}

const updateOperationCategory = (operationDefinition) => post(url.UPDATE_OPERATION_CATEGORY,operationDefinition)

function* onDeleteOperationCategory({id}) {
    try {
        const response = yield call(deleteOperationCategory, id);
        if (response.status === 200){
            yield put(deleteOperationCategorySuccess(response,id));
        }else{
            yield put(deleteOperationCategoryFail(response));
        }
    } catch (error) {
        yield put(deleteOperationCategoryFail(error));
    }
}

const deleteOperationCategory = (id) => del(`${url.DELETE_OPERATION_CATEGORY}/${id}`);

function* onAddNewOperationCategory({ payload: operationDefinition }) {
    try {
        const response = yield call(addNewOperationCategory, operationDefinition);

        yield put(addOperationCategorySuccess(response));
    } catch (error) {
        yield put(addOperationCategoryFail(error));
    }
}

const addNewOperationCategory = (operationDefinition) => post(url.ADD_NEW_OPERATION_CATEGORY,operationDefinition);

function* operationCategoriesSaga() {
    yield takeEvery(GET_OPERATION_CATEGORIES, fetchOperationCategories);
    yield takeEvery(ADD_NEW_OPERATION_CATEGORY, onAddNewOperationCategory);
    yield takeEvery(UPDATE_OPERATION_CATEGORY, onUpdateOperationCategory);
    yield takeEvery(DELETE_OPERATION_CATEGORY, onDeleteOperationCategory);
}

export default operationCategoriesSaga;
