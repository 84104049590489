import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  ADD_USER,
  CHANGE_PASSWORD,
  DELETE_USER,
  GET_USER_PROFILE,
  GET_USERS,
  GET_USERS_BY_ROLE,
  RESET_PASSWORD,
  UPDATE_USER,
  USER_CRITERIA
} from "./actionTypes";

import {
  addUserFail,
  addUserSuccess,
  changePasswordFail,
  changePasswordSuccess,
  deleteUserFail,
  deleteUserSuccess,
  getUserProfileFail,
  getUserProfileSuccess,
  getUsersByRoleFail,
  getUsersByRoleSuccess,
  getUsersFail,
  getUsersSuccess,
  resetPasswordFail,
  resetPasswordSuccess,
  updateUserFail,
  updateUserSuccess,
  userCriteriaFail,
  userCriteriaSuccess
} from "./actions";

import { del, get, post } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

function* fetchUsers() {
  try {
    const response = yield call(getUsers);
    yield put(getUsersSuccess(response));
  } catch (error) {
    yield put(getUsersFail(error));
  }
}

const getUsers = () => get(url.GET_USERS);

function* fetchUserProfile({ payload: id }) {
  try {
    const response = yield call(getUserProfile, id);
    yield put(getUserProfileSuccess(response));
  } catch (error) {
    yield put(getUserProfileFail(error));
  }
}

const getUserProfile = (id) => get(`${url.GET_USER_PROFILE}/${id}`);

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user);
    yield put(updateUserSuccess(response));
  } catch (error) {
    yield put(updateUserFail(error));
  }
}

const updateUser = (user) => post(url.UPDATE_USER, user);

function* onDeleteUser({ payload: id }) {
  try {
    const response = yield call(deleteUser, id);
    yield put(deleteUserSuccess(response, id));
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

const deleteUser = (id) => del(`${url.DELETE_USER}/${id}`);

function* onAddNewUser({ payload: user }) {
  try {
    const response = yield call(addNewUser, user);
    if (response.status && response.data && response.status !== 200) {
      yield put(addUserFail(response));
    } else {
      yield put(addUserSuccess(response));
    }

  } catch (error) {
    yield put(addUserFail(error));
  }
}

const addNewUser = (user) => post(url.ADD_NEW_USER, user);


function* onGetAllByRole({ payload: role }) {

  try {
    const response = yield call(getUsersByRole, role);

    yield put(getUsersByRoleSuccess(response));
  } catch (error) {

    yield put(getUsersByRoleFail(error));
  }
}

const getUsersByRole = (role) => get(`${url.GET_USERS_BY_ROLE}/${role}`);

function* onChangePassword({ payload: user }) {

  try {
    const response = yield call(postChangePassword, user);
    yield put(changePasswordSuccess(response));
  } catch (error) {
    yield put(changePasswordFail(error));
  }
}

const postChangePassword = (user) => post(url.CHANGE_PASSWORD, user);


function* fetchUserCriteria({ payload: req }) {

  try {
    const response = yield call(getUserCriteria, req);
    yield put(userCriteriaSuccess(response, req));
  } catch (error) {
    yield put(userCriteriaFail(error));
  }
}

const getUserCriteria = (req) => post(url.USER_CRITERIA, req);

function* onResetPassword({ payload: user }) {
  try {
    const response = yield call(postResetPassword, user);
    yield put(resetPasswordSuccess(response));
  } catch (error) {
    yield put(resetPasswordFail(error));
  }
}

const postResetPassword = (user) => post(url.RESET_PASSWORD, user);

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers);
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile);
  yield takeEvery(ADD_USER, onAddNewUser);
  yield takeEvery(UPDATE_USER, onUpdateUser);
  yield takeEvery(DELETE_USER, onDeleteUser);
  yield takeEvery(GET_USERS_BY_ROLE, onGetAllByRole);
  yield takeEvery(CHANGE_PASSWORD, onChangePassword);
  yield takeEvery(USER_CRITERIA, fetchUserCriteria);
  yield takeEvery(RESET_PASSWORD, onResetPassword);
}

export default usersSaga;
