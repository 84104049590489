import {
    GET_LEAD_INTEGRATION_DEFINITION_FAIL,
    GET_LEAD_INTEGRATION_DEFINITION_SUCCESS,
    ADD_LEAD_INTEGRATION_DEFINITION_SUCCESS,
    ADD_LEAD_INTEGRATION_DEFINITION_FAIL,
    UPDATE_LEAD_INTEGRATION_DEFINITION_SUCCESS,
    UPDATE_LEAD_INTEGRATION_DEFINITION_FAIL,
    DELETE_LEAD_INTEGRATION_DEFINITION_SUCCESS,
    DELETE_LEAD_INTEGRATION_DEFINITION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    leadIntegrationDefs: [],
    error: {},
    definition: {}
};

const leadIntegrationDefs = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LEAD_INTEGRATION_DEFINITION_SUCCESS:
            return {
                ...state,
                leadIntegrationDefs: action.payload,
            };

        case GET_LEAD_INTEGRATION_DEFINITION_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_LEAD_INTEGRATION_DEFINITION_SUCCESS:
            return {
                ...state,
                leadIntegrationDefs: [...state.leadIntegrationDefs, action.payload],
                definition: action.payload,
            };

        case ADD_LEAD_INTEGRATION_DEFINITION_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_LEAD_INTEGRATION_DEFINITION_SUCCESS:
            return {
                ...state,
                definition: action.payload,
                leadIntegrationDefs: state.leadIntegrationDefs.map(leadIntegrationDef =>
                    leadIntegrationDef.id.toString() === action.payload.id.toString()
                        ? { leadIntegrationDef, ...action.payload }
                        : leadIntegrationDef
                ),
            };

        case UPDATE_LEAD_INTEGRATION_DEFINITION_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_LEAD_INTEGRATION_DEFINITION_SUCCESS:
            return {
                ...state,
                leadIntegrationDefs: state.leadIntegrationDefs.filter(
                    leadIntegrationDef => leadIntegrationDef.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_LEAD_INTEGRATION_DEFINITION_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default leadIntegrationDefs;
