
/* FIRM_INFOS */
export const GET_FIRM_INFOS = "GET_FIRM_INFOS"
export const GET_FIRM_INFOS_SUCCESS = "GET_FIRM_INFOS_SUCCESS"
export const GET_FIRM_INFOS_FAIL = "GET_FIRM_INFOS_FAIL"

/**
 * add FIRM_INFO
 */
export const ADD_NEW_FIRM_INFO = "ADD_NEW_FIRM_INFO"
export const ADD_FIRM_INFO_SUCCESS = "ADD_FIRM_INFO_SUCCESS"
export const ADD_FIRM_INFO_FAIL = "ADD_FIRM_INFO_FAIL"

/**
 * Edit FIRM_INFO
 */
export const UPDATE_FIRM_INFO = "UPDATE_FIRM_INFO"
export const UPDATE_FIRM_INFO_SUCCESS = "UPDATE_FIRM_INFO_SUCCESS"
export const UPDATE_FIRM_INFO_FAIL = "UPDATE_FIRM_INFO_FAIL"

/**
 * Delete FIRM_INFO
 */
export const DELETE_FIRM_INFO = "DELETE_FIRM_INFO"
export const DELETE_FIRM_INFO_SUCCESS = "DELETE_FIRM_INFO_SUCCESS"
export const DELETE_FIRM_INFO_FAIL = "DELETE_FIRM_INFO_FAIL"
