import {
  GET_AUTH_LOGS,
  GET_AUTH_LOGS_ERROR,
  GET_AUTH_LOGS_SUCCESS, GET_FACEBOOK_AUTH_LOGS, GET_FACEBOOK_AUTH_LOGS_ERROR, GET_FACEBOOK_AUTH_LOGS_SUCCESS,
  GET_LAST_LOGINS,
  GET_LAST_LOGINS_ERROR,
  GET_LAST_LOGINS_FOR_USER,
  GET_LAST_LOGINS_FOR_USER_ERROR,
  GET_LAST_LOGINS_FOR_USER_SUCCESS,
  GET_LAST_LOGINS_SUCCESS
} from "./actionTypes";

export const getLastLogins = () => ({
  type: GET_LAST_LOGINS
});

export const getLastLoginsSuccess = res => ({
  type: GET_LAST_LOGINS_SUCCESS,
  payload: res
});

export const getLastLoginsError = res => ({
  type: GET_LAST_LOGINS_ERROR,
  payload: res
});

export const getLastLoginsForUser = user => {
  return {
    type: GET_LAST_LOGINS_FOR_USER,
    payload: user
  };
};

export const getLastLoginsForUserSuccess = user => {
  return {
    type: GET_LAST_LOGINS_FOR_USER_SUCCESS,
    payload: user
  };
};

export const getLastLoginsForUserError = user => {
  return {
    type: GET_LAST_LOGINS_FOR_USER_ERROR,
    payload: user
  };
};

export const getAuthLogs = req => ({
  type: GET_AUTH_LOGS,
  payload: req
});

export const getAuthLogsSuccess = (res) => ({
  type: GET_AUTH_LOGS_SUCCESS,
  payload: res
});

export const getAuthLogsFail = user => {
  return {
    type: GET_AUTH_LOGS_ERROR,
    payload: user
  };
};

export const getFacebookAuthLogs = () => ({
  type: GET_FACEBOOK_AUTH_LOGS
});

export const getFacebookAuthLogsSuccess = (res) => ({
  type: GET_FACEBOOK_AUTH_LOGS_SUCCESS,
  payload: res
});

export const getFacebookAuthLogsFail = user => {
  return {
    type: GET_FACEBOOK_AUTH_LOGS_ERROR,
    payload: user
  };
};
