/* LEAD_REPORT_BY_USER */
export const LEAD_REPORT_BY_USER = "LEAD_REPORT_BY_USER"
export const LEAD_REPORT_BY_USER_SUCCESS = "LEAD_REPORT_BY_USER_SUCCESS"
export const LEAD_REPORT_BY_USER_FAIL = "LEAD_REPORT_BY_USER_FAIL"

/* LEAD_COUNTS_BY_SEGMENT */
export const LEAD_COUNTS_BY_SEGMENT = "LEAD_COUNTS_BY_SEGMENT"
export const LEAD_COUNTS_BY_SEGMENT_SUCCESS = "LEAD_COUNTS_BY_SEGMENT_SUCCESS"
export const LEAD_COUNTS_BY_SEGMENT_FAIL = "LEAD_COUNTS_BY_SEGMENT_FAIL"

/* LEAD_COUNTS_BY_RESOURCE  */
export const LEAD_COUNTS_BY_RESOURCE = "LEAD_COUNTS_BY_RESOURCE"
export const LEAD_COUNTS_BY_RESOURCE_SUCCESS = "LEAD_COUNTS_BY_RESOURCE_SUCCESS"
export const LEAD_COUNTS_BY_RESOURCE_FAIL = "LEAD_COUNTS_BY_RESOURCE_FAIL"