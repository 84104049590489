import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../hooks/withRouter"
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";
import {
  addNewDescriptionTemplate as onAddNewDescriptionTemplate,
  deleteDescriptionTemplate as onDeleteDescriptionTemplate,
  getDescriptionTemplates as onGetDescriptionTemplates,
  updateDescriptionTemplate as onUpdateDescriptionTemplate
} from "store/descriptionTemplate/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Description, Name } from "./DescriptionTemplateCol";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const DescriptionTemplate = props => {

  //meta title
  document.title = `CRM | ${props.t("Description Template")}`;

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [descriptionTemplateList, setDescriptionTemplateList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [descriptionTemplate, setDescriptionTemplate] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [type, setType] = useState();
  const [description, setDescription] = useState(`<b>${props.t("DESC_TEMP_MESSAGE")}</b>`);

  const validation = {
    values: {
      name: (descriptionTemplate && descriptionTemplate.name) || "",
      type: (descriptionTemplate && descriptionTemplate.type) || "",
      description: (descriptionTemplate && descriptionTemplate.description) || ""
    },

    handleSubmit: (values) => {
      if (isEdit) {
        const updateDescriptionTemplate = {
          id: descriptionTemplate ? descriptionTemplate.id : 0,
          name: values.name,
          type: values.type,
          description: values.description
        };
        // update descriptionTemplate
        dispatch(onUpdateDescriptionTemplate(updateDescriptionTemplate));
      } else {
        const newDescriptionTemplate = {
          name: values["name"],
          type: values["type"],
          description: values["description"]
        };
        // save new descriptionTemplate
        dispatch(onAddNewDescriptionTemplate(newDescriptionTemplate));
      }
      toggle();
    }
  };

  const handleDescriptionTemplateClick = arg => {
    const descriptionTemplate = arg;

    setDescriptionTemplate({
      id: descriptionTemplate.id,
      name: descriptionTemplate.name,
      type: descriptionTemplate.type,
      description: descriptionTemplate.description
    });

    setType({ label: descriptionTemplate.type, value: descriptionTemplate.type });
    setDescription(descriptionTemplate.description);

    setIsEdit(true);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Type")}`,
        Header: "Type",
        accessor: "type",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Description",
        accessor: "description",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const descriptionTemplateData = cellProps.row.original;
                  handleDescriptionTemplateClick(descriptionTemplateData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const descriptionTemplateData = cellProps.row.original;
                  onClickDelete(descriptionTemplateData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const { descriptionTemplates, error } = useSelector(state => ({
    descriptionTemplates: state.DescriptionTemplate.descriptionTemplates,
    error: state.DescriptionTemplate.error
  }));

  const handleChange = (e) => {
    setDescriptionTemplate({
      ...descriptionTemplate,
      [e.target.name]: e.target.value
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setDescriptionTemplate(null);
      setDescription("");
    } else {
      setModal(true);
    }
  };

  //delete descriptionTemplate
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (descriptionTemplate) => {
    setDescriptionTemplate(descriptionTemplate);
    setDeleteModal(true);
  };

  const handleDeleteDescriptionTemplate = () => {
    if (descriptionTemplate.id) {
      dispatch(onDeleteDescriptionTemplate(descriptionTemplate.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetDescriptionTemplates());
      setFirstCall(false);
    }
  }, [dispatch, descriptionTemplates]);

  useEffect(() => {
    setDescriptionTemplateList(descriptionTemplates);
  }, [descriptionTemplates]);

  useEffect(() => {
    if (!isEmpty(descriptionTemplates)) {
      setDescriptionTemplateList(descriptionTemplates);
    }
  }, [descriptionTemplates]);

  const handleDescriptionTemplateClicks = () => {
    setDescriptionTemplateList("");
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetDescriptionTemplates());
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  function handleChangeType(event) {
    setType(event);
    setDescriptionTemplate({
      ...descriptionTemplate,
      ["type"]: event.value
    });
  }

  function handleChangeDescription(desc) {
    setDescription(desc);
    setDescriptionTemplate({
      ...descriptionTemplate,
      ["description"]: desc
    });
  }

  const typeOptions = [{ label: `${props.t("OFFER")}`, value: "OFFER" }, {
    label: `${props.t("OPERATION")}`,
    value: "OPERATION"
  }];
  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteDescriptionTemplate}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Operation")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={descriptionTemplates}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleDescriptionTemplateClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Name")}</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.name || ""}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Type")}</Label>
                              <Select
                                value={type}
                                onChange={(event) => {
                                  handleChangeType(event);
                                }}
                                options={typeOptions}
                                className="select2-selection"
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Description")}</Label>
                              <div className="container-fluid">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={description}
                                  onReady={editor => console.log("Editor is ready to use!")}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleChangeDescription(data);
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-lead"
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
DescriptionTemplate.propTypes = {
  descriptionTemplates: PropTypes.array,
  onGetDescriptionTemplates: PropTypes.func,
  onAddNewDescriptionTemplate: PropTypes.func,
  onDeleteDescriptionTemplate: PropTypes.func,
  onUpdateDescriptionTemplate: PropTypes.func
};

export default withRouter(withTranslation()(DescriptionTemplate));
