import {
    GET_MEDICAL_OPERATIONS,
    GET_MEDICAL_OPERATIONS_FAIL,
    GET_MEDICAL_OPERATIONS_SUCCESS,
    ADD_NEW_MEDICAL_OPERATION,
    ADD_MEDICAL_OPERATION_SUCCESS,
    ADD_MEDICAL_OPERATION_FAIL,
    UPDATE_MEDICAL_OPERATION,
    UPDATE_MEDICAL_OPERATION_SUCCESS,
    UPDATE_MEDICAL_OPERATION_FAIL,
    DELETE_MEDICAL_OPERATION,
    DELETE_MEDICAL_OPERATION_SUCCESS,
    DELETE_MEDICAL_OPERATION_FAIL,
} from "./actionTypes"

export const getMedicalOperations = () => ({
    type: GET_MEDICAL_OPERATIONS,
})

export const getMedicalOperationsSuccess = operations => ({
    type: GET_MEDICAL_OPERATIONS_SUCCESS,
    payload: operations,
})

export const getMedicalOperationsFail = error => ({
    type: GET_MEDICAL_OPERATIONS_FAIL,
    payload: error,
})

export const addNewMedicalOperation = operation => ({
    type: ADD_NEW_MEDICAL_OPERATION,
    payload: operation,
})

export const addMedicalOperationSuccess = operation => ({
    type: ADD_MEDICAL_OPERATION_SUCCESS,
    payload: operation,
})

export const addMedicalOperationFail = error => ({
    type: ADD_MEDICAL_OPERATION_FAIL,
    payload: error,
})

export const updateMedicalOperation = operation => ({
    type: UPDATE_MEDICAL_OPERATION,
    payload: operation,
})

export const updateMedicalOperationSuccess = operation => ({
    type: UPDATE_MEDICAL_OPERATION_SUCCESS,
    payload: operation,
})

export const updateMedicalOperationFail = error => ({
    type: UPDATE_MEDICAL_OPERATION_FAIL,
    payload: error,
})

export const deleteMedicalOperation = id => ({
    type: DELETE_MEDICAL_OPERATION,
    id,
})

export const deleteMedicalOperationSuccess = (operation,req) => ({
    type: DELETE_MEDICAL_OPERATION_SUCCESS,
    payload: operation,
    req:req,
})

export const deleteMedicalOperationFail = error => ({
    type: DELETE_MEDICAL_OPERATION_FAIL,
    payload: error,
})