import { call, put, takeEvery } from "redux-saga/effects";

// MeetingRooms Redux States
import { ADD_NEW_MEETING_ROOM, DELETE_MEETING_ROOM, GET_MEETING_ROOMS, UPDATE_MEETING_ROOM } from "./actionTypes";

import {
  addMeetingRoomFail,
  addMeetingRoomSuccess,
  deleteMeetingRoomFail,
  deleteMeetingRoomSuccess,
  getMeetingRoomsFail,
  getMeetingRoomsSuccess,
  updateMeetingRoomFail,
  updateMeetingRoomSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchMeetingRooms() {
  try {
    const response = yield call(getMeetingRooms);
    yield put(getMeetingRoomsSuccess(response));
  } catch (error) {
    yield put(getMeetingRoomsFail(error));
  }
}

const getMeetingRooms = () => get(url.MEETING_ROOM_GET_ALL);

function* onUpdateMeetingRoom({ payload: meetingRoom }) {
  try {
    const response = yield call(updateMeetingRoom, meetingRoom);
    yield put(updateMeetingRoomSuccess(response));
  } catch (error) {
    yield put(updateMeetingRoomFail(error));
  }
}

const updateMeetingRoom = (meetingRoom) => post(url.MEETING_ROOM, meetingRoom);

function* onDeleteMeetingRoom({ id }) {
  try {
    const response = yield call(deleteMeetingRoom, id);
    yield put(deleteMeetingRoomSuccess(response, id));
  } catch (error) {
    yield put(deleteMeetingRoomFail(error));
  }
}

const deleteMeetingRoom = (id) => del(`${url.MEETING_ROOM}/${id}`);

function* onAddNewMeetingRoom({ payload: meetingRoom }) {
  try {
    const response = yield call(addNewMeetingRoom, meetingRoom);
    yield put(addMeetingRoomSuccess(response));
  } catch (error) {
    yield put(addMeetingRoomFail(error));
  }
}

const addNewMeetingRoom = (meetingRoom) => post(url.MEETING_ROOM, meetingRoom);

function* meetingRoomsSaga() {
  yield takeEvery(GET_MEETING_ROOMS, fetchMeetingRooms);
  yield takeEvery(ADD_NEW_MEETING_ROOM, onAddNewMeetingRoom);
  yield takeEvery(UPDATE_MEETING_ROOM, onUpdateMeetingRoom);
  yield takeEvery(DELETE_MEETING_ROOM, onDeleteMeetingRoom);
}

export default meetingRoomsSaga;
