
/* MEETING_ROOMS */
export const GET_MEETING_ROOMS = "GET_MEETING_ROOMS"
export const GET_MEETING_ROOMS_SUCCESS = "GET_MEETING_ROOMS_SUCCESS"
export const GET_MEETING_ROOMS_FAIL = "GET_MEETING_ROOMS_FAIL"

/**
 * add MEETING_ROOM
 */
export const ADD_NEW_MEETING_ROOM = "ADD_NEW_MEETING_ROOM"
export const ADD_MEETING_ROOM_SUCCESS = "ADD_MEETING_ROOM_SUCCESS"
export const ADD_MEETING_ROOM_FAIL = "ADD_MEETING_ROOM_FAIL"

/**
 * Edit MEETING_ROOM
 */
export const UPDATE_MEETING_ROOM = "UPDATE_MEETING_ROOM"
export const UPDATE_MEETING_ROOM_SUCCESS = "UPDATE_MEETING_ROOM_SUCCESS"
export const UPDATE_MEETING_ROOM_FAIL = "UPDATE_MEETING_ROOM_FAIL"

/**
 * Delete MEETING_ROOM
 */
export const DELETE_MEETING_ROOM = "DELETE_MEETING_ROOM"
export const DELETE_MEETING_ROOM_SUCCESS = "DELETE_MEETING_ROOM_SUCCESS"
export const DELETE_MEETING_ROOM_FAIL = "DELETE_MEETING_ROOM_FAIL"

/**
 * Lead MEETING_ROOM
 */
export const GET_LEAD_MEETING_ROOMS = "GET_LEAD_MEETING_ROOMS"
export const GET_LEAD_MEETING_ROOMS_SUCCESS = "GET_LEAD_MEETING_ROOMS_SUCCESS"
export const GET_LEAD_MEETING_ROOMS_FAIL = "GET_LEAD_MEETING_ROOMS_FAIL"


/**
 * Lead MEETING_ROOM
 */
export const GET_USER_MEETING_ROOMS = "GET_USER_MEETING_ROOMS"
export const GET_USER_MEETING_ROOMS_SUCCESS = "GET_USER_MEETING_ROOMS_SUCCESS"
export const GET_USER_MEETING_ROOMS_FAIL = "GET_USER_MEETING_ROOMS_FAIL"

/**
 * Today MEETING_ROOM
 */
export const GET_TODAY_MEETING_ROOMS = "GET_TODAY_MEETING_ROOMS"
export const GET_TODAY_MEETING_ROOMS_SUCCESS = "GET_TODAY_MEETING_ROOMS_SUCCESS"
export const GET_TODAY_MEETING_ROOMS_FAIL = "GET_TODAY_MEETING_ROOMS_FAIL"

/**
 * MEETING_ROOM CRITERIA
 */
export const GET_MEETING_ROOM_CRITERIA = "GET_MEETING_ROOM_CRITERIA"
export const GET_MEETING_ROOM_CRITERIA_SUCCESS = "GET_MEETING_ROOM_CRITERIA_SUCCESS"
export const GET_MEETING_ROOM_CRITERIA_FAIL = "GET_MEETING_ROOM_CRITERIA_FAIL"

/**
 * MEETING_ROOM COUNT
 */
export const GET_MEETING_ROOM_COUNT = "GET_MEETING_ROOM_COUNT"
export const GET_MEETING_ROOM_COUNT_SUCCESS = "GET_MEETING_ROOM_COUNT_SUCCESS"
export const GET_MEETING_ROOM_COUNT_FAIL = "GET_MEETING_ROOM_COUNT_FAIL"