import {
  ADD_MEETING_ROOM_FAIL,
  ADD_MEETING_ROOM_SUCCESS,
  ADD_NEW_MEETING_ROOM,
  DELETE_MEETING_ROOM,
  DELETE_MEETING_ROOM_FAIL,
  DELETE_MEETING_ROOM_SUCCESS,
  GET_MEETING_ROOMS,
  GET_MEETING_ROOMS_FAIL,
  GET_MEETING_ROOMS_SUCCESS,
  UPDATE_MEETING_ROOM,
  UPDATE_MEETING_ROOM_FAIL,
  UPDATE_MEETING_ROOM_SUCCESS
} from "./actionTypes";

export const getMeetingRooms = () => ({
  type: GET_MEETING_ROOMS
});

export const getMeetingRoomsSuccess = meetingRooms => ({
  type: GET_MEETING_ROOMS_SUCCESS,
  payload: meetingRooms
});

export const getMeetingRoomsFail = error => ({
  type: GET_MEETING_ROOMS_FAIL,
  payload: error
});

export const addNewMeetingRoom = meetingRoom => ({
  type: ADD_NEW_MEETING_ROOM,
  payload: meetingRoom
});

export const addMeetingRoomSuccess = meetingRoom => ({
  type: ADD_MEETING_ROOM_SUCCESS,
  payload: meetingRoom
});

export const addMeetingRoomFail = error => ({
  type: ADD_MEETING_ROOM_FAIL,
  payload: error
});

export const updateMeetingRoom = meetingRoom => ({
  type: UPDATE_MEETING_ROOM,
  payload: meetingRoom
});

export const updateMeetingRoomSuccess = meetingRoom => ({
  type: UPDATE_MEETING_ROOM_SUCCESS,
  payload: meetingRoom
});

export const updateMeetingRoomFail = error => ({
  type: UPDATE_MEETING_ROOM_FAIL,
  payload: error
});

export const deleteMeetingRoom = id => ({
  type: DELETE_MEETING_ROOM,
  id
});

export const deleteMeetingRoomSuccess = (meetingRoom,req) => ({
  type: DELETE_MEETING_ROOM_SUCCESS,
  payload: meetingRoom,
  req:req,
});

export const deleteMeetingRoomFail = error => ({
  type: DELETE_MEETING_ROOM_FAIL,
  payload: error
});