import {
    ADD_LEAD_INTEGRATION_DEFINITION,
    ADD_LEAD_INTEGRATION_DEFINITION_FAIL,
    ADD_LEAD_INTEGRATION_DEFINITION_SUCCESS,
    DELETE_LEAD_INTEGRATION_DEFINITION,
    DELETE_LEAD_INTEGRATION_DEFINITION_FAIL,
    DELETE_LEAD_INTEGRATION_DEFINITION_SUCCESS,
    GET_LEAD_INTEGRATION_DEFINITION,
    GET_LEAD_INTEGRATION_DEFINITION_FAIL,
    GET_LEAD_INTEGRATION_DEFINITION_SUCCESS,
    UPDATE_LEAD_INTEGRATION_DEFINITION,
    UPDATE_LEAD_INTEGRATION_DEFINITION_FAIL,
    UPDATE_LEAD_INTEGRATION_DEFINITION_SUCCESS
} from "./actionTypes";

export const getLeadIntegrationDefinitions = () => ({
    type: GET_LEAD_INTEGRATION_DEFINITION,
})

export const getLeadIntegrationDefinitionsSuccess = definitions => ({
    type: GET_LEAD_INTEGRATION_DEFINITION_SUCCESS,
    payload: definitions,
})

export const getLeadIntegrationDefinitionsFail = error => ({
    type: GET_LEAD_INTEGRATION_DEFINITION_FAIL,
    payload: error,
})

export const addNewLeadIntegrationDefinition = definition => ({
    type: ADD_LEAD_INTEGRATION_DEFINITION,
    payload: definition,
})

export const addLeadIntegrationDefinitionSuccess = definition => ({
    type: ADD_LEAD_INTEGRATION_DEFINITION_SUCCESS,
    payload: definition,
})

export const addLeadIntegrationDefinitionFail = error => ({
    type: ADD_LEAD_INTEGRATION_DEFINITION_FAIL,
    payload: error,
})

export const updateLeadIntegrationDefinition = definition => ({
    type: UPDATE_LEAD_INTEGRATION_DEFINITION,
    payload: definition,
})

export const updateLeadIntegrationDefinitionSuccess = definition => ({
    type: UPDATE_LEAD_INTEGRATION_DEFINITION_SUCCESS,
    payload: definition,
})

export const updateLeadIntegrationDefinitionFail = error => ({
    type: UPDATE_LEAD_INTEGRATION_DEFINITION_FAIL,
    payload: error,
})

export const deleteLeadIntegrationDefinition = id => ({
    type: DELETE_LEAD_INTEGRATION_DEFINITION,
    id,
})

export const deleteLeadIntegrationDefinitionSuccess = (definition,req) => ({
    type: DELETE_LEAD_INTEGRATION_DEFINITION_SUCCESS,
    payload: definition,
    req:req,
})

export const deleteLeadIntegrationDefinitionFail = error => ({
    type: DELETE_LEAD_INTEGRATION_DEFINITION_FAIL,
    payload: error,
})