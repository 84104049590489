import React from "react"
import { Container, Row, Col } from "reactstrap"
import version from '../../../package.json'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={3}>{new Date().getFullYear()} © TechLab.</Col>
            <Col md={3}><Link to="/privacy-policy"><i className="bx bx-link-external"/> Privacy Policy</Link></Col>
            <Col md={3}><Link to="/terms-and-conditions"><i className="bx bx-link-external"/> Terms & Conditions</Link></Col>
            <Col md={3}>
              <div className="text-sm-end d-none d-sm-block">
                v{version.version}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
