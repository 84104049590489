import {
    GET_DOCTORS_FAIL,
    GET_DOCTORS_SUCCESS,
    ADD_DOCTOR_SUCCESS,
    ADD_DOCTOR_FAIL,
    UPDATE_DOCTOR_SUCCESS,
    UPDATE_DOCTOR_FAIL,
    DELETE_DOCTOR_SUCCESS,
    DELETE_DOCTOR_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    doctors: [],
    error: {},
};

const Doctor = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DOCTORS_SUCCESS:
            return {
                ...state,
                doctors: action.payload,
            };

        case GET_DOCTORS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_DOCTOR_SUCCESS:
            return {
                ...state,
                doctors: [...state.doctors, action.payload],
            };

        case ADD_DOCTOR_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_DOCTOR_SUCCESS:
            return {
                ...state,
                doctors: state.doctors.map(doctor =>
                    doctor.id.toString() === action.payload.id.toString()
                        ? { doctor, ...action.payload }
                        : doctor
                ),
            };

        case UPDATE_DOCTOR_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_DOCTOR_SUCCESS:
            return {
                ...state,
                doctors: state.doctors.filter(
                    doctor => doctor.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_DOCTOR_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default Doctor;
