import React from "react";
import PropTypes from "prop-types";
import withRouter from "../hooks/withRouter";
import LanguageDropdown from "./CommonForBoth/TopbarDropdown/LanguageDropdown";

const NonAuthLayoutWithLanguage = (props) => {
  return (
    <React.Fragment>
      <header id="language-drop">
        <div className="text-end bg-white">
          <LanguageDropdown />
        </div>
      </header>
      {props.children}
    </React.Fragment>
  );
};

NonAuthLayoutWithLanguage.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
};
export default withRouter(NonAuthLayoutWithLanguage);

