import {
  ADD_PATIENT_FAIL,
  ADD_PATIENT_SUCCESS,
  ADD_NEW_PATIENT,
  ASSIGN_DOCTOR,
  ASSIGN_DOCTOR_FAIL,
  ASSIGN_DOCTOR_SUCCESS,
  DELETE_PATIENT,
  DELETE_PATIENT_FAIL,
  DELETE_PATIENT_SUCCESS,
  GET_PATIENT_BY_ID,
  GET_PATIENT_BY_ID_FAIL,
  GET_PATIENT_BY_ID_SUCCESS,
  GET_PATIENTS,
  GET_PATIENTS_FAIL,
  GET_PATIENTS_SUCCESS,
  PATIENT_CRITERIA,
  PATIENT_CRITERIA_FAIL,
  PATIENT_CRITERIA_SUCCESS,
  UPDATE_PATIENT,
  UPDATE_PATIENT_FAIL,
  UPDATE_PATIENT_SUCCESS, ADD_NEW_PATIENT_WITH_APPT, ADD_NEW_PATIENT_WITH_APPT_SUCCESS, ADD_NEW_PATIENT_WITH_APPT_FAIL
} from "./actionTypes";

export const getPatients = () => ({
  type: GET_PATIENTS
});

export const getPatientsSuccess = patients => ({
  type: GET_PATIENTS_SUCCESS,
  payload: patients
});

export const getPatientsFail = error => ({
  type: GET_PATIENTS_FAIL,
  payload: error
});

export const addNewPatient = patient => ({
  type: ADD_NEW_PATIENT,
  payload: patient
});

export const addPatientSuccess = patient => ({
  type: ADD_PATIENT_SUCCESS,
  payload: patient
});

export const addPatientFail = error => ({
  type: ADD_PATIENT_FAIL,
  payload: error
});

export const updatePatient = patient => ({
  type: UPDATE_PATIENT,
  payload: patient
});

export const updatePatientSuccess = patient => ({
  type: UPDATE_PATIENT_SUCCESS,
  payload: patient
});

export const updatePatientFail = error => ({
  type: UPDATE_PATIENT_FAIL,
  payload: error
});

export const deletePatient = patient => ({
  type: DELETE_PATIENT,
  payload: patient
});

export const deletePatientSuccess = patient => ({
  type: DELETE_PATIENT_SUCCESS,
  payload: patient
});

export const deletePatientFail = error => ({
  type: DELETE_PATIENT_FAIL,
  payload: error
});

export const getPatientById = id => ({
  type: GET_PATIENT_BY_ID,
  id
});

export const getPatientByIdSuccess = patient => ({
  type: GET_PATIENT_BY_ID_SUCCESS,
  payload: patient
});

export const getPatientByIdFail = error => ({
  type: GET_PATIENT_BY_ID_FAIL,
  payload: error
});

export const assignDoctor = req => ({
  type: ASSIGN_DOCTOR,
  payload: req
});

export const assignDoctorSuccess = patient => ({
  type: ASSIGN_DOCTOR_SUCCESS,
  payload: patient
});

export const assignDoctorFail = error => ({
  type: ASSIGN_DOCTOR_FAIL,
  payload: error
});

export const patientCriteria = res => ({
  type: PATIENT_CRITERIA,
  payload: res
});

export const patientCriteriaSuccess = (res) => ({
  type: PATIENT_CRITERIA_SUCCESS,
  payload: res
});

export const patientCriteriaFail = error => ({
  type: PATIENT_CRITERIA_FAIL,
  payload: error
});