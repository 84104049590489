import {
  ADD_MEETING_ROOM_FAIL,
  ADD_MEETING_ROOM_SUCCESS,
  DELETE_MEETING_ROOM_FAIL,
  DELETE_MEETING_ROOM_SUCCESS,
  GET_LEAD_MEETING_ROOMS_FAIL,
  GET_LEAD_MEETING_ROOMS_SUCCESS,
  GET_MEETING_ROOM_COUNT_FAIL,
  GET_MEETING_ROOM_COUNT_SUCCESS,
  GET_MEETING_ROOM_CRITERIA_FAIL,
  GET_MEETING_ROOM_CRITERIA_SUCCESS,
  GET_MEETING_ROOMS_FAIL,
  GET_MEETING_ROOMS_SUCCESS,
  GET_TODAY_MEETING_ROOMS_FAIL,
  GET_TODAY_MEETING_ROOMS_SUCCESS,
  GET_USER_MEETING_ROOMS_FAIL,
  GET_USER_MEETING_ROOMS_SUCCESS,
  UPDATE_MEETING_ROOM_FAIL,
  UPDATE_MEETING_ROOM_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  meetingRooms: [],
  meetingRoomCount: 0,
  error: {}
};

const MeetingRoom = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEETING_ROOMS_SUCCESS:
      return {
        ...state,
        meetingRooms: action.payload
      };

    case GET_MEETING_ROOMS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_LEAD_MEETING_ROOMS_SUCCESS:
      return {
        ...state,
        meetingRooms: action.payload
      };

    case GET_LEAD_MEETING_ROOMS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_USER_MEETING_ROOMS_SUCCESS:
      return {
        ...state,
        meetingRooms: action.payload
      };

    case GET_USER_MEETING_ROOMS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_MEETING_ROOM_SUCCESS:
      return {
        ...state,
        meetingRooms: [...state.meetingRooms, action.payload]
      };

    case ADD_MEETING_ROOM_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_MEETING_ROOM_SUCCESS:
      return {
        ...state,
        meetingRooms: state.meetingRooms.map(meetingRoom =>
          meetingRoom.id.toString() === action.payload.id.toString()
            ? { meetingRoom, ...action.payload }
            : meetingRoom
        )
      };

    case UPDATE_MEETING_ROOM_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_MEETING_ROOM_SUCCESS:
      return {
        ...state,
        meetingRooms: state.meetingRooms.filter(
          meetingRoom => meetingRoom.id.toString() !== action.req.toString()
        )
      };

    case DELETE_MEETING_ROOM_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_TODAY_MEETING_ROOMS_SUCCESS:
      return {
        ...state,
        meetingRooms: action.payload
      };

    case GET_TODAY_MEETING_ROOMS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_MEETING_ROOM_CRITERIA_SUCCESS:
      return {
        ...state,
        meetingRooms: action.payload.content
      };

    case GET_MEETING_ROOM_CRITERIA_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_MEETING_ROOM_COUNT_SUCCESS:
      return {
        ...state,
        meetingRoomCount: action.payload
      };

    case GET_MEETING_ROOM_COUNT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return {
        ...state
      };
  }
};

export default MeetingRoom;
