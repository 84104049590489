import PropTypes from "prop-types";
import React, {useCallback, useEffect, useRef, useState} from "react";

import {MetisMenu} from "metismenujs";
import withRouter from "../../hooks/withRouter";

//i18n
import {withTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import Menu from "./Menu";
import SimpleBar from "simplebar-react";

const SidebarContent = props => {
    const ref = useRef();
    const [isProcessingCompleted, setIsProcessingCompleted] = useState(false);

    const activateParentDropdown = useCallback((item) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag
                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }, []);

    const removeActivation = (items) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;
            if (item.classList.contains("has-arrow")) {
                const parentChild = parent.childNodes[1];
                parentChild.classList.add("mm-collapse");
            }

            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.length && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null;
                if (parent2El && parent2El.id !== "side-menu") {
                    parent2El.classList.remove("mm-show");
                }

                parent.classList.remove("mm-active");
                const parent2 = parent.parentElement;
                if (parent2) {
                    parent2.classList.remove("mm-show");
                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove("mm-active"); // li
                        parent3.childNodes[0].classList.remove("mm-active");

                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.remove("mm-show"); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove("mm-show"); // li
                                parent5.childNodes[0].classList.remove("mm-active"); // a tag
                            }
                        }
                    }
                }
            }
        }
    };

    const path = useLocation();
    const activeMenu = useCallback(() => {
        if (isProcessingCompleted) {
            const pathName = path.pathname;
            let matchingMenuItem = null;
            const ul = document.getElementById("sidebar-menu");
            const items = ul.getElementsByTagName("a");

            removeActivation(items);
            for (let i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname && items[i].pathname !== "/") {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
    }, [path, isProcessingCompleted]);

    useEffect(() => {
        new MetisMenu("#side-menu");
    }, []);

    useEffect(() => {
        activeMenu();
    }, [activeMenu]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        activeMenu();
    }, [activeMenu]);

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }

    const callbackRendered = useCallback((e) => {
        setIsProcessingCompleted(e)
    }, [])

    const handleAriaExpanded = useCallback((e) => {
        e.preventDefault();
        let targetObj;
        if (e.target.nodeName === "A") {
            targetObj = e.target;
        } else if (e.target.nodeName === "SPAN" || e.target.nodeName === "I") {
            targetObj = e.target.parentElement;
        } else {
            targetObj = e.target.childNodes[0];
        }

        const parent = targetObj.parentElement;
        const parent2El = parent.childNodes[1] && parent.childNodes[1].children[0] && parent.childNodes[1].children[0].childNodes[0];

        const collapsible = parent.childNodes[1]
        if (parent2El.classList.contains("active")) {
            const ul = document.getElementById("sidebar-menu");
            const items = ul.getElementsByTagName("a");
            collapsible.classList.remove("mm-show")
            collapsible.classList.add("mm-collapse")
            removeActivation(items)
        } else {
            collapsible.classList.add("mm-show")
            activateParentDropdown(parent2El);
        }

    }, []);

    return (
        <SimpleBar className="h-100" ref={ref}>
            <div id="sidebar-menu">
                <Menu id="generated-menu" handleAriaExpanded={handleAriaExpanded} t={props.t}
                      callbackRendered={callbackRendered}/>
            </div>
        </SimpleBar>
    );
};

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any
};

export default withRouter(withTranslation()(SidebarContent));
