
/* OPERATION_DEFINITIONS */
export const GET_OPERATION_DEFINITIONS = "GET_OPERATION_DEFINITIONS"
export const GET_OPERATION_DEFINITIONS_SUCCESS = "GET_OPERATION_DEFINITIONS_SUCCESS"
export const GET_OPERATION_DEFINITIONS_FAIL = "GET_OPERATION_DEFINITIONS_FAIL"

/**
 * add OPERATION_DEFINITION
 */
export const ADD_NEW_OPERATION_DEFINITION = "ADD_NEW_OPERATION_DEFINITION"
export const ADD_OPERATION_DEFINITION_SUCCESS = "ADD_OPERATION_DEFINITION_SUCCESS"
export const ADD_OPERATION_DEFINITION_FAIL = "ADD_OPERATION_DEFINITION_FAIL"

/**
 * Edit OPERATION_DEFINITION
 */
export const UPDATE_OPERATION_DEFINITION = "UPDATE_OPERATION_DEFINITION"
export const UPDATE_OPERATION_DEFINITION_SUCCESS = "UPDATE_OPERATION_DEFINITION_SUCCESS"
export const UPDATE_OPERATION_DEFINITION_FAIL = "UPDATE_OPERATION_DEFINITION_FAIL"

/**
 * Delete OPERATION_DEFINITION
 */
export const DELETE_OPERATION_DEFINITION = "DELETE_OPERATION_DEFINITION"
export const DELETE_OPERATION_DEFINITION_SUCCESS = "DELETE_OPERATION_DEFINITION_SUCCESS"
export const DELETE_OPERATION_DEFINITION_FAIL = "DELETE_OPERATION_DEFINITION_FAIL"
