
/* APPOINTMENTS */
export const GET_APPOINTMENTS = "GET_APPOINTMENTS"
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS"
export const GET_APPOINTMENTS_FAIL = "GET_APPOINTMENTS_FAIL"

/**
 * add APPOINTMENT
 */
export const ADD_NEW_APPOINTMENT = "ADD_NEW_APPOINTMENT"
export const ADD_APPOINTMENT_SUCCESS = "ADD_APPOINTMENT_SUCCESS"
export const ADD_APPOINTMENT_FAIL = "ADD_APPOINTMENT_FAIL"

/**
 * Edit APPOINTMENT
 */
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT"
export const UPDATE_APPOINTMENT_SUCCESS = "UPDATE_APPOINTMENT_SUCCESS"
export const UPDATE_APPOINTMENT_FAIL = "UPDATE_APPOINTMENT_FAIL"

/**
 * Delete APPOINTMENT
 */
export const DELETE_APPOINTMENT = "DELETE_APPOINTMENT"
export const DELETE_APPOINTMENT_SUCCESS = "DELETE_APPOINTMENT_SUCCESS"
export const DELETE_APPOINTMENT_FAIL = "DELETE_APPOINTMENT_FAIL"

/**
 * Lead APPOINTMENT
 */
export const GET_LEAD_APPOINTMENTS = "GET_LEAD_APPOINTMENTS"
export const GET_LEAD_APPOINTMENTS_SUCCESS = "GET_LEAD_APPOINTMENTS_SUCCESS"
export const GET_LEAD_APPOINTMENTS_FAIL = "GET_LEAD_APPOINTMENTS_FAIL"


/**
 * Lead APPOINTMENT
 */
export const GET_USER_APPOINTMENTS = "GET_USER_APPOINTMENTS"
export const GET_USER_APPOINTMENTS_SUCCESS = "GET_USER_APPOINTMENTS_SUCCESS"
export const GET_USER_APPOINTMENTS_FAIL = "GET_USER_APPOINTMENTS_FAIL"

/**
 * Today APPOINTMENT
 */
export const GET_TODAY_APPOINTMENTS = "GET_TODAY_APPOINTMENTS"
export const GET_TODAY_APPOINTMENTS_SUCCESS = "GET_TODAY_APPOINTMENTS_SUCCESS"
export const GET_TODAY_APPOINTMENTS_FAIL = "GET_TODAY_APPOINTMENTS_FAIL"


/**
 * APPT CRITERIA
 */
export const GET_APPOINTMENT_CRITERIA = "GET_APPOINTMENT_CRITERIA"
export const GET_APPOINTMENT_CRITERIA_SUCCESS = "GET_APPOINTMENT_CRITERIA_SUCCESS"
export const GET_APPOINTMENT_CRITERIA_FAIL = "GET_APPOINTMENT_CRITERIA_FAIL"