
/* MEETING_ROOM_RESERVATIONS */
export const GET_MEETING_ROOM_RESERVATIONS = "GET_MEETING_ROOM_RESERVATIONS"
export const GET_MEETING_ROOM_RESERVATIONS_SUCCESS = "GET_MEETING_ROOM_RESERVATIONS_SUCCESS"
export const GET_MEETING_ROOM_RESERVATIONS_FAIL = "GET_MEETING_ROOM_RESERVATIONS_FAIL"

/**
 * add MEETING_ROOM_RESERVATION
 */
export const ADD_NEW_MEETING_ROOM_RESERVATION = "ADD_NEW_MEETING_ROOM_RESERVATION"
export const ADD_MEETING_ROOM_RESERVATION_SUCCESS = "ADD_MEETING_ROOM_RESERVATION_SUCCESS"
export const ADD_MEETING_ROOM_RESERVATION_FAIL = "ADD_MEETING_ROOM_RESERVATION_FAIL"

/**
 * Edit MEETING_ROOM_RESERVATION
 */
export const UPDATE_MEETING_ROOM_RESERVATION = "UPDATE_MEETING_ROOM_RESERVATION"
export const UPDATE_MEETING_ROOM_RESERVATION_SUCCESS = "UPDATE_MEETING_ROOM_RESERVATION_SUCCESS"
export const UPDATE_MEETING_ROOM_RESERVATION_FAIL = "UPDATE_MEETING_ROOM_RESERVATION_FAIL"

/**
 * Delete MEETING_ROOM_RESERVATION
 */
export const DELETE_MEETING_ROOM_RESERVATION = "DELETE_MEETING_ROOM_RESERVATION"
export const DELETE_MEETING_ROOM_RESERVATION_SUCCESS = "DELETE_MEETING_ROOM_RESERVATION_SUCCESS"
export const DELETE_MEETING_ROOM_RESERVATION_FAIL = "DELETE_MEETING_ROOM_RESERVATION_FAIL"

/**
 * Lead MEETING_ROOM_RESERVATION
 */
export const GET_USER_MEETING_ROOM_RESERVATIONS = "GET_USER_MEETING_ROOM_RESERVATIONS"
export const GET_USER_MEETING_ROOM_RESERVATIONS_SUCCESS = "GET_USER_MEETING_ROOM_RESERVATIONS_SUCCESS"
export const GET_USER_MEETING_ROOM_RESERVATIONS_FAIL = "GET_USER_MEETING_ROOM_RESERVATIONS_FAIL"

/**
 * Today MEETING_ROOM_RESERVATION
 */
export const GET_MEETING_ROOM_RESERVATIONS_BY_ROOM = "GET_MEETING_ROOM_RESERVATIONS_BY_ROOM"
export const GET_MEETING_ROOM_RESERVATIONS_BY_ROOM_SUCCESS = "GET_MEETING_ROOM_RESERVATIONS_BY_ROOM_SUCCESS"
export const GET_MEETING_ROOM_RESERVATIONS_BY_ROOM_FAIL = "GET_MEETING_ROOM_RESERVATIONS_BY_ROOM_FAIL"