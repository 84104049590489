import React from "react";

export const menu = [
  {
    id: "dashboard",
    label: "Dashboard",
    path: "/dashboard",
    icon: "bx bx-home-circle"
  },
  {
    id: "admin-dashboard",
    label: "Dashboard",
    path: "/admin-dashboard",
    icon: "bx bx-home-circle"
  },
  {
    id: "doctor-dashboard",
    label: "Dashboard",
    path: "/doctor-dashboard",
    icon: "bx bx-home-circle"
  },
  {
    id: "calendar",
    label: "Calendar",
    path: "/calendar",
    icon: "bx bxs-calendar-event"
  },
  {
    id: "leads",
    label: "Leads",
    path: "/#",
    icon: "bx bx-user-circle",
    children: [
      {
        id: "leads",
        label: "Leads List",
        path: "/leads",
        icon: "bx bxs-detail"
      }, {
        id: "lead-import",
        label: "Lead Import",
        path: "/lead-import",
        icon: "bx bxs-layer-plus"
      }, {
        id: "reminders",
        label: "Reminders",
        path: "/reminders",
        icon: "mdi mdi-calendar-alert"
      },
      {
        id: "appointments",
        label: "Appointments",
        path: "/appointments",
        icon: "bx bxs-calendar-alt"
      },]
  },
  {
    id: "users",
    label: "Users",
    path: "/#",
    icon: "bx bxs-user",
    children: [
      {
        id: "userList",
        label: "Users List",
        path: "/users-list",
        icon: "bx bx-user-check"
      }, {
        id: "users-profile",
        label: "User Profile",
        path: "/users-profile",
        icon: "bx bxs-user-detail"
      },
      {
        id: "group",
        label: "Groups",
        path: "/group",
        icon: "bx bx-group"
      },
      {
        id: "department",
        label: "Departments",
        path: "/department",
        icon: "bx bx-clinic"
      },
      {
        id: "section",
        label: "Section",
        path: "/section",
        icon: "bx bx-dock-top"
      }
      ]
  },
  {
    id: "patients",
    label: "Patients",
    path: "/#",
    icon: "bx bx-user",
    children: [
      {
        id: "patientList",
        label: "Patient List",
        path: "/patients",
        icon: "bx bx-user-check",
      },
      {
        id: "patientAppointments",
        label: "Patient Appointments",
        path: "/patient-appointment",
        icon: "bx bx-bookmarks"
      },
    ]
  },
  {
    id: "parameters",
    label: "Parameters",
    path: "/#",
    icon: "bx bx-tone",
    children: [
      {
        id: "segments",
        label: "Segments",
        path: "/segments",
        icon: "bx bxs-detail"
      },
      {
        id: "categories",
        label: "Categories",
        path: "/categories",
        icon: "bx bx-tone"
      },
      {
        id: "resources",
        label: "Resources",
        path: "/resources",
        icon: "bx bx-store"
      },
      {
        id: "references",
        label: "References",
        path: "/references",
        icon: "bx bx-radar"
      },
      {
        id: "system-parameter",
        label: "System Parameters",
        path: "/system-parameter",
        icon: "bx bx-paperclip"
      },
      {
        id: "languages",
        label: "Languages",
        path: "/languages",
        icon: "bx bxs-landmark"
      }]
  },
  {
    id: "meetingRoom",
    label: "Meeting Operations",
    path: "/#",
    icon: "bx bx-cloud-lightning",
    children: [
      {
        id: "meetingRoom",
        label: "Meeting Room",
        path: "/meeting-room",
        icon: "bx bx-package"
      }, {
        id: "meetingRoomReservation",
        label: "Meeting Reservations",
        path: "/meeting-room-reservation",
        icon: "bx bx-flag"
      },
    ]
  },
  {
    id: "reports",
    label: "Reports",
    path: "/#",
    icon: "bx bxs-report",
    children: [
      {
        id: "userReports",
        label: "User Reports",
        path: "/reports",
        icon: "bx bx-paperclip"
      },
      {
        id: "reportList",
        label: "Report List",
        path: "/report-list",
        icon: "bx bx-list-ol"
      }
    ]
  },
  {
    id: "integrationSettings",
    label: "Integration Settings",
    path: "/#",
    icon: "bx bxs-analyse",
    children: [
      {
        id: "forms",
        label: "Facebook Forms",
        path: "/forms",
        icon: "bx bx-file"
      },
      {
        id: "integrationDefinition",
        label: "Integration Definition",
        path: "/integration-definition",
        icon: "bx bx-folder"
      },
      {
        id: "integration",
        label: "Integration",
        path: "/integration",
        icon: "bx bxl-facebook-circle"
      },
      {
        id: "leadIntegrationDefinition",
        label: "Lead Integration Definition",
        path: "/lead-integration-definition",
        icon: "bx bxl-unsplash"
      }
    ]
  },
  {
    id: "operation",
    label: "Operation",
    path: "/#",
    icon: "bx bx-health",
    children: [
      {
        id: "doctor",
        label: "Doctor",
        path: "/doctor",
        icon: "bx bxs-star"
      },
      {
        id: "hospital",
        label: "Hospital",
        path: "/hospital",
        icon: "bx bx-home-alt"
      },
      {
        id: "operationCategory",
        label: "Operation Category",
        path: "/operation-category",
        icon: "bx bx-dock-top"
      },
      {
        id: "operationDefinition",
        label: "Operation Definition",
        path: "/operation-definition",
        icon: "bx bxs-detail"
      }
    ]
  },
  {
    id: "offer",
    label: "Offer",
    path: "/#",
    icon: "bx bxs-adjust-alt",
    children: [
      {
        id: "offer",
        label: "Offer",
        path: "/offer",
        icon: "bx bxs-offer"
      },
      {
        id: "offerItem",
        label: "Offer Item",
        path: "/offer-item",
        icon: "bx bx-list-ol"
      },
      {
        id: "descriptionTemplate",
        label: "Description Template",
        path: "/description-template",
        icon: "bx bxs-landscape"
      }
    ]
  },
  {
    id: "history",
    label: "History",
    path: "/#",
    icon: "bx bx-history",
    children: [
      {
        id: "leadHistory",
        label: "Lead History",
        path: "/lead-history",
        icon: "bx bx-user-check"
      },
      {
        id: "userLoginHistory",
        label: "User Login History",
        path: "/user-login-history",
        icon: "bx bxs-log-in-circle"
      },
      {
        id: "facebookLoginHistory",
        label: "Facebook Login History",
        path: "/facebook-login-history",
        icon: "bx bx-log-in"
      }
    ]
  },
  {
    id: "translate",
    label: "Translate",
    path: "/translate",
    icon: "bx bx-tone"
  },
  {/*  {
    id: "privileges",
    label: "Privileges",
    path: "/role",
    icon: "bx bx-lock-open"
  },
  */},
  {
    id: "paymentOperations",
    label: "Payment Operations",
    path: "/#",
    icon: "bx bx-credit-card",
    children: [
      {
        id: "paymentList",
        label: "Payment Definition List",
        path: "/payment-list",
        icon: "bx bx-list-ol"
      },
      {
        id: "guardPayment",
        label: "Payment",
        path: "/payment",
        icon: "bx bx-money"
      }
    ]
  },
  {
    id: "firmInfo",
    label: "Firm Info",
    path: "/firm-info",
    icon: "bx bx-collection"
  }
];