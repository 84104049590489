import {
    GET_PLATFORMS_FAIL,
    GET_PLATFORMS_SUCCESS,
    ADD_PLATFORM_SUCCESS,
    ADD_PLATFORM_FAIL,
    UPDATE_PLATFORM_SUCCESS,
    UPDATE_PLATFORM_FAIL,
    DELETE_PLATFORM_SUCCESS,
    DELETE_PLATFORM_FAIL,
} from "./actionTypes";

const INIT_STATE = {
   platforms: [],
    error: {},
};

const Platforms = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLATFORMS_SUCCESS:
            return {
                ...state,
               platforms: action.payload,
            };

        case GET_PLATFORMS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_PLATFORM_SUCCESS:
            return {
                ...state,
               platforms: [...state.forms, action.payload],
            };

        case ADD_PLATFORM_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_PLATFORM_SUCCESS:
            return {
                ...state,
               platforms: state.forms.map(form =>
                   platform.id.toString() === action.payload.id.toString()
                        ? {platform, ...action.payload }
                        :platform
                ),
            };

        case UPDATE_PLATFORM_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_PLATFORM_SUCCESS:
            return {
                ...state,
               platforms: state.forms.filter(
                   platform =>platform.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_PLATFORM_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default Platforms;
