
/* TRANSLATES */
export const GET_TRANSLATES = "GET_TRANSLATES"
export const GET_TRANSLATES_SUCCESS = "GET_TRANSLATES_SUCCESS"
export const GET_TRANSLATES_FAIL = "GET_TRANSLATES_FAIL"

/**
 * add TRANSLATE
 */
export const ADD_NEW_TRANSLATE = "ADD_NEW_TRANSLATE"
export const ADD_TRANSLATE_SUCCESS = "ADD_TRANSLATE_SUCCESS"
export const ADD_TRANSLATE_FAIL = "ADD_TRANSLATE_FAIL"

/**
 * Edit TRANSLATE
 */
export const UPDATE_TRANSLATE = "UPDATE_TRANSLATE"
export const UPDATE_TRANSLATE_SUCCESS = "UPDATE_TRANSLATE_SUCCESS"
export const UPDATE_TRANSLATE_FAIL = "UPDATE_TRANSLATE_FAIL"

/**
 * Delete TRANSLATE
 */
export const DELETE_TRANSLATE = "DELETE_TRANSLATE"
export const DELETE_TRANSLATE_SUCCESS = "DELETE_TRANSLATE_SUCCESS"
export const DELETE_TRANSLATE_FAIL = "DELETE_TRANSLATE_FAIL"

/**
 * TRANSLATE Criteria
 */
export const TRANSLATE_CRITERIA = "TRANSLATE_CRITERIA"
export const TRANSLATE_CRITERIA_SUCCESS = "TRANSLATE_CRITERIA_SUCCESS"
export const TRANSLATE_CRITERIA_FAIL = "TRANSLATE_CRITERIA_FAIL"

/**
 * TRANSLATE Criteria
 */
export const SAVE_ALL_TRANSLATES = "SAVE_ALL_TRANSLATES"
export const SAVE_ALL_TRANSLATES_SUCCESS = "SAVE_ALL_TRANSLATES_SUCCESS"
export const SAVE_ALL_TRANSLATES_FAIL = "SAVE_ALL_TRANSLATES_FAIL"
