import { call, put, takeEvery } from "redux-saga/effects";

// Parameters Redux States
import { ADD_NEW_PARAMETER, DELETE_PARAMETER, GET_PARAMETERS, UPDATE_PARAMETER } from "./actionTypes";

import {
    addParameterFail,
    addParameterSuccess,
    deleteParameterFail,
    deleteParameterSuccess,
    getParametersFail,
    getParametersSuccess,
    updateParameterFail,
    updateParameterSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { get, post, del } from "../../helpers/api_helper";

function* fetchParameters() {
    try {
        const response = yield call(getParameters);
        yield put(getParametersSuccess(response));
    } catch (error) {
        yield put(getParametersFail(error));
    }
}

const getParameters = () => get(url.GET_PARAMETERS)

function* onUpdateParameter({ payload: parameter }) {
    try {
        const response = yield call(updateParameter, parameter);
        yield put(updateParameterSuccess(response));
    } catch (error) {
        yield put(updateParameterFail(error));
    }
}

const updateParameter = (parameter) => post(url.UPDATE_PARAMETER,parameter)

function* onDeleteParameter({id}) {
    try {
        const response = yield call(deleteParameter, id);
        yield put(deleteParameterSuccess(response));
    } catch (error) {
        yield put(deleteParameterFail(error));
    }
}

const deleteParameter = (id) => del(`${url.DELETE_PARAMETER}/${id}`);

function* onAddNewParameter({ payload: parameter }) {
    try {
        const response = yield call(addNewParameter, parameter);

        yield put(addParameterSuccess(response));
    } catch (error) {
        yield put(addParameterFail(error));
    }
}

const addNewParameter = (parameter) => post(url.ADD_NEW_PARAMETER,parameter);

function* parametersSaga() {
    yield takeEvery(GET_PARAMETERS, fetchParameters);
    yield takeEvery(ADD_NEW_PARAMETER, onAddNewParameter);
    yield takeEvery(UPDATE_PARAMETER, onUpdateParameter);
    yield takeEvery(DELETE_PARAMETER, onDeleteParameter);
}

export default parametersSaga;
