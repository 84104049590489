import {
  GET_NOTES,
  GET_NOTES_FAIL,
  GET_NOTES_SUCCESS,
  ADD_NEW_NOTE,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_FAIL,
  UPDATE_NOTE,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAIL,
  DELETE_NOTE,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAIL, GET_LEAD_NOTES, GET_LEAD_NOTES_SUCCESS, GET_LEAD_NOTES_FAIL
} from "./actionTypes";

export const getNotes = () => ({
  type: GET_NOTES
});

export const getNotesSuccess = notes => ({
  type: GET_NOTES_SUCCESS,
  payload: notes
});

export const getNotesFail = error => ({
  type: GET_NOTES_FAIL,
  payload: error
});

export const addNewNote = note => ({
  type: ADD_NEW_NOTE,
  payload: note
});

export const addNoteSuccess = note => ({
  type: ADD_NOTE_SUCCESS,
  payload: note
});

export const addNoteFail = error => ({
  type: ADD_NOTE_FAIL,
  payload: error
});

export const updateNote = note => ({
  type: UPDATE_NOTE,
  payload: note
});

export const updateNoteSuccess = note => ({
  type: UPDATE_NOTE_SUCCESS,
  payload: note
});

export const updateNoteFail = error => ({
  type: UPDATE_NOTE_FAIL,
  payload: error
});

export const deleteNote = id => ({
  type: DELETE_NOTE,
  id
});

export const deleteNoteSuccess = (note, req) => ({
  type: DELETE_NOTE_SUCCESS,
  payload: note,
  req: req
});

export const deleteNoteFail = error => ({
  type: DELETE_NOTE_FAIL,
  payload: error
});

export const getLeadNotes = id => ({
  type: GET_LEAD_NOTES,
  id
});

export const getLeadNotesSuccess = note => ({
  type: GET_LEAD_NOTES_SUCCESS,
  payload: note
});

export const getLeadNotesFail = error => ({
  type: GET_LEAD_NOTES_FAIL,
  payload: error
});