import React from "react";
import { formatDateForScreen } from "../../common/commonFunctions";

const appointmentId = (cell) => {
  return cell.value ? cell.value : "";
};

const CreateUser = (cell) => {
  return cell.value ? cell.value : "";
};

const ApptDate = (cell) => {
  return cell.value ? formatDateForScreen(cell.value) : "";
};

const Status = (cell) => {
  return cell.value ? cell.value : "";
};

const Description = (cell) => {
  return cell.value ? cell.value : "";
};

export {
  appointmentId,
  CreateUser,
  ApptDate,
  Status,
  Description
};