import {
  ADD_SCREEN_FAIL,
  ADD_SCREEN_SUCCESS,
  DELETE_SCREEN_FAIL,
  DELETE_SCREEN_SUCCESS, GET_SCREENS_FAIL, GET_SCREENS_SUCCESS,
  UPDATE_SCREEN_FAIL,
  UPDATE_SCREEN_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  screens: [],
  error: {},
};

const Screen = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SCREENS_SUCCESS:
      return {
        ...state,
        screens: [...state.screens, action.payload],
      };

    case GET_SCREENS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_SCREEN_SUCCESS:
      return {
        ...state,
        screens: [...state.screens, action.payload],
      };

    case ADD_SCREEN_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_SCREEN_SUCCESS:
      return {
        ...state,
        screens: state.screens.map(screen =>
          screen.id.toString() === action.payload.id.toString()
            ? { screen, ...action.payload }
            : screen
        ),
      };

    case UPDATE_SCREEN_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_SCREEN_SUCCESS:
      return {
        ...state,
        screens: state.screens.filter(
          screen => screen.id.toString() !== action.req.toString()
        ),
      };

    case DELETE_SCREEN_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Screen;
