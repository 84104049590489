import {
  ADD_NEW_PATIENT_WITH_APPT_FAIL,
  ADD_NEW_PATIENT_WITH_APPT_SUCCESS,
  ADD_PATIENT_APPOINTMENT_FAIL,
  ADD_PATIENT_APPOINTMENT_SUCCESS,
  DELETE_PATIENT_APPOINTMENT_FAIL,
  DELETE_PATIENT_APPOINTMENT_SUCCESS,
  GET_LEAD_PATIENT_APPOINTMENTS_FAIL,
  GET_LEAD_PATIENT_APPOINTMENTS_SUCCESS,
  GET_PATIENT_APPOINTMENT_CRITERIA_FAIL,
  GET_PATIENT_APPOINTMENT_CRITERIA_SUCCESS,
  GET_PATIENT_APPOINTMENTS_FAIL,
  GET_PATIENT_APPOINTMENTS_SUCCESS,
  GET_TODAY_PATIENT_APPOINTMENTS_FAIL,
  GET_TODAY_PATIENT_APPOINTMENTS_SUCCESS,
  GET_USER_PATIENT_APPOINTMENTS_FAIL,
  GET_USER_PATIENT_APPOINTMENTS_SUCCESS,
  UPDATE_PATIENT_APPOINTMENT_FAIL,
  UPDATE_PATIENT_APPOINTMENT_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  patientAppointments: [],
  error: {},
  success: false
};

const PatientAppointment = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PATIENT_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        patientAppointments: action.payload
      };

    case GET_PATIENT_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_LEAD_PATIENT_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        patientAppointments: action.payload
      };

    case GET_LEAD_PATIENT_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_USER_PATIENT_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        patientAppointments: action.payload
      };

    case GET_USER_PATIENT_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_PATIENT_APPOINTMENT_SUCCESS:
      return {
        ...state,
        patientAppointments: [...state.patientAppointments, action.payload],
        success: true
      };

    case ADD_PATIENT_APPOINTMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_PATIENT_APPOINTMENT_SUCCESS:
      return {
        ...state,
        success: true,
        patientAppointments: state.patientAppointments.map(patientAppointment =>
          patientAppointment.id.toString() === action.payload.id.toString()
            ? action.payload
            : patientAppointment
        )
      };

    case UPDATE_PATIENT_APPOINTMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_PATIENT_APPOINTMENT_SUCCESS:
      return {
        ...state,
        success: true,
        patientAppointments: state.patientAppointments.filter(
          patientAppointment => patientAppointment.id.toString() !== action.req.toString()
        )
      };

    case DELETE_PATIENT_APPOINTMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_TODAY_PATIENT_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        patientAppointments: action.payload
      };

    case GET_TODAY_PATIENT_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_PATIENT_APPOINTMENT_CRITERIA_SUCCESS:
      return {
        ...state,
        patientAppointments: action.payload.content
      };

    case GET_PATIENT_APPOINTMENT_CRITERIA_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_NEW_PATIENT_WITH_APPT_SUCCESS:
      return {
        ...state,
        patientAppointments: [action.payload, ...state.patientAppointments],
        success: true
      };

    case ADD_NEW_PATIENT_WITH_APPT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return {
        ...state,
        success: false,
        error: {}
      };
  }
};

export default PatientAppointment;
