import {
    GET_SEGMENTS_FAIL,
    GET_SEGMENTS_SUCCESS,
    ADD_SEGMENT_SUCCESS,
    ADD_SEGMENT_FAIL,
    UPDATE_SEGMENT_SUCCESS,
    UPDATE_SEGMENT_FAIL,
    DELETE_SEGMENT_SUCCESS,
    DELETE_SEGMENT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    segments: [],
    error: {},
};

const Segment = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SEGMENTS_SUCCESS:
            return {
                ...state,
                segments: action.payload,
            };

        case GET_SEGMENTS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_SEGMENT_SUCCESS:
            return {
                ...state,
                segments: [...state.segments, action.payload],
            };

        case ADD_SEGMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_SEGMENT_SUCCESS:
            return {
                ...state,
                segments: state.segments.map(segment =>
                    segment.id.toString() === action.payload.id.toString()
                        ? { segment, ...action.payload }
                        : segment
                ),
            };

        case UPDATE_SEGMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_SEGMENT_SUCCESS:
            return {
                ...state,
                segments: state.segments.filter(
                    segment => segment.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_SEGMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return {
                ...state,
                error: {}
            };
    }
};

export default Segment;
