import {
  GET_LAST_LOGINS,
  GET_LAST_LOGINS_ERROR,
  GET_LAST_LOGINS_FOR_USER,
  GET_LAST_LOGINS_SUCCESS,
  GET_LAST_LOGINS_FOR_USER_ERROR,
  GET_LAST_LOGINS_FOR_USER_SUCCESS,
  GET_AUTH_LOGS,
  GET_AUTH_LOGS_SUCCESS,
  GET_AUTH_LOGS_ERROR,
  GET_FACEBOOK_AUTH_LOGS_SUCCESS, GET_FACEBOOK_AUTH_LOGS_ERROR
} from "./actionTypes";

const INIT_STATE = {
  authLogResponse: [],
  authLogCriteria: {},
  error: {},
  facebookLogResponse: {},
  facebookLogError: {},
};

const authLog = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LAST_LOGINS:
      return {
        ...state,
        authLogResponse: action.payload
      };

    case GET_LAST_LOGINS_ERROR:
      return {
        ...state,
        error: action.payload.data
      };

    case GET_LAST_LOGINS_SUCCESS:
      return {
        ...state,
        authLogResponse: [...state.authLogResponse, action.payload]
      };

    case GET_LAST_LOGINS_FOR_USER:
      return {
        ...state,
        authLogResponse: action.payload
      };

    case GET_LAST_LOGINS_FOR_USER_SUCCESS:
      return {
        ...state,
        authLogResponse: [...state.authLogResponse, action.payload]
      };

    case GET_LAST_LOGINS_FOR_USER_ERROR:
      return {
        ...state,
        error: action.payload.data
      };

    case GET_AUTH_LOGS_SUCCESS:
      return {
        ...state,
        authLogCriteria: action.payload,
      };

    case GET_AUTH_LOGS_ERROR:
      return {
        ...state,
        error: action.payload.data
      };

    case GET_FACEBOOK_AUTH_LOGS_SUCCESS:
      return {
        ...state,
        facebookLogResponse: action.payload,
      };

    case GET_FACEBOOK_AUTH_LOGS_ERROR:
      return {
        ...state,
        facebookLogError: action.payload.data
      };

    default:
      return state;
  }
};

export default authLog;
