
/* SECTIONS */
export const GET_SECTIONS = "GET_SECTIONS"
export const GET_SECTIONS_SUCCESS = "GET_SECTIONS_SUCCESS"
export const GET_SECTIONS_FAIL = "GET_SECTIONS_FAIL"

/**
 * add SECTION
 */
export const ADD_NEW_SECTION = "ADD_NEW_SECTION"
export const ADD_SECTION_SUCCESS = "ADD_SECTION_SUCCESS"
export const ADD_SECTION_FAIL = "ADD_SECTION_FAIL"

/**
 * Edit SECTION
 */
export const UPDATE_SECTION = "UPDATE_SECTION"
export const UPDATE_SECTION_SUCCESS = "UPDATE_SECTION_SUCCESS"
export const UPDATE_SECTION_FAIL = "UPDATE_SECTION_FAIL"

/**
 * Delete SECTION
 */
export const DELETE_SECTION = "DELETE_SECTION"
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS"
export const DELETE_SECTION_FAIL = "DELETE_SECTION_FAIL"
