import { call, put, takeEvery } from "redux-saga/effects";

// Categories Redux States
import { GET_AUTH_LOGS, GET_FACEBOOK_AUTH_LOGS, GET_LAST_LOGINS, GET_LAST_LOGINS_FOR_USER } from "./actionTypes";

import {
  getAuthLogsFail,
  getAuthLogsSuccess,
  getFacebookAuthLogsFail,
  getFacebookAuthLogsSuccess,
  getLastLoginsError,
  getLastLoginsForUserError,
  getLastLoginsForUserSuccess,
  getLastLoginsSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { get, post } from "../../helpers/api_helper";

function* fetchLastLogins() {
  try {
    const response = yield call(getLoginAttempts);
    yield put(getLastLoginsSuccess(response));
  } catch (error) {
    yield put(getLastLoginsError(error));
  }
}

const getLoginAttempts = () => get(url.GET_LOGIN_ATTEMPTS);

function* fetchLastLoginsForUser(loggedInUser) {
  try {
    const response = yield call(getLoginAttemptsForUser(loggedInUser.id));
    yield put(getLastLoginsForUserSuccess(response));
  } catch (error) {
    yield put(getLastLoginsForUserError(error));
  }
}

const getLoginAttemptsForUser = (id) => get(url.GET_USER_LOGIN_ATTEMPTS + "/" + id + "/" + 20);

function* getAuthLogs({ payload: req }) {
  try {
    const response = yield call(callGetAuthLogsApi, req);
    yield put(getAuthLogsSuccess(response));
  } catch (error) {
    yield put(getAuthLogsFail(error));
  }
}

const callGetAuthLogsApi = (req) => post(url.GET_AUTH_LOGS, req);

function* getFacebookAuthLogs() {
  try {
    const response = yield call(callGetFacebookAuthLogs);
    yield put(getFacebookAuthLogsSuccess(response));
  } catch (error) {
    yield put(getFacebookAuthLogsFail(error));
  }
}

const callGetFacebookAuthLogs = () => get(url.GET_FACEBOOK_AUTH_LOG);

function* authLogSaga() {
  yield takeEvery(GET_LAST_LOGINS, fetchLastLogins);
  yield takeEvery(GET_LAST_LOGINS_FOR_USER, fetchLastLoginsForUser);
  yield takeEvery(GET_AUTH_LOGS, getAuthLogs);
  yield takeEvery(GET_FACEBOOK_AUTH_LOGS, getFacebookAuthLogs);
}

export default authLogSaga;
