import { call, put, takeEvery } from "redux-saga/effects";

// Leads Redux States
import {
  ADD_NEW_LEAD,
  ASSIGN_REPRESENTATIVE,
  ASSIGN_SEGMENT,
  DELETE_LEAD,
  GET_LEAD_BY_ID,
  GET_LEADS,
  LEAD_CRITERIA,
  TRANSFER_LEAD_DATA,
  UPDATE_LEAD
} from "./actionTypes";

import {
  addLeadFail,
  addLeadSuccess,
  assignRepresentativeFail,
  assignRepresentativeSuccess,
  assignSegmentFail,
  assignSegmentSuccess,
  leadCriteriaFail,
  leadCriteriaSuccess,
  deleteLeadFail,
  deleteLeadSuccess,
  getLeadByIdFail,
  getLeadByIdSuccess,
  getLeadsFail,
  getLeadsSuccess,
  transferDataFail,
  transferDataSuccess,
  updateLeadFail,
  updateLeadSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchLeads() {
  try {
    const response = yield call(getLeads);
    yield put(getLeadsSuccess(response));
  } catch (error) {
    yield put(getLeadsFail(error));
  }
}

const getLeads = () => get(url.GET_LEADS);

function* onUpdateLead({ payload: lead }) {
  try {
    const response = yield call(updateLead, lead);
    yield put(updateLeadSuccess(response));
  } catch (error) {
    yield put(updateLeadFail(error));
  }
}

const updateLead = (lead) => post(url.UPDATE_LEAD, lead);

function* onDeleteLead({ payload: lead }) {
  try {
    const response = yield call(deleteLead, lead);
    if (response.length > 0) {
      yield put(deleteLeadSuccess(response));
    } else {
      yield put(deleteLeadSuccess(lead));
    }
  } catch (error) {
    yield put(deleteLeadFail(error));
  }
}

const deleteLead = (lead) => del(url.DELETE_LEAD, lead);

function* onAddNewLead({ payload: lead }) {
  try {
    const response = yield call(addNewLead, lead);
    yield put(addLeadSuccess(response));
  } catch (error) {
    yield put(addLeadFail(error));
  }
}

const addNewLead = (lead) => post(url.ADD_NEW_LEAD, lead);

function* onGetLead({ id }) {
  try {
    const response = yield call(getLeadById, id);
    yield put(getLeadByIdSuccess(response));
  } catch (error) {
    yield put(getLeadByIdFail(error));
  }
}

const getLeadById = (id) => get(`${url.GET_LEAD_BY_ID}/${id}`);

function* onAssignRepresentative({ payload: request }) {
  try {
    const response = yield call(assignRepresent, request);
    yield put(assignRepresentativeSuccess(response));
  } catch (error) {
    yield put(assignRepresentativeFail(error));
  }
}

const assignRepresent = (req) => post(url.SET_REPRESENTATIVE, req);

function* onAssignSegment({ payload: request }) {
  try {
    const response = yield call(assignSegment, request);
    yield put(assignSegmentSuccess(response));
  } catch (error) {
    yield put(assignSegmentFail(error));
  }
}

const assignSegment = (req) => post(url.SET_SEGMENT, req);

function* fetchLeadCriteria({ payload: req }) {

  try {
    const response = yield call(getLeadCriteria, req);
    yield put(leadCriteriaSuccess(response, req));
  } catch (error) {
    yield put(leadCriteriaFail(error));
  }
}

const getLeadCriteria = (req) => post(url.LEADS_CRITERIA, req);

function* fetchTransferData({ payload: req }) {
  try {
    const response = yield call(sendTransferData, req);
    yield put(transferDataSuccess(response, req));
  } catch (error) {
    yield put(transferDataFail(error));
  }
}

const sendTransferData = (req) => post(url.TRANSFER_DATA, req);

function* leadsSaga() {
  yield takeEvery(GET_LEADS, fetchLeads);
  yield takeEvery(ADD_NEW_LEAD, onAddNewLead);
  yield takeEvery(UPDATE_LEAD, onUpdateLead);
  yield takeEvery(DELETE_LEAD, onDeleteLead);
  yield takeEvery(GET_LEAD_BY_ID, onGetLead);
  yield takeEvery(ASSIGN_REPRESENTATIVE, onAssignRepresentative);
  yield takeEvery(ASSIGN_SEGMENT, onAssignSegment);
  yield takeEvery(LEAD_CRITERIA, fetchLeadCriteria);
  yield takeEvery(TRANSFER_LEAD_DATA, fetchTransferData);
}

export default leadsSaga;
