import {
    GET_REFERENCES_FAIL,
    GET_REFERENCES_SUCCESS,
    ADD_REFERENCE_SUCCESS,
    ADD_REFERENCE_FAIL,
    UPDATE_REFERENCE_SUCCESS,
    UPDATE_REFERENCE_FAIL,
    DELETE_REFERENCE_SUCCESS,
    DELETE_REFERENCE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    references: [],
    error: {},
};

const Reference = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REFERENCES_SUCCESS:
            return {
                ...state,
                references: action.payload,
            };

        case GET_REFERENCES_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_REFERENCE_SUCCESS:
            return {
                ...state,
                references: [...state.references, action.payload],
            };

        case ADD_REFERENCE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_REFERENCE_SUCCESS:
            return {
                ...state,
                references: state.references.map(reference =>
                    reference.id.toString() === action.payload.id.toString()
                        ? { reference, ...action.payload }
                        : reference
                ),
            };

        case UPDATE_REFERENCE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_REFERENCE_SUCCESS:
            return {
                ...state,
                references: state.references.filter(
                    reference => reference.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_REFERENCE_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default Reference;
