import {
    GET_OPERATION_DEFINITIONS,
    GET_OPERATION_DEFINITIONS_FAIL,
    GET_OPERATION_DEFINITIONS_SUCCESS,
    ADD_NEW_OPERATION_DEFINITION,
    ADD_OPERATION_DEFINITION_SUCCESS,
    ADD_OPERATION_DEFINITION_FAIL,
    UPDATE_OPERATION_DEFINITION,
    UPDATE_OPERATION_DEFINITION_SUCCESS,
    UPDATE_OPERATION_DEFINITION_FAIL,
    DELETE_OPERATION_DEFINITION,
    DELETE_OPERATION_DEFINITION_SUCCESS,
    DELETE_OPERATION_DEFINITION_FAIL,
} from "./actionTypes"

export const getOperationDefinitions = () => ({
    type: GET_OPERATION_DEFINITIONS,
})

export const getOperationDefinitionsSuccess = doctors => ({
    type: GET_OPERATION_DEFINITIONS_SUCCESS,
    payload: doctors,
})

export const getOperationDefinitionsFail = error => ({
    type: GET_OPERATION_DEFINITIONS_FAIL,
    payload: error,
})

export const addNewOperationDefinition = doctor => ({
    type: ADD_NEW_OPERATION_DEFINITION,
    payload: doctor,
})

export const addOperationDefinitionSuccess = doctor => ({
    type: ADD_OPERATION_DEFINITION_SUCCESS,
    payload: doctor,
})

export const addOperationDefinitionFail = error => ({
    type: ADD_OPERATION_DEFINITION_FAIL,
    payload: error,
})

export const updateOperationDefinition = doctor => ({
    type: UPDATE_OPERATION_DEFINITION,
    payload: doctor,
})

export const updateOperationDefinitionSuccess = doctor => ({
    type: UPDATE_OPERATION_DEFINITION_SUCCESS,
    payload: doctor,
})

export const updateOperationDefinitionFail = error => ({
    type: UPDATE_OPERATION_DEFINITION_FAIL,
    payload: error,
})

export const deleteOperationDefinition = id => ({
    type: DELETE_OPERATION_DEFINITION,
    id,
})

export const deleteOperationDefinitionSuccess = (doctor,req) => ({
    type: DELETE_OPERATION_DEFINITION_SUCCESS,
    payload: doctor,
    req:req,
})

export const deleteOperationDefinitionFail = error => ({
    type: DELETE_OPERATION_DEFINITION_FAIL,
    payload: error,
})