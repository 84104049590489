import { call, put, takeEvery } from "redux-saga/effects";

// PatientNotes Redux States
import { ADD_NEW_PATIENT_NOTE, DELETE_PATIENT_NOTE, GET_PATIENT_NOTES, UPDATE_PATIENT_NOTE } from "./actionTypes";

import {
  addPatientNoteFail,
  addPatientNoteSuccess,
  deletePatientNoteFail,
  deletePatientNoteSuccess,
  getPatientNotesFail,
  getPatientNotesSuccess,
  updatePatientNoteFail,
  updatePatientNoteSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchPatientNotes({id}) {
  try {
    const response = yield call(getPatientPatientNotes,id);
    yield put(getPatientNotesSuccess(response));
  } catch (error) {
    yield put(getPatientNotesFail(error));
  }
}

const getPatientPatientNotes = (id) => get(`${url.GET_PATIENT_NOTE}/${id}`);

function* onUpdatePatientNote({ payload: patientNote }) {
  try {
    const response = yield call(updatePatientNote, patientNote);
    yield put(updatePatientNoteSuccess(response));
  } catch (error) {
    yield put(updatePatientNoteFail(error));
  }
}

const updatePatientNote = (patientNote) => post(url.UPDATE_PATIENT_NOTE,patientNote)

function* onDeletePatientNote({id}) {
  try {
    const response = yield call(deletePatientNote, id);
    yield put(deletePatientNoteSuccess(response, id));
  } catch (error) {
    yield put(deletePatientNoteFail(error));
  }
}

const deletePatientNote = (id) => del(`${url.DELETE_PATIENT_NOTE}/${id}`);

function* onAddNewPatientNote({ payload: patientNote }) {
  try {
    const response = yield call(addNewPatientNote, patientNote);

    yield put(addPatientNoteSuccess(response));
  } catch (error) {
    yield put(addPatientNoteFail(error));
  }
}

const addNewPatientNote = (patientNote) => post(url.ADD_NEW_PATIENT_NOTE,patientNote);

function* patientNotesSaga() {
  yield takeEvery(GET_PATIENT_NOTES, fetchPatientNotes);
  yield takeEvery(ADD_NEW_PATIENT_NOTE, onAddNewPatientNote);
  yield takeEvery(UPDATE_PATIENT_NOTE, onUpdatePatientNote);
  yield takeEvery(DELETE_PATIENT_NOTE, onDeletePatientNote);
}

export default patientNotesSaga;
