
/* SCREENS */
export const GET_SCREENS = "GET_SCREENS"
export const GET_SCREENS_SUCCESS = "GET_SCREENS_SUCCESS"
export const GET_SCREENS_FAIL = "GET_SCREENS_FAIL"

/**
 * add SCREEN
 */
export const ADD_NEW_SCREEN = "ADD_NEW_SCREEN"
export const ADD_SCREEN_SUCCESS = "ADD_SCREEN_SUCCESS"
export const ADD_SCREEN_FAIL = "ADD_SCREEN_FAIL"

/**
 * Edit SCREEN
 */
export const UPDATE_SCREEN = "UPDATE_SCREEN"
export const UPDATE_SCREEN_SUCCESS = "UPDATE_SCREEN_SUCCESS"
export const UPDATE_SCREEN_FAIL = "UPDATE_SCREEN_FAIL"

/**
 * Delete SCREEN
 */
export const DELETE_SCREEN = "DELETE_SCREEN"
export const DELETE_SCREEN_SUCCESS = "DELETE_SCREEN_SUCCESS"
export const DELETE_SCREEN_FAIL = "DELETE_SCREEN_FAIL"