
/* USER_AVAILABILITY */
export const GET_USER_AVAILABILITY = "GET_USER_AVAILABILITY"
export const GET_USER_AVAILABILITY_SUCCESS = "GET_USER_AVAILABILITY_SUCCESS"
export const GET_USER_AVAILABILITY_FAIL = "GET_USER_AVAILABILITY_FAIL"

/**
 * add USER_AVAILABILITY
 */
export const ADD_NEW_USER_AVAILABILITY = "ADD_NEW_USER_AVAILABILITY"
export const ADD_USER_AVAILABILITY_SUCCESS = "ADD_USER_AVAILABILITY_SUCCESS"
export const ADD_USER_AVAILABILITY_FAIL = "ADD_USER_AVAILABILITY_FAIL"

/**
 * Edit USER_AVAILABILITY
 */
export const UPDATE_USER_AVAILABILITY = "UPDATE_USER_AVAILABILITY"
export const UPDATE_USER_AVAILABILITY_SUCCESS = "UPDATE_USER_AVAILABILITY_SUCCESS"
export const UPDATE_USER_AVAILABILITY_FAIL = "UPDATE_USER_AVAILABILITY_FAIL"

/**
 * Delete USER_AVAILABILITY
 */
export const DELETE_USER_AVAILABILITY = "DELETE_USER_AVAILABILITY"
export const DELETE_USER_AVAILABILITY_SUCCESS = "DELETE_USER_AVAILABILITY_SUCCESS"
export const DELETE_USER_AVAILABILITY_FAIL = "DELETE_USER_AVAILABILITY_FAIL"

export const GET_USER_AVAILABILITY_BY_ID = "GET_USER_AVAILABILITY_BY_ID"
export const GET_USER_AVAILABILITY_BY_ID_SUCCESS = "GET_USER_AVAILABILITY_BY_ID_SUCCESS"
export const GET_USER_AVAILABILITY_BY_ID_FAIL = "GET_USER_AVAILABILITY_BY_ID_FAIL"
