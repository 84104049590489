import React, { useEffect } from "react";
import PropTypes from "prop-types";

const PayTRIframe = (props) => {
  const iframe = document.createElement("iframe");
  useEffect(() => {
    if (props.source) {
      iframe.src = `https://www.paytr.com/odeme/guvenli/${props.source}`;
      iframe.id = "paytriframe";
      iframe.style.width = "100%";
      iframe.allowFullscreen = true;
      iframe.style.fill = "true";
      iframe.style.scroll = "false";

      document.getElementById("payTrContainer").appendChild(iframe);

      window.iFrameResize({}, "#paytriframe");

    }
  }, [props.source]);

  return (
    <div id="payTrContainer" style={{ width: "100%", height: "100%" }}>
    </div>
  );
};

PayTRIframe.propTypes = {
  source: PropTypes.string
};

export default PayTRIframe;
