import {
    ADD_NEW_USER_AVAILABILITY,
    ADD_USER_AVAILABILITY_FAIL,
    ADD_USER_AVAILABILITY_SUCCESS,
    DELETE_USER_AVAILABILITY,
    DELETE_USER_AVAILABILITY_FAIL,
    DELETE_USER_AVAILABILITY_SUCCESS,
    GET_USER_AVAILABILITY,
    GET_USER_AVAILABILITY_BY_ID,
    GET_USER_AVAILABILITY_BY_ID_FAIL,
    GET_USER_AVAILABILITY_BY_ID_SUCCESS,
    GET_USER_AVAILABILITY_FAIL,
    GET_USER_AVAILABILITY_SUCCESS,
    UPDATE_USER_AVAILABILITY,
    UPDATE_USER_AVAILABILITY_FAIL,
    UPDATE_USER_AVAILABILITY_SUCCESS
} from "./actionTypes";

export const getUserAvailability = () => ({
    type: GET_USER_AVAILABILITY,
})

export const getUserAvailabilitySuccess = UserAvailabilitys => ({
    type: GET_USER_AVAILABILITY_SUCCESS,
    payload: UserAvailabilitys,
})

export const getUserAvailabilityFail = error => ({
    type: GET_USER_AVAILABILITY_FAIL,
    payload: error,
})

export const addNewUserAvailability = UserAvailability => ({
    type: ADD_NEW_USER_AVAILABILITY,
    payload: UserAvailability,
})

export const addUserAvailabilitySuccess = UserAvailability => ({
    type: ADD_USER_AVAILABILITY_SUCCESS,
    payload: UserAvailability,
})

export const addUserAvailabilityFail = error => ({
    type: ADD_USER_AVAILABILITY_FAIL,
    payload: error,
})

export const updateUserAvailability = UserAvailability => ({
    type: UPDATE_USER_AVAILABILITY,
    payload: UserAvailability,
})

export const updateUserAvailabilitySuccess = UserAvailability => ({
    type: UPDATE_USER_AVAILABILITY_SUCCESS,
    payload: UserAvailability,
})

export const updateUserAvailabilityFail = error => ({
    type: UPDATE_USER_AVAILABILITY_FAIL,
    payload: error,
})

export const deleteUserAvailability = id => ({
    type: DELETE_USER_AVAILABILITY,
    id,
})

export const deleteUserAvailabilitySuccess = (UserAvailability,req) => ({
    type: DELETE_USER_AVAILABILITY_SUCCESS,
    payload: UserAvailability,
    req: req,
})

export const deleteUserAvailabilityFail = error => ({
    type: DELETE_USER_AVAILABILITY_FAIL,
    payload: error,
})


export const getUserAvailabilityById = (id) => ({
    type: GET_USER_AVAILABILITY_BY_ID,
    payload: id
});

export const getUserAvailabilityByIdSuccess = userProfile => ({
    type: GET_USER_AVAILABILITY_BY_ID_SUCCESS,
    payload: userProfile
});

export const getUserAvailabilityByIdFail = error => ({
    type: GET_USER_AVAILABILITY_BY_ID_FAIL,
    payload: error
});