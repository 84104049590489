import {
    GET_RESOURCES,
    GET_RESOURCES_FAIL,
    GET_RESOURCES_SUCCESS,
    ADD_NEW_RESOURCE,
    ADD_RESOURCE_SUCCESS,
    ADD_RESOURCE_FAIL,
    UPDATE_RESOURCE,
    UPDATE_RESOURCE_SUCCESS,
    UPDATE_RESOURCE_FAIL,
    DELETE_RESOURCE,
    DELETE_RESOURCE_SUCCESS,
    DELETE_RESOURCE_FAIL,
} from "./actionTypes"

export const getResources = () => ({
    type: GET_RESOURCES,
})

export const getResourcesSuccess = resources => ({
    type: GET_RESOURCES_SUCCESS,
    payload: resources,
})

export const getResourcesFail = error => ({
    type: GET_RESOURCES_FAIL,
    payload: error,
})

export const addNewResource = resource => ({
    type: ADD_NEW_RESOURCE,
    payload: resource,
})

export const addResourceSuccess = resource => ({
    type: ADD_RESOURCE_SUCCESS,
    payload: resource,
})

export const addResourceFail = error => ({
    type: ADD_RESOURCE_FAIL,
    payload: error,
})

export const updateResource = resource => ({
    type: UPDATE_RESOURCE,
    payload: resource,
})

export const updateResourceSuccess = resource => ({
    type: UPDATE_RESOURCE_SUCCESS,
    payload: resource,
})

export const updateResourceFail = error => ({
    type: UPDATE_RESOURCE_FAIL,
    payload: error,
})

export const deleteResource = id => ({
    type: DELETE_RESOURCE,
    id,
})

export const deleteResourceSuccess = (resource,req) => ({
    type: DELETE_RESOURCE_SUCCESS,
    payload: resource,
    req:req,
})

export const deleteResourceFail = error => ({
    type: DELETE_RESOURCE_FAIL,
    payload: error,
})