
/* USER_WORK_SCHEDULES */
export const GET_USER_WORK_SCHEDULES = "GET_USER_WORK_SCHEDULES"
export const GET_USER_WORK_SCHEDULES_SUCCESS = "GET_USER_WORK_SCHEDULES_SUCCESS"
export const GET_USER_WORK_SCHEDULES_FAIL = "GET_USER_WORK_SCHEDULES_FAIL"

/**
 * add USER_WORK_SCHEDULE
 */
export const ADD_NEW_USER_WORK_SCHEDULE = "ADD_NEW_USER_WORK_SCHEDULE"
export const ADD_USER_WORK_SCHEDULE_SUCCESS = "ADD_USER_WORK_SCHEDULE_SUCCESS"
export const ADD_USER_WORK_SCHEDULE_FAIL = "ADD_USER_WORK_SCHEDULE_FAIL"

/**
 * Edit USER_WORK_SCHEDULE
 */
export const UPDATE_USER_WORK_SCHEDULE = "UPDATE_USER_WORK_SCHEDULE"
export const UPDATE_USER_WORK_SCHEDULE_SUCCESS = "UPDATE_USER_WORK_SCHEDULE_SUCCESS"
export const UPDATE_USER_WORK_SCHEDULE_FAIL = "UPDATE_USER_WORK_SCHEDULE_FAIL"

/**
 * Delete USER_WORK_SCHEDULE
 */
export const DELETE_USER_WORK_SCHEDULE = "DELETE_USER_WORK_SCHEDULE"
export const DELETE_USER_WORK_SCHEDULE_SUCCESS = "DELETE_USER_WORK_SCHEDULE_SUCCESS"
export const DELETE_USER_WORK_SCHEDULE_FAIL = "DELETE_USER_WORK_SCHEDULE_FAIL"

export const GET_USER_WORK_SCHEDULE_BY_ID = "GET_USER_WORK_SCHEDULE_BY_ID"
export const GET_USER_WORK_SCHEDULE_BY_ID_SUCCESS = "GET_USER_WORK_SCHEDULE_BY_ID_SUCCESS"
export const GET_USER_WORK_SCHEDULE_BY_ID_FAIL = "GET_USER_WORK_SCHEDULE_BY_ID_FAIL"
