
/* HOSPITALS */
export const GET_HOSPITALS = "GET_HOSPITALS"
export const GET_HOSPITALS_SUCCESS = "GET_HOSPITALS_SUCCESS"
export const GET_HOSPITALS_FAIL = "GET_HOSPITALS_FAIL"

/**
 * add HOSPITAL
 */
export const ADD_NEW_HOSPITAL = "ADD_NEW_HOSPITAL"
export const ADD_HOSPITAL_SUCCESS = "ADD_HOSPITAL_SUCCESS"
export const ADD_HOSPITAL_FAIL = "ADD_HOSPITAL_FAIL"

/**
 * Edit HOSPITAL
 */
export const UPDATE_HOSPITAL = "UPDATE_HOSPITAL"
export const UPDATE_HOSPITAL_SUCCESS = "UPDATE_HOSPITAL_SUCCESS"
export const UPDATE_HOSPITAL_FAIL = "UPDATE_HOSPITAL_FAIL"

/**
 * Delete HOSPITAL
 */
export const DELETE_HOSPITAL = "DELETE_HOSPITAL"
export const DELETE_HOSPITAL_SUCCESS = "DELETE_HOSPITAL_SUCCESS"
export const DELETE_HOSPITAL_FAIL = "DELETE_HOSPITAL_FAIL"
