import React, { useEffect, useState } from "react";
import withRouter from "../../hooks/withRouter";
import PropTypes from "prop-types";
import { Button, Input, InputGroup, Label } from "reactstrap";

//Import Breadcrumb
import {
  deleteMailInfo as onDeleteMailInfo,
  getMailInfos as onGetMailInfos,
  updateMailInfo as onUpdateMailInfo
} from "store/mailInfo/actions";

//redux
import { useDispatch, useSelector } from "react-redux";

// Column
import { withTranslation } from "react-i18next";
import Select from "react-select";
import useAuth from "../../hooks/useAuth";

const MailInfo = props => {

  //meta title
  document.title = `CRM | ${props.t("Mail Info")}`;

  const { authUser } = useAuth();
  const roleModerator = authUser && authUser.role && authUser.role.name === "ROLE_MODERATOR";

  const dispatch = useDispatch();

  const initialMailInfo = {
    mailHost: "",
    mailPort: 0,
    mailProtocol: "",
    mailUsername: "",
    mailPassword: "",
    smtpAuth: false,
    smtpQuitWait: false,
    smtpStartTlsEnable: false,
    smtpSslEnable: false,
    status: "ACTIVE",
    fromWho: "CUSTOMER"
  };

  const { mailInfos, error, success } = useSelector(state => ({
    mailInfos: state.MailInfo.mailInfos,
    error: state.MailInfo.error,
    success: state.MailInfo.success
  }));

  const [mailInfo, setMailInfo] = useState(initialMailInfo);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [status, setSelectedStatus] = useState({ label: "", value: "" });
  const [smtpAuth, setSelectedSmtpAuth] = useState({ label: "", value: "" });
  const [smtpQuitWait, setSelectedSmtpQuitWait] = useState({ label: "", value: "" });
  const [smtpStartTlsEnable, setSelectedSmtpStartTlsEnable] = useState({ label: "", value: "" });
  const [smtpSslEnable, setSelectedSmtpSslEnable] = useState({ label: "", value: "" });
  const [fromWho, setSelectedFromWho] = useState({ label: "", value: "" });
  const [showPassword, setShowPassword] = useState(false);

  const handleChangeMailInfo = (e) => {
    setMailInfo({
      ...mailInfo,
      [e.target.name]: e.target.value
    });
  };

  //delete mailInfo
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteMailInfo = () => {
    if (mailInfo.id) {
      dispatch(onDeleteMailInfo(mailInfo.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (mailInfos && mailInfos.length > 0) {
      setMailInfo(mailInfos[0]);
      setSelectedStatus({
        label: mailInfos[0] && mailInfos[0].status || "",
        value: mailInfos[0].status || ""
      });
      setSelectedSmtpAuth({
        label: mailInfos[0].smtpAuth === true ? props.t("YES") : props.t("NO") ,
        value: mailInfos[0] && mailInfos[0].smtpAuth || ""
      });
      setSelectedSmtpQuitWait({
        label: mailInfos[0].smtpQuitWait === true ? props.t("YES") : props.t("NO") ,
        value: mailInfos[0].smtpQuitWait || ""
      });
      setSelectedSmtpStartTlsEnable({
        label: mailInfos[0].smtpStartTlsEnable === true ? props.t("YES") : props.t("NO") ,
        value: mailInfos[0].smtpStartTlsEnable || ""
      });
      setSelectedSmtpSslEnable({
        label: mailInfos[0].smtpSslEnable === true ? props.t("YES") : props.t("NO") ,
        value: mailInfos[0].smtpSslEnable || ""
      });
      setSelectedFromWho({
        label: mailInfos[0].fromWho || "" ,
        value: mailInfos[0].fromWho || ""
      });
    }
  }, [mailInfos]);

  useEffect(() => {
    dispatch(onGetMailInfos());
  }, []);

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && Object.entries(error).length > 0) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    } else if (success) {
      setMessage("Success");
      setShowNotification(true);
      setNotificationType("Success");
    }
  }, [error, success]);

  function updateMailInfo() {
    const updatemailInfoReq = Object.assign({
      id: mailInfo && mailInfo.id || null,
      mailHost: mailInfo && mailInfo.mailHost,
      mailPort: mailInfo && mailInfo.mailPort,
      mailProtocol: mailInfo && mailInfo.mailProtocol,
      mailUsername: mailInfo && mailInfo.mailUsername,
      mailPassword: mailInfo && mailInfo.mailPassword,
      smtpAuth: mailInfo && mailInfo.smtpAuth,
      smtpQuitWait: mailInfo && mailInfo.smtpQuitWait,
      smtpStartTlsEnable: mailInfo && mailInfo.smtpStartTlsEnable,
      smtpSslEnable: mailInfo && mailInfo.smtpSslEnable,
      status: mailInfo && mailInfo.status,
      fromWho: mailInfo && mailInfo.fromWho
    });
    // update mailInfo
    dispatch(onUpdateMailInfo(updatemailInfoReq));
  }

  const onSelectFromWho = (e) => {
    setSelectedFromWho(e);
    setMailInfo({
      ...mailInfo,
      fromWho: (e !== null && e.value !== null ? e.value : false)
    });
  };

  const onSelectSmtpAuth = (e) => {
    setSelectedSmtpAuth(e);
    setMailInfo({
      ...mailInfo,
      smtpAuth: (e !== null && e.value !== null ? e.value : false)
    });
  };

  const onSelectSmtpQuitWait = (e) => {
    setSelectedSmtpQuitWait(e);
    setMailInfo({
      ...mailInfo,
      smtpQuitWait: (e !== null && e.value !== null ? e.value : false)
    });
  };

  const onSelectSmtpStartTlsEnable = (e) => {
    setSelectedSmtpStartTlsEnable(e);
    setMailInfo({
      ...mailInfo,
      smtpStartTlsEnable: (e !== null && e.value !== null ? e.value : false)
    });
  };

  const onSelectSmtpSslEnable = (e) => {
    setSelectedSmtpSslEnable(e);
    setMailInfo({
      ...mailInfo,
      smtpSslEnable: (e !== null && e.value !== null ? e.value : false)
    });
  };

  const onSelectStatus = (e) => {
    setSelectedStatus(e);
    setMailInfo({
      ...mailInfo,
      status: (e !== null && e.value !== null ? e.value : null)
    });
  };

  return (
    <React.Fragment>
      <div className="mt-2">
        <div className="row">
          <div className="col-xs-6 col-md-2 mb-3">
            <Label className="form-label">{props.t("Host")}</Label>
            <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
            <Input
              id="mailHost"
              name="mailHost"
              type="text"
              onChange={handleChangeMailInfo}
              value={mailInfo.mailHost || ""}
              required={true}
              invalid={!mailInfo.mailHost}
            />
          </div>
          <div className="col-xs-6 col-md-2 mb-3">
            <Label className="form-label">{props.t("Port")}</Label>
            <Input
              id="mailPort"
              name="mailPort"
              type="text"
              onChange={handleChangeMailInfo}
              value={mailInfo.mailPort || ""}
            />
          </div>
          <div className="col-xs-6 col-md-2 mb-3">
            <Label className="form-label">{props.t("Protocol")}</Label>
            <Input
              id="mailProtocol"
              name="mailProtocol"
              type="text"
              onChange={handleChangeMailInfo}
              value={mailInfo.mailProtocol || ""}
            />
          </div>
          <div className="col-xs-6 col-md-2 mb-3">
            <Label className="form-label">{props.t("Username")}</Label>
            <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
            <Input
              id="mailUsername"
              name="mailUsername"
              type="text"
              onChange={handleChangeMailInfo}
              value={mailInfo.mailUsername || ""}
              required={true}
              invalid={!mailInfo.mailUsername}
            />
          </div>
          <div className="col-xs-6 col-md-2 mb-3">
            <Label className="form-label">{props.t("Mail Password")}</Label>
            <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
            <InputGroup>
              <Input
                id="smsPassword"
                name="smsPassword"
                type={
                  showPassword ? "text" : "password"
                }
                onChange={handleChangeMailInfo}
                value={mailInfo.mailPassword || ""}
                required={true}
                invalid={!mailInfo.mailPassword}
              />
              <Button
                color="light"
                outline
                onMouseDown={() => setShowPassword(true)}
                onMouseUp={() => setShowPassword(false)}>
                <i className="fa fa-eye text-secondary font-size-16" />
              </Button>
            </InputGroup>
          </div>
          <div className="col-xs-6 col-md-2 mb-3">
            <Label className="form-label">{props.t("From Who")}</Label>
            <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
            <Select
              value={fromWho}
              onChange={(event) => onSelectFromWho(event)}
              options={[{ label: props.t("TECHLAB"), value: "TECHLAB" }, { label: props.t("CUSTOMER"), value: "CUSTOMER" }]}
              className="bs-select"
              isClearable={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6 col-md-2 mb-3">
            <Label className="form-label">{props.t("Status")}</Label>
            <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
            <Select
              value={status}
              onChange={(event) => onSelectStatus(event)}
              options={[{ label: props.t("ACTIVE"), value: "ACTIVE" }, { label: props.t("PASSIVE"), value: "PASSIVE" }]}
              className="bs-select"
              isClearable={true}
            />
          </div>
          <div className="col-xs-6 col-md-2 mb-3">
            <Label className="form-label">{props.t("SMTP Auth")}</Label>
            <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
            <Select
              value={smtpAuth}
              onChange={(event) => onSelectSmtpAuth(event)}
              options={[{ label: props.t("YES"), value: true }, { label: props.t("NO"), value: false }]}
              className="bs-select"
              isClearable={true}
            />
          </div>
          <div className="col-xs-6 col-md-2 mb-3">
            <Label className="form-label">{props.t("SMTP Quit Wait")}</Label>
            <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
            <Select
              value={smtpQuitWait}
              onChange={(event) => onSelectSmtpQuitWait(event)}
              options={[{ label: props.t("YES"), value: true }, { label: props.t("NO"), value: false }]}
              className="bs-select"
              isClearable={true}
            />
          </div>
          <div className="col-xs-6 col-md-2 mb-3">
            <Label className="form-label">{props.t("SMTP TLS Enabled")}</Label>
            <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
            <Select
              value={smtpStartTlsEnable}
              onChange={(event) => onSelectSmtpStartTlsEnable(event)}
              options={[{ label: props.t("YES"), value: true }, { label: props.t("NO"), value: false }]}
              className="bs-select"
              isClearable={true}
            />
          </div>
          <div className="col-xs-6 col-md-2 mb-3">
            <Label className="form-label">{props.t("SMTP SSL Enabled")}</Label>
            <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
            <Select
              value={smtpSslEnable}
              onChange={(event) => onSelectSmtpSslEnable(event)}
              options={[{ label: props.t("YES"), value: true }, { label: props.t("NO"), value: false }]}
              className="bs-select"
              isClearable={true}
            />
          </div>
        </div>
        <div className="text-end mb-3">
          <button
            id="detailSave"
            className="btn btn-success save-mailInfo"
            onClick={updateMailInfo}
          >
            {props.t("Save Changes")}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};
MailInfo.propTypes = {
  mailInfos: PropTypes.array,
  onGetMailInfos: PropTypes.func,
  onAddNewMailInfo: PropTypes.func,
  onDeleteMailInfo: PropTypes.func,
  onUpdateMailInfo: PropTypes.func
};

export default withRouter(withTranslation()(MailInfo));
