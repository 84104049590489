import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationDE from "./locales/de/translation.json";
import translationIT from "./locales/it/translation.json";
import translationRU from "./locales/ru/translation.json";
import translationES from "./locales/es/translation.json";
import translationEN from "./locales/en/translation.json";
import translationTR from "./locales/tr/translation.json";
import translationNL from "./locales/nl/translation.json";
import translationAE from "./locales/ar/translation.json";
import translationBG from "./locales/bg/translation.json";

// the translations
const resources = {
  de: {
    translation: translationDE
  },
  it: {
    translation: translationIT
  },
  ru: {
    translation: translationRU
  },
  es: {
    translation: translationES
  },
  en: {
    translation: translationEN
  },
  tr: {
    translation: translationTR
  },
  nl: {
    translation: translationNL
  },
  ar: {
    translation: translationAE
  },
  bg: {
    translation: translationBG
  }
};

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en");
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
