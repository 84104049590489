import {
  ADD_OPERATION_DEFINITION_FAIL,
  ADD_OPERATION_DEFINITION_SUCCESS,
  DELETE_OPERATION_DEFINITION_FAIL,
  DELETE_OPERATION_DEFINITION_SUCCESS,
  GET_OPERATION_DEFINITIONS_FAIL,
  GET_OPERATION_DEFINITIONS_SUCCESS,
  UPDATE_OPERATION_DEFINITION_FAIL,
  UPDATE_OPERATION_DEFINITION_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  operationDefinitions: [],
  error: {}
};

const OperationDefinition = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OPERATION_DEFINITIONS_SUCCESS:
      return {
        ...state,
        operationDefinitions: action.payload
      };

    case GET_OPERATION_DEFINITIONS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_OPERATION_DEFINITION_SUCCESS:
      return {
        ...state,
        operationDefinitions: [...state.operationDefinitions, action.payload]
      };

    case ADD_OPERATION_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_OPERATION_DEFINITION_SUCCESS:
      return {
        ...state,
        operationDefinitions: state.operationDefinitions.map(operationDefinition =>
          operationDefinition.id.toString() === action.payload.id.toString()
            ? { operationDefinition, ...action.payload }
            : operationDefinition
        )
      };

    case UPDATE_OPERATION_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_OPERATION_DEFINITION_SUCCESS:
      return {
        ...state,
        operationDefinitions: state.operationDefinitions.filter(
          operationDefinition => operationDefinition.id.toString() !== action.req.toString()
        )
      };

    case DELETE_OPERATION_DEFINITION_FAIL:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default OperationDefinition;
