import PropTypes from "prop-types";
import React from "react";

import { Route, Routes } from "react-router-dom";
import version from "../package.json";

// Import Routes all
import { authProtectedRoutes, privateRoutes, publicRoutes } from "./routes";

// Import all middleware
// layouts Format
import VerticalLayout from "./components/VerticalLayout";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import CacheBuster from "react-cache-buster";
import { initFacebookSdk } from "./helpers/initFacebookSdk";
import Authmiddleware from "./routes/route";
import NonAuthLayoutWithLanguage from "./components/NonAuthLayoutWithLanguage";

initFacebookSdk().then(r => console.log("Sdk Initialized"));

const App = props => {

  function getLayout() {
    let layoutCls = VerticalLayout;
    return layoutCls;
  }

  initFacebookSdk().then(r => console.log("Sdk Initialized"));


  const isLoggedIn = localStorage.getItem("authUser") !== undefined;
  const Layout = getLayout();

  return (
    <CacheBuster
      currentVersion={version.version}
      isEnabled={true}
      isVerboseMode={false}
      onCacheClear={getLayout()}
    >
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={route.layout ? (
              <NonAuthLayoutWithLanguage>
                {route.component}
              </NonAuthLayoutWithLanguage>
            ):(
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            )
            }
            key={idx}
            exact={true}
          />
        ))}

        {privateRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayoutWithLanguage>
                {route.component}
              </NonAuthLayoutWithLanguage>
            }
            key={idx}
            isLoggedIn={isLoggedIn}
                exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware path={route.path}>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}

      </Routes>
    </CacheBuster>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;
