import { facebook } from "../config";

export function initFacebookSdk() {
  return new Promise(resolve => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: facebook.APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v16.0'
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.defer = true;
      js.async=true;
      js.crossOrigin ="anonymous"
      js.nonce="zoaztbiW"
      js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v16.0&appId=547261750198502&autoLogAppEvents=1";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  });
}