
/* PARAMETERS */
export const GET_PARAMETERS = "GET_PARAMETERS"
export const GET_PARAMETERS_SUCCESS = "GET_PARAMETERS_SUCCESS"
export const GET_PARAMETERS_FAIL = "GET_PARAMETERS_FAIL"

/**
 * add PARAMETER
 */
export const ADD_NEW_PARAMETER = "ADD_NEW_PARAMETER"
export const ADD_PARAMETER_SUCCESS = "ADD_PARAMETER_SUCCESS"
export const ADD_PARAMETER_FAIL = "ADD_PARAMETER_FAIL"

/**
 * Edit PARAMETER
 */
export const UPDATE_PARAMETER = "UPDATE_PARAMETER"
export const UPDATE_PARAMETER_SUCCESS = "UPDATE_PARAMETER_SUCCESS"
export const UPDATE_PARAMETER_FAIL = "UPDATE_PARAMETER_FAIL"

/**
 * Delete PARAMETER
 */
export const DELETE_PARAMETER = "DELETE_PARAMETER"
export const DELETE_PARAMETER_SUCCESS = "DELETE_PARAMETER_SUCCESS"
export const DELETE_PARAMETER_FAIL = "DELETE_PARAMETER_FAIL"
