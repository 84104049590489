import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {
    Alert,
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane
} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";

import {
    createAnnualPayment,
    createInstantUserPayment,
    getUnpaidPayments,
    paymentProcess,
    updateUserCountAndPrice
} from "../../store/payment/actions";
import {useDispatch, useSelector} from "react-redux";
import classnames from "classnames";
import PayTRIframe from "../../components/Common/PayTRIframe";
import {isBefore, isSameDay} from "date-fns";
import {DateToString, formatDateForScreen} from "../../common/commonFunctions";
import {withTranslation} from "react-i18next";
import useAuth from "../../hooks/useAuth";
import {getFirmInfos as onGetFirmInfos} from "../../store/firmInfo/actions";
import Notification from "../../components/Notification";

const PaymentGuard = props => {

    //meta title
    document.title = `CRM | ${props.t("Guard")}`;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const localDashboard = localStorage.getItem("dashboard")

    const {postponeEnabled, setNavigatePayment, dashboard, paymentDueDate} = useAuth();

    const [activeTab, setactiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);
    const [userCount, setUserCount] = useState(0);
    const [selectedPayments, setSelectedPayments] = useState([]);
    const [annualPaymentChecked, setAnnualPaymentChecked] = useState(false);
    const [standardPaymentChecked, setStandardPaymentChecked] = useState(false);
    const [perUserPaymentChecked, setPerUserPaymentChecked] = useState(false);
    const [perUserCount, setPerUserCount] = useState("");
    const [activeUserCount, setActiveUserCount] = useState(0);
    const [token, setToken] = useState("");
    const [mappedPayments, setMappedPayments] = useState([]);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");

    const {unpaidPayments, processResponse} = useSelector(state => ({
        unpaidPayments: state.Payment.unpaidPayments,
        processResponse: state.Payment.processResponse
    }));

    useEffect(() => {
        dispatch(onGetFirmInfos());
    }, []);

    const {firmInfos} = useSelector(state => ({
        firmInfos: state.FirmInfo.firmInfos
    }));

    useEffect(() => {
        if (firmInfos && firmInfos.length > 0 && firmInfos[0].userCount) {
            setActiveUserCount(firmInfos[0].userCount);
        }
    }, [firmInfos]);

    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];
            if (tab >= 1 && tab <= 3) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
    }

    useEffect(() => {
        if (activeTab !== 3) {
            setToken("");
        } else {
            handlePaySelectedPayments();
        }
    }, [activeTab]);

    function handlePaymentStep() {
        if (annualPaymentChecked) {
            dispatch(createAnnualPayment(userCount));
        } else if (standardPaymentChecked) {
            dispatch(getUnpaidPayments());
        } else if (perUserPaymentChecked) {
            dispatch(createInstantUserPayment(perUserCount));
        }
    }

    function onChangeUserCount(e) {
        setUserCount(e.target.value);
    }

    function handleUpdateUserCount() {
        const request = selectedPayments.map(({check, ...keepAttrs}) => {
            return ({
                    ...keepAttrs,
                    ["userCount"]: userCount
                }
            );
        });
        dispatch(updateUserCountAndPrice(request));
    }

    function handlePaySelectedPayments() {
        const req = selectedPayments.map(({check, ...keepAttrs}) => keepAttrs);
        dispatch(paymentProcess(req));
    }

    function handleChangeUserCount(e) {
        setUserCount(e.target.value);
    }

    function handleChangePerUserCount(e) {
        setPerUserCount(e.target.value);
    }

    function handleChangeSelectAnnualPayment() {
        setAnnualPaymentChecked(true);
        setPerUserPaymentChecked(false);
        setStandardPaymentChecked(false);
    }

    function handleChangeSelectStandardPayment() {
        setStandardPaymentChecked(true);
        setAnnualPaymentChecked(false);
        setPerUserPaymentChecked(false);
    }

    function handleChangeSelectPerUserPayment() {
        setPerUserPaymentChecked(true);
        setStandardPaymentChecked(false);
        setAnnualPaymentChecked(false);
    }

    function handleChangeSelect(e) {
        let tempArr = selectedPayments;
        const fetchSelectedPayments = async () => {
            try {
                const targetRow = unpaidPayments.filter(payment => payment.id.toString() === e.target.id);
                const condition = isBefore(new Date(targetRow[0].paymentDueDate), new Date()) || isSameDay(new Date(targetRow[0].paymentDueDate), new Date());
                const exist = tempArr.some(item => item.id === targetRow[0].id);
                if (e.target.checked && !exist) {
                    tempArr.push(targetRow[0]);
                } else {
                    if (!condition) {
                        tempArr = tempArr.filter(arrItem => arrItem.id !== targetRow[0].id);
                    }
                }
                return tempArr;
            } catch (err) {
                console.log("fetchPaymentInfoError", err);
            }
        };

        if (unpaidPayments && unpaidPayments.length > 0) {
            fetchSelectedPayments().then(r => {
                setSelectedPayments(r);
                setNextButtonDisabled(false)
            });
        }
    }

    useEffect(() => {
        if (processResponse && processResponse.body && processResponse.body.token) {
            setToken(processResponse.body.token);
        }
    }, [processResponse && processResponse.body && processResponse.body.token]);

    useEffect(() => {
        const fetchMappedPayments = async () => {
            try {
                return await unpaidPayments.map((payment, key) => {
                    const condition = ((isBefore(new Date(payment.paymentDueDate), new Date()) || isSameDay(new Date(payment.paymentDueDate), new Date())));
                    return condition ? {...payment, check: true} : {...payment, check: false};
                });
            } catch (err) {
                console.log("fetchPaymentInfoError", err);
            }
        };

        if (unpaidPayments && unpaidPayments.length > 0) {
            fetchMappedPayments().then(r => {
                setMappedPayments(r);
            });
        }

    }, [unpaidPayments]);


    useEffect(() => {
        const fetchSelectedPayments = async () => {
            try {
                return mappedPayments.filter((payment) => payment.check === true);
            } catch (err) {
                console.log("fetchPaymentInfoError", err);
            }
        };

        if (mappedPayments && mappedPayments.length > 0) {
            fetchSelectedPayments().then(r => {
                console.log("selectedPayments", r), setSelectedPayments(r);
                setNextButtonDisabled(false)
            });
        }
    }, [mappedPayments]);

    function handlePostponePayment() {
        setNavigatePayment(false);
    };

    const {error, success} = useSelector(state => ({
        error: state.Payment.error,
        success: state.Payment.success
    }));

    useEffect(() => {
        if (error && Object.entries(error).length > 0) {
            setMessage(error.data.message);
            setShowNotification(true);
            setNotificationType("Danger");
        } else if (success === true) {
            setMessage(props.t("Success"));
            setShowNotification(true);
            setNotificationType("Success");
        }
    }, [dispatch, error, success])

    const handleClose = () => {
        setMessage("");
        setShowNotification(false);
        setNotificationType("Info")
    };

    return (
        <div className="page-content">
            <Container fluid={true}>
                <Row>
                    <Notification
                        message={message}
                        show={showNotification}
                        type={notificationType}
                        callbackShow={handleClose}
                    />
                    <Card>
                        <CardBody>
                            <div className="wizard clearfix">
                                <Alert color="info" className="text-center">{props.t("PAYMENT_INFO")}</Alert>
                                <div className="steps clearfix">
                                    <ul>
                                        <NavItem
                                            className={classnames({current: activeTab === 1})}
                                        >
                                            <NavLink
                                                className={classnames({current: activeTab === 1})}
                                                onClick={() => {
                                                    setactiveTab(1);
                                                }}
                                                disabled={!(passedSteps || []).includes(1)}
                                            >
                                                        <span
                                                            className="number">1.</span>{props.t("Choose Plan")} ({annualPaymentChecked ? 1 : (standardPaymentChecked ? 2 : (perUserPaymentChecked ? 3 : ""))})
                                            </NavLink>
                                        </NavItem>
                                        <NavItem
                                            className={classnames({current: activeTab === 2})}
                                        >
                                            <NavLink
                                                className={classnames({active: activeTab === 2})}
                                                onClick={() => {
                                                    setactiveTab(2);
                                                }}
                                                disabled={!(passedSteps || []).includes(2)}
                                            >
                                                        <span
                                                            className="number ms-2">2.</span>{props.t("Show Plan Detail")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem
                                            className={classnames({current: activeTab === 3})}
                                        >
                                            <NavLink
                                                className={classnames({active: activeTab === 3})}
                                                onClick={() => {
                                                    setactiveTab(3);
                                                }}
                                                disabled={!(passedSteps || []).includes(3)}
                                            >
                                                <span className="number">3.</span>{props.t("Payment")}
                                            </NavLink>
                                        </NavItem>
                                    </ul>
                                </div>
                                <div className="content clearfix mt-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId={1}>
                                            <Row>
                                                <Col xl="4" sm="12" key={"createPayment"}
                                                     className={annualPaymentChecked ? "card-body shadow-lg bg-gradient border border-3" : "card-body shadow-sm"}>
                                                    <Link to="#" onClick={handleChangeSelectAnnualPayment}>
                                                        <div className="col text-center col-12">
                                                            <div className="avatar-xl m-auto">
                    <span className="avatar-title rounded-circle bg-success bg-opacity-25 text-error font-size-16">
                    <i className="mdi mdi-credit-card-clock-outline text-success display-4"/>
                    </span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1 m-5 overflow-hidden">
                                                                <div className="col text-center col-12">
                                                                    <h5 className="text-truncate font-size-15">
                                                                        {props.t("Annual Payment")}
                                                                    </h5>
                                                                    <div className="row">
                                                                        <p className="text-center font-size-15">
                                                                            {props.t("ANNUAL_PAYMENT_MESSAGE")}
                                                                        </p>
                                                                        <p className="text-center font-size-13">
                                                                            {props.t("User Limitation Count")} : {activeUserCount}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-3">
                                                                    <div className="input-group">
                                                                        <div className="col-4">
                                                                            <Label
                                                                                className="form-label mt-2">{props.t("User Count")}</Label>
                                                                        </div>
                                                                        <div className="col-8">
                                                                            <Input
                                                                                name="userCount"
                                                                                type="text"
                                                                                onChange={handleChangeUserCount}
                                                                                value={userCount || ""}
                                                                                invalid={annualPaymentChecked && (userCount === 0 || activeUserCount > userCount)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </Col>
                                                <Col xl="4" sm="12" key={"seeUnpaid"}
                                                     className={standardPaymentChecked ? "card-body shadow-lg bg-gradient border border-3" : "card-body shadow-sm"}>
                                                    <Link to="#" onClick={handleChangeSelectStandardPayment}>
                                                        <div className="col text-center col-12">
                                                            <div className="avatar-xl m-auto">
                <span className="avatar-title rounded-circle bg-warning bg-opacity-25 text-error font-size-16">
                       <i className="mdi mdi-credit-card-check-outline text-warning display-4"/>
                    </span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1 m-5 overflow-hidden">
                                                                <div className="col text-center col-12">
                                                                    <h5 className="text-truncate font-size-15">
                                                                        {props.t("Standard Payment")}
                                                                    </h5>
                                                                    <div className="row">
                                                                        <p className="text-center font-size-15">
                                                                            {props.t("STANDARD_PAYMENT_MESSAGE")}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </Col>
                                                <Col xl="4" sm="12" key={"instantPayment"}
                                                     className={perUserPaymentChecked ? "card-body shadow-lg bg-gradient border border-3" : "card-body shadow-sm"}>
                                                    <Link to="#" onClick={handleChangeSelectPerUserPayment}>
                                                        <div className="col text-center col-12">
                                                            <div className="avatar-xl m-auto">
                <span className="avatar-title rounded-circle bg-primary bg-opacity-25 text-error font-size-16">
                       <i className="mdi mdi-users-outline text-primary display-4"/>
                    </span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1 m-5 overflow-hidden">
                                                                <div className="col text-center col-12">
                                                                    <h5 className="text-truncate font-size-15">
                                                                        {props.t("User Payment")}
                                                                    </h5>
                                                                    <div className="row">
                                                                        <p className="text-center font-size-15">
                                                                            {props.t("USER_PAYMENT_MESSAGE")}
                                                                        </p>
                                                                    </div>
                                                                    <div className="mt-3">
                                                                        <div className="input-group">
                                                                            <div className="col-4">
                                                                                <Label
                                                                                    className="form-label mt-2">{props.t("User Count")}</Label>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <Input
                                                                                    name="instantPaymentUser"
                                                                                    type="text"
                                                                                    onChange={handleChangePerUserCount}
                                                                                    value={perUserCount || ""}
                                                                                    invalid={perUserPaymentChecked && (perUserCount == 0 || perUserCount === "")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </Col>
                                            </Row>
                                            <Row className="m-5">
                                                {postponeEnabled ? (
                                                    <>
                                                        <Alert
                                                            color="info"
                                                            className="text-center">{props.t("POSTPONE_INFO", {paymentDueDate: formatDateForScreen(paymentDueDate)})}</Alert>
                                                        <Link
                                                            to={dashboard && dashboard.screenUrl || localDashboard}
                                                            className="btn btn-outline-success"
                                                            onClick={handlePostponePayment}
                                                        >
                                                            <i className="mdi mdi-truck-fast me-1"/> {props.t("Postpone Payment")}
                                                        </Link>
                                                    </>
                                                ) : null}
                                            </Row>

                                            <div style={{float: "right"}}>
                                                <Button
                                                    outline
                                                    color="primary"
                                                    disabled={annualPaymentChecked === true && (userCount === "" || activeUserCount > userCount) || perUserPaymentChecked && perUserCount === "" || (standardPaymentChecked === false && perUserPaymentChecked === false && annualPaymentChecked === false)}
                                                    onClick={() => {
                                                        handlePaymentStep();
                                                        toggleTab(2);
                                                    }}
                                                >
                                                    {props.t("Next Step")}
                                                </Button>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            {selectedPayments.length > 0 && !perUserPaymentChecked && !standardPaymentChecked && (
                                                <div className="row">
                                                    <Alert color="warning" style={{marginTop: "3px"}}>
                                                        {props.t("UPDATE_USER_COUNT_BUTTON_MESSAGE", {userCount: activeUserCount})}
                                                    </Alert>

                                                    <div className="col-2 float-end mt-3">
                                                        <Label
                                                            className="form-label">{props.t("User Count")}</Label>
                                                    </div>
                                                    <div className="col-2 float-end">
                                                        <Input
                                                            id="userCount"
                                                            className="mt-2"
                                                            value={userCount}
                                                            onChange={(e) => onChangeUserCount(e)}
                                                        />
                                                    </div>
                                                    <div className="col-4 float-end">
                                                        <Button
                                                            id="updateUserCount"
                                                            type="button"
                                                            color="success"
                                                            className="btn-md btn-rounded m-2"
                                                            disabled={userCount === "" || selectedPayments.length === 0 || activeUserCount > userCount}
                                                            onClick={() => handleUpdateUserCount()}
                                                        >
                                                            <i className="mdi mdi-check me-1"/>
                                                            {props.t("Update User Count & Recalculate")}
                                                        </Button>
                                                    </div>
                                                </div>)}
                                            {mappedPayments && mappedPayments.length > 0 && (
                                                <Table className="table-nowrap mt-5">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col-1">{props.t("#")}</th>
                                                        <th scope="col">{props.t("Payment Period")}</th>
                                                        <th scope="col">{props.t("Payment Amount")}</th>
                                                        <th scope="col">{props.t("Paid Amount")}</th>
                                                        <th scope="col-2">{props.t("User Count")}</th>
                                                        <th scope="col">{props.t("Payment Due Date")}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {mappedPayments.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>
                                                                    <div
                                                                        className="form-check form-checkbox-outline form-check-info mb-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id={item.id}
                                                                            disabled={item.check}
                                                                            defaultChecked={item.check}
                                                                            onChange={handleChangeSelect}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>{item.year}-{item.month}</td>
                                                                <td>{item.paymentAmount ? parseFloat(item.paymentAmount).toFixed(2) : 0} ₺</td>
                                                                <td>{item.paidAmount ? parseFloat(item.paidAmount).toFixed(2) : 0} ₺</td>
                                                                <td>{item.userCount}</td>
                                                                <td>
                                                                    {(item.check) ? (
                                                                        <Badge
                                                                            className={"font-size-14 badge-soft-danger"}
                                                                            color="danger"
                                                                            pill
                                                                        >
                                                                            {DateToString(new Date(item.paymentDueDate), "DD-MM-YYYY", "-")}
                                                                        </Badge>
                                                                    ) : (
                                                                        <Badge
                                                                            className={"font-size-14 badge-soft-warning"}
                                                                            color="warning"
                                                                            pill
                                                                        >
                                                                            {DateToString(new Date(item.paymentDueDate), "DD-MM-YYYY", "-")}
                                                                        </Badge>)}
                                                                </td>
                                                            </tr>);
                                                    })
                                                    }
                                                    </tbody>
                                                </Table>
                                            )}
                                            <div style={{float: "right"}}>
                                                <Button
                                                    className="ms-auto me-0"
                                                    outline
                                                    color="secondary"
                                                    onClick={() => {
                                                        toggleTab(1);
                                                    }}
                                                >
                                                    {props.t("Previous Step")}
                                                </Button>
                                                <Button
                                                    className="ms-auto me-0"
                                                    outline
                                                    color="primary"
                                                    disabled={nextButtonDisabled}
                                                    onClick={() => {
                                                        toggleTab(3);
                                                    }}
                                                >
                                                    {props.t("Next Step")}
                                                </Button>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId={3}>
                                            <div>
                                                <Col xl="12" sm="12">
                                                    <Card className="shadow-none border mb-0">
                                                        {token && token.length > 0 && (
                                                            <PayTRIframe source={token}/>)}
                                                    </Card>
                                                </Col>
                                            </div>
                                            <div style={{float: "right"}}>
                                                <Button
                                                    className="ms-auto me-0"
                                                    outline
                                                    color="secondary"
                                                    onClick={() => {
                                                        toggleTab(2);
                                                    }}
                                                >
                                                    {props.t("Previous Step")}
                                                </Button>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
        </div>
    );
};

PaymentGuard.propTypes = {
    t: PropTypes.any
};

export default withTranslation()(PaymentGuard);
