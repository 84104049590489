import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "reactstrap";

import { withTranslation } from "react-i18next";
import { CreateDate, ReminderDesc } from "../PatientCol";
import { formatDate } from "../../../common/commonFunctions";
import * as url from "../../../helpers/url_helper";
import CustomDatePicker from "../../../components/Common/CustomDatePicker";
import BasicTable from "../../../components/Common/BasicTable";
import Select from "react-select";
import { post } from "../../../helpers/api_helper";

const PatientReminder = props => {

  const [reminderDate, setReminderDate] = useState(null);
  const [reminderDescription, setReminderDescription] = useState("");
  const [selectedReminder, setSelectedReminder] = useState(false);
  const [custReminder, setCustReminder] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [patient, setPatient] = useState({});
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [sectionOptions, setSectionOptions] = useState(props.sectionOptions);
  const [clinicOptions, setClinicOptions] = useState();
  const [selectedSection, setSelectedSection] = useState(null);

  const patientBody = {
    size: 100,
    page: 0,
    patient: patient.id
  };

  useEffect(() => {
    setForceUpdate(props.forceUpdate);
  }, [props.forceUpdate]);

  const reminderColumns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Date")}`,
        accessor: "reminderDate",
        filterable: true,
        className: "col-3",
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Specialist")}`,
        accessor: "reminderUser.fullName",
        filterable: true,
        className: "col-3",
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        accessor: "description",
        filterable: true,
        className: "col-6",
        Cell: (cellProps) => {
          return <ReminderDesc {...cellProps} />;
        }
      }

    ],
    []
  );

  useEffect(() => {
    setPatient(props.patient);
  }, [props.patient]);

  useEffect(() => {
    setSectionOptions(props.sectionOptions);
  }, [props.sectionOptions]);

  useEffect(() => {
    if (forceUpdate && patient.id || patient.id) {
      post(url.GET_PATIENT_REMINDER_CRITERIA, patientBody).then(res => {
        setCustReminder(res.content);
      });
      setForceUpdate(false);
    }
  }, [forceUpdate]);

  const saveReminder = (e) => {
    const filteredDoctor = props.doctors && props.doctors.find(item=>item.id === selectedDoctor.value)

    const createReminder = {
      reminderDate: formatDate(reminderDate),
      description: reminderDescription,
      patient: props.patient,
      reminderUser: filteredDoctor
    };

    post(url.ADD_NEW_PATIENT_REMINDER, createReminder).then((res) => {
      setForceUpdate(true);
    });
    setReminderDate("");
    setReminderDescription("");
    setSelectedReminder(false);
  };

  function onChangeClinicSelect(event) {
    if (event !== null) {
      setSelectedClinic(event);
    }else{
      setSelectedClinic(null)
      setSelectedDoctor(null)
    }
  }

  function onChangeDoctorSelect(event) {
    if (event !== null) {
      setSelectedDoctor(event);
    }else{
      setSelectedDoctor(null)
    }
  }

  function onChangeSectionSelect(event) {
    if (event !== null) {
      setSelectedSection(event);
    } else {
      setSelectedSection(null);
      setSelectedClinic(null);
      setSelectedDoctor(null);
    }
  }

  useEffect(() => {
    if (selectedSection && selectedSection.value) {
      const clinicList = selectedSection && selectedSection.value.departments && selectedSection.value.departments.map(item => ({
        label: item.departmentName,
        value: item
      }));
      setClinicOptions(clinicList);
    }
  }, [selectedSection]);

  useEffect(() => {
    const doctorList = selectedClinic && selectedClinic.value.users && selectedClinic.value.users.map((doctor) => ({
      label: doctor.fullName,
      value: doctor.id
    }));
    setDoctorOptions(doctorList || []);
  }, [selectedClinic]);

  const handleChangeReminderDate = (e) => {
    setSelectedReminder(true);
    setReminderDate(e);
  };

  const handleChangeReminderDescription = (e) => {
    setReminderDescription(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="row mt-3">
        <div className="col-11">
          <div className="row">
            <div className="col-xs-12 col-md-3">
              <label>{props.t("Reminder Time")}</label>
              <CustomDatePicker
                id="reminderDate"
                selected={reminderDate}
                callback={handleChangeReminderDate}
                showTimeSelect={true}
                showTimeSelectOnly={false}
              />
            </div>
            <div className="col-xs-12 col-md-3">
              <label>{props.t("Section")}</label>
              <Select
                className="bs-select"
                value={selectedSection}
                onChange={(e) => onChangeSectionSelect(e)}
                options={sectionOptions}
              />
            </div>
            <div className="col-xs-12 col-md-3">
              <label>{props.t("Clinic")}</label>
              <Select
                className="bs-select"
                value={selectedClinic}
                onChange={(e) => onChangeClinicSelect(e)}
                options={clinicOptions}
              />
            </div>
            <div className="col-xs-12 col-md-3">
              <label>{props.t("Specialist")}</label>
              <Select
                className="bs-select"
                value={selectedDoctor}
                onChange={(e) => onChangeDoctorSelect(e)}
                options={doctorOptions}
              />
            </div>
            <div className="col-xs-12 col-md-12">
              <label>{props.t("Description")}</label>
              <Input
                id="reminderDesc"
                value={reminderDescription}
                onChange={handleChangeReminderDescription}
              />
            </div>
          </div>
        </div>
        <div className="col-1">
          <div className="mt-4 float-end">
            <Button
              id="saveReminder"
              type="button"
              color="success"
              onClick={saveReminder}
              disabled={!selectedReminder}
            >
              {props.t("Create")}
            </Button>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        {custReminder && custReminder.length > 0 ? (
          <BasicTable
            id="custReminder"
            customPageSize={10}
            columns={reminderColumns}
            data={custReminder}
            className="custom-header-basic-css"
          />) : null}
      </div>
    </React.Fragment>
  );
};
PatientReminder.propTypes = {
  doctors: PropTypes.any,
  patient: PropTypes.any,
  clinicOptions: PropTypes.any,
};

export default withTranslation()(PatientReminder);
