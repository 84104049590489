import {call, put, takeEvery} from "redux-saga/effects";

// Departments Redux States
import {ADD_NEW_DEPARTMENT, DELETE_DEPARTMENT, GET_DEPARTMENTS, UPDATE_DEPARTMENT} from "./actionTypes";

import {
    addDepartmentFail,
    addDepartmentSuccess,
    deleteDepartmentFail,
    deleteDepartmentSuccess,
    getDepartmentsFail,
    getDepartmentsSuccess,
    updateDepartmentFail,
    updateDepartmentSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import {del, get, post} from "../../helpers/api_helper";

const lang = localStorage.getItem("i18nextLng").toUpperCase();

function* fetchDepartments() {
    try {
        const response = yield call(getDepartments);
        yield put(getDepartmentsSuccess(response));
    } catch (error) {
        yield put(getDepartmentsFail(error));
    }
}

const getDepartments = () => get(url.DEPARTMENT_GET_ALL + "/" + lang)

function* onUpdateDepartment({payload: department}) {
    try {
        const response = yield call(updateDepartment, department);
        yield put(updateDepartmentSuccess(response));
    } catch (error) {
        yield put(updateDepartmentFail(error));
    }
}

const updateDepartment = (department) => post(url.DEPARTMENT_API, department)

function* onDeleteDepartment({id}) {
    try {
        const response = yield call(deleteDepartment, id);
        if (response.status === 200) {
            yield put(deleteDepartmentSuccess(response, id));
        } else {
            yield put(deleteDepartmentFail(response));
        }
    } catch (error) {
        yield put(deleteDepartmentFail(error));
    }
}

const deleteDepartment = (id) => del(`${url.DEPARTMENT_API}/${id}`);

function* onAddNewDepartment({payload: department}) {
    try {
        const response = yield call(addNewDepartment, department);

        yield put(addDepartmentSuccess(response));
    } catch (error) {
        yield put(addDepartmentFail(error));
    }
}

const addNewDepartment = (department) => post(url.DEPARTMENT_API, department);

function* departmentsSaga() {
    yield takeEvery(GET_DEPARTMENTS, fetchDepartments);
    yield takeEvery(ADD_NEW_DEPARTMENT, onAddNewDepartment);
    yield takeEvery(UPDATE_DEPARTMENT, onUpdateDepartment);
    yield takeEvery(DELETE_DEPARTMENT, onDeleteDepartment);
}

export default departmentsSaga;
