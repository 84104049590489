
/* MAIL_INFOS */
export const GET_MAIL_INFOS = "GET_MAIL_INFOS"
export const GET_MAIL_INFOS_SUCCESS = "GET_MAIL_INFOS_SUCCESS"
export const GET_MAIL_INFOS_FAIL = "GET_MAIL_INFOS_FAIL"

/**
 * add MAIL_INFO
 */
export const ADD_NEW_MAIL_INFO = "ADD_NEW_MAIL_INFO"
export const ADD_MAIL_INFO_SUCCESS = "ADD_MAIL_INFO_SUCCESS"
export const ADD_MAIL_INFO_FAIL = "ADD_MAIL_INFO_FAIL"

/**
 * Edit MAIL_INFO
 */
export const UPDATE_MAIL_INFO = "UPDATE_MAIL_INFO"
export const UPDATE_MAIL_INFO_SUCCESS = "UPDATE_MAIL_INFO_SUCCESS"
export const UPDATE_MAIL_INFO_FAIL = "UPDATE_MAIL_INFO_FAIL"

/**
 * Delete MAIL_INFO
 */
export const DELETE_MAIL_INFO = "DELETE_MAIL_INFO"
export const DELETE_MAIL_INFO_SUCCESS = "DELETE_MAIL_INFO_SUCCESS"
export const DELETE_MAIL_INFO_FAIL = "DELETE_MAIL_INFO_FAIL"
