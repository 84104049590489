import { call, put, takeEvery } from "redux-saga/effects";

// DescriptionTemplates Redux States
import { ADD_NEW_DESCRIPTION_TEMPLATE, DELETE_DESCRIPTION_TEMPLATE, GET_DESCRIPTION_TEMPLATES, UPDATE_DESCRIPTION_TEMPLATE } from "./actionTypes";

import {
    addDescriptionTemplateFail,
    addDescriptionTemplateSuccess,
    deleteDescriptionTemplateFail,
    deleteDescriptionTemplateSuccess,
    getDescriptionTemplatesFail,
    getDescriptionTemplatesSuccess,
    updateDescriptionTemplateFail,
    updateDescriptionTemplateSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchDescriptionTemplates() {
    try {
        const response = yield call(getDescriptionTemplates);
        yield put(getDescriptionTemplatesSuccess(response));
    } catch (error) {
        yield put(getDescriptionTemplatesFail(error));
    }
}

const getDescriptionTemplates = () => get(url.GET_DESCRIPTION_TEMPLATES)

function* onUpdateDescriptionTemplate({ payload: descriptionTemplate }) {
    try {
        const response = yield call(updateDescriptionTemplate, descriptionTemplate);
        yield put(updateDescriptionTemplateSuccess(response));
    } catch (error) {
        yield put(updateDescriptionTemplateFail(error));
    }
}

const updateDescriptionTemplate = (descriptionTemplate) => post(url.UPDATE_DESCRIPTION_TEMPLATE,descriptionTemplate)

function* onDeleteDescriptionTemplate({id}) {
    try {
        const response = yield call(deleteDescriptionTemplate, id);
        yield put(deleteDescriptionTemplateSuccess(response,id));
    } catch (error) {
        yield put(deleteDescriptionTemplateFail(error));
    }
}

const deleteDescriptionTemplate = (id) => del(`${url.DELETE_DESCRIPTION_TEMPLATE}/${id}`);

function* onAddNewDescriptionTemplate({ payload: descriptionTemplate }) {
    try {
        const response = yield call(addNewDescriptionTemplate, descriptionTemplate);

        yield put(addDescriptionTemplateSuccess(response));
    } catch (error) {
        yield put(addDescriptionTemplateFail(error));
    }
}

const addNewDescriptionTemplate = (descriptionTemplate) => post(url.ADD_NEW_DESCRIPTION_TEMPLATE,descriptionTemplate);

function* descriptionTemplatesSaga() {
    yield takeEvery(GET_DESCRIPTION_TEMPLATES, fetchDescriptionTemplates);
    yield takeEvery(ADD_NEW_DESCRIPTION_TEMPLATE, onAddNewDescriptionTemplate);
    yield takeEvery(UPDATE_DESCRIPTION_TEMPLATE, onUpdateDescriptionTemplate);
    yield takeEvery(DELETE_DESCRIPTION_TEMPLATE, onDeleteDescriptionTemplate);
}

export default descriptionTemplatesSaga;
