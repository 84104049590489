import React from "react";

const FormId = (cell) => {
  return cell.value ? cell.value : "";
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

const PageId = (cell) => {
  return cell.value ? cell.value : "";
};

const Deletable = (cell) => {
  return cell.value ? cell.value : "";
};

export {
  FormId,
  Name,
  PageId,
  Deletable
};