import {
    GET_OFFERS,
    GET_OFFERS_FAIL,
    GET_OFFERS_SUCCESS,
    ADD_NEW_OFFER,
    ADD_OFFER_SUCCESS,
    ADD_OFFER_FAIL,
    UPDATE_OFFER,
    UPDATE_OFFER_SUCCESS,
    UPDATE_OFFER_FAIL,
    DELETE_OFFER,
    DELETE_OFFER_SUCCESS,
    DELETE_OFFER_FAIL, GET_TEMPLATE_OFFERS, GET_TEMPLATE_OFFERS_SUCCESS, GET_TEMPLATE_OFFERS_FAIL
} from "./actionTypes";

export const getOffers = () => ({
    type: GET_OFFERS,
})

export const getOffersSuccess = operations => ({
    type: GET_OFFERS_SUCCESS,
    payload: operations,
})

export const getOffersFail = error => ({
    type: GET_OFFERS_FAIL,
    payload: error,
})

export const addNewOffer = operation => ({
    type: ADD_NEW_OFFER,
    payload: operation,
})

export const addOfferSuccess = operation => ({
    type: ADD_OFFER_SUCCESS,
    payload: operation,
})

export const addOfferFail = error => ({
    type: ADD_OFFER_FAIL,
    payload: error,
})

export const updateOffer = operation => ({
    type: UPDATE_OFFER,
    payload: operation,
})

export const updateOfferSuccess = operation => ({
    type: UPDATE_OFFER_SUCCESS,
    payload: operation,
})

export const updateOfferFail = error => ({
    type: UPDATE_OFFER_FAIL,
    payload: error,
})

export const deleteOffer = id => ({
    type: DELETE_OFFER,
    id,
})

export const deleteOfferSuccess = (operation,req) => ({
    type: DELETE_OFFER_SUCCESS,
    payload: operation,
    req:req,
})

export const deleteOfferFail = error => ({
    type: DELETE_OFFER_FAIL,
    payload: error,
})

export const getTemplateOffers = () => ({
    type: GET_TEMPLATE_OFFERS,
})

export const getTemplateOffersSuccess = operations => ({
    type: GET_TEMPLATE_OFFERS_SUCCESS,
    payload: operations,
})

export const getTemplateOffersFail = error => ({
    type: GET_TEMPLATE_OFFERS_FAIL,
    payload: error,
})