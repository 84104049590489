import {
    ADD_USER_WORK_SCHEDULE_FAIL,
    ADD_USER_WORK_SCHEDULE_SUCCESS,
    ADD_NEW_USER_WORK_SCHEDULE,
    DELETE_USER_WORK_SCHEDULE,
    DELETE_USER_WORK_SCHEDULE_FAIL,
    DELETE_USER_WORK_SCHEDULE_SUCCESS,
    GET_USER_WORK_SCHEDULES,
    GET_USER_WORK_SCHEDULES_FAIL,
    GET_USER_WORK_SCHEDULES_SUCCESS,
    UPDATE_USER_WORK_SCHEDULE,
    UPDATE_USER_WORK_SCHEDULE_FAIL,
    UPDATE_USER_WORK_SCHEDULE_SUCCESS,
    GET_USER_WORK_SCHEDULE_BY_ID,
    GET_USER_WORK_SCHEDULE_BY_ID_SUCCESS,
    GET_USER_WORK_SCHEDULE_BY_ID_FAIL
} from "./actionTypes";
import { GET_USER_PROFILE, GET_USER_PROFILE_FAIL, GET_USER_PROFILE_SUCCESS } from "../users/actionTypes";

export const getUserWorkSchedules = () => ({
    type: GET_USER_WORK_SCHEDULES,
})

export const getUserWorkSchedulesSuccess = userWorkSchedules => ({
    type: GET_USER_WORK_SCHEDULES_SUCCESS,
    payload: userWorkSchedules,
})

export const getUserWorkSchedulesFail = error => ({
    type: GET_USER_WORK_SCHEDULES_FAIL,
    payload: error,
})

export const addNewUserWorkSchedule = userWorkSchedule => ({
    type: ADD_NEW_USER_WORK_SCHEDULE,
    payload: userWorkSchedule,
})

export const addUserWorkScheduleSuccess = userWorkSchedule => ({
    type: ADD_USER_WORK_SCHEDULE_SUCCESS,
    payload: userWorkSchedule,
})

export const addUserWorkScheduleFail = error => ({
    type: ADD_USER_WORK_SCHEDULE_FAIL,
    payload: error,
})

export const updateUserWorkSchedule = userWorkSchedule => ({
    type: UPDATE_USER_WORK_SCHEDULE,
    payload: userWorkSchedule,
})

export const updateUserWorkScheduleSuccess = userWorkSchedule => ({
    type: UPDATE_USER_WORK_SCHEDULE_SUCCESS,
    payload: userWorkSchedule,
})

export const updateUserWorkScheduleFail = error => ({
    type: UPDATE_USER_WORK_SCHEDULE_FAIL,
    payload: error,
})

export const deleteUserWorkSchedule = id => ({
    type: DELETE_USER_WORK_SCHEDULE,
    id,
})

export const deleteUserWorkScheduleSuccess = (userWorkSchedule,req) => ({
    type: DELETE_USER_WORK_SCHEDULE_SUCCESS,
    payload: userWorkSchedule,
    req: req,
})

export const deleteUserWorkScheduleFail = error => ({
    type: DELETE_USER_WORK_SCHEDULE_FAIL,
    payload: error,
})


export const getUserWorkScheduleById = (id) => ({
    type: GET_USER_WORK_SCHEDULE_BY_ID,
    payload: id
});

export const getUserWorkScheduleByIdSuccess = userProfile => ({
    type: GET_USER_WORK_SCHEDULE_BY_ID_SUCCESS,
    payload: userProfile
});

export const getUserWorkScheduleByIdFail = error => ({
    type: GET_USER_WORK_SCHEDULE_BY_ID_FAIL,
    payload: error
});