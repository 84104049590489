import { call, put, takeEvery } from "redux-saga/effects";

// MeetingRoomReservations Redux States
import {
  ADD_NEW_MEETING_ROOM_RESERVATION,
  DELETE_MEETING_ROOM_RESERVATION,
  GET_MEETING_ROOM_RESERVATIONS,
  GET_MEETING_ROOM_RESERVATIONS_BY_ROOM,
  GET_USER_MEETING_ROOM_RESERVATIONS,
  UPDATE_MEETING_ROOM_RESERVATION
} from "./actionTypes";

import {
  addMeetingRoomReservationFail,
  addMeetingRoomReservationSuccess,
  deleteMeetingRoomReservationFail,
  deleteMeetingRoomReservationSuccess, getMeetingRoomReservationsByRoomFail, getMeetingRoomReservationsByRoomSuccess,
  getMeetingRoomReservationsFail,
  getMeetingRoomReservationsSuccess,
  getUserMeetingRoomReservationsFail,
  getUserMeetingRoomReservationsSuccess,
  updateMeetingRoomReservationFail,
  updateMeetingRoomReservationSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchMeetingRoomReservations() {
  try {
    const response = yield call(getMeetingRoomReservations);
    yield put(getMeetingRoomReservationsSuccess(response));
  } catch (error) {
    yield put(getMeetingRoomReservationsFail(error));
  }
}

const getMeetingRoomReservations = () => get(url.MEETING_ROOM_RESERVATION_GET_ALL);

function* onUpdateMeetingRoomReservation({ payload: meetingRoomReservation }) {
  try {
    const response = yield call(updateMeetingRoomReservation, meetingRoomReservation);
    yield put(updateMeetingRoomReservationSuccess(response));
  } catch (error) {
    yield put(updateMeetingRoomReservationFail(error));
  }
}

const updateMeetingRoomReservation = (meetingRoomReservation) => post(url.MEETING_ROOM_RESERVATION, meetingRoomReservation);

function* onDeleteMeetingRoomReservation({ id }) {
  try {
    const response = yield call(deleteMeetingRoomReservation, id);
    yield put(deleteMeetingRoomReservationSuccess(response, id));
  } catch (error) {
    yield put(deleteMeetingRoomReservationFail(error));
  }
}

const deleteMeetingRoomReservation = (id) => del(`${url.MEETING_ROOM_RESERVATION}/${id}`);

function* onAddNewMeetingRoomReservation({ payload: meetingRoomReservation }) {
  try {
    const response = yield call(addNewMeetingRoomReservation, meetingRoomReservation);
    yield put(addMeetingRoomReservationSuccess(response));
  } catch (error) {
    yield put(addMeetingRoomReservationFail(error));
  }
}

const addNewMeetingRoomReservation = (meetingRoomReservation) => post(url.MEETING_ROOM_RESERVATION, meetingRoomReservation);

function* fetchUserMeetingRoomReservations({ id }) {
  try {
    const response = yield call(getUserMeetingRoomReservations, id);
    yield put(getUserMeetingRoomReservationsSuccess(response));
  } catch (error) {
    yield put(getUserMeetingRoomReservationsFail(error));
  }
}

const getUserMeetingRoomReservations = (id) => get(`${url.MEETING_ROOM_RESERVATION}/${id}`);

function* fetchMeetingRoomReservationsByRoom({ id }) {
  try {
    const response = yield call(getMeetingRoomReservationsByRoomId, id);
    yield put(getMeetingRoomReservationsByRoomSuccess(response));
  } catch (error) {
    yield put(getMeetingRoomReservationsByRoomFail(error));
  }
}

const getMeetingRoomReservationsByRoomId = (id) => get(`${url.MEETING_ROOM_RESERVATION}/getByMeetingRoomId/${id}`);

function* meetingRoomReservationsSaga() {
  yield takeEvery(GET_MEETING_ROOM_RESERVATIONS, fetchMeetingRoomReservations);
  yield takeEvery(ADD_NEW_MEETING_ROOM_RESERVATION, onAddNewMeetingRoomReservation);
  yield takeEvery(UPDATE_MEETING_ROOM_RESERVATION, onUpdateMeetingRoomReservation);
  yield takeEvery(DELETE_MEETING_ROOM_RESERVATION, onDeleteMeetingRoomReservation);
  yield takeEvery(GET_USER_MEETING_ROOM_RESERVATIONS, fetchUserMeetingRoomReservations);
  yield takeEvery(GET_MEETING_ROOM_RESERVATIONS_BY_ROOM, fetchMeetingRoomReservationsByRoom);
}

export default meetingRoomReservationsSaga;