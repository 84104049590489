import {
    GET_PLATFORMS,
    GET_PLATFORMS_FAIL,
    GET_PLATFORMS_SUCCESS,
    ADD_NEW_PLATFORM,
    ADD_PLATFORM_SUCCESS,
    ADD_PLATFORM_FAIL,
    UPDATE_PLATFORM,
    UPDATE_PLATFORM_SUCCESS,
    UPDATE_PLATFORM_FAIL,
    DELETE_PLATFORM,
    DELETE_PLATFORM_SUCCESS,
    DELETE_PLATFORM_FAIL,
} from "./actionTypes"

export const getPlatforms = () => ({
    type: GET_PLATFORMS,
})

export const getPlatformsSuccess =platforms => ({
    type: GET_PLATFORMS_SUCCESS,
    payload:platforms,
})

export const getPlatformsFail = error => ({
    type: GET_PLATFORMS_FAIL,
    payload: error,
})

export const addNewPlatform =platform => ({
    type: ADD_NEW_PLATFORM,
    payload:platform,
})

export const addPlatformSuccess =platform => ({
    type: ADD_PLATFORM_SUCCESS,
    payload:platform,
})

export const addPlatformFail = error => ({
    type: ADD_PLATFORM_FAIL,
    payload: error,
})

export const updatePlatform =platform => ({
    type: UPDATE_PLATFORM,
    payload:platform,
})

export const updatePlatformSuccess =platform => ({
    type: UPDATE_PLATFORM_SUCCESS,
    payload:platform,
})

export const updatePlatformFail = error => ({
    type: UPDATE_PLATFORM_FAIL,
    payload: error,
})

export const deletePlatform = id => ({
    type: DELETE_PLATFORM,
    id,
})

export const deletePlatformSuccess =(platform,req) => ({
    type: DELETE_PLATFORM_SUCCESS,
    payload:platform,
    req:req,
})

export const deletePlatformFail = error => ({
    type: DELETE_PLATFORM_FAIL,
    payload: error,
})