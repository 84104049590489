import { call, put, takeEvery } from "redux-saga/effects";

// Offers Redux States
import { ADD_NEW_OFFER, DELETE_OFFER, GET_OFFERS, GET_TEMPLATE_OFFERS, UPDATE_OFFER } from "./actionTypes";

import {
    addOfferFail,
    addOfferSuccess,
    deleteOfferFail,
    deleteOfferSuccess,
    getOffersFail,
    getOffersSuccess, getTemplateOffersFail, getTemplateOffersSuccess,
    updateOfferFail,
    updateOfferSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";
import { OFFER_TEMPLATES } from "../../helpers/url_helper";

function* fetchOffers() {
    try {
        const response = yield call(getOffers);
        yield put(getOffersSuccess(response));
    } catch (error) {
        yield put(getOffersFail(error));
    }
}

const getOffers = () => get(url.GET_OFFERS)

function* fetchTemplateOffers() {
    try {
        const response = yield call(getTemplateOfferList);
        yield put(getTemplateOffersSuccess(response));
    } catch (error) {
        yield put(getTemplateOffersFail(error));
    }
}

const getTemplateOfferList = () => get(url.OFFER_TEMPLATES)

function* onUpdateOffer({ payload: operationDefinition }) {
    try {
        const response = yield call(updateOffer, operationDefinition);
        yield put(updateOfferSuccess(response));
    } catch (error) {
        yield put(updateOfferFail(error));
    }
}

const updateOffer = (operationDefinition) => post(url.UPDATE_OFFER,operationDefinition)

function* onDeleteOffer({id}) {
    try {
        const response = yield call(deleteOffer, id);
        if (response.status === 200){
            yield put(deleteOfferSuccess(response,id));
        }else{
            yield put(deleteOfferFail(response));
        }
    } catch (error) {
        yield put(deleteOfferFail(error));
    }
}

const deleteOffer = (id) => del(`${url.DELETE_OFFER}/${id}`);

function* onAddNewOffer({ payload: operationDefinition }) {
    try {
        const response = yield call(addNewOffer, operationDefinition);
        yield put(addOfferSuccess(response));
    } catch (error) {
        yield put(addOfferFail(error));
    }
}

const addNewOffer = (operationDefinition) => post(url.ADD_NEW_OFFER,operationDefinition);

function* offerSaga() {
    yield takeEvery(GET_OFFERS, fetchOffers);
    yield takeEvery(GET_TEMPLATE_OFFERS, fetchTemplateOffers);
    yield takeEvery(ADD_NEW_OFFER, onAddNewOffer);
    yield takeEvery(UPDATE_OFFER, onUpdateOffer);
    yield takeEvery(DELETE_OFFER, onDeleteOffer);
}

export default offerSaga;
