import {
    ADD_SECTION_FAIL,
    ADD_SECTION_SUCCESS,
    ADD_NEW_SECTION,
    DELETE_SECTION,
    DELETE_SECTION_FAIL,
    DELETE_SECTION_SUCCESS,
    GET_SECTIONS,
    GET_SECTIONS_FAIL,
    GET_SECTIONS_SUCCESS,
    UPDATE_SECTION,
    UPDATE_SECTION_FAIL,
    UPDATE_SECTION_SUCCESS
} from "./actionTypes";

export const getSections = () => ({
    type: GET_SECTIONS,
})

export const getSectionsSuccess = groups => ({
    type: GET_SECTIONS_SUCCESS,
    payload: groups,
})

export const getSectionsFail = error => ({
    type: GET_SECTIONS_FAIL,
    payload: error,
})

export const addNewSection = group => ({
    type: ADD_NEW_SECTION,
    payload: group,
})

export const addSectionSuccess = group => ({
    type: ADD_SECTION_SUCCESS,
    payload: group,
})

export const addSectionFail = error => ({
    type: ADD_SECTION_FAIL,
    payload: error,
})

export const updateSection = group => ({
    type: UPDATE_SECTION,
    payload: group,
})

export const updateSectionSuccess = group => ({
    type: UPDATE_SECTION_SUCCESS,
    payload: group,
})

export const updateSectionFail = error => ({
    type: UPDATE_SECTION_FAIL,
    payload: error,
})

export const deleteSection = id => ({
    type: DELETE_SECTION,
    id,
})

export const deleteSectionSuccess = (group,req) => ({
    type: DELETE_SECTION_SUCCESS,
    payload: group,
    req: req,
})

export const deleteSectionFail = error => ({
    type: DELETE_SECTION_FAIL,
    payload: error,
})
