import PropTypes from "prop-types";
import React, { useEffect } from "react";
import withRouter from "../../hooks/withRouter";

import { logoutUser } from "../../store/actions";

//redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Logout = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authUser, setAuthUser, setScreenList, setScreenActionList } = useAuth();

  useEffect(() => {
    setAuthUser(null);
    localStorage.setItem("authUser", null);
    dispatch(logoutUser(props.history));
    navigate("/login");
    setScreenList([]);
    setScreenActionList([]);
  }, []);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object
};

export default withRouter(Logout);
