import {
  ADD_PATIENT_REMINDER_FAIL,
  ADD_PATIENT_REMINDER_SUCCESS,
  DELETE_PATIENT_REMINDER_FAIL,
  DELETE_PATIENT_REMINDER_SUCCESS,
  GET_PATIENT_REMINDERS_FAIL,
  GET_PATIENT_REMINDERS_SUCCESS,
  GET_PATIENT_REMINDER_COUNT_FAIL,
  GET_PATIENT_REMINDER_COUNT_SUCCESS,
  GET_PATIENT_REMINDER_CRITERIA_FAIL,
  GET_PATIENT_REMINDER_CRITERIA_SUCCESS,
  GET_TODAY_PATIENT_REMINDERS_FAIL,
  GET_TODAY_PATIENT_REMINDERS_SUCCESS,
  GET_USER_PATIENT_REMINDERS_FAIL,
  GET_USER_PATIENT_REMINDERS_SUCCESS,
  UPDATE_PATIENT_REMINDER_FAIL,
  UPDATE_PATIENT_REMINDER_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  reminders: [],
  reminderCount: 0,
  error: {}
};

const PatientReminder = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PATIENT_REMINDERS_SUCCESS:
      return {
        ...state,
        reminders: action.payload
      };

    case GET_PATIENT_REMINDERS_FAIL:
      return {
        ...state,
        error: action.payload
      };
      
    case GET_USER_PATIENT_REMINDERS_SUCCESS:
      return {
        ...state,
        reminders: action.payload
      };

    case GET_USER_PATIENT_REMINDERS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_PATIENT_REMINDER_SUCCESS:
      return {
        ...state,
        reminders: [...state.reminders, action.payload]
      };

    case ADD_PATIENT_REMINDER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_PATIENT_REMINDER_SUCCESS:
      return {
        ...state,
        reminders: state.reminders.map(reminder =>
          reminder.id.toString() === action.payload.id.toString()
            ? { reminder, ...action.payload }
            : reminder
        )
      };

    case UPDATE_PATIENT_REMINDER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_PATIENT_REMINDER_SUCCESS:
      return {
        ...state,
        reminders: state.reminders.filter(
          reminder => reminder.id.toString() !== action.req.toString()
        )
      };

    case DELETE_PATIENT_REMINDER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_TODAY_PATIENT_REMINDERS_SUCCESS:
      return {
        ...state,
        reminders: action.payload
      };

    case GET_TODAY_PATIENT_REMINDERS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_PATIENT_REMINDER_CRITERIA_SUCCESS:
      return {
        ...state,
        reminders: action.payload.content
      };

    case GET_PATIENT_REMINDER_CRITERIA_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_PATIENT_REMINDER_COUNT_SUCCESS:
      return {
        ...state,
        reminderCount: action.payload
      };

    case GET_PATIENT_REMINDER_COUNT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return {
        ...state
      };
  }
};

export default PatientReminder;
