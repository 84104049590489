import {
    GET_OFFER_ITEMS_FAIL,
    GET_OFFER_ITEMS_SUCCESS,
    ADD_OFFER_ITEM_SUCCESS,
    ADD_OFFER_ITEM_FAIL,
    UPDATE_OFFER_ITEM_SUCCESS,
    UPDATE_OFFER_ITEM_FAIL,
    DELETE_OFFER_ITEM_SUCCESS,
    DELETE_OFFER_ITEM_FAIL, GET_TEMPLATE_OFFER_ITEMS_FAIL, GET_TEMPLATE_OFFER_ITEMS_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    offerItems: [],
    error: {},
};

const OfferItem = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_OFFER_ITEMS_SUCCESS:
            return {
                ...state,
                offerItems: action.payload,
            };

        case GET_OFFER_ITEMS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case GET_TEMPLATE_OFFER_ITEMS_SUCCESS:
            return {
                ...state,
                offerItems: action.payload,
            };

        case GET_TEMPLATE_OFFER_ITEMS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_OFFER_ITEM_SUCCESS:
            return {
                ...state,
                offerItems: [...state.offerItems, action.payload],
            };

        case ADD_OFFER_ITEM_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_OFFER_ITEM_SUCCESS:
            return {
                ...state,
                offerItems: state.offerItems.map(offerItem =>
                    offerItem.id.toString() === action.payload.id.toString()
                        ? { offerItem, ...action.payload }
                        : offerItem
                ),
            };

        case UPDATE_OFFER_ITEM_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_OFFER_ITEM_SUCCESS:
            return {
                ...state,
                offerItems: state.offerItems.filter(
                    offerItem => offerItem.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_OFFER_ITEM_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default OfferItem;
