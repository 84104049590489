import { call, put, takeEvery } from "redux-saga/effects";

// Forms Redux States
import { ADD_NEW_FORM, DELETE_FORM, GET_FORMS, UPDATE_FORM } from "./actionTypes";

import {
    addFormFail,
    addFormSuccess,
    deleteFormFail,
    deleteFormSuccess,
    getFormsFail,
    getFormsSuccess,
    updateFormFail,
    updateFormSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchForms() {
    try {
        const response = yield call(getForms);
        yield put(getFormsSuccess(response));
    } catch (error) {
        yield put(getFormsFail(error));
    }
}

const getForms = () => get(url.GET_FORMS)

function* onUpdateForm({ payload: form }) {
    try {
        const response = yield call(updateForm, form);
        yield put(updateFormSuccess(response));
    } catch (error) {
        yield put(updateFormFail(error));
    }
}

const updateForm = (form) => post(url.UPDATE_FORM,form)

function* onDeleteForm({ id }) {
    try {
        const response = yield call(deleteForm, id);
        if (response.status === 200){
            yield put(deleteFormSuccess(response,id));
        }else{
            yield put(deleteFormFail(response));
        }
    } catch (error) {
        yield put(deleteFormFail(error));
    }
}

const deleteForm = (id) => del(`${url.DELETE_FORM}/${id}`);

function* onAddNewForm({ payload: form }) {
    try {
        const response = yield call(addNewForm, form);

        yield put(addFormSuccess(response));
    } catch (error) {
        yield put(addFormFail(error));
    }
}

const addNewForm = (form) => post(url.ADD_NEW_FORM,form);

function* integrationDefinitionSaga() {
    yield takeEvery(GET_FORMS, fetchForms);
    yield takeEvery(ADD_NEW_FORM, onAddNewForm);
    yield takeEvery(UPDATE_FORM, onUpdateForm);
    yield takeEvery(DELETE_FORM, onDeleteForm);
}

export default integrationDefinitionSaga;
