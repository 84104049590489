
/* OFFERS */
export const GET_OFFERS = "GET_OFFERS"
export const GET_OFFERS_SUCCESS = "GET_OFFERS_SUCCESS"
export const GET_OFFERS_FAIL = "GET_OFFERS_FAIL"

/* OFFERS */
export const GET_TEMPLATE_OFFERS = "GET_TEMPLATE_OFFERS"
export const GET_TEMPLATE_OFFERS_SUCCESS = "GET_TEMPLATE_OFFERS_SUCCESS"
export const GET_TEMPLATE_OFFERS_FAIL = "GET_TEMPLATE_OFFERS_FAIL"

/**
 * add OFFER
 */
export const ADD_NEW_OFFER = "ADD_NEW_OFFER"
export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS"
export const ADD_OFFER_FAIL = "ADD_OFFER_FAIL"

/**
 * Edit OFFER
 */
export const UPDATE_OFFER = "UPDATE_OFFER"
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS"
export const UPDATE_OFFER_FAIL = "UPDATE_OFFER_FAIL"

/**
 * Delete OFFER
 */
export const DELETE_OFFER = "DELETE_OFFER"
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS"
export const DELETE_OFFER_FAIL = "DELETE_OFFER_FAIL"
