import { call, put, takeEvery } from "redux-saga/effects";

// Reminders Redux States
import {
  ADD_NEW_REMINDER,
  DELETE_REMINDER,
  GET_REMINDERS,
  UPDATE_REMINDER,
  GET_LEAD_REMINDERS,
  GET_USER_REMINDERS,
  GET_TODAY_REMINDERS, GET_REMINDER_CRITERIA, GET_REMINDER_COUNT
} from "./actionTypes";

import {
  addReminderFail,
  addReminderSuccess,
  deleteReminderFail,
  deleteReminderSuccess,
  getRemindersFail,
  getRemindersSuccess,
  updateReminderFail,
  updateReminderSuccess,
  getLeadRemindersFail,
  getLeadRemindersSuccess,
  getUserRemindersSuccess,
  getUserRemindersFail,
  getTodayRemindersSuccess,
  getTodayRemindersFail,
  getReminderCriteriaSuccess,
  getReminderCriteriaFail,
  getReminderCountSuccess,
  getReminderCountFail
} from "./actions";
import * as url from "../../helpers/url_helper";
import { get, post, del } from "../../helpers/api_helper";
import { GET_LEAD_REMINDER } from "../../helpers/url_helper";

function* fetchLeadReminders({ id }) {
  try {
    const response = yield call(getLeadReminders, id);
    yield put(getLeadRemindersSuccess(response));
  } catch (error) {
    yield put(getLeadRemindersFail(error));
  }
}

const getLeadReminders = (id) => get(`${url.GET_LEAD_REMINDER}/${id}`);

function* fetchReminders() {
  try {
    const response = yield call(getReminders);
    yield put(getRemindersSuccess(response));
  } catch (error) {
    yield put(getRemindersFail(error));
  }
}

const getReminders = () => get(url.GET_REMINDERS);

function* onUpdateReminder({ payload: reminder }) {
  try {
    const response = yield call(updateReminder, reminder);
    yield put(updateReminderSuccess(response));
  } catch (error) {
    yield put(updateReminderFail(error));
  }
}

const updateReminder = (reminder) => post(url.UPDATE_REMINDER, reminder);

function* onDeleteReminder({ id }) {
  try {
    const response = yield call(deleteReminder, id);
    yield put(deleteReminderSuccess(response, id));
  } catch (error) {
    yield put(deleteReminderFail(error));
  }
}

const deleteReminder = (id) => del(`${url.DELETE_REMINDER}/${id}`);

function* onAddNewReminder({ payload: reminder }) {
  try {
    const response = yield call(addNewReminder, reminder);
    yield put(addReminderSuccess(response));
  } catch (error) {
    yield put(addReminderFail(error));
  }
}

const addNewReminder = (reminder) => post(url.ADD_NEW_REMINDER, reminder);

function* fetchUserReminders({ id }) {
  try {
    const response = yield call(getUserReminders, id);
    yield put(getUserRemindersSuccess(response));
  } catch (error) {
    yield put(getUserRemindersFail(error));
  }
}

const getUserReminders = (id) => get(`${url.GET_USER_REMINDERS}/${id}`);

function* fetchTodayReminders() {
  try {
    const response = yield call(getTodayReminders);
    yield put(getTodayRemindersSuccess(response));
  } catch (error) {
    yield put(getTodayRemindersFail(error));
  }
}

const getTodayReminders = () => get(url.GET_TODAY_REMINDER);


function* fetchReminderCriteria({ payload: body }) {
  try {
    const response = yield call(getReminderCriteria, body);
    yield put(getReminderCriteriaSuccess(response));
  } catch (error) {
    yield put(getReminderCriteriaFail(error));
  }
}

const getReminderCriteria = (body) => post(url.REMINDER_CRITERIA, body);

function* fetchReminderCount() {
  try {
    const response = yield call(getReminderCount);
    yield put(getReminderCountSuccess(response));
  } catch (error) {
    yield put(getReminderCountFail(error));
  }
}

const getReminderCount = () => get(url.GET_NOTIFICATION_COUNT);

function* remindersSaga() {
  yield takeEvery(GET_REMINDERS, fetchReminders);
  yield takeEvery(ADD_NEW_REMINDER, onAddNewReminder);
  yield takeEvery(UPDATE_REMINDER, onUpdateReminder);
  yield takeEvery(DELETE_REMINDER, onDeleteReminder);
  yield takeEvery(GET_LEAD_REMINDERS, fetchLeadReminders);
  yield takeEvery(GET_USER_REMINDERS, fetchUserReminders);
  yield takeEvery(GET_TODAY_REMINDERS, fetchTodayReminders);
  yield takeEvery(GET_REMINDER_CRITERIA, fetchReminderCriteria);
  yield takeEvery(GET_REMINDER_COUNT, fetchReminderCount);
}

export default remindersSaga;
