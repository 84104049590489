import {
  GET_APPOINTMENT_HISTORY_BY_FILTER_FAIL, GET_APPOINTMENT_HISTORY_BY_FILTER_SUCCESS,
  GET_APPOINTMENT_HISTORY_FAIL,
  GET_APPOINTMENT_HISTORY_SUCCESS, GET_LEAD_HISTORY_BY_FILTER_FAIL, GET_LEAD_HISTORY_BY_FILTER_SUCCESS,
  GET_LEAD_HISTORY_FAIL,
  GET_LEAD_HISTORY_SUCCESS, GET_NOTE_HISTORY_BY_FILTER_FAIL, GET_NOTE_HISTORY_BY_FILTER_SUCCESS,
  GET_NOTE_HISTORY_FAIL,
  GET_NOTE_HISTORY_SUCCESS, GET_REMINDER_HISTORY_BY_FILTER_FAIL, GET_REMINDER_HISTORY_BY_FILTER_SUCCESS,
  GET_REMINDER_HISTORY_FAIL,
  GET_REMINDER_HISTORY_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  leadHistory: [],
  appointmentHistory: [],
  noteHistory: [],
  reminderHistory: [],
  error: {}
};

const History = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEAD_HISTORY_SUCCESS:
      return {
        ...state,
        leadHistory: action.payload
      };

    case GET_LEAD_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_LEAD_HISTORY_BY_FILTER_SUCCESS:
      return {
        ...state,
        leadHistory: action.payload
      };

    case GET_LEAD_HISTORY_BY_FILTER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_APPOINTMENT_HISTORY_SUCCESS:
      return {
        ...state,
        appointmentHistory: action.payload
      };

    case GET_APPOINTMENT_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_APPOINTMENT_HISTORY_BY_FILTER_SUCCESS:
      return {
        ...state,
        appointmentHistory: action.payload
      };

    case GET_APPOINTMENT_HISTORY_BY_FILTER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_NOTE_HISTORY_SUCCESS:
      return {
        ...state,
        noteHistory: action.payload
      };

    case GET_NOTE_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_NOTE_HISTORY_BY_FILTER_SUCCESS:
      return {
        ...state,
        noteHistory: action.payload
      };

    case GET_NOTE_HISTORY_BY_FILTER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_REMINDER_HISTORY_SUCCESS:
      return {
        ...state,
        reminderHistory: action.payload
      };

    case GET_REMINDER_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_REMINDER_HISTORY_BY_FILTER_SUCCESS:
      return {
        ...state,
        reminderHistory: action.payload
      };

    case GET_REMINDER_HISTORY_BY_FILTER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default History;
