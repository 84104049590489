import {
  GET_FORMS,
  GET_FORMS_FAIL,
  GET_FORMS_SUCCESS,
  ADD_NEW_FORM,
  ADD_FORM_SUCCESS,
  ADD_FORM_FAIL,
  UPDATE_FORM,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_FAIL,
  DELETE_FORM,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_FAIL
} from "./actionTypes";

export const getForms = () => ({
  type: GET_FORMS
});

export const getFormsSuccess = forms => ({
  type: GET_FORMS_SUCCESS,
  payload: forms
});

export const getFormsFail = error => ({
  type: GET_FORMS_FAIL,
  payload: error
});

export const addNewForm = form => ({
  type: ADD_NEW_FORM,
  payload: form
});

export const addFormSuccess = form => ({
  type: ADD_FORM_SUCCESS,
  payload: form
});

export const addFormFail = error => ({
  type: ADD_FORM_FAIL,
  payload: error
});

export const updateForm = form => ({
  type: UPDATE_FORM,
  payload: form
});

export const updateFormSuccess = form => ({
  type: UPDATE_FORM_SUCCESS,
  payload: form
});

export const updateFormFail = error => ({
  type: UPDATE_FORM_FAIL,
  payload: error
});

export const deleteForm = id => ({
  type: DELETE_FORM,
  id
});

export const deleteFormSuccess = (form, req) => ({
  type: DELETE_FORM_SUCCESS,
  payload: form,
  req: req
});

export const deleteFormFail = error => ({
  type: DELETE_FORM_FAIL,
  payload: error
});