
/* OFFER_ITEMS */
export const GET_OFFER_ITEMS = "GET_OFFER_ITEMS"
export const GET_OFFER_ITEMS_SUCCESS = "GET_OFFER_ITEMS_SUCCESS"
export const GET_OFFER_ITEMS_FAIL = "GET_OFFER_ITEMS_FAIL"

/* OFFER_ITEMS */
export const GET_TEMPLATE_OFFER_ITEMS = "GET_TEMPLATE_OFFER_ITEMS"
export const GET_TEMPLATE_OFFER_ITEMS_SUCCESS = "GET_TEMPLATE_OFFER_ITEMS_SUCCESS"
export const GET_TEMPLATE_OFFER_ITEMS_FAIL = "GET_TEMPLATE_OFFER_ITEMS_FAIL"

/**
 * add OFFER_ITEM
 */
export const ADD_NEW_OFFER_ITEM = "ADD_NEW_OFFER_ITEM"
export const ADD_OFFER_ITEM_SUCCESS = "ADD_OFFER_ITEM_SUCCESS"
export const ADD_OFFER_ITEM_FAIL = "ADD_OFFER_ITEM_FAIL"

/**
 * Edit OFFER_ITEM
 */
export const UPDATE_OFFER_ITEM = "UPDATE_OFFER_ITEM"
export const UPDATE_OFFER_ITEM_SUCCESS = "UPDATE_OFFER_ITEM_SUCCESS"
export const UPDATE_OFFER_ITEM_FAIL = "UPDATE_OFFER_ITEM_FAIL"

/**
 * Delete OFFER_ITEM
 */
export const DELETE_OFFER_ITEM = "DELETE_OFFER_ITEM"
export const DELETE_OFFER_ITEM_SUCCESS = "DELETE_OFFER_ITEM_SUCCESS"
export const DELETE_OFFER_ITEM_FAIL = "DELETE_OFFER_ITEM_FAIL"
