import { call, put, takeEvery } from "redux-saga/effects";

// MailInfos Redux States
import { ADD_NEW_MAIL_INFO, DELETE_MAIL_INFO, GET_MAIL_INFOS, UPDATE_MAIL_INFO } from "./actionTypes";

import {
    addMailInfoFail,
    addMailInfoSuccess,
    deleteMailInfoFail,
    deleteMailInfoSuccess,
    getMailInfosFail,
    getMailInfosSuccess,
    updateMailInfoFail,
    updateMailInfoSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchMailInfos() {
    try {
        const response = yield call(getMailInfos);
        yield put(getMailInfosSuccess(response));
    } catch (error) {
        yield put(getMailInfosFail(error));
    }
}

const getMailInfos = () => get(url.GET_MAIL_INFO)

function* onUpdateMailInfo({ payload: operationDefinition }) {
    try {
        const response = yield call(updateMailInfo, operationDefinition);
        yield put(updateMailInfoSuccess(response));
    } catch (error) {
        yield put(updateMailInfoFail(error));
    }
}

const updateMailInfo = (operationDefinition) => post(url.UPDATE_MAIL_INFO,operationDefinition)

function* onDeleteMailInfo({id}) {
    try {
        const response = yield call(deleteMailInfo, id);
        if (response.status === 200){
            yield put(deleteMailInfoSuccess(response,id));
        }else{
            yield put(deleteMailInfoFail(response));
        }
    } catch (error) {
        yield put(deleteMailInfoFail(error));
    }
}

const deleteMailInfo = (id) => del(`${url.DELETE_MAIL_INFO}/${id}`);

function* onAddNewMailInfo({ payload: operationDefinition }) {
    try {
        const response = yield call(addNewMailInfo, operationDefinition);
        yield put(addMailInfoSuccess(response));
    } catch (error) {
        yield put(addMailInfoFail(error));
    }
}

const addNewMailInfo = (operationDefinition) => post(url.ADD_NEW_MAIL_INFO,operationDefinition);

function* mailInfoSaga() {
    yield takeEvery(GET_MAIL_INFOS, fetchMailInfos);
    yield takeEvery(ADD_NEW_MAIL_INFO, onAddNewMailInfo);
    yield takeEvery(UPDATE_MAIL_INFO, onUpdateMailInfo);
    yield takeEvery(DELETE_MAIL_INFO, onDeleteMailInfo);
}

export default mailInfoSaga;
