import {
  ADD_NEW_PATIENT_REMINDER,
  ADD_PATIENT_REMINDER_FAIL,
  ADD_PATIENT_REMINDER_SUCCESS,
  DELETE_PATIENT_REMINDER,
  DELETE_PATIENT_REMINDER_FAIL,
  DELETE_PATIENT_REMINDER_SUCCESS,
  GET_PATIENT_REMINDER_COUNT,
  GET_PATIENT_REMINDER_COUNT_FAIL,
  GET_PATIENT_REMINDER_COUNT_SUCCESS,
  GET_PATIENT_REMINDER_CRITERIA,
  GET_PATIENT_REMINDER_CRITERIA_FAIL,
  GET_PATIENT_REMINDER_CRITERIA_SUCCESS,
  GET_PATIENT_REMINDERS,
  GET_PATIENT_REMINDERS_FAIL,
  GET_PATIENT_REMINDERS_SUCCESS,
  GET_TODAY_PATIENT_REMINDERS,
  GET_TODAY_PATIENT_REMINDERS_FAIL,
  GET_TODAY_PATIENT_REMINDERS_SUCCESS,
  GET_USER_PATIENT_REMINDERS,
  GET_USER_PATIENT_REMINDERS_FAIL,
  GET_USER_PATIENT_REMINDERS_SUCCESS,
  UPDATE_PATIENT_REMINDER,
  UPDATE_PATIENT_REMINDER_FAIL,
  UPDATE_PATIENT_REMINDER_SUCCESS
} from "./actionTypes";

export const getPatientReminders = () => ({
  type: GET_PATIENT_REMINDERS
});

export const getPatientRemindersSuccess = reminders => ({
  type: GET_PATIENT_REMINDERS_SUCCESS,
  payload: reminders
});

export const getPatientRemindersFail = error => ({
  type: GET_PATIENT_REMINDERS_FAIL,
  payload: error
});

export const addNewPatientReminder = reminder => ({
  type: ADD_NEW_PATIENT_REMINDER,
  payload: reminder
});

export const addPatientReminderSuccess = reminder => ({
  type: ADD_PATIENT_REMINDER_SUCCESS,
  payload: reminder
});

export const addPatientReminderFail = error => ({
  type: ADD_PATIENT_REMINDER_FAIL,
  payload: error
});

export const updatePatientReminder = reminder => ({
  type: UPDATE_PATIENT_REMINDER,
  payload: reminder
});

export const updatePatientReminderSuccess = reminder => ({
  type: UPDATE_PATIENT_REMINDER_SUCCESS,
  payload: reminder
});

export const updatePatientReminderFail = error => ({
  type: UPDATE_PATIENT_REMINDER_FAIL,
  payload: error
});

export const deletePatientReminder = id => ({
  type: DELETE_PATIENT_REMINDER,
  id
});

export const deletePatientReminderSuccess = (reminder,req) => ({
  type: DELETE_PATIENT_REMINDER_SUCCESS,
  payload: reminder,
  req:req,
});

export const deletePatientReminderFail = error => ({
  type: DELETE_PATIENT_REMINDER_FAIL,
  payload: error
});

export const getUserPatientReminders = id => ({
  type: GET_USER_PATIENT_REMINDERS,
  id
});

export const getUserPatientRemindersSuccess = reminder => ({
  type: GET_USER_PATIENT_REMINDERS_SUCCESS,
  payload: reminder
});

export const getUserPatientRemindersFail = error => ({
  type: GET_USER_PATIENT_REMINDERS_FAIL,
  payload: error
});

export const getTodayPatientReminders = () => ({
  type: GET_TODAY_PATIENT_REMINDERS
});

export const getTodayPatientRemindersSuccess = reminder => ({
  type: GET_TODAY_PATIENT_REMINDERS_SUCCESS,
  payload: reminder
});

export const getTodayPatientRemindersFail = error => ({
  type: GET_TODAY_PATIENT_REMINDERS_FAIL,
  payload: error
});

export const getPatientReminderCriteria = reminder => ({
  type: GET_PATIENT_REMINDER_CRITERIA,
  payload: reminder
});

export const getPatientReminderCriteriaSuccess = reminder => ({
  type: GET_PATIENT_REMINDER_CRITERIA_SUCCESS,
  payload: reminder
});

export const getPatientReminderCriteriaFail = error => ({
  type: GET_PATIENT_REMINDER_CRITERIA_FAIL,
  payload: error
});

export const getPatientReminderCount = () => ({
  type: GET_PATIENT_REMINDER_COUNT
});

export const getPatientReminderCountSuccess = count => ({
  type: GET_PATIENT_REMINDER_COUNT_SUCCESS,
  payload: count
});

export const getPatientReminderCountFail = error => ({
  type: GET_PATIENT_REMINDER_COUNT_FAIL,
  payload: error
});