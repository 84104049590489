
/* REMINDERS */
export const GET_REMINDERS = "GET_REMINDERS"
export const GET_REMINDERS_SUCCESS = "GET_REMINDERS_SUCCESS"
export const GET_REMINDERS_FAIL = "GET_REMINDERS_FAIL"

/**
 * add REMINDER
 */
export const ADD_NEW_REMINDER = "ADD_NEW_REMINDER"
export const ADD_REMINDER_SUCCESS = "ADD_REMINDER_SUCCESS"
export const ADD_REMINDER_FAIL = "ADD_REMINDER_FAIL"

/**
 * Edit REMINDER
 */
export const UPDATE_REMINDER = "UPDATE_REMINDER"
export const UPDATE_REMINDER_SUCCESS = "UPDATE_REMINDER_SUCCESS"
export const UPDATE_REMINDER_FAIL = "UPDATE_REMINDER_FAIL"

/**
 * Delete REMINDER
 */
export const DELETE_REMINDER = "DELETE_REMINDER"
export const DELETE_REMINDER_SUCCESS = "DELETE_REMINDER_SUCCESS"
export const DELETE_REMINDER_FAIL = "DELETE_REMINDER_FAIL"

/**
 * Lead REMINDER
 */
export const GET_LEAD_REMINDERS = "GET_LEAD_REMINDERS"
export const GET_LEAD_REMINDERS_SUCCESS = "GET_LEAD_REMINDERS_SUCCESS"
export const GET_LEAD_REMINDERS_FAIL = "GET_LEAD_REMINDERS_FAIL"


/**
 * Lead REMINDER
 */
export const GET_USER_REMINDERS = "GET_USER_REMINDERS"
export const GET_USER_REMINDERS_SUCCESS = "GET_USER_REMINDERS_SUCCESS"
export const GET_USER_REMINDERS_FAIL = "GET_USER_REMINDERS_FAIL"

/**
 * Today REMINDER
 */
export const GET_TODAY_REMINDERS = "GET_TODAY_REMINDERS"
export const GET_TODAY_REMINDERS_SUCCESS = "GET_TODAY_REMINDERS_SUCCESS"
export const GET_TODAY_REMINDERS_FAIL = "GET_TODAY_REMINDERS_FAIL"

/**
 * REMINDER CRITERIA
 */
export const GET_REMINDER_CRITERIA = "GET_REMINDER_CRITERIA"
export const GET_REMINDER_CRITERIA_SUCCESS = "GET_REMINDER_CRITERIA_SUCCESS"
export const GET_REMINDER_CRITERIA_FAIL = "GET_REMINDER_CRITERIA_FAIL"

/**
 * REMINDER COUNT
 */
export const GET_REMINDER_COUNT = "GET_REMINDER_COUNT"
export const GET_REMINDER_COUNT_SUCCESS = "GET_REMINDER_COUNT_SUCCESS"
export const GET_REMINDER_COUNT_FAIL = "GET_REMINDER_COUNT_FAIL"