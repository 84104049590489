import { call, put, takeEvery } from "redux-saga/effects";

// Leads Redux States
import {
  GET_LEAD_COUNTS_BY_RESOURCE,
  GET_LEAD_COUNTS_BY_SEGMENT,
  GET_LEAD_REPORTS_BY_USER
} from "../../helpers/url_helper";
import { get } from "../../helpers/api_helper";
import {
  getLeadCountsReportByResourceFail,
  getLeadCountsReportByResourceSuccess,
  getLeadCountsReportBySegmentFail, getLeadCountsReportBySegmentSuccess,
  getLeadReportByUserFail,
  getLeadReportByUserSuccess
} from "./actions";
import {LEAD_COUNTS_BY_RESOURCE, LEAD_COUNTS_BY_SEGMENT, LEAD_REPORT_BY_USER} from "./actionTypes";

function* onGetLeadReportsByUser() {
  try {
    const response = yield call(getLeadReportsByUser);
    yield put(getLeadReportByUserSuccess(response));
  } catch (error) {
    yield put(getLeadReportByUserFail(error));
  }
}

const getLeadReportsByUser = () => get(GET_LEAD_REPORTS_BY_USER);

function* onGetLeadCountsReportsBySegment() {
  try {
    const response = yield call(getLeadCountsReportBySegment);
    yield put(getLeadCountsReportBySegmentSuccess(response));
  } catch (error) {
    yield put(getLeadCountsReportBySegmentFail(error));
  }
}

const getLeadCountsReportBySegment = () => get(GET_LEAD_COUNTS_BY_SEGMENT);

function* onGetLeadCountsReportsByResource() {
  try {
    const response = yield call(getLeadCountsReportByResource);
    yield put(getLeadCountsReportByResourceSuccess(response));
  } catch (error) {
    yield put(getLeadCountsReportByResourceFail(error));
  }
}

const getLeadCountsReportByResource = () => get(GET_LEAD_COUNTS_BY_RESOURCE);

function* reportSaga() {
  yield takeEvery(LEAD_REPORT_BY_USER, onGetLeadReportsByUser);
  yield takeEvery(LEAD_COUNTS_BY_SEGMENT, onGetLeadCountsReportsBySegment);
  yield takeEvery(LEAD_COUNTS_BY_RESOURCE, onGetLeadCountsReportsByResource);
}

export default reportSaga;
