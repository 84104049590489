import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess } from "./actions";
import { post } from "../../../helpers/api_helper";
import { get } from "../../../helpers/axios_with_headers";
import * as url from "../../../helpers/url_helper";
import useAuth from "../../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { isBefore } from "date-fns";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      username: user.username,
      password: user.password
    });
    localStorage.setItem("authUser", JSON.stringify(response));

    const { setAuthUser, authUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard";
    setAuthUser({ response });
    navigate(from, { replace: true });
    get(url.UNPAID_PAYMENTS).then(res => {
      if (res.status === 200 && res.data.length > 0) {
        const maxDate = new Date(Math.max(...res.data.map(e => new Date(e.paymentDueDate))));
        if (isBefore(maxDate, new Date())) {
          history.push("/locked");
        } else {
          if (response.role.name === "ROLE_ADMIN"){
            history.push("/guard-payment");
          }else{
            history.push("/dashboard");
          }
        }
      }
    });
    if (response.contracts.length > 0 || response.passwordChangeRequired === true) {
      history.push("/guard");
    } else {
      if (response.contracts.length > 0 || response.passwordChangeRequired === true) {
        history.push({
        pathname: "/guard",
        state: {
          passwordChangeRequired: response.passwordChangeRequired,
          termsContract: response.contracts && response.contracts[response.contracts.findIndex(contract => contract.contractType === "TERMS_CONDITIONS")],
          policyContract: response.contracts && response.contracts[response.contracts.findIndex(contract => contract.contractType === "PRIVACY_POLICY")],
          user: response.id
        }
      });
      } else {
        history.push("/dashboard");
      }
    }
    yield put(loginSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

const postJwtLogin = data => post(url.LOGIN, data);

function* logoutUser({ payload: { history } }) {
  try {
    const { authUser, setAuthUser } = useAuth();
    const user = JSON.parse(localStorage.getItem("authUser"));
    yield call(postLogout, {
      username: user.username
    });
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    setAuthUser({});
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

const postLogout = data => post(url.LOGOUT, data);

function* socialLogin({ payload: { data, history, type } }) {
  try {
    const response = yield call(postSocialLogin, data);
    localStorage.setItem("authUser", JSON.stringify(response));
    yield put(loginSuccess(response));
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
    history.push("/dashboard");
  }
}

const postSocialLogin = data => post(url.FB_LOGIN, data);

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
}

export default authSaga;
