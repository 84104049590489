import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../hooks/withRouter";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";
import {
  addNewReference as onAddNewReference,
  deleteReference as onDeleteReference,
  getReferences as onGetReferences,
  updateReference as onUpdateReference
} from "store/reference/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Code, Description, Name } from "./referenceCol";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import Select from "react-select";

const Reference = props => {

  //meta title
  document.title = `CRM | ${props.t("References")}`;

  const dispatch = useDispatch();

  const { references, error } = useSelector(state => ({
    references: state.Reference.references,
    error: state.Reference.error
  }));

  const statusOptions = [
    { label: props.t("ACTIVE"), value: "ACTIVE" },
    { label: props.t("PASSIVE"), value: "PASSIVE" }];

  const [modal, setModal] = useState(false);
  const [referenceList, setReferenceList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [reference, setReference] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");


  // validation
  const validation = {
    values: {
      name: (reference && reference.name) || "",
      code: (reference && reference.code) || "",
      status: (reference && reference.status) || null,
      description: (reference && reference.description) || ""
    },
    handleSubmit: (values) => {
      if (isEdit) {
        const updateReference = {
          id: reference ? reference.id : 0,
          name: values.name,
          code: values.code,
          status: values.status,
          description: values.description
        };
        // update reference
        dispatch(onUpdateReference(updateReference));
      } else {
        const newReference = {
          name: values["name"],
          code: values["code"],
          status: values["status"],
          description: values["description"]
        };
        // save new reference
        dispatch(onAddNewReference(newReference));
      }
      toggle();
    }
  };

  const handleReferenceClick = arg => {
    const reference = arg;

    setReference({
      id: reference.id,
      name: reference.name,
      code: reference.code,
      status: reference.status,
      description: reference.description
    });

    setIsEdit(true);
    toggle();
  };

  // Customber Column
  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        Header: "İsim",
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Code")}`,
        Header: "Kod",
        accessor: "code",
        filterable: true,
        Cell: (cellProps) => {
          return <Code {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Açıklama",
        accessor: "description",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Status")}`,
        Header: "status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          return <Code {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const referenceData = cellProps.row.original;
                  handleReferenceClick(referenceData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const referenceData = cellProps.row.original;
                  onClickDelete(referenceData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setReference(null);
    } else {
      setModal(true);
    }
  };

  //delete reference
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (reference) => {
    setReference(reference);
    setDeleteModal(true);
  };

  const handleDeleteReference = () => {
    if (reference.id) {
      dispatch(onDeleteReference(reference.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetReferences());
      setFirstCall(false);
    }
  }, [dispatch, references]);

  useEffect(() => {
    setReferenceList(references);
  }, [references]);

  useEffect(() => {
    if (!isEmpty(references)) {
      setReferenceList(references);
    }
  }, [references]);

  const handleReferenceClicks = () => {
    setReferenceList("");
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetReferences());
  };

  const handleChange = (e) => {
    setReference({
      ...reference,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  const onChangeInStatusSelect = event => {
    setReference({
      ...reference,
      ["status"]: event.value
    });
  };

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteReference}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("References")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={references}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleReferenceClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Name")}</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.name || ""}
                                invalid={!validation.values.name}
                                required={true}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Code")}</Label>
                              <Input
                                name="code"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.code || ""}
                                invalid={!validation.values.code}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Description")}</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.description || ""}
                                invalid={!validation.values.description}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Status")} <i
                                className="mdi mdi-alert-circle-outline error me-1" /></Label>
                              <Select
                                value={{label:validation.values.status || "" ,value: validation.values.status || null} }
                                onChange={(e) => {
                                  onChangeInStatusSelect(e);
                                }}
                                options={statusOptions}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-lead"
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
Reference.propTypes = {
  references: PropTypes.array,
  onGetReferences: PropTypes.func,
  onAddNewReference: PropTypes.func,
  onDeleteReference: PropTypes.func,
  onUpdateReference: PropTypes.func
};

export default withRouter(withTranslation()(Reference));
