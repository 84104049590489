import React from "react";

const id = (cell) => {
  return cell.value ? cell.value : "";
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

const Currency = (cell) => {
  return cell.value ? cell.value : "";
};

const Description = (cell) => {
  return cell.value ? cell.value : "";
};

const DefaultPrice = (cell) => {
  return cell.value ? cell.value : 0;
};

const TotalPrice = (cell) => {
  return cell.value ? cell.value : 0;
};

export {
  id,
  Name,
  Description,
  DefaultPrice,
  Currency,
  TotalPrice
};