import {
  ADD_MAIL_INFO_FAIL,
  ADD_MAIL_INFO_SUCCESS,
  DELETE_MAIL_INFO_FAIL,
  DELETE_MAIL_INFO_SUCCESS,
  GET_MAIL_INFOS_FAIL,
  GET_MAIL_INFOS_SUCCESS,
  UPDATE_MAIL_INFO_FAIL,
  UPDATE_MAIL_INFO_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  mailInfos: [],
  error: {},
  success: false
};

const MailInfo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MAIL_INFOS_SUCCESS:
      return {
        ...state,
        mailInfos: action.payload
      };

    case GET_MAIL_INFOS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_MAIL_INFO_SUCCESS:
      return {
        ...state,
        mailInfos: [...state.mailInfos, action.payload],
        success: true
      };

    case ADD_MAIL_INFO_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_MAIL_INFO_SUCCESS:
      return {
        ...state,
        mailInfos: state.mailInfos.map(mailInfo =>
          mailInfo.id.toString() === action.payload.id.toString()
            ? { mailInfo, ...action.payload }
            : mailInfo
        ),
        success: true
      };

    case UPDATE_MAIL_INFO_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_MAIL_INFO_SUCCESS:
      return {
        ...state,
        mailInfos: state.mailInfos.filter(
          mailInfo => mailInfo.id.toString() !== action.req.toString()
        )
      };

    case DELETE_MAIL_INFO_FAIL:
      return {
        ...state,
        error: action.payload
      };
    default:
      return {
        ...state,
        success: false,
          error: {}
      };
  }
};

export default MailInfo;
