import { call, put, takeEvery } from "redux-saga/effects";

// UserWorkSchedules Redux States
import {
    ADD_NEW_USER_WORK_SCHEDULE,
    DELETE_USER_WORK_SCHEDULE,
    GET_USER_WORK_SCHEDULES,
    GET_USER_WORK_SCHEDULE_BY_ID,
    UPDATE_USER_WORK_SCHEDULE
} from "./actionTypes";

import {
    addUserWorkScheduleFail,
    addUserWorkScheduleSuccess,
    deleteUserWorkScheduleFail,
    deleteUserWorkScheduleSuccess, getUserWorkScheduleByIdFail, getUserWorkScheduleByIdSuccess,
    getUserWorkSchedulesFail,
    getUserWorkSchedulesSuccess,
    updateUserWorkScheduleFail,
    updateUserWorkScheduleSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";
import { getUserProfileFail, getUserProfileSuccess } from "../users/actions";

function* fetchUserWorkSchedules() {
  try {
    const response = yield call(getUserWorkSchedules);
    yield put(getUserWorkSchedulesSuccess(response));
  } catch (error) {
    yield put(getUserWorkSchedulesFail(error));
  }
}

const getUserWorkSchedules = () => get(url.WORK_SCHEDULE_API);

function* onUpdateUserWorkSchedule({ payload: userWorkSchedule }) {
  try {
    const response = yield call(updateUserWorkSchedule, userWorkSchedule);
    yield put(updateUserWorkScheduleSuccess(response));
  } catch (error) {
    yield put(updateUserWorkScheduleFail(error));
  }
}

const updateUserWorkSchedule = (userWorkSchedule) => post(url.WORK_SCHEDULE_API, userWorkSchedule);

function* onDeleteUserWorkSchedule({ id }) {
  try {
    const response = yield call(deleteUserWorkSchedule, id);
    if (response.status === 200) {
      yield put(deleteUserWorkScheduleSuccess(response, id));
    } else {
      yield put(deleteUserWorkScheduleFail(response));
    }
  } catch (error) {
    yield put(deleteUserWorkScheduleFail(error));
  }
}

const deleteUserWorkSchedule = (id) => del(`${url.WORK_SCHEDULE_API}/${id}`);

function* onAddNewUserWorkSchedule({ payload: userWorkSchedule }) {
  try {
    const response = yield call(addNewUserWorkSchedule, userWorkSchedule);

    yield put(addUserWorkScheduleSuccess(response));
  } catch (error) {
    yield put(addUserWorkScheduleFail(error));
  }
}

const addNewUserWorkSchedule = (userWorkSchedule) => post(url.WORK_SCHEDULE_API, userWorkSchedule);

function* fetchUserWorkScheduleById({ payload: id }) {
  try {
    const response = yield call(getUserScheduleById, id);
    yield put(getUserWorkScheduleByIdSuccess(response));
  } catch (error) {
    yield put(getUserWorkScheduleByIdFail(error));
  }
}

const getUserScheduleById = (id) => get(`${url.WORK_SCHEDULE_API_BY_ID}/${id}`);

function* userWorkSchedulesSaga() {
  yield takeEvery(GET_USER_WORK_SCHEDULES, fetchUserWorkSchedules);
  yield takeEvery(ADD_NEW_USER_WORK_SCHEDULE, onAddNewUserWorkSchedule);
  yield takeEvery(UPDATE_USER_WORK_SCHEDULE, onUpdateUserWorkSchedule);
  yield takeEvery(DELETE_USER_WORK_SCHEDULE, onDeleteUserWorkSchedule);
  yield takeEvery(GET_USER_WORK_SCHEDULE_BY_ID, fetchUserWorkScheduleById);
}

export default userWorkSchedulesSaga;
