import {
    GET_LANGUAGES,
    GET_LANGUAGES_FAIL,
    GET_LANGUAGES_SUCCESS,
    ADD_NEW_LANGUAGE,
    ADD_LANGUAGE_SUCCESS,
    ADD_LANGUAGE_FAIL,
    UPDATE_LANGUAGE,
    UPDATE_LANGUAGE_SUCCESS,
    UPDATE_LANGUAGE_FAIL,
    DELETE_LANGUAGE,
    DELETE_LANGUAGE_SUCCESS,
    DELETE_LANGUAGE_FAIL,
} from "./actionTypes"

export const getLanguages = () => ({
    type: GET_LANGUAGES,
})

export const getLanguagesSuccess = languages => ({
    type: GET_LANGUAGES_SUCCESS,
    payload: languages,
})

export const getLanguagesFail = error => ({
    type: GET_LANGUAGES_FAIL,
    payload: error,
})

export const addNewLanguage = language => ({
    type: ADD_NEW_LANGUAGE,
    payload: language,
})

export const addLanguageSuccess = language => ({
    type: ADD_LANGUAGE_SUCCESS,
    payload: language,
})

export const addLanguageFail = error => ({
    type: ADD_LANGUAGE_FAIL,
    payload: error,
})

export const updateLanguage = language => ({
    type: UPDATE_LANGUAGE,
    payload: language,
})

export const updateLanguageSuccess = language => ({
    type: UPDATE_LANGUAGE_SUCCESS,
    payload: language,
})

export const updateLanguageFail = error => ({
    type: UPDATE_LANGUAGE_FAIL,
    payload: error,
})

export const deleteLanguage = id => ({
    type: DELETE_LANGUAGE,
    id,
})

export const deleteLanguageSuccess = (language,req) => ({
    type: DELETE_LANGUAGE_SUCCESS,
    payload: language,
    req:req
})

export const deleteLanguageFail = error => ({
    type: DELETE_LANGUAGE_FAIL,
    payload: error,
})